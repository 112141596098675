import { useDrop } from 'react-dnd';
import { Flex, Paragraph } from 'theme-ui';
import { completeDrag, loadTaskData } from './api';
import TaskButton from './task-button';

const Swimlane = ({ data, state, stateRef, updateState, navigate }) => {
    const [, drop] = useDrop(() => ({
        accept: 'task_button',
        hover: (item, monitor) => {
            updateState({ ...stateRef.current, hoveringOver: data.key });
        },
        drop: async (item) => {
            const firstStatusInTheSwimlane = stateRef.current?.organisation?.default_visualisation?.swimlanes?.find(
                (x) => x.key === data.key
            )?.statuses?.[0];

            if (firstStatusInTheSwimlane !== item.status) {
                if (
                    ['done', 'closed']?.includes(firstStatusInTheSwimlane) &&
                    !!item?.subtasks?.find((y) => y.status !== 'done')
                ) {
                    return updateState({
                        ...stateRef.current,
                        subtasksIncompleteAlert: true,
                    });
                }

                await completeDrag(
                    { ...stateRef.current, task: { id: item.id }, hoveringOver: null },
                    updateState,
                    item.id,
                    firstStatusInTheSwimlane,
                    loadTaskData
                );
            } else {
                updateState({ ...stateRef.current, hoveringOver: null });
            }
        },
    }));

    return (
        <Flex
            ref={drop}
            sx={{
                flexDirection: 'column',
                borderRadius: 10,
                filter: 'drop-shadow(0 0 0.18rem #000)',
                backgroundColor: stateRef.current.hoveringOver === data.key ? 'lightGrey' : '#FFF',
                minHeight: '85vh',
                padding: '20px',
                width: 320,
                mr: 30,
                justifyContent: 'flex-start',
                alignSelf: 'flex-start',
            }}
        >
            <Paragraph
                sx={{
                    fontSize: 18,
                    fontWeight: '600',
                    alignSelf: 'flex-start',
                    textAlign: 'left',
                    color: data.color || 'auto',
                }}
            >
                {data.label} {`(${data.tasks?.length || 0})`}
            </Paragraph>

            <Flex
                sx={{
                    alignSelf: 'center',
                    mt: 20,
                    borderBottom: '1px solid #CCC',
                    mb: 20,
                    height: '2px',
                    width: '100%',
                }}
            />

            {data.tasks.map((task) => (
                <Flex sx={{ flexDirection: 'column' }} key={`task_${task.id}_${task.status}`}>
                    <TaskButton
                        key={`task_${task.id}_${task.status}`}
                        data={task}
                        statuses={data.statuses}
                        state={state}
                        updateState={updateState}
                        navigate={navigate}
                        organisation={state?.organisation}
                    />
                    <Flex sx={{ height: 20 }} />
                </Flex>
            ))}
        </Flex>
    );
};

export default Swimlane;
