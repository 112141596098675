/* eslint-disable prefer-const */
import { Flex, Paragraph, Button, Alert, Box } from 'theme-ui';
import axios from 'axios';
import jsonLogic from 'json-logic-js';
import externalApis from './external-apis';
import { MultipleInputs, DOB, SingleInput, Information } from './inputs';
import { Address } from './address';
import { trackLoggedInEvent } from './utils/analytics';
import { CountrySelect, AdditionalNationality, Dropdown } from './selects';
import { validateSelectInput, validateDOB, validateTextInput, validateInputIsNotEmpty } from './validators';
import Spinner from './spinner';

export const hydrateUserData = (user: any) => {
    const dobSplit = user?.date_of_birth?.split('-') || [];

    return {
        dob: user?.date_of_birth || '',
        dob_dd: dobSplit[2] || '',
        dob_mm: dobSplit[1] || '',
        dob_yyyy: dobSplit[0] || '',
        nationality: user?.nationality || '',
        street: user?.address?.street || '',
        sub_street: user?.address?.sub_street || '',
        town: user?.address?.town || '',
        post_code: user?.address?.post_code || '',
        state: user?.address?.state || '',
        country: user?.address?.country || '',
        first_name: user?.first_name || '',
        middle_names: user?.middle_names || '',
        last_name: user?.last_name || '',
        has_other_nationality: user?.has_other_nationality || false,
        other_nationality: user?.other_nationality || '',
        country_of_birth: user?.country_of_birth || '',
        place_of_birth: user?.place_of_birth || '',
        occupation: user?.occupation || '',
        monthly_net_income: user?.monthly_net_income || '',
        annual_net_income: user?.annual_net_income || '',
        intended_amount_to_deposit_as_collateral: user?.intended_amount_to_deposit_as_collateral || '',
        source_of_collateral: user?.source_of_collateral || '',
        repayment_source_of_funds: user?.repayment_source_of_funds || '',
    };
};

export const saveFlag = async (body: any, url: any) => {
    try {
        await axios({
            url,
            method: 'PUT',
            data: body,
        });
    } catch (e) {
        console.log('swallow ');
    }
};

export const getOnfidoToken = async () =>
    await axios({
        url: `${process.env.REACT_APP_TRANSACTIONS_API}/kyc/sdk-token`,
        method: 'POST',
        data: {
            referer: process.env.REACT_APP_ONFIDO_REFERER,
        },
    });

export const createOnfidoChecks = async (checks: any) => {
    try {
        return await axios({
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/kyc/checks`,
            method: 'POST',
            data: {
                checks,
            },
        });
    } catch (e) {
        return { data: { error: true } };
    }
};

export const pollForOnfidoChecks = async () => {
    try {
        return await axios({
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/users`,
        });
    } catch (e) {
        return { data: { error: true } };
    }
};

export const checkAddressAutoVerified = async () => {
    let addressVerificationResult = false;
    try {
        for (let i = 0; i < 20; i += 1) {
            const { data: getUserResponse } = await pollForOnfidoChecks();

            if (getUserResponse?.kyc?.address_verification === 'PASS') {
                addressVerificationResult = true;
                break;
            }
            if (
                getUserResponse?.kyc?.address_verification === 'FAIL' ||
                getUserResponse?.kyc?.address_verification === null
            ) {
                addressVerificationResult = false;
                break;
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
        }
    } catch (e) {
        return false;
    }
    return addressVerificationResult;
};

export const startOnFido = async (currentState: any, updateState: any, checksToPerform: any, useOnfidoPoa: any) => {
    if (typeof window !== 'undefined') {
        try {
            updateState({ ...currentState, loading: true });
            const { data } = await getOnfidoToken();
            const { sdk_token } = data;

            const allowDrivingLicence = currentState.countries.find(
                (item: any) => item.code === currentState.nationality
            )?.driving_licence_accepted;

            const countryOfResidence = currentState.countries.find((item: any) => item.code === currentState.country); // @ts-ignore

            const steps: any = [];

            if (useOnfidoPoa) {
                steps.push('poa');
            } else {
                steps.push({
                    type: 'document',
                    options: {
                        documentTypes: {
                            driving_licence: allowDrivingLicence,
                            passport: {
                                country: currentState.country,
                            },
                            national_identity_card: true,
                            residence_permit: false,
                        },
                        forceCrossDevice: true,
                    },
                });
                steps.push({
                    type: 'face',
                    options: {
                        requestedVariant: 'video',
                        photoCaptureFallback: false,
                    },
                });
            }

            // @ts-ignore
            // REMOVED ONFIDO SDK CALL
            // import('onfido-sdk-ui').then(({ init }) => {
            //     init({
            //         // useModal: false,
            //         token: sdk_token,
            //         onComplete: async (data: any) => {
            //             console.log('complete ', data);
            //             await createOnfidoChecks(checksToPerform);
            //             let userAutoVerified = false;
            //             if (!useOnfidoPoa) {
            //                 updateState({ ...currentState, loading: false, checkingAddress: true });
            //                 userAutoVerified = await checkAddressAutoVerified();
            //             }

            //             if (userAutoVerified || useOnfidoPoa) {
            //                 // we have just (completed POA || address auto verified) so now onto Questions 2
            //                 updateState({
            //                     ...currentState,
            //                     loading: false,
            //                     checkingAddress: false,
            //                     onfidoPOA: false,
            //                     onfidoManualPOA: false,
            //                     questionsComplete: false,
            //                     questionsPart2: true,
            //                 });
            //             } else {
            //                 // onfido completed && address NOT auto verified so now onto POA
            //                 window.location.assign('/library?kyc=poa');
            //             }

            //             trackLoggedInEvent('OnfidoComplete', {});
            //         },
            //         onError: (e: any) => {
            //             updateState({
            //                 ...currentState,
            //                 error: 'failed to complete document upload',
            //                 onfidoLoaded: false,
            //                 loading: false,
            //             });

            //             trackLoggedInEvent('OnfidoError', {
            //                 errorMessage: e.message,
            //             });
            //         },
            //         onUserExit: (userExitCode: any) => {
            //             console.log('user has exited steps ', userExitCode);
            //         },
            //         customUI: {
            //             colorBackgroundSurfaceModal: '#ffffff',
            //             borderWidthSurfaceModal: '0px',
            //         },
            //         steps: [
            //             {
            //                 type: 'welcome',
            //                 options: {
            //                     title: "Let's verify your identity",
            //                 },
            //             },
            //             ...steps,

            //             // @ts-ignore
            //             'complete',
            //         ],
            //     });
            // });
            updateState({ ...currentState, onfidoLoaded: true, loading: false });
        } catch (err) {
            // @ts-ignore
            const errorMsg = err?.message || 'failed to load KYC';
            updateState({ ...currentState, error: errorMsg, onfidoLoaded: false, loading: false });
        }
    }
};

const runValidators = (
    currentState: any,
    errorState: any,
    updateErrorState: any,
    validators: any,
    saveAs: any,
    errorKey: any
) => {
    let errors = 0;
    if (!validators || validators?.length < 1) {
        updateErrorState({ ...errorState, [errorKey]: false });
        return 0;
    }

    for (const validator of validators) {
        const { type, errorMessage } = validator;
        const args = validator?.args || [];

        if (type === 'checkInputLength') {
            if (!validateTextInput(currentState[saveAs], ...args)) {
                errors++;
            }
        }

        if (type === 'notEmpty') {
            if (!validateInputIsNotEmpty(currentState[saveAs])) {
                errors++;
            }
        }

        if (type === 'country-select') {
            if (!validateSelectInput(currentState[saveAs])) {
                errors++;
            }
        }

        if (type === 'additional-nationality') {
            if (currentState.OtherNationalityRadio) {
                if (!validateSelectInput(currentState.other_nationality)) {
                    errors++;
                }
            }
        }

        if (type === 'address') {
            if (!validateTextInput(currentState.street, ...args)) {
                errors++;
            }
            if (!validateTextInput(currentState.town, ...args)) {
                errors++;
            }
            if (!validateTextInput(currentState.post_code, 4, 12)) {
                errors++;
            }
            if (!validateSelectInput(currentState.country)) {
                errors++;
            }
        }

        if (type === 'dob') {
            const { dob_dd, dob_mm, dob_yyyy } = currentState;
            if (!validateTextInput(currentState.dob_dd, 2, 2)) {
                errors++;
            }
            if (!validateTextInput(currentState.dob_mm, 2, 2)) {
                errors++;
            }
            if (!validateTextInput(currentState.dob_yyyy, 4, 4)) {
                errors++;
            }
            if (!errors) {
                const dob = `${dob_dd}-${dob_mm}-${dob_yyyy}`;
                if (!validateDOB(dob)) {
                    errors++;
                }
            }
        }

        if (type === 'dropdown') {
            if (!validateSelectInput(currentState[saveAs])) {
                errors++;
            }
        }

        if (errors > 0) {
            updateErrorState({ ...errorState, [errorKey]: true, msg: errorMessage });
            return errors;
        } else {
            updateErrorState({ ...errorState, [errorKey]: false, msg: '' });
            return errors;
        }
    }
};

const validateQuestion = (currentQuestion: any, errorState: any, updateErrorState: any, currentState: any) => {
    const { type, errorKey, inputs, saveAs, validators } = currentQuestion;

    if (type === 'multipleInputs') {
        const filteredInputs = inputs?.filter((item: any) => item?.required);
        for (const item of filteredInputs) {
            const errorsPresent =
                runValidators(currentState, errorState, updateErrorState, item?.validators, item?.saveAs, errorKey) ||
                0;

            if (errorsPresent > 0) {
                return false;
            }
        }
        return true;
    } else {
        const errorsPresent =
            runValidators(currentState, errorState, updateErrorState, validators, saveAs, errorKey) || 0;
        return errorsPresent < 1;
    }
};

const prepareAndSaveData = async (
    currentQuestion: any,
    currentState: any,
    saveAPIUrl = `${process.env.REACT_APP_TRANSACTIONS_API}/users`
) => {
    const { type, inputs, saveAs } = currentQuestion;

    let body = {};
    if (type === 'multipleInputs') {
        inputs?.map((item: any) => {
            if (currentState[item?.saveAs]) {
                // @ts-ignore
                body[item?.saveAs] = currentState[item?.saveAs];
            }
        });
    } else if (type === 'dob') {
        const { dob_dd, dob_mm, dob_yyyy } = currentState;
        body = { dob: `${dob_yyyy}-${dob_mm}-${dob_dd}` };
    } else if (type === 'additional-nationality') {
        if (currentState?.OtherNationalityRadio) {
            body = { other_nationality: currentState?.other_nationality, has_other_nationality: true };
        } else {
            body = { has_other_nationality: false };
        }
    } else if (type === 'dropdown') {
        body = { [saveAs]: currentState[saveAs] };
    } else if (type === 'address') {
        body = {
            address: {
                street: currentState?.street || '',
                sub_street: currentState?.sub_street || '',
                town: currentState?.town || '',
                post_code: currentState?.post_code || '',
                state: currentState?.state || '',
                country: currentState?.country || '',
            },
        };
    } else {
        body = { [saveAs]: currentState[saveAs] };
    }

    await saveFlag(body, saveAPIUrl);

    return;
};

const QuestionButtons = ({
    currentState,
    updateState,
    currentIndex,
    currentQuestion,
    errorState,
    updateErrorState,
    finalQuestions,
    updateLoading,
    questionType,
}: any) => (
    <Flex
        sx={{
            width: '100%',
            height: '60px',
            bottom: '0',
            position: 'absolute',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            '@media screen and (max-width: 550px)': {
                position: 'fixed',
                bottom: '0px',
                width: '340px',
                background: 'white',
                height: '130px',
                alignItems: 'flex-start',
            },
        }}
    >
        <Button
            sx={{
                width: '200px',
                opacity: currentState?.currentQuestionId === 0 ? 0.3 : 1,
                '@media screen and (max-width: 550px)': {
                    width: '145px',
                },
            }}
            variant="secondary"
            onClick={async () => {
                updateLoading(true);
                setTimeout(() => {
                    updateLoading(false);
                }, 600);
                if (currentState?.currentQuestionId !== 0) {
                    updateState({
                        ...currentState,
                        currentQuestionId: currentIndex - 1,
                    });
                    updateErrorState({ ...errorState, msg: '' });
                    trackLoggedInEvent('KYCPageChangeUsingButton', {
                        questionId: currentState?.currentQuestionId,
                        saveAs: currentQuestion?.saveAs,
                    });
                }
            }}
            data-testid="prev-btn"
        >
            Prev
        </Button>
        <Button
            sx={{
                width: '200px',
                '@media screen and (max-width: 550px)': {
                    width: '145px',
                    ml: '15px',
                },
            }}
            variant="secondary"
            onClick={async () => {
                if (questionType === 'information') {
                    updateState({
                        ...currentState,
                        currentQuestionId: currentIndex + 1,
                    });
                    updateErrorState({ ...errorState, msg: '' });
                } else {
                    updateLoading(true);

                    if (validateQuestion(currentQuestion, errorState, updateErrorState, currentState)) {
                        await prepareAndSaveData(currentQuestion, currentState);
                        const lastIdToUse = finalQuestions
                            ? currentState?.lastQuestionIdFinal
                            : currentState?.lastQuestionId;

                        if (currentState?.currentQuestionId === lastIdToUse) {
                            if (finalQuestions) {
                                updateState({
                                    ...currentState,
                                    questionsComplete: true,
                                    processing: true,
                                    questionsPart2: false,
                                    currentQuestionId: 0,
                                });
                            } else {
                                updateState({
                                    ...currentState,
                                    questionsComplete: true,
                                    currentQuestionId: 0,
                                });
                            }
                        } else {
                            updateState({
                                ...currentState,
                                currentQuestionId: currentIndex + 1,
                            });
                            updateErrorState({ ...errorState, msg: '' });
                        }

                        trackLoggedInEvent('KYCPageChangeUsingButton', {
                            questionId: currentState?.currentQuestionId,
                            saveAs: currentQuestion?.saveAs,
                        });
                    }
                    updateLoading(false);
                }
            }}
            data-testid="next-btn"
        >
            Next
        </Button>
    </Flex>
);

const QuestionButtonsStandalone = ({
    currentState,
    updateState,
    currentIndex,
    currentQuestion,
    errorState,
    updateErrorState,
    finalQuestions,
    updateLoading,
    questionType,
    metaData,
    accountSummary,
    transactions,
    user,
}: any) => (
    <Flex
        sx={{
            width: '100%',
            height: '60px',
            bottom: '0',
            position: 'absolute',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            '@media screen and (max-width: 550px)': {
                position: 'fixed',
                bottom: '0px',
                width: '340px',
                background: 'white',
                height: '130px',
                alignItems: 'flex-start',
            },
        }}
    >
        <Button
            sx={{
                width: '200px',
                opacity: currentState?.currentQuestionId === 0 ? 0.3 : 1,
                '@media screen and (max-width: 550px)': {
                    width: '145px',
                },
            }}
            variant="secondary"
            onClick={async () => {
                updateLoading(true);
                setTimeout(() => {
                    updateLoading(false);
                }, 600);

                const visitedIds = currentState?.questionsVisitedIds;

                if (visitedIds?.length > 0) {
                    const updatedVistedIds = visitedIds.slice(0, -1);
                    const IDtoGoBackTo = updatedVistedIds.at(-1);
                    updateState({
                        ...currentState,
                        currentQuestionId: IDtoGoBackTo,
                        questionsVisitedIds: updatedVistedIds,
                    });
                    updateErrorState({ ...errorState, msg: '' });
                    trackLoggedInEvent('StandaloneDSLPageChangeUsingButton', {
                        questionId: currentState?.currentQuestionId,
                        saveAs: currentQuestion?.saveAs,
                    });
                }
            }}
            data-testid="prev-btn"
        >
            Prev
        </Button>
        <Button
            sx={{
                width: '200px',
                '@media screen and (max-width: 550px)': {
                    width: '145px',
                    ml: '15px',
                },
            }}
            variant="secondary"
            onClick={async () => {
                if (questionType === 'information') {
                    updateState({
                        ...currentState,
                        currentQuestionId: currentIndex + 1,
                    });
                    updateErrorState({ ...errorState, msg: '' });
                } else {
                    updateLoading(true);

                    if (validateQuestion(currentQuestion, errorState, updateErrorState, currentState)) {
                        await prepareAndSaveData(currentQuestion, currentState, metaData?.saveApiToUse);

                        if (currentQuestion?.finalQuestion) {
                            updateState({
                                ...currentState,
                                questionsComplete: true,
                                currentQuestionId: 0,
                            });
                        } else {
                            let nextQuestionId = currentIndex + 1;

                            if (currentQuestion?.nextId) {
                                nextQuestionId = currentQuestion?.nextId;
                            }

                            if (currentQuestion?.jsonLogic) {
                                const { rules, truthyNextId, falsyNextId } = currentQuestion?.jsonLogic || {};
                                const JsonResult = jsonLogic.apply(rules, currentState);

                                nextQuestionId = JsonResult ? truthyNextId : falsyNextId;
                            }

                            if (currentQuestion?.callApi) {
                                const { apiToUse, jsonLogic: jsLogic } = currentQuestion?.callApi || {}; // @ts-ignore
                                const api = externalApis[apiToUse];

                                if (!api) {
                                    console.error(`Api does not exist: ${apiToUse}`);
                                } else {
                                    const apiResponse = await api(currentState, accountSummary, transactions, user);
                                    if (jsLogic) {
                                        const { rules, truthyNextId, falsyNextId } = jsLogic;
                                        const JsonResult = jsonLogic.apply(rules, apiResponse);
                                        nextQuestionId = JsonResult ? truthyNextId : falsyNextId;
                                    }
                                }
                            }

                            const visitedIds = currentState?.questionsVisitedIds;

                            updateState({
                                ...currentState,
                                currentQuestionId: nextQuestionId,
                                questionsVisitedIds: [...visitedIds, nextQuestionId],
                            });

                            updateErrorState({ ...errorState, msg: '' });
                        }

                        trackLoggedInEvent('StandaloneDSLPageChangeUsingButton', {
                            questionId: currentState?.currentQuestionId,
                            saveAs: currentQuestion?.saveAs,
                        });
                    }
                    updateLoading(false);
                }
            }}
            data-testid="next-btn"
        >
            Next
        </Button>
    </Flex>
);

export const QuestionCarouselComponent = ({
    currentState,
    updateState,
    questions,
    overlay,
    updateOverlay,
    errorState,
    updateErrorState,
    finalQuestions,
    currentlyLoading,
    updateLoading,
    standalone,
    metaData,
    accountSummary = {},
    transactions = [],
    user = {},
}: any) => {
    const questionsVisitedIds = currentState?.questionsVisitedIds || [];
    const currentIndex = currentState?.currentQuestionId || 0;
    let currentQuestion = questions[currentIndex];

    if (standalone) {
        const tmpQuestionArray = [...questionsVisitedIds];
        const lastId = tmpQuestionArray.pop() || 0;
        currentQuestion = questions.find((item: any) => {
            return item?.id === `${lastId}`;
        });
    }

    const questionType = currentQuestion?.type || false;

    return (
        <Flex
            sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                '@media screen and (max-width: 550px)': {
                    flexDirection: 'column',
                    width: '340px',
                    mt: '80px',
                    minheight: '400px',
                    overflow: 'scroll',
                },
            }}
        >
            <Flex
                sx={{
                    flex: 1,
                    mb: '70px',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                {currentlyLoading && <Spinner />}
                {questionType && !currentlyLoading && (
                    <Box>
                        <Paragraph
                            sx={{
                                textAlign: 'center',
                                width: '100%',
                                pl: '20px',
                                pr: '20px',
                                mt: '30px',
                                fontSize: '20px',
                                fontWeight: '600',
                                '@media screen and (max-width: 550px)': {
                                    mt: '50px',
                                },
                            }}
                        >
                            {currentQuestion?.question}
                        </Paragraph>
                        {currentQuestion?.subtitle && (
                            <Paragraph sx={{ textAlign: 'center', mt: 1 }}>{currentQuestion?.subtitle}</Paragraph>
                        )}
                    </Box>
                )}

                {questionType === 'information' && !currentlyLoading && (
                    <Information
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                    />
                )}
                {questionType === 'multipleInputs' && !currentlyLoading && (
                    <MultipleInputs
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}

                {questionType === 'input' && !currentlyLoading && (
                    <SingleInput
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}

                {questionType === 'dob' && !currentlyLoading && (
                    <DOB
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}

                {questionType === 'address' && !currentlyLoading && (
                    <Address
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}

                {questionType === 'country-select' && !currentlyLoading && (
                    <CountrySelect
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}

                {questionType === 'additional-nationality' && !currentlyLoading && (
                    <AdditionalNationality
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}

                {questionType === 'dropdown' && !currentlyLoading && (
                    <Dropdown
                        currentQuestion={currentQuestion}
                        currentState={currentState}
                        updateState={updateState}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                    />
                )}
                {errorState?.msg && errorState?.msg !== '' && (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: '-14px',
                            '@media screen and (max-width: 550px)': {
                                mt: '-50px',
                                pb: '40px',
                            },
                        }}
                    >
                        <Alert data-testid="kyc-error" variant="error">
                            {errorState?.msg}
                        </Alert>
                    </Flex>
                )}
                {!questionType && (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Paragraph sx={{ textAlign: 'center', fontSize: '20px', mt: '100px' }}>
                            something went wrong, try again later.
                        </Paragraph>
                        {overlay && (
                            <Button
                                sx={{
                                    mt: 40,
                                    width: '200px',
                                }}
                                variant="secondary"
                                onClick={() =>
                                    updateOverlay({
                                        ...overlay,
                                        componentToRender: 'StartKYC',
                                        show: false,
                                    })
                                }
                                data-testid="close-btn"
                            >
                                Close
                            </Button>
                        )}
                    </Flex>
                )}
            </Flex>
            {questionType && !currentlyLoading && !standalone && (
                <QuestionButtons
                    currentState={currentState}
                    updateState={updateState}
                    currentIndex={currentIndex}
                    currentQuestion={currentQuestion}
                    errorState={errorState}
                    updateErrorState={updateErrorState}
                    finalQuestions={finalQuestions}
                    updateLoading={updateLoading}
                    questionType={questionType}
                />
            )}
            {questionType && !currentlyLoading && standalone && (
                <QuestionButtonsStandalone
                    currentState={currentState}
                    updateState={updateState}
                    currentIndex={currentIndex}
                    currentQuestion={currentQuestion}
                    errorState={errorState}
                    updateErrorState={updateErrorState}
                    finalQuestions={finalQuestions}
                    updateLoading={updateLoading}
                    questionType={questionType}
                    metaData={metaData}
                    accountSummary={accountSummary}
                    transactions={transactions}
                    user={user}
                />
            )}
        </Flex>
    );
};

export const ProgressComponent = ({
    currentState,
    updateState,
    questions,
    overlay,
    updateOverlay,
    errorState,
    updateErrorState,
    updateLoading,
}: any) => {
    const currentIndex = currentState?.currentQuestionId || 0;
    const currentQuestion = questions[currentIndex];
    const questionType = questions[currentIndex]?.type || false;

    return (
        <Flex
            sx={{
                position: 'absolute',
                width: '400px',
                height: '30px',
                top: '17px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {questions.map((item: any, i: any) => {
                const notAllowed = i > currentIndex + 1;
                return (
                    <Box
                        key={`progress-${i}`}
                        sx={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '10px',
                            backgroundColor: i == currentIndex ? 'secondary' : 'lightgrey',
                            cursor: notAllowed ? 'not-allowed' : 'pointer',
                            ml: '5px',
                            mr: '5px',
                        }}
                        onClick={async () => {
                            if (!notAllowed) {
                                if (questionType === 'information') {
                                    updateState({
                                        ...currentState,
                                        currentQuestionId: i,
                                    });
                                    updateErrorState({ ...errorState, msg: '' });
                                } else {
                                    updateLoading(true);

                                    if (validateQuestion(currentQuestion, errorState, updateErrorState, currentState)) {
                                        await prepareAndSaveData(currentQuestion, currentState);
                                        updateState({
                                            ...currentState,
                                            currentQuestionId: i,
                                        });
                                        updateErrorState({ ...errorState, msg: '' });
                                        trackLoggedInEvent('KYCPageChangeUsingProgress', {
                                            questionId: currentState?.currentQuestionId,
                                            saveAs: currentQuestion?.saveAs,
                                        });
                                    }
                                    updateLoading(false);
                                }
                            }
                        }}
                    ></Box>
                );
            })}
        </Flex>
    );
};
