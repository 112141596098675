/** @jsxImportSource theme-ui */
import { Paragraph, Box } from 'theme-ui';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({
    id,
    text,
    place = 'bottom',
    type = 'dark',
    effect = 'float',
    customSx = {},
    positionSx = { position: 'absolute', left: '160px', top: '15px' },
}) => (
    <Box sx={{ width: '50px', height: '50px', ...positionSx }}>
        <Paragraph
            sx={{
                position: 'relative',
                width: '25px',
                height: '25px',
                margin: '6px',
                textAlign: 'center',
                cursor: 'pointer',
                ...customSx,
            }}
            onClick={(e) => e.stopPropagation()}
            data-for={id}
            data-tip={text}
            data-iscapture="true"
        >
            <i
                style={{
                    width: '20px',
                    height: '20px',
                    fontSize: 18,
                    textAlign: 'center',
                    marginTop: '0px',
                    color: 'inherit',
                }}
                className="far fa-info-circle"
            />
        </Paragraph>
        <ReactTooltip className="custom-tooltip" id={id} place={place} type={type} effect={effect} multiline={true} />
    </Box>
);

export default Tooltip;
