import { Button, Flex, Paragraph } from 'theme-ui';
import Overlay from '../overlay/overlay';

const ConfirmDialog = ({ updateState, state, text, stateKey, showOkOnly }) => (
    <Overlay
        size="small"
        updateOverlay={() => updateState({ ...state, [stateKey]: false })}
        embeddedComponent={
            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                <Paragraph sx={{ fontSize: 16, fontWeight: 400, mt: 90, textAlign: 'center', ml: 20, mr: 20 }}>
                    {text}
                </Paragraph>
                {showOkOnly && (
                    <Flex sx={{ width: 150, mt: 50, justifyContent: 'center' }}>
                        <Button
                            sx={{
                                backgroundColor: 'white',
                                color: '#000',
                                border: '1px solid #CCC',
                                width: 70,
                            }}
                            onClick={() => updateState({ ...state, [stateKey]: false })}
                        >
                            OK
                        </Button>
                    </Flex>
                )}
                {!showOkOnly && (
                    <Flex sx={{ width: 150, mt: 50, justifyContent: 'space-between' }}>
                        <Button
                            sx={{
                                backgroundColor: 'white',
                                color: '#000',
                                border: '1px solid #CCC',
                                width: 70,
                            }}
                            onClick={() => updateState({ ...state, [stateKey]: false })}
                        >
                            No
                        </Button>
                        <Button sx={{ backgroundColor: 'primary', width: 70 }} onClick={() => state.confirmCallback()}>
                            Yes
                        </Button>
                    </Flex>
                )}
            </Flex>
        }
    />
);

export default ConfirmDialog;
