const validatePassword = (password: string) => [
    password?.length >= 10,
    !!password?.match(/[0-9]{1,}/),
    !!password?.match(/[a-z]{1,}/),
    !!password?.match(/[A-Z]{1,}/),
];

const passwordFailureReasons = [
    'Must be over 10 characters',
    'Must contain a number',
    'Must contain a lowercase character',
    'Must contain an uppercase character',
];

export const invalidPasswordReasons = (pass: any) =>
    validatePassword(pass)
        .map((item, i) => (item === false ? passwordFailureReasons[i] : null))
        .filter((reasons) => reasons !== null);

export default validatePassword;
