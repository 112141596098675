import { Flex } from 'theme-ui';

const Divider = ({ width = '100%', ml = 0, mt = 20, mb = 20, sx }) => (
    <Flex
        sx={{
            height: '1px',
            width,
            mt,
            mb,
            ml,
            borderBottom: '1px solid #EFEFEF',
            ...(sx || {}),
        }}
    />
);

export default Divider;
