export const checkProgress = (userData: any) => {
    if (!userData?.mfa?.setup && userData?.kyc?.status !== 'verified') {
        return {
            setup: false,
            message: 'Please verify your identity and setup multi factor authentication.',
        };
    }

    if (userData?.kyc?.status !== 'verified') {
        return {
            setup: false,
            message: 'Please verify your identity.',
        };
    }

    if (!userData?.mfa?.setup) {
        return {
            setup: false,
            message: 'Please setup multi factor authentication.',
        };
    }

    return {
        setup: true,
        message: '',
    };
};
