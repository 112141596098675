/* eslint-disable no-undef */
import axios from 'axios';
import { Button, Flex, Input, Paragraph } from 'theme-ui';

const invite = async (state, updateState) => {
    try {
        if (!state.newUserEmailAddress || !state.newUserEmailAddress?.includes('@')) {
            return updateState({ ...state, inviteError: 'Please enter an email address' });
        }
        if (!state.newFirstName) {
            return updateState({ ...state, inviteError: 'Please enter a first name' });
        }
        if (!state.newLastName) {
            return updateState({ ...state, inviteError: 'Please enter a last name' });
        }
        if (!state.newUserEmailAddress || !state.newUserEmailAddress?.includes('@')) {
            return updateState({ ...state, inviteError: 'Please enter an email address' });
        }
        updateState({ ...state, loading: true, inviteError: null, confirmMessage: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/add-user`,
            method: 'POST',
            data: {
                email_address: state.newUserEmailAddress,
                first_name: state.newFirstName,
                last_name: state.newLastName,
            },
        });

        const {
            data: { users, total: usersTotal },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
        });

        updateState({ ...state, loading: false, inviteError: null, confirmMessage: 'Invite sent', users, usersTotal });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            inviteError: e?.response?.data?.message || 'Uh oh, there has been an error inviting this user',
            confirmMessage: null,
        });
    }
};

const InviteUser = ({ state, updateState }) => {
    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph
                sx={{
                    width: '95px',
                    height: '40px',
                    p: '10px 5px',
                    mb: '25px',
                    mt: 20,
                    fontSize: '13px',
                    cursor: 'pointer',
                    border: '1px solid #eaecf0',
                }}
                onClick={async () => {
                    updateState({
                        ...state,
                        userView: 'list',
                        newUserEmailAddress: null,
                        inviteError: null,
                        error: null,
                    });
                }}
            >
                <i
                    style={{
                        marginTop: '0px',
                        fontSize: '12px',
                        marginLeft: '9px',
                        marginRight: '6px',
                    }}
                    className={`fas fa-chevron-left`}
                />{' '}
                Back
            </Paragraph>

            <Paragraph sx={{ mt: 10 }}>Email address</Paragraph>

            <Input
                value={state.newUserEmailAddress}
                onChange={(e) =>
                    updateState({
                        ...state,
                        newUserEmailAddress: e.target.value?.toLowerCase().trim(),
                    })
                }
                sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', mb: 20 }}
            />

            <Paragraph sx={{ mt: 10 }}>First name</Paragraph>

            <Input
                value={state.newFirstName}
                onChange={(e) =>
                    updateState({
                        ...state,
                        newFirstName: e.target.value,
                    })
                }
                sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', mb: 20 }}
            />

            <Paragraph sx={{ mt: 10 }}>Last name</Paragraph>

            <Input
                value={state.newLastName}
                onChange={(e) =>
                    updateState({
                        ...state,
                        newLastName: e.target.value,
                    })
                }
                sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', mb: 20 }}
            />

            <Button
                sx={{
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    color: '#000',
                    fontSize: 14,
                    height: 40,
                    width: 150,
                    mt: 20,
                }}
                onClick={async () => {
                    await invite(state, updateState);
                }}
            >
                Invite
            </Button>

            {state.inviteError && <Paragraph sx={{ mt: 20, color: 'red' }}>{state.inviteError}</Paragraph>}
            {state.confirmMessage && <Paragraph sx={{ mt: 20, color: 'green' }}>{state.confirmMessage}</Paragraph>}
        </Flex>
    );
};

export default InviteUser;
