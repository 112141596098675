/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import { Flex, Paragraph, Button, Input, Box, Image } from 'theme-ui';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import Spinner from '../../spinner';

const setupCode = async (currentState: any, updateState: any) => {
    try {
        updateState({ ...currentState, loading: true });

        const user = await Auth.currentAuthenticatedUser();

        if (!currentState.startAgain && user.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
            return updateState({ ...currentState, view: 'done' });
        }

        const code = await Auth.setupTOTP(user);
        return updateState({ ...currentState, loading: false, code, user });
    } catch (e) {
        return updateState({
            ...currentState,
            loading: false,
            error: 'The code entered does not match',
        });
    }
};

const verifyCode = async (currentState: any, updateState: any) => {
    try {
        updateState({
            ...currentState,
            loading: true,
            error: null,
        });
        await Auth.verifyTotpToken(currentState.user, currentState.otp);
        await Auth.setPreferredMFA(currentState.user, 'TOTP');

        window.location.reload();
    } catch (e) {
        try {
            // ensure one is set
            await Auth.setPreferredMFA(currentState.user, 'SMS');
        } catch (ex) {
            // swallow
        }
        updateState({
            ...currentState,
            loading: false,
            error: 'The code entered does not match',
        });
    }
};

const getFullTotpUrl = (currentState: any) =>
    `otpauth://totp/${process.env.REACT_APP_TOTP_NAME}:${currentState.user.username}?secret=${currentState.code}&issuer=${process.env.REACT_APP_TOTP_NAME}`;

const MfaSetup = ({ cancelHandler }: any) => {
    const [currentState, updateState] = useState({
        code: '',
        user: null,
        error: null,
        otp: '',
        copied: false,
        loading: false,
        view: 'setup',
        startAgain: false,
    });

    useEffect(() => {
        if (currentState.view === 'setup') {
            setupCode(currentState, updateState);
        }
    }, [currentState.view]);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                flexDirection: 'column',
                backgroundColor: '#FFFFFF',
                margin: 0,
                padding: 0,
            }}
        >
            <Flex
                sx={{
                    border: '0px red solid',
                    flexDirection: 'column',
                    mt: '10px',
                    mb: cancelHandler ? 0 : '100px',
                }}
            >
                {currentState.loading && <Spinner />}

                <Flex
                    sx={{
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        borderRadius: 12,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 30,
                        width: '100%',
                    }}
                >
                    {currentState.code === '' && currentState.view === 'setup' && <Spinner />}

                    {currentState.view === 'done' && (
                        <Flex
                            sx={{
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Paragraph sx={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>
                                You're all setup!
                            </Paragraph>
                            <Box sx={{ width: 326, height: 200, mt: 50 }}>
                                <Image
                                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/password-success.png`}
                                    alt="Success"
                                />
                            </Box>
                            <Paragraph
                                sx={{
                                    fontSize: 13,
                                    mt: 70,
                                    width: [280, 380],
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                If you're experiencing difficult signing in or have lost your device please get in touch
                            </Paragraph>
                        </Flex>
                    )}

                    {currentState.code !== '' && currentState.view === 'setup' && (
                        <>
                            <Paragraph sx={{ mb: 10, ml: 20, mr: 20, textAlign: 'center' }}>
                                1. Scan the QR code into your Authenticator App
                            </Paragraph>

                            <Paragraph
                                sx={{
                                    mb: 20,
                                    ml: 20,
                                    mr: 20,
                                    fontSize: 13,
                                    textAlign: 'center',
                                }}
                            >
                                e.g. Google or Microsoft Authenticator which you can download from the App/Play Store
                            </Paragraph>

                            <QRCode size={200} value={getFullTotpUrl(currentState)} data-testid="mfa-qr-code" />

                            <Paragraph sx={{ mt: 30, mb: 20, ml: 20, mr: 20, textAlign: 'center' }}>
                                2. Enter the code from your app to check it's all setup
                            </Paragraph>

                            <Input
                                sx={{ width: [280, 300], mb: 20, textAlign: 'center' }}
                                data-testid="mfa-onetimecode"
                                type="text"
                                placeholder="Please enter your one time code"
                                value={currentState.otp}
                                onChange={(e) => updateState({ ...currentState, otp: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        verifyCode(currentState, updateState);
                                    }
                                }}
                            />

                            <Button
                                data-testid="mfa-verify-btn"
                                sx={{ width: [280, 300] }}
                                variant="primary"
                                onClick={() => verifyCode(currentState, updateState)}
                            >
                                Verify Code
                            </Button>

                            {cancelHandler && (
                                <Paragraph
                                    data-testid="mfa-auth-cancel"
                                    onClick={() => cancelHandler()}
                                    sx={{
                                        mt: 20,
                                        textAlign: 'center',
                                        maxWidth: '400px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Cancel
                                </Paragraph>
                            )}

                            {currentState.error && (
                                <Paragraph sx={{ mt: 20, mb: 20, color: 'red' }} data-testid="error-txt">
                                    {currentState.error}
                                </Paragraph>
                            )}

                            <Button
                                onClick={async () => {
                                    try {
                                        await Auth.signOut();
                                    } catch (e) {
                                        //
                                    }
                                    window.location.assign('/');
                                }}
                                sx={{ mt: 30 }}
                                variant="light"
                            >
                                Sign out
                            </Button>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default MfaSetup;
