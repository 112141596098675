/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Spinner from '../components/spinner';
import Sidebar from '../components/sidebar';
import { defaultOverlayState, defaultKYCJourneyState, defaultSettings, defaultProfile } from '../utils/default-states';
import Overlay from '../components/overlay/overlay';
import Header from '../components/header';
import ProfileOverlay from '../components/overlay/profile/index.jsx';
import { actions as userActions } from '../store/reducers/users';
import { actions as menuActions } from '../store/reducers/menu';
import ImageAi from '../components/openai';

const defaultState = {
    loading: true,
    partialLoading: false,
    error: null,
};

const ImageAiContainer = ({
    dataLoading,
    refreshCoreData,
    userData,
    accountSummary,
    isMobile,
    dismissTopMenu,
    logout,
    timestampOfLastDataLoad,
}) => {
    const [state, updateState] = useState(defaultState);
    const [user, updateUser] = useState(userData);
    const [overlay, updateOverlay] = useState(defaultOverlayState);
    const [kycJourney, updateKycJourney] = useState(defaultKYCJourneyState);

    const [settingsJourney, updateSettingsJourney] = useState(defaultSettings);
    const [profileJourney, updateProfileJourney] = useState(defaultProfile);
    const navigate = useNavigate();

    const headerRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (headerRef.current && !headerRef.current.contains(e.target)) {
                // setIsVisible(false);
                dismissTopMenu();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick, false);
        return () => {
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick, false);
            };
        };
    }, []);

    if (state.loading) {
        return (
            <Flex sx={{ width: '100%', height: '100%', justifyContent: 'center' }}>
                <Spinner />
            </Flex>
        );
    }

    const fullySetup = userData?.mfa?.setup && userData?.kyc?.status === 'verified';
    const kycStatusParam = new URLSearchParams(window.location.search).get('kyc') || false;

    return (
        <>
            {overlay?.show && <Overlay copy={{}} overlay={overlay} updateOverlay={updateOverlay} />}

            {!overlay?.show && kycJourney?.show && (
                <Overlay copy={{}} overlay={kycJourney} updateOverlay={updateKycJourney} />
            )}
            {kycStatusParam === 'poa' && <Overlay copy={{}} overlay={kycJourney} updateOverlay={updateKycJourney} />}

            {!overlay?.show && settingsJourney?.show && (
                <Overlay copy={{}} overlay={settingsJourney} updateOverlay={updateSettingsJourney} />
            )}
            {!overlay?.show && profileJourney?.show && (
                <Overlay
                    copy={{}}
                    overlay={profileJourney}
                    updateOverlay={updateProfileJourney}
                    embeddedComponent={<ProfileOverlay overlay={overlay} updateOverlay={updateProfileJourney} />}
                />
            )}
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: 'primary',
                    position: 'relative',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar
                        currentPage="Images"
                        overlay={overlay}
                        accountSummary={accountSummary}
                        user={user}
                        copy={{}}
                        settingsJourney={settingsJourney}
                        updateSettingsJourney={updateSettingsJourney}
                    />
                    {dataLoading && (
                        <Flex
                            sx={{
                                width: '100%',
                                height: '100vh',
                                zIndex: '9999',
                                position: 'absolute',
                            }}
                        >
                            <Spinner />
                        </Flex>
                    )}
                    {state.error && (
                        <Paragraph
                            sx={{
                                color: 'red',
                                minHeight: '20px',
                                mt: '70px',
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            {state.error}
                        </Paragraph>
                    )}

                    {!state.loading && (
                        <>
                            <Flex
                                sx={{
                                    width: 'calc(100% - 147px)',
                                    border: '0px solid lightgrey',
                                    minHeight: 'calc(100vh - 25px)',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '25px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    mt: '15px',
                                    ml: '130px',
                                }}
                            >
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        ml: '50px',
                                    }}
                                >
                                    <Flex
                                        sx={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            mb: '30px',
                                        }}
                                    >
                                        <Flex sx={{ flexDirection: 'column' }}>
                                            <Paragraph
                                                sx={{
                                                    fontWeight: '600',
                                                    fontSize: '25px',
                                                    color: 'primary',
                                                    textAlign: 'left',
                                                    mt: '30px',
                                                }}
                                            >
                                                Images
                                            </Paragraph>
                                            <Paragraph
                                                sx={{
                                                    fontSize: '15px',
                                                    color: 'primary',
                                                    textAlign: 'left',
                                                    mt: '10px',
                                                }}
                                            >
                                                Generate an image based on your prompt
                                            </Paragraph>
                                        </Flex>
                                    </Flex>
                                    <ImageAi />
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageAiContainer);
