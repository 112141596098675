import { useEffect, useState } from 'react';
import { Flex, Input, Paragraph } from 'theme-ui';
import SignatureCanvas from '../signature';

const PortalSignature = ({ state, updateState, question, isDisabled }) => {
    const names = [];
    state.organisation?.clients?.forEach((x) => {
        if (!names.find((y) => y.label === x.user_name)) {
            names.push({
                value: x.id,
                label: x.user_name,
            });
        }
    });

    const [name, setName] = useState(question?.nameForSignature || names?.[0]?.label);

    useEffect(() => {
        if (!name) return;

        ['Great Vibes', 'Pacifico'].forEach((x, idx) => {
            const canvas = document.getElementById(`canvas-signature-${question.uuid}-${idx + 1}`);
            const ctx = canvas.getContext('2d');
            ctx.font = `20px ${x}`;
            ctx.fillStyle = 'black';
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillText(name, 30, 40);
        });
        updateState({
            ...state,
            questionnaire: {
                ...state.questionnaire,
                definition: {
                    ...state.questionnaire.definition,
                    questions: state.questionnaire?.definition?.questions?.map((x) => {
                        if (x.uuid === question.uuid) {
                            x.nameForSignature = name;
                        }
                        return x;
                    }),
                },
            },
        });
    }, [name]);

    return (
        <Flex sx={{ flexDirection: 'column', ml: 0 }}>
            <Flex sx={{ flexDirection: 'column' }}>
                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <Paragraph sx={{ mt: 10, mb: 0 }}>Confirm your name and select a signature</Paragraph>
                    <Input
                        sx={{ height: 40, mt: 10, mb: 10, width: 300 }}
                        value={name}
                        onChange={(e) => {
                            if (isDisabled) return;
                            setName(e.target.value);
                        }}
                    />
                </Flex>
                {name ? (
                    <>
                        <Flex
                            sx={{
                                cursor: 'pointer',
                                mt: 20,
                                width: 300,
                                border:
                                    question.signatureChosen === 'pacifico' ? '1px solid #000' : '1px solid #EFEFEF',
                            }}
                            onClick={(e) => {
                                if (isDisabled) return;

                                updateState({
                                    ...state,
                                    questionnaire: {
                                        ...state.questionnaire,
                                        definition: {
                                            ...state.questionnaire.definition,
                                            questions: state.questionnaire?.definition?.questions?.map((x) => {
                                                if (x.uuid === question.uuid) {
                                                    x.answer = document
                                                        .getElementById(`canvas-signature-${question.uuid}-1`)
                                                        .toDataURL('image/png');
                                                    x.signatureType = 'selected';
                                                    x.signatureChosen = 'pacifico';
                                                }
                                                return x;
                                            }),
                                        },
                                    },
                                });
                            }}
                        >
                            <canvas id={`canvas-signature-${question.uuid}-1`} width="300" height="60"></canvas>
                        </Flex>
                        <Flex
                            sx={{
                                cursor: 'pointer',
                                mt: 20,
                                width: 300,
                                border:
                                    question.signatureChosen === 'great-vibes' ? '1px solid #000' : '1px solid #EFEFEF',
                            }}
                            onClick={(e) => {
                                if (isDisabled) return;
                                updateState({
                                    ...state,
                                    questionnaire: {
                                        ...state.questionnaire,
                                        definition: {
                                            ...state.questionnaire.definition,
                                            questions: state.questionnaire?.definition?.questions?.map((x) => {
                                                if (x.uuid === question.uuid) {
                                                    x.answer = document
                                                        .getElementById(`canvas-signature-${question.uuid}-2`)
                                                        .toDataURL('image/png');
                                                    x.signatureType = 'selected';
                                                    x.signatureChosen = 'great-vibes';
                                                }
                                                return x;
                                            }),
                                        },
                                    },
                                });
                            }}
                        >
                            <canvas id={`canvas-signature-${question.uuid}-2`} width="300" height="60"></canvas>
                        </Flex>
                    </>
                ) : null}
            </Flex>

            <Flex sx={{ flexDirection: 'column', mt: 30 }}>
                <Paragraph sx={{}}>or draw your own signature:</Paragraph>

                <SignatureCanvas
                    defaultValue={question.signatureType === 'manual' ? question.answer : null}
                    id={`canvas-signature-${question.uuid}-manual`}
                    containerSx={{ mt: 10 }}
                    signatureSx={{ borderRadius: 12 }}
                    width={300}
                    height={120}
                    disableInteraction={isDisabled}
                    drawingComplete={(res) => {
                        if (isDisabled) return;
                        updateState({
                            ...state,
                            questionnaire: {
                                ...state.questionnaire,
                                definition: {
                                    ...state.questionnaire.definition,
                                    questions: state.questionnaire?.definition?.questions?.map((x) => {
                                        if (x.uuid === question.uuid) {
                                            x.answer = res.base64;
                                            x.signatureType = 'manual';
                                            x.signatureChosen = null;
                                        }
                                        return x;
                                    }),
                                },
                            },
                        });
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default PortalSignature;
