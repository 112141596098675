/* eslint-disable no-undef */
import { Button, Flex, Paragraph, Spinner, Textarea } from 'theme-ui';
import Divider from '../divider';
import { useState } from 'react';
import axios from 'axios';

const fullSend = async (state, updateState, localState, updateLocalState) => {
    updateLocalState({ ...localState, loading: true });
    await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/notifications/portal`,
        method: 'POST',
        data: {
            message: localState.message,
        },
    });
    updateLocalState({ ...localState, loading: false });
    updateState({ ...state, showSendMsgModal: false });
};

const SendMessage = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ message: '' });
    return (
        <Flex sx={{ ml: 20, mt: 20, mr: 20, flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 16 }}>Send message</Paragraph>
            <Divider />
            <Textarea
                value={localState.message}
                onChange={(e) => updateLocalState({ ...localState, message: e.target.value })}
                rows={9}
            />
            <Flex sx={{ alignItems: 'center' }}>
                <Button
                    onClick={async () => {
                        if (localState.message) {
                            await fullSend(state, updateState, localState, updateLocalState);
                        }
                    }}
                    disabled={!localState.message}
                    sx={{ width: 100, mt: 20, mr: 20 }}
                >
                    Send
                </Button>
                {localState.loading && <Spinner sx={{ mt: 15 }} />}
            </Flex>
        </Flex>
    );
};

export default SendMessage;
