/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Flex } from 'theme-ui';

const customStyles = {
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const loadReportData = async (state, updateState, localState, updateLocalState) => {
    updateState({ ...state, loading: true });

    try {
        let {
            data: { rows, dataTableConfig },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/reports`, {
            params: {
                report_name: state.report_to_download?.value,
            },
        });

        updateState({ ...state, loading: false });
        updateLocalState({
            ...localState,
            data: rows,
            columns: dataTableConfig.map((x) => ({
                ...x,
                selector: (row) =>
                    ['sent_at', 'last_activity_timestamp', 'SentAt', 'LastUpdated'].includes(x.selector) &&
                    row[x.selector]
                        ? moment(row[x.selector], 'DD/MM/YYYY HH:mm').toDate()
                        : row[x.selector],
                format: (row) =>
                    x.isHref ? (
                        <a rel="noreferrer" target="_blank" href={row.href}>
                            {row[x.selector]}
                        </a>
                    ) : (
                        row[x.selector]
                    ),
            })),
        });
    } catch (e) {
        updateState({ ...state, loading: false });
        updateLocalState({
            ...localState,
            data: [],
            columns: [],
        });
        toast('We have been unable to generate the report data at this time, please try again later', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

const ReportTable = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ data: [], columns: [] });

    useEffect(() => {
        loadReportData(state, updateState, localState, updateLocalState);
    }, [state.report_to_download]);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <DataTable
                title={state.report_to_download?.label}
                columns={localState.columns}
                data={localState.data}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
            />
        </Flex>
    );
};

export default ReportTable;
