import { PDFDocument } from 'pdf-lib';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePdf } from '@mikecousins/react-pdf';
import { Box, Button, Flex, Paragraph } from 'theme-ui';
import Sidebar from '../components/sidebar';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import { defaultOverlayState, defaultSettings } from '../utils/default-states';
import SignatureCanvas from '../components/signature';
import moment from 'moment';

function base64ImageToBytes(base64Image) {
    // Split the base64 string into the data prefix and the actual base64 data
    const parts = base64Image.split(';base64,');
    const imageData = parts[1];

    // Decode the base64 string to a regular string
    const decodedString = atob(imageData);

    // Create a Uint8Array to hold the byte values
    const byteNumbers = new Uint8Array(decodedString.length);

    // Map each character to a byte
    for (let i = 0; i < decodedString.length; i++) {
        byteNumbers[i] = decodedString.charCodeAt(i);
    }

    return byteNumbers;
}

const clearSignatures = (annotationsCanvasRef, updateState, state, clearState = true) => {
    const annotationsCanvas = annotationsCanvasRef.current;
    const annotationCtx = annotationsCanvas.getContext('2d');
    annotationCtx.clearRect(0, 0, annotationsCanvas.width, annotationsCanvas.height);
    if (clearState) {
        updateState({
            ...state,
            locationOfSignatureBoxes: [],
            indexedPageAnnotationLayerDataUrls: {},
        });
    }
};

const injectSignatures = async (state, updateState, annotationsCanvasRef, page) => {
    if (!state.signatureBase64) return;

    var img = new Image();

    img.src = state.signatureBase64;

    const annotationsCanvas = annotationsCanvasRef.current;
    const annotationCtx = annotationsCanvas.getContext('2d');
    annotationCtx.clearRect(0, 0, annotationsCanvas.width, annotationsCanvas.height);

    await new Promise((resolve) => setTimeout(resolve, 300));

    state.locationOfSignatureBoxes?.forEach(({ x, y, belongsToPage }) => {
        if (belongsToPage === page) {
            const finalXPosition = x;
            const finalYPosition = y - 10;
            let timestampXPosition = finalXPosition;
            let timestampYPosition = y + 60 - 10;

            if (!state.signatureChosen) {
                timestampXPosition += 50;
                timestampYPosition += 20;
            }

            annotationCtx.drawImage(img, finalXPosition, finalYPosition);
            annotationCtx.fillText(
                `Signed ${moment().format('HH:mm Do MMM YYYY')}`,
                timestampXPosition,
                timestampYPosition
            );
        }
    });

    const indexedPageAnnotationLayerDataUrls = state.indexedPageAnnotationLayerDataUrls;
    indexedPageAnnotationLayerDataUrls[page] = annotationsCanvasRef.current.toDataURL('image/png');

    updateState({
        ...state,
        indexedPageAnnotationLayerDataUrls,
    });
};

const DocSign = ({ organisation, logout, userData, accountSummary }) => {
    const [state, updateState] = useState({
        organisation,
        indexedPageAnnotationLayerDataUrls: {},
        pdfPageWidth: 600,
        pdfPageHeight: 900,
    });

    const [user, updateUser] = useState(userData);
    const [overlay, updateOverlay] = useState(defaultOverlayState);

    const [settingsJourney, updateSettingsJourney] = useState(defaultSettings);
    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const canvasRef = useRef(null);
    const annotationsCanvasRef = useRef(null);

    const { pdfDocument, pdfPage } = usePdf({
        file: 'https://cdn.accru.finance/documents/privacy-policy.pdf',
        page,
        canvasRef,
    });

    useEffect(() => {
        ['Great Vibes', 'Pacifico'].forEach((x, idx) => {
            const canvas = document.getElementById(`canvas-signature-${idx + 1}`);
            const ctx = canvas.getContext('2d');
            ctx.font = `20px ${x}`;
            ctx.fillStyle = 'black';
            ctx.fillText('Louis Quaintance', 0, 35);
        });

        (async () => {
            const url = 'https://cdn.accru.finance/documents/privacy-policy.pdf';
            const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            updateState({
                ...state,
                pdfDoc,
                pdfPageWidth: pdfDoc.getPage(0).getWidth(),
                pdfPageHeight: pdfDoc.getPage(0).getHeight(),
            });
        })();
    }, []);

    return (
        <Flex
            sx={{
                minHeight: '100vh',
                paddingBottom: '100px',
                width: '100vw',
                flexDirection: 'column',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundColor: '#fff',
            }}
            data-testid="home-screen"
        >
            <Flex
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    flexDirection: 'column',
                }}
            >
                <Sidebar
                    currentPage="DocSign"
                    overlay={overlay}
                    accountSummary={accountSummary}
                    user={user}
                    copy={{}}
                    settingsJourney={settingsJourney}
                    updateSettingsJourney={updateSettingsJourney}
                />

                <Flex sx={{ backgroundColor: 'white', width: 1300, height: '90vh', mt: 50, ml: 150 }}>
                    <Flex sx={{ flexDirection: 'column', ml: 20, mt: 20 }}>
                        <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>Provide a signature</Paragraph>

                        <Paragraph sx={{ mt: 10 }}>You can either draw a signature:</Paragraph>

                        <SignatureCanvas
                            defaultValue={state.drawnSignature}
                            id={`signature_canvas_123`}
                            containerSx={{ mt: 10 }}
                            width={300}
                            height={80}
                            drawingComplete={(res) => {
                                updateState({ ...state, signatureBase64: res.base64, signatureChosen: null });
                            }}
                        />

                        <Paragraph sx={{ mt: 20, mb: 20 }}>
                            or you can select one of the following signatures:
                        </Paragraph>

                        <Flex
                            sx={{
                                cursor: 'pointer',
                                mt: 20,
                                padding: 10,
                                border: state.signatureChosen === 'pacifico' ? '1px solid #000' : '1px solid #EFEFEF',
                            }}
                            onClick={(e) => {
                                updateState({
                                    ...state,
                                    signatureChosen: 'pacifico',
                                    drawnSignature: '',
                                    signatureBase64: document
                                        .getElementById('canvas-signature-1')
                                        .toDataURL('image/png'),
                                });
                            }}
                        >
                            <canvas id="canvas-signature-1" width="300" height="60"></canvas>
                        </Flex>
                        <Flex
                            sx={{
                                cursor: 'pointer',
                                mt: 20,
                                mb: 20,
                                padding: 10,
                                border:
                                    state.signatureChosen === 'great-vibes' ? '1px solid #000' : '1px solid #EFEFEF',
                            }}
                            onClick={(e) => {
                                updateState({
                                    ...state,
                                    signatureChosen: 'great-vibes',
                                    drawnSignature: '',
                                    signatureBase64: document
                                        .getElementById('canvas-signature-2')
                                        .toDataURL('image/png'),
                                });
                            }}
                        >
                            <canvas id="canvas-signature-2" width="300" height="60"></canvas>
                        </Flex>
                        {Boolean(pdfDocument && pdfDocument.numPages) && (
                            <nav style={{ backgroundColor: 'white' }}>
                                <ul className="pager">
                                    <li className="previous">
                                        <button
                                            disabled={page === 1}
                                            onClick={() => {
                                                clearSignatures(annotationsCanvasRef, updateState, state, false);
                                                setPage(page - 1);
                                                injectSignatures(state, updateState, annotationsCanvasRef, page - 1);
                                            }}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    <li className="next">
                                        <button
                                            disabled={page === pdfDocument.numPages}
                                            onClick={() => {
                                                clearSignatures(annotationsCanvasRef, updateState, state, false);
                                                setPage(page + 1);
                                                injectSignatures(state, updateState, annotationsCanvasRef, page + 1);
                                            }}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        )}
                        <Button
                            onClick={async () => {
                                injectSignatures(state, updateState, annotationsCanvasRef, page);
                            }}
                            sx={{ width: 170, mb: 30, mt: 20 }}
                        >
                            Inject signatures
                        </Button>

                        <Button
                            onClick={async () => {
                                const pngForRendering = canvasRef.current.toDataURL('image/png');
                                const annotationsLayer = annotationsCanvasRef.current.toDataURL('image/png');

                                const pdfDoc = await PDFDocument.create();

                                const page = pdfDoc.addPage();

                                const { width, height } = page.getSize();

                                const pdfPageAsImage = await pdfDoc.embedPng(base64ImageToBytes(pngForRendering));
                                const annotationsPageAsImage = await pdfDoc.embedPng(
                                    base64ImageToBytes(annotationsLayer)
                                );

                                page.drawImage(pdfPageAsImage, {
                                    x: 0,
                                    y: 0,
                                    width,
                                    height,
                                });

                                page.drawImage(annotationsPageAsImage, {
                                    x: 0,
                                    y: 0,
                                    width,
                                    height,
                                });

                                const pdfBytes = await pdfDoc.save();

                                const blob = new Blob([pdfBytes], { type: 'application/pdf' });

                                const blobUrl = URL.createObjectURL(blob);

                                const a = document.createElement('a');
                                a.href = blobUrl;
                                a.download = 'Final.pdf';
                                a.click();
                            }}
                            sx={{ width: 200, mb: 30 }}
                        >
                            Download final pdf
                        </Button>
                        <Button
                            onClick={async () => {
                                const url = 'https://cdn.accru.finance/documents/privacy-policy.pdf';
                                const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

                                const pdfDoc = await PDFDocument.load(existingPdfBytes);

                                for (const [thePageNumber, dataUrl] of Object.entries(
                                    state.indexedPageAnnotationLayerDataUrls
                                )) {
                                    const pdfDocPageRef = pdfDoc.getPage(parseInt(thePageNumber, 10) - 1);

                                    pdfDocPageRef.setSize(state.pdfPageWidth, state.pdfPageHeight);

                                    const pdfPageAsImage = await pdfDoc.embedPng(base64ImageToBytes(dataUrl));

                                    pdfDocPageRef.drawImage(pdfPageAsImage, {
                                        x: 0,
                                        y: 0,
                                        width: state.pdfPageWidth,
                                        height: state.pdfPageHeight,
                                    });
                                }

                                const pdfBytes = await pdfDoc.save();

                                const blob = new Blob([pdfBytes], {
                                    type: 'application/pdf',
                                });

                                const blobUrl = URL.createObjectURL(blob);

                                const a = document.createElement('a');
                                a.href = blobUrl;
                                a.download = 'Final.pdf';
                                a.click();
                            }}
                            sx={{ width: 200, mb: 30 }}
                        >
                            Download final pdf 2
                        </Button>
                        <Button
                            onClick={() => {
                                clearSignatures(annotationsCanvasRef, updateState, state);
                            }}
                            sx={{ mt: 0, width: 170 }}
                        >
                            Clear signatures
                        </Button>
                    </Flex>

                    <Box
                        sx={{
                            padding: 10,
                            border: '1px solid lightGrey',
                            position: 'relative',
                            width: state.pdfPageWidth,
                            height: state.pdfPageHeight,
                            ml: 20,
                            mt: 20,
                        }}
                    >
                        <canvas
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            ref={canvasRef}
                        />
                        <canvas
                            onMouseDown={({ clientX, clientY }) => {
                                const canvas = annotationsCanvasRef.current;
                                const ctx = canvas.getContext('2d');

                                var rect = canvas.getBoundingClientRect();
                                const location = {
                                    x: clientX - rect.left,
                                    y: clientY - rect.top,
                                };

                                var svg = `<svg width="200" height="50" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="200" height="50" fill="yellow" fill-opacity="0.5" />
                                </svg>`;

                                // Convert SVG to data URL
                                var imgSrc = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);

                                // Create an image object
                                var img = new Image();

                                // Set the source of the image
                                img.src = imgSrc;

                                // Draw the image onto the canvas when it's loaded
                                img.onload = function () {
                                    ctx.drawImage(img, location.x, location.y);

                                    const indexedPageAnnotationLayerDataUrls = state.indexedPageAnnotationLayerDataUrls;
                                    indexedPageAnnotationLayerDataUrls[page] =
                                        annotationsCanvasRef.current.toDataURL('image/png');

                                    updateState({
                                        ...state,
                                        indexedPageAnnotationLayerDataUrls,
                                        locationOfSignatureBoxes: [
                                            ...(state.locationOfSignatureBoxes || []),
                                            { ...location, belongsToPage: page },
                                        ],
                                    });
                                };
                            }}
                            ref={annotationsCanvasRef}
                            width={state.pdfPageWidth}
                            height={state.pdfPageHeight}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: 'state.pdfPageWidthpx',
                                height: 'state.pdfPageHeightpx',
                            }}
                        />
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocSign);
