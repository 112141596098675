/** @jsxImportSource theme-ui */
import { Input, Flex, Label } from 'theme-ui';

const FormInput = (props: any) => (
    <Flex
        sx={{
            flexDirection: 'column',
            width: '100%',
        }}
    >
        {props?.label && <Label sx={{ marginBottom: '8px', ...(props.labelStyle || {}) }}>{props?.label}</Label>}
        <Input
            {...props}
            sx={{
                mb: 2,
                backgroundColor: 'white',
                height: 45,
                borderRadius: 10,
                borderColor: props?.error ? 'red' : 'lightgrey',
                paddingLeft: 15,
                paddingRight: 15,
                outline: 'none',
                ...(props.sx || {}),
            }}
        />
    </Flex>
);

export default FormInput;
