import { Flex, Paragraph, Button, Input, Spinner, Image, Box } from 'theme-ui';

const PasswordSuccess = ({ overlay, updateOverlay }: any) => (
    <Flex
        sx={{
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Flex
            sx={{
                width: '100px',
                alignItems: 'center',
                height: 'auto',
                margin: '0px auto 0px',
                '@media screen and (max-width: 700px)': {
                    width: '100px',
                    height: 'auto',
                },
            }}
        >
            <i
                style={{
                    width: '100px',
                    fontSize: 100,
                    textAlign: 'center',
                    marginTop: '0px',
                    color: 'green',
                }}
                className="fas fa-check-circle"
            />
        </Flex>

        <Paragraph sx={{ fontSize: '30px', mt: 5, textAlign: 'center' }} data-testid="pwd-changed-success">
            Congratulations
        </Paragraph>

        <Paragraph
            sx={{
                fontSize: '16px',
                mt: '30px',
                textAlign: 'center',
                ml: '40px',
                mr: '40px',
            }}
        >
            You have successfully changed your password
        </Paragraph>

        <Button
            sx={{
                mt: 40,
                mb: 25,
            }}
            data-testid="back-home-btn"
            variant="primary-wide"
            onClick={() => {
                document.body.style.overflowY = 'auto';
                updateOverlay({
                    ...overlay,
                    componentToRender: 'SettingsOverlay',
                    show: false,
                });
            }}
        >
            Back to home
        </Button>
    </Flex>
);

export default PasswordSuccess;
