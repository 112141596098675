import { Flex, Box } from 'theme-ui';
import { useEffect } from 'react';
import { InputLabel, FormInput } from './form-elements';

export const Information = ({ currentQuestion, currentState, updateState }: any) => {
    useEffect(() => {
        setTimeout(() => {
            updateState({ ...currentState, loading: false });
        }, 200);
    }, [currentQuestion?.saveAs]);

    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Flex
                sx={{
                    width: '100px',
                    alignItems: 'center',
                    height: 'auto',
                    margin: '0px auto 0px',
                    '@media screen and (max-width: 700px)': {
                        width: '100px',
                        height: 'auto',
                    },
                }}
            >
                <i
                    style={{
                        width: '100px',
                        fontSize: 100,
                        textAlign: 'center',
                        marginTop: '0px',
                        color: '#2B8ACC',
                    }}
                    className="fas fa-calendar-check"
                />
            </Flex>
        </Flex>
    );
};
export const SingleInput = ({ currentQuestion, currentState, updateState, errorState, updateErrorState }: any) => {
    const isRequired = currentQuestion?.required;
    const errorKey = currentQuestion?.errorKey;
    const saveAs = currentQuestion?.saveAs;
    const subtitle = currentQuestion?.subtitle; // Merlin RMV after inserting into Q

    useEffect(() => {
        setTimeout(() => {
            updateState({ ...currentState, loading: false });
        }, 200);
    }, [currentQuestion?.saveAs]);

    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {isRequired && (
                <InputLabel
                    customSX={{ textAlign: 'left', width: '300px' }}
                    title="Answer required"
                    subtitle={`${isRequired ? ' *' : ''}`}
                />
            )}
            {!currentState?.loading && (
                <FormInput
                    width="300px"
                    mt="5px"
                    mb="15px"
                    errorState={errorState}
                    errorKey={isRequired ? errorKey : false}
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey={saveAs}
                    placeholder={currentQuestion?.placeholder || currentQuestion?.question}
                />
            )}
            {/* This is to stop form input rendering with incorrect saveAs key */}
            {currentState?.loading && (
                <Box
                    sx={{
                        width: '300px',
                        height: '50px',
                        backgroundColor: 'white',
                        borderRadius: 6,
                        borderColor: 'inputBorder',
                        mt: '5px',
                        mb: '15px',
                        border: '1px solid lightgrey',
                    }}
                />
            )}
        </Flex>
    );
};

export const MultipleInputs = ({ currentQuestion, currentState, updateState, errorState, updateErrorState }: any) => {
    const inputs = currentQuestion?.inputs || [];
    const errorKey = currentQuestion?.errorKey;
    useEffect(() => {
        updateState({ ...currentState, loading: false });
    }, []);
    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {inputs?.map((item: any) => {
                const isRequired = item?.required;
                return (
                    <Box key={item?.saveAs}>
                        <InputLabel
                            customSX={{ textAlign: 'left', width: '300px' }}
                            title={item?.key}
                            subtitle={`${isRequired ? ' *' : ''}`}
                        />
                        <FormInput
                            width="300px"
                            mt="5px"
                            mb="15px"
                            errorState={errorState}
                            errorKey={isRequired ? errorKey : false}
                            state={currentState}
                            updateState={updateState}
                            updateErrorState={updateErrorState}
                            stateKey={item?.saveAs}
                            placeholder={item?.key}
                        />
                    </Box>
                );
            })}
        </Flex>
    );
};

export const DOB = ({ currentQuestion, currentState, updateState, errorState, updateErrorState }: any) => {
    const isRequired = currentQuestion?.required;
    useEffect(() => {
        updateState({ ...currentState, loading: false });
    }, []);

    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Flex sx={{ width: '300px' }}>
                <Flex sx={{ width: '100px', flexDirection: 'column', mr: '20px', ml: '10px' }}>
                    <InputLabel
                        customSX={{ textAlign: 'center', width: '70px' }}
                        title="DD"
                        subtitle={`${isRequired ? ' *' : ''}`}
                    />
                    <FormInput
                        width="70px"
                        mt="5px"
                        mb="15px"
                        errorState={errorState}
                        errorKey="dob"
                        state={currentState}
                        updateState={updateState}
                        updateErrorState={updateErrorState}
                        stateKey="dob_dd"
                        placeholder="DD"
                    />
                </Flex>
                <Flex sx={{ width: '100px', flexDirection: 'column', mr: '20px' }}>
                    <InputLabel
                        customSX={{ textAlign: 'center', width: '70px' }}
                        title="MM"
                        subtitle={`${isRequired ? ' *' : ''}`}
                    />
                    <FormInput
                        width="70px"
                        mt="5px"
                        mb="15px"
                        errorState={errorState}
                        errorKey="dob"
                        state={currentState}
                        updateState={updateState}
                        updateErrorState={updateErrorState}
                        stateKey="dob_mm"
                        placeholder="MM"
                    />
                </Flex>
                <Flex sx={{ width: '100px', flexDirection: 'column' }}>
                    <InputLabel
                        customSX={{ textAlign: 'center', width: '70px' }}
                        title="YYYY"
                        subtitle={`${isRequired ? ' *' : ''}`}
                    />
                    <FormInput
                        width="70px"
                        mt="5px"
                        mb="15px"
                        errorState={errorState}
                        errorKey="dob"
                        state={currentState}
                        updateState={updateState}
                        updateErrorState={updateErrorState}
                        stateKey="dob_yyyy"
                        placeholder="YYYY"
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
