/* eslint-disable no-undef */
import { Checkbox, Flex, Paragraph } from 'theme-ui';
import theme from '../../theme';
import moment from 'moment';
import axios from 'axios';

const EmailInList = ({ email, index, state, updateState }) => {
    return (
        <Flex
            sx={{
                p: '15px',
                flexDirection: 'column',
                mb: '3px',
                cursor: 'pointer',
                bg: state.selectedEmailIndex === index ? 'highlight' : 'background',
                borderRadius: '4px',
                border: '1px solid #EFEFEF',
                borderLeft: `3px solid ${theme.colors.primary}`,
                boxSizing: 'border-box',
                ':hover': { bg: 'highlight' },
            }}
            onClick={async () => {
                try {
                    const email = state.emails?.[index];

                    updateState({
                        ...state,
                        loading: true,
                        selectedEmailIndex: index,
                    });

                    if (email?.cids_rendered) {
                        updateState({
                            ...state,
                            loading: false,
                            selectedEmailIndex: index,
                            emails: state.emails?.map((email, emailIdx) => {
                                if (emailIdx === index) {
                                    email.isRead = true;
                                }
                                return email;
                            }),
                        });
                    } else {
                        const {
                            data: { html, attachments },
                        } = await axios({
                            url: `${process.env.REACT_APP_AQRU_AI_API}/outlook/process-html`,
                            method: 'POST',
                            data: {
                                message_id: email?.id,
                                html: email?.body?.content,
                            },
                        });
                        updateState({
                            ...state,
                            loading: false,
                            selectedEmailIndex: index,
                            emails: state.emails?.map((email, emailIdx) => {
                                if (emailIdx === index) {
                                    email.body.content = html;
                                    email.isRead = true;
                                    email.cids_rendered = true;
                                    email.file_attachments_loaded = attachments;
                                }
                                return email;
                            }),
                        });
                    }
                } catch (e) {
                    updateState({
                        ...state,
                        loading: false,
                        selectedEmailIndex: index,
                    });
                }
            }}
        >
            <Flex sx={{ alignItems: 'center' }}>
                <Flex
                    sx={{ mr: 20 }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const emailsSelected = state.emailsSelected?.includes(index)
                            ? state.emailsSelected?.filter((x) => x !== index)
                            : [...(state.emailsSelected || []), index];
                        updateState({ ...state, emailsSelected });
                    }}
                >
                    <Checkbox checked={state.emailsSelected?.includes(index)} />
                </Flex>
                <Flex sx={{ flexDirection: 'column', width: '100%' }}>
                    <Paragraph
                        sx={{
                            color: theme.colors.primary,
                            fontSize: 16,
                            mb: 10,
                            fontWeight: email.isRead ? 400 : 700,
                        }}
                    >
                        {state.type === 'inbox'
                            ? email.from?.emailAddress?.name
                            : email.toRecipients?.[0]?.emailAddress?.name}
                    </Paragraph>
                    <Paragraph
                        sx={{
                            color: theme.colors.primary,
                            mb: 10,
                            fontWeight: email.isRead ? 400 : 700,
                        }}
                    >
                        {email.subject}
                    </Paragraph>
                    <Paragraph>{email.bodyPreview || 'No preview'}</Paragraph>
                    <Paragraph sx={{ mt: 10 }}>{moment(email.receivedDateTime).format('HH:mm DD/MM/YYYY')}</Paragraph>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EmailInList;
