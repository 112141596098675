import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { loadAvatar } from '../settings/my-profile';
import { updateTaskAndSort } from './api';
import theme from '../../theme';
import { Flex, Input } from 'theme-ui';

const TaskInListView = ({ data, state, updateState, organisation, navigate, idx }) => {
    const assignedTo = data?.relations?.find((x) => x.type === 'assigned_to')?.id;

    const user = state.users?.find((x) => x.raw_id === assignedTo);

    const avatarUrl = loadAvatar({ user });

    const [localState, updateLocalState] = useState({ avatarData: null });

    useEffect(() => {
        if (avatarUrl) {
            (async () => {
                const res = await axios.get(avatarUrl);
                updateLocalState({ ...localState, avatarData: res.data });
            })();
        }
    }, [avatarUrl]);

    const cells = [];

    for (const x of state.columns) {
        if (x.value === 'title') {
            cells.push(
                <td style={{ width: 250, textAlign: 'left', padding: 20 }}>
                    <Flex sx={{ flexDirection: 'column', position: 'relative' }}>
                        <a
                            title={'Title'}
                            href={`/tasks?id=${data.id}`}
                            style={{
                                fontWeight: '600',
                                color: moment(data.due_date).isSameOrBefore(moment()) ? 'red' : theme.colors.primary,
                                textDecoration: 'none',
                            }}
                        >
                            {data.title}
                        </a>
                    </Flex>
                </td>
            );
        }
        if (x.value === 'client_name') {
            cells.push(
                <td title="Client name" style={{ width: 200, textAlign: 'left', padding: 20 }}>
                    <a
                        title={'Title'}
                        href={`/tasks?id=${data.id}`}
                        style={{
                            textDecoration: 'none',
                            color: '#000',
                        }}
                    >
                        {data.client?.name}
                    </a>
                </td>
            );
        }
        if (x.value === 'internal_reference_code') {
            cells.push(
                <td title={'Client identifier'} style={{ width: 200, textAlign: 'left', padding: 20 }}>
                    <a
                        title={'Title'}
                        href={`/tasks?id=${data.id}`}
                        style={{
                            textDecoration: 'none',
                            color: '#000',
                        }}
                    >
                        {data.client?.internal_reference_code}
                    </a>
                </td>
            );
        }
        if (x.value === 'service') {
            cells.push(
                <td title={'Service'} style={{ width: 200, textAlign: 'left', padding: 20 }}>
                    <a
                        title={'Title'}
                        href={`/tasks?id=${data.id}`}
                        style={{
                            textDecoration: 'none',
                            color: '#000',
                        }}
                    >
                        {state.organisation?.workflow_definition?.services?.[data.additional_data?.service]?.label}
                    </a>
                </td>
            );
        }
        if (x.value === 'status') {
            cells.push(
                <td title={'Status'} style={{ width: 200, padding: 20 }}>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '170px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            if (
                                ['done', 'closed']?.includes(c?.value) &&
                                !!data?.subtasks?.find((y) => y.status !== 'done')
                            ) {
                                return updateState({
                                    ...state,
                                    subtasksIncompleteAlert: true,
                                });
                            }

                            const tasks_for_list = [...state.tasks_for_list];
                            tasks_for_list[idx].status = c.value;

                            await updateTaskAndSort(
                                data,
                                {
                                    status: c.value,
                                },
                                {
                                    ...state,
                                    tasks_for_list,
                                },
                                updateState
                            );
                        }}
                        placeholder="Select..."
                        value={{
                            value: data.status,
                            label: state.organisation?.default_visualisation?.statuses?.find(
                                (x) => x.key === data.status
                            )?.label,
                        }}
                        options={[
                            ...(state.organisation?.default_visualisation?.statuses?.map((x) => ({
                                value: x.key,
                                label: x.label,
                            })) || []),
                        ]}
                    />
                </td>
            );
        }
        if (x.value === 'priority') {
            cells.push(
                <td title={'Priority'} style={{ width: 200, padding: 20 }}>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '170px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            const tasks_for_list = [...state.tasks_for_list];
                            tasks_for_list[idx].priority = c.value;

                            await updateTaskAndSort(
                                data,
                                {
                                    priority: c.value,
                                },
                                {
                                    ...state,
                                    tasks_for_list,
                                },
                                updateState
                            );
                        }}
                        placeholder="Select..."
                        value={{
                            value: data.priority,
                            label: state.organisation?.default_visualisation?.priorities?.find(
                                (x) => x.key === data.priority
                            )?.label,
                        }}
                        options={[
                            ...(state.organisation?.default_visualisation?.priorities?.map((x) => ({
                                value: x.key,
                                label: x.label,
                            })) || []),
                        ]}
                    />
                </td>
            );
        }
        if (x.value === 'relevant_period_end') {
            cells.push(
                <td title={'Relevant Period End'} style={{ width: 200, padding: 20 }}>
                    {data.additional_data?.relevant_period_end
                        ? moment(data.additional_data?.relevant_period_end).format('DD-MM-YYYY')
                        : ''}
                </td>
            );
        }
        if (x.value === 'date_sent_to_client') {
            cells.push(
                <td title={'Date sent to client'} style={{ width: 200, padding: 20 }}>
                    {data.date_sent_to_client ? moment(data.date_sent_to_client).format('DD-MM-YYYY') : ''}
                </td>
            );
        }
        if (x.value === 'due_date') {
            cells.push(
                <td
                    title={'Internal Due Date'}
                    style={{
                        width: 200,
                        padding: 20,
                        color: moment(data.due_date).isSameOrBefore(moment()) ? 'red' : 'auto',
                    }}
                >
                    {data.due_date ? moment(data.due_date).format('DD-MM-YYYY') : ''}
                </td>
            );
        }
        if (x.value === 'statutory_due_date') {
            cells.push(
                <td title={'Statutory Due Date'} style={{ width: 200, padding: 20 }}>
                    {data.additional_data?.statutory_due_date
                        ? moment(data.additional_data?.statutory_due_date).format('DD-MM-YYYY')
                        : ''}
                </td>
            );
        }
        if (x.value === 'preparers') {
            const relations = data.relations?.filter((x) => x.type === 'assigned_to');
            cells.push(
                <td title={'Preparers'} style={{ width: 200, padding: 20 }}>
                    {relations?.length
                        ? relations
                              .map((x) => {
                                  if (!x.first_name || !x.last_name) {
                                      return x.email_address;
                                  }
                                  return `${x.first_name || ''} ${x.last_name || ''}`;
                              })
                              .join(' ')
                        : 'Unassigned'}
                </td>
            );
        }
        if (x.value === 'reviewers') {
            const relations = data.relations?.filter((x) => x.type === 'reviewer');
            cells.push(
                <td title={'Reviewers'} style={{ width: 200, padding: 20 }}>
                    {relations?.length
                        ? relations
                              .map((x) => {
                                  if (!x.first_name || !x.last_name) {
                                      return x.email_address;
                                  }
                                  return `${x.first_name || ''} ${x.last_name || ''}`;
                              })
                              .join(' ')
                        : 'Unassigned'}
                </td>
            );
        }
        if (x.value === 'partners') {
            const relations = data.relations?.filter((x) => x.type === 'partner');
            cells.push(
                <td title={'Partners'} style={{ width: 200, padding: 20 }}>
                    {relations?.length
                        ? relations
                              .map((x) => {
                                  if (!x.first_name || !x.last_name) {
                                      return x.email_address;
                                  }
                                  return `${x.first_name || ''} ${x.last_name || ''}`;
                              })
                              .join(' ')
                        : 'Unassigned'}
                </td>
            );
        }
        if (x.value === 'expected_fee') {
            cells.push(
                <td title={'Expected Fee'} style={{ width: 200, padding: 20 }}>
                    <Input
                        value={data.additional_data?.expected_fee}
                        onChange={(e) => {
                            const tasks_for_list = [...state.tasks_for_list];
                            tasks_for_list[idx].additional_data.expected_fee = e.target.value;
                            updateState({
                                ...state,
                                tasks_for_list,
                                fieldChanged: 'expected_fee',
                            });
                        }}
                        onBlur={async () => {
                            if (state.fieldChanged !== 'expected_fee') return;

                            await updateTaskAndSort(
                                data,
                                {
                                    additional_data: {
                                        expected_fee: state.tasks_for_list[idx].additional_data.expected_fee,
                                    },
                                },
                                {
                                    ...state,
                                },
                                updateState
                            );
                        }}
                        placeholder="Enter the expected fee"
                        type="number"
                        sx={{
                            width: 200,
                            fontWeight: '400',
                            border: `1px solid #a3a69b`,
                        }}
                    />
                </td>
            );
        }
        if (x.value === 'expected_date_billed') {
            cells.push(
                <td title={'Expected Date Billed'} style={{ width: 100, padding: 20 }}>
                    <Input
                        type="date"
                        value={
                            data.additional_data?.expected_date_billed
                                ? moment(data.additional_data?.expected_date_billed).format('YYYY-MM-DD')
                                : ''
                        }
                        onChange={(e) => {
                            const tasks_for_list = [...state.tasks_for_list];
                            tasks_for_list[idx].additional_data.expected_date_billed = e.target.value;
                            updateState({
                                ...state,
                                tasks_for_list,
                                fieldChanged: 'expected_date_billed',
                            });
                        }}
                        onBlur={async () => {
                            if (state.fieldChanged !== 'expected_date_billed') return;

                            await updateTaskAndSort(
                                data,
                                {
                                    additional_data: {
                                        expected_date_billed:
                                            state.tasks_for_list[idx].additional_data.expected_date_billed,
                                    },
                                },
                                {
                                    ...state,
                                },
                                updateState
                            );
                        }}
                        sx={{
                            width: 200,
                            fontWeight: '400',
                            border: `1px solid #a3a69b`,
                        }}
                    />
                </td>
            );
        }

        if (x.value?.startsWith('client__')) {
            const keyInClientData = x.value?.split('client__')[1];

            let label = ((state?.organisation || {})?.schema || {})[keyInClientData]?.label;
            const typeOfEditableItem = ((state?.organisation || {})?.schema || {})[keyInClientData]?.type;

            let valueToRender = data.client?.client_data?.[keyInClientData];

            if (typeOfEditableItem === 'dropdown') {
                valueToRender = ((state?.organisation || {})?.schema || {})[keyInClientData]?.dropdownOptions?.find(
                    (x) => x.id === valueToRender
                )?.label;
            }

            cells.push(
                <td title={label} style={{ width: 200, textAlign: 'left', padding: 20 }}>
                    {valueToRender}
                </td>
            );
        }
    }

    return (
        <tr
            style={{
                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                borderLeft: 'drop-shadow(0 0 0.18rem #015dc5)',
            }}
        >
            {cells}
        </tr>
    );
};

export default TaskInListView;
