/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import * as uuid from 'uuid';
import Identicon from 'identicon.js';
import { PieChart, Pie, Cell, Legend, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

import { useNavigate } from 'react-router-dom';
import { Button, Flex, Image, Input, Label, Paragraph, Select } from 'theme-ui';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import Divider from '../components/divider';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import { defaultOverlayState, defaultSettings } from '../utils/default-states';
import axios from 'axios';
import moment from 'moment';
import MfaSetup from '../components/overlay/mfa/setup';
import Overlay from '../components/overlay/overlay';
import PortalQuestions from '../components/portal/questions';
import ClientSignature from '../components/signature/client-signature';
import PasswordValidation from '../components/password-validation';
import ReactSelect from 'react-select';
import mixpanel from 'mixpanel-browser';
import ListReceipts from '../components/receipts/list-receipts';
import { ucase } from '../utils/string-utils';

const defaultState = {
    loading: true,
    partialLoading: false,
    initialLoadComplete: false,
    error: null,
    questionnaires: [],
};

export const fireOffNotification = (text) => {
    axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/notifications/portal-activity`,
        method: 'POST',
        data: {
            text,
        },
    })
        .then(() => ({}))
        .catch((e) => console.error(e));
};

const handleFileChange = (state, updateState, localState, updateLocalState, client_uuid) => async (e) => {
    updateState({ ...state, loading: true });

    const filesToUpload = [...e.target.files];

    for (let index = 0; index < filesToUpload.length; index++) {
        const newFile = filesToUpload[index];

        const {
            data: { presigned_url },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/documents/portal`,
            method: 'POST',
            data: {
                content_type: newFile.type,
                file_name: newFile.name,
                action: 'share',
                client_uuid,
                send_email: index === 0,
            },
        });

        await axios.put(presigned_url, newFile, {
            headers: { 'Content-Type': newFile.type },
        });
    }

    const { data: organisation } = await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
    });

    updateState({ ...state, loading: false, organisation });

    updateLocalState({ ...localState, successMessage: true });
};

const UploadSharedDocuments = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({
        successMessage: null,
        client_selected: { value: state.organisation?.clients?.[0]?.id, label: state.organisation?.clients?.[0]?.name },
    });

    if (localState.successMessage) {
        return (
            <Flex sx={{ ml: 20, mt: 25, flexDirection: 'column', mr: 20, alignItems: 'center' }}>
                <Paragraph sx={{ fontSize: 18, fontWeight: 600, color: 'green', mb: 10 }}>
                    Successfully Uploaded!
                </Paragraph>
                <i
                    style={{
                        marginTop: '5px',
                        fontSize: '60px',
                        cursor: 'pointer',
                        color: 'green',
                        marginBottom: 20,
                    }}
                    className={'far fa-check-circle'}
                />
                <Button onClick={() => updateState({ ...state, showUploadDocumentOverlay: false })}>Close</Button>
            </Flex>
        );
    }

    return (
        <Flex sx={{ ml: 20, mt: 25, flexDirection: 'column', mr: 20 }}>
            <Paragraph sx={{ fontSize: 18, fontWeight: 600 }}>Upload documents</Paragraph>
            <Divider />
            <ReactSelect
                styles={{
                    control: (provided) => ({
                        ...provided,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                        width: [300, '480px'],
                        minHeight: '40px',
                        borderRadius: 10,
                        marginBottom: '30px',
                        border: '1px solid #a3a69b',
                        fontSize: '14px',
                    }),
                }}
                onChange={async (value) => {
                    updateLocalState({
                        ...localState,
                        client_selected: value,
                    });
                }}
                placeholder={'Select the client the document belongs to'}
                value={localState.client_selected}
                options={state.organisation?.clients?.map((client) => ({ value: client.id, label: client.name }))}
            />

            <Input
                id="file_input"
                style={{
                    fontSize: '15px',
                    border: '1px solid #ccc',
                }}
                type="file"
                multiple
                accept=".pdf,.txt,.docx,.xlsx,.jpeg,.jpg,.msg"
                onChange={handleFileChange(
                    state,
                    updateState,
                    localState,
                    updateLocalState,
                    localState.client_selected?.value
                )}
            />
        </Flex>
    );
};

const extractDocumentsToShare = (client_name, documents) => {
    let shareable = [];

    for (let idx = 0; idx < documents.length; idx++) {
        const document = documents[idx];
        document.client_name = client_name;
        if (document.type === 'file' && document.share) {
            shareable.push(document);
        }
        if (document.type !== 'file' && document.documents?.length) {
            shareable = [...shareable, ...extractDocumentsToShare(client_name, document.documents)];
        }
    }

    return shareable;
};

const SharedDocuments = ({ state, updateState }) => {
    let shareableDocs = [];

    for (let index = 0; index < state.organisation?.clients?.length; index++) {
        const client = state.organisation?.clients[index];
        shareableDocs = [
            ...shareableDocs,
            ...extractDocumentsToShare(client.name, client?.client_data?.file_browser_data?.documents || []),
        ];
    }

    const seen = new Set();
    shareableDocs = shareableDocs.filter((item) => {
        const keyValue = item.document_uuid;
        if (seen.has(keyValue)) {
            return false;
        } else {
            seen.add(keyValue);
            return true;
        }
    });

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            {state.showUploadDocumentOverlay && (
                <Overlay
                    copy={{}}
                    maxWidth={520}
                    maxHeight={480}
                    embeddedComponent={<UploadSharedDocuments state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showUploadDocumentOverlay: false })}
                />
            )}

            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Paragraph sx={{ fontWeight: 400, fontSize: [17, 22] }}>Shared Documents</Paragraph>
                <Button
                    onClick={() => updateState({ ...state, showUploadDocumentOverlay: true })}
                    sx={{ ml: 20 }}
                    variant="light"
                >
                    Upload documents
                </Button>
            </Flex>
            <Divider />
            {shareableDocs?.map((document) => (
                <Flex sx={{ flexDirection: 'column' }} key={`${document.client_name}_${document.document_uuid}`}>
                    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Flex sx={{ flexDirection: ['column', 'row'] }}>
                            <Paragraph>{document.client_name}</Paragraph>
                            <Paragraph sx={{ mt: [10, 'unset'], ml: ['unset', 20], width: [200, 'unset'] }}>
                                {document.name}
                            </Paragraph>
                        </Flex>
                        <Button
                            sx={{ fontSize: [13, 15] }}
                            onClick={async () => {
                                try {
                                    updateState({ ...state, loading: true, error: null });

                                    const {
                                        data: { url, file_name },
                                    } = await axios.get(
                                        `${process.env.REACT_APP_AQRU_AI_API}/documents/portal-user/${document.document_uuid}`
                                    );

                                    const a = window.document.createElement('a');
                                    a.href = url;
                                    a.download = file_name;
                                    a.click();

                                    updateState({ ...state, loading: false, error: null });
                                } catch (e) {
                                    console.log(e);
                                    updateState({
                                        ...state,
                                        loading: false,
                                        error: 'We have been unable to download your document',
                                    });
                                }
                            }}
                        >
                            Download
                        </Button>
                    </Flex>
                    <Divider />
                </Flex>
            ))}
        </Flex>
    );
};

const MyDetails = ({ state, updateState }) => {
    const contactData =
        state.organisation?.clients?.[0]?.client_data?.key_contacts?.find(
            (contact) => contact.email_address === state.organisation?.user?.email_address
        ) || {};

    const [localState, updateLocalState] = useState({ ...contactData });
    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Paragraph sx={{ fontWeight: 400, fontSize: 22 }}>My Details</Paragraph>
            <Divider />
            <Flex sx={{ alignItems: 'center', mb: 20, mt: 20 }}>
                <Label id="title-label" sx={{ width: [100, 200] }}>
                    Title
                </Label>
                <Select
                    sx={{
                        width: [220, 400],
                        height: 40,
                        backgroundColor: 'white',
                        borderRadius: 10,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
                    }}
                    value={localState.title}
                    onChange={(e) => updateLocalState({ ...localState, title: e.target.value })}
                >
                    <option value="">Select a title</option>
                    <option value="Mr">Mr</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                    <option value="Revd">Revd</option>
                    <option value="Lady">Lady</option>
                    <option value="Mx">Mx</option>
                </Select>
            </Flex>
            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Label id="first-name-label" sx={{ width: 200 }}>
                    First Name
                </Label>
                <Input
                    sx={{ width: 400 }}
                    aria-labelledby="first-name-label"
                    placeholder="Contact first name"
                    value={localState.first_name}
                    onChange={(e) => updateLocalState({ ...localState, first_name: e.target.value })}
                />
            </Flex>
            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Label id="middle-name-label" sx={{ width: 200 }}>
                    Middle Name
                </Label>
                <Input
                    sx={{ width: 400 }}
                    aria-labelledby="middle-name-label"
                    placeholder="Contact middle name"
                    value={localState.middle_name}
                    onChange={(e) => updateLocalState({ ...localState, middle_name: e.target.value })}
                />
            </Flex>
            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Label id="last-name-label" sx={{ width: 200 }}>
                    Last Name
                </Label>
                <Input
                    sx={{ width: 400 }}
                    aria-labelledby="last-name-label"
                    placeholder="Contact last name"
                    value={localState.last_name}
                    onChange={(e) => updateLocalState({ ...localState, last_name: e.target.value })}
                />
            </Flex>
            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Label id="preferred-name-label" sx={{ width: 200 }}>
                    Preferred Name
                </Label>
                <Input
                    sx={{ width: 400 }}
                    aria-labelledby="preferred-name-label"
                    placeholder="Contact preferred name"
                    value={localState.preferred_name}
                    onChange={(e) => updateLocalState({ ...localState, preferred_name: e.target.value })}
                />
            </Flex>
            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Label id="tel-label" sx={{ width: 200 }}>
                    Telephone number
                </Label>
                <Input
                    sx={{ width: 400 }}
                    aria-labelledby="tel-label"
                    placeholder="Contact telephone number"
                    value={localState.telephone_number}
                    onChange={(e) => updateLocalState({ ...localState, telephone_number: e.target.value })}
                />
            </Flex>

            <Flex sx={{ alignItems: 'center', mb: 20 }}>
                <Label id="jt-label" sx={{ width: 200 }}>
                    Job title
                </Label>
                <Input
                    sx={{ width: 400 }}
                    aria-labelledby="jt-label"
                    placeholder="Contact job title e.g. CEO"
                    value={localState.job_title}
                    onChange={(e) => updateLocalState({ ...localState, job_title: e.target.value })}
                />
            </Flex>

            <Flex sx={{ alignItems: 'flex-start', mb: 20 }}>
                <Label id="addr-label" sx={{ width: [105, 200], mt: 10 }}>
                    Address
                </Label>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Input
                        sx={{ width: [215, 400] }}
                        aria-labelledby="addr-label"
                        placeholder="First line of the address"
                        value={localState.address?.line1}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                address: {
                                    ...(localState.address || {}),
                                    line1: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        sx={{ width: [215, 400], mt: 10 }}
                        aria-labelledby="addr-label"
                        placeholder="City"
                        value={localState.address?.city}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                address: {
                                    ...(localState.address || {}),
                                    city: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        sx={{ width: [215, 400], mt: 10 }}
                        aria-labelledby="addr-label"
                        placeholder="Postal code"
                        value={localState.address?.postcode}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                address: {
                                    ...(localState.address || {}),
                                    postcode: e.target.value,
                                },
                            })
                        }
                    />
                </Flex>
            </Flex>
            <Button
                onClick={async () => {
                    await updateClient(localState, updateLocalState, state, updateState);
                }}
                sx={{ width: 100, mt: 30, ml: [0, 500] }}
            >
                Update
            </Button>
        </Flex>
    );
};

const updateClient = async (localState, updateLocalState, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        await Promise.all([
            ...(state.organisation?.clients?.map((client) => {
                return axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${client?.raw_id}/users/foo`,
                    method: 'PUT',
                    data: {
                        ...localState,
                    },
                });
            }) || []),
        ]);

        updateState({ ...state, loading: false });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We have been unable to update your data' });
    }
};

const loadData = async (state, updateState, initialLoad = false) => {
    try {
        updateState({ ...state, loading: true });

        const { data: organisation } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
        });

        if (organisation?.user_type === 'client_user') {
            const clients = [];
            let res = await Promise.all([
                axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/docsign`,
                }),
                ...(organisation?.clients?.map((client) => {
                    if (clients?.find((x) => x.raw_id === client?.raw_id)) {
                        return Promise.resolve({ data: [] });
                    }
                    clients.push(client);
                    return axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${client?.raw_id}/users/foo/qs`,
                    });
                }) || []),
            ]);

            let questionnaires = [];
            for (let index = 1; index < res.length; index++) {
                const element = res[index];
                questionnaires = [
                    ...questionnaires,
                    ...(element.data.questionnaires || [])?.map((x) => ({
                        ...x,
                        client_name: clients[index - 1]?.name,
                    })),
                ];
            }

            const questionnaire_groups = {};
            for (const questionnaire of questionnaires || []) {
                const client_id = questionnaire.client_id;
                if (!questionnaire_groups[client_id]) {
                    questionnaire_groups[client_id] = [];
                }
                questionnaire_groups[client_id].push(questionnaire);
            }

            const document_groups = {};
            for (const document of res[0]?.data?.documents || []) {
                const client_id = document.client?.raw_id;
                if (!document_groups[client_id]) {
                    document_groups[client_id] = [];
                }
                document_groups[client_id].push(document);
            }

            updateState({
                ...state,
                loading: false,
                organisation,
                questionnaires: questionnaires.sort((x, y) => new Date(y.created_at) - new Date(x.created_at)),
                documents: res[0]?.data?.documents,
                document_groups,
                questionnaire_groups,
                userId: organisation.user_id,
                initialLoadComplete: initialLoad ? true : state.initialLoadComplete,
            });
        } else {
            const [{ data }] = await Promise.all([
                (async () => {
                    try {
                        const response = await axios({
                            url: `${process.env.REACT_APP_AQRU_AI_API}/dashboard`,
                        });
                        return response;
                    } catch (e) {
                        return { data: { activity: [], user_task_totals: {}, tasks_by_status_processed: {} } };
                    }
                })(),
            ]);

            const baseUrl = `https://aflow-avatars-${
                process.env.REACT_APP_IS_LIVE === '1' ? 'prod' : 'uat'
            }.s3.eu-west-1.amazonaws.com`;

            if (data.activity?.length) {
                for (const item of data.activity || []) {
                    if (item?.additional_data?.avatar_key) {
                        const res = await axios.get(`${baseUrl}/${item?.additional_data?.avatar_key}`);
                        item.avatarData = res.data;
                    }
                }
            }

            updateState({
                ...state,
                loading: false,
                organisation,
                data: {
                    ...data,
                    user_task_totals: data.user_task_totals,
                    tasks_by_status: data.tasks_by_status_processed,
                },
            });
        }
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We are currently unable to load your data' });
    }
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const Home = ({ userData, dismissTopMenu, refreshCoreData }) => {
    const [state, updateState] = useState({
        ...defaultState,
        view: 'list',
        dataToShow: 'documents',
        sectionsOpen: [0],
    });
    const [user, updateUser] = useState(userData);
    const [overlay, updateOverlay] = useState(defaultOverlayState);

    const [settingsJourney, updateSettingsJourney] = useState(defaultSettings);
    const navigate = useNavigate();

    const headerRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                let authenticatedUser;
                try {
                    authenticatedUser = await Auth.currentAuthenticatedUser();
                } catch (exxx) {
                    authenticatedUser = await Auth.currentAuthenticatedUser();
                }

                try {
                    mixpanel.identify(authenticatedUser?.username);
                } catch (exx) {
                    // suppress
                }

                const attributes = authenticatedUser?.attributes || {};

                if (authenticatedUser?.preferredMFA === 'NOMFA' && attributes?.['custom:source_v2'] !== 'portal_user') {
                    return updateState({ ...state, mfaSetupRequired: true });
                }

                updateUser(attributes);
                refreshCoreData();

                try {
                    await loadData(state, updateState, true);
                } catch (e) {
                    console.log(e);
                    mixpanel.track('dashboard_error', { message: e.message });
                }
            } catch (e) {
                if (
                    (e && e?.includes && e?.includes('not authenticated')) ||
                    (e && e.message && e?.message?.includes('not authenticated'))
                ) {
                    window.location.assign('/');
                }
                console.log('here', e);
                mixpanel.track('dashboard_error_2', { message: e.message });
            }
        })();
    }, []);

    useEffect(() => {
        if (state.dataToShow === 'receipts') return;

        (async () => {
            try {
                if (state.initialLoadComplete) {
                    await loadData(state, updateState, false);
                }
            } catch (e) {
                mixpanel.track('dashboard_error_initialLoadComplete', { message: e.message });
                console.log(e);
            }
        })();
    }, [state.dataToShow]);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (headerRef.current && !headerRef.current.contains(e.target)) {
                // setIsVisible(false);
                dismissTopMenu();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick, false);
        return () => {
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick, false);
            };
        };
    }, []);

    if (state.userDoesNotBelongToAnOrganisation) {
        if (document.getElementById('logo_wrapper')) {
            document.getElementById('logo_wrapper').style.display = 'none';
        }
        return (
            <Flex sx={{ width: '100vw', height: '100vh' }}>
                <Flex
                    sx={{
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        alignItems: 'center',
                        padding: 40,
                        flexDirection: 'column',
                        margin: '0 auto',
                        width: 600,
                    }}
                >
                    <Image
                        sx={{ mt: 40, width: 300, mb: 0, ml: 0 }}
                        src={'https://cdn.accru.finance/af/logo-dark.png'}
                        alt=""
                    />
                    <Paragraph sx={{ mt: 50, fontWeight: 400, fontSize: 20 }}>
                        Welcome to Accounting Flow. You're almost there!
                    </Paragraph>
                    <Paragraph sx={{ mt: 30, mb: 30, fontWeight: 400, textAlign: 'center' }}>
                        You currently don't belong to an organisation yet. You should receive an invite to join your
                        organisation shortly.
                    </Paragraph>
                    <Button onClick={() => logout()}>Sign out</Button>
                </Flex>
            </Flex>
        );
    }

    if (state.mfaSetupRequired) {
        if (document.getElementById('logo_wrapper')) {
            document.getElementById('logo_wrapper').style.display = 'none';
        }
        return (
            <Flex
                sx={{
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    alignItems: 'center',
                    padding: 40,
                    margin: '0 auto',
                    width: 600,
                    mt: 60,
                }}
            >
                <Image
                    sx={{ mt: 40, width: 300, mb: 0, ml: 0, cursor: 'pointer' }}
                    src={'https://cdn.accru.finance/af/logo-dark.png'}
                    alt=""
                />
                <Paragraph sx={{ mt: 30, fontWeight: 400, fontSize: 20 }}>
                    Welcome to Accounting Flow. You're almost there!
                </Paragraph>
                <Paragraph sx={{ mt: 20, mb: 10, fontWeight: 400, fontSize: 20 }}>
                    To keep you and your data safe, it's time to setup MFA
                </Paragraph>
                <a
                    rel="noreferrer"
                    href="https://support.microsoft.com/en-gb/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a"
                    target="_blank"
                >
                    How to setup Microsoft Authenticator
                </a>
                <MfaSetup />
            </Flex>
        );
    }

    if (!state.organisation) {
        if (document.getElementById('logo_wrapper')) {
            document.getElementById('logo_wrapper').style.display = 'none';
        }
        return (
            <Flex
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: '#FFF',
                    justifyContent: 'center',
                }}
            >
                <Spinner sx={{ ml: '45%' }} />
            </Flex>
        );
    }

    if (state.organisation?.user_type === 'client_user') {
        return (
            <Flex
                sx={{
                    minHeight: '100vh',
                    height: 'auto',
                    paddingBottom: '0px',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: 'primary',
                    position: 'relative',
                    overflowX: ['unset', 'scroll'],
                }}
                data-testid="home-screen"
            >
                {state.loading && <Spinner />}

                <Flex
                    sx={{
                        minHeight: 'calc(100vh)',
                        height: 'auto',
                        width: '100vw',
                        overflowX: ['unset', 'scroll'],
                        flexDirection: 'column',
                        backgroundColor: '#FFF',
                    }}
                >
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            height: 'auto',
                            mt: 100,
                            ml: [15, 30],
                        }}
                    >
                        {['viewAnswers', 'editQuestionnaire'].includes(state.view) && (
                            <PortalQuestions
                                state={state}
                                updateState={updateState}
                                organisation={state.organisation}
                            />
                        )}

                        {state.view === 'editDocument' && (
                            <ClientSignature
                                state={state}
                                updateState={updateState}
                                organisation={state.organisation}
                            />
                        )}

                        {state.view === 'list' && (
                            <Flex sx={{ flexDirection: 'column', mb: 50, width: ['100%', 1200] }}>
                                <Paragraph sx={{ fontWeight: 600, fontSize: 25, mb: 20, mt: 40, width: [300, 400] }}>
                                    Welcome{' '}
                                    {state.organisation?.clients?.length === 1
                                        ? state.organisation?.user?.first_name
                                        : ''}
                                </Paragraph>

                                <Paragraph
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.5)',
                                        fontWeight: 600,
                                        fontSize: 18,
                                        mb: 0,
                                        mt: 20,
                                        width: [300, 400],
                                    }}
                                >
                                    CLIENT PORTAL
                                </Paragraph>

                                <Divider sx={{ width: ['300px', '100%'] }} />

                                <Flex
                                    sx={{
                                        mt: 20,
                                        mb: '0',
                                        pb: '0',
                                        height: ['200px', '60px'],
                                        borderRadius: '10px',
                                        border: '1px solid lightgray',
                                        borderBottom: '0px solid lightgray',
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 12,
                                        width: ['92%', 1100],
                                        flexDirection: ['column', 'row'],
                                    }}
                                >
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: ['flex-start', 'center'],
                                            alignItems: 'center',
                                            borderRight: [0, '1px solid lightgray'],
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            height: [60],
                                            width: ['100%', 'auto'],
                                            borderTopLeftRadius: 12,
                                            borderTopRightRadius: [12, 'unset'],
                                            borderBottom: [
                                                state.dataToShow === 'documents'
                                                    ? '6px solid #e8b923'
                                                    : '1px solid #ccc',
                                                state.dataToShow === 'documents'
                                                    ? '6px solid #e8b923'
                                                    : '6px solid #fff',
                                            ],
                                        }}
                                        onClick={() => updateState({ ...state, dataToShow: 'documents' })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: ['14px', '14px'],
                                                color: state.dataToShow === 'documents' ? 'primary' : 'text',
                                                fontWeight: state.dataToShow === 'documents' ? '700' : '400',
                                            }}
                                        >
                                            Documents (
                                            {state?.documents?.filter((x) => x.status !== 'finalised')?.length || 0})
                                        </Paragraph>
                                    </Flex>
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: ['flex-start', 'center'],
                                            borderRight: [0, '1px solid lightgray'],
                                            cursor: 'pointer',
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                            width: ['100%', 'auto'],
                                            height: 60,
                                            borderBottom: [
                                                state.dataToShow === 'questionnaires'
                                                    ? '6px solid #e8b923'
                                                    : '1px solid #ccc',
                                                state.dataToShow === 'questionnaires'
                                                    ? '6px solid #e8b923'
                                                    : '6px solid #fff',
                                            ],
                                        }}
                                        onClick={() => updateState({ ...state, dataToShow: 'questionnaires' })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: ['14px', '14px'],
                                                alignSelf: 'center',
                                                color: state.dataToShow === 'questionnaires' ? 'primary' : 'text',
                                                fontWeight: state.dataToShow === 'questionnaires' ? '700' : '400',
                                            }}
                                        >
                                            Questionnaires (
                                            {state?.questionnaires?.filter((x) => x.status === 'todo')?.length || 0})
                                        </Paragraph>
                                    </Flex>
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: ['flex-start', 'center'],
                                            borderRight: [0, '1px solid lightgray'],
                                            cursor: 'pointer',
                                            width: ['100%', 'auto'],
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                            height: 60,
                                            borderBottom: [
                                                state.dataToShow === 'receipts'
                                                    ? '6px solid #e8b923'
                                                    : '1px solid #ccc',
                                                state.dataToShow === 'receipts'
                                                    ? '6px solid #e8b923'
                                                    : '6px solid #fff',
                                            ],
                                        }}
                                        onClick={() => updateState({ ...state, dataToShow: 'receipts' })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: ['14px', '14px'],
                                                alignSelf: 'center',
                                                color: state.dataToShow === 'receipts' ? 'primary' : 'text',
                                                fontWeight: state.dataToShow === 'receipts' ? '700' : '400',
                                            }}
                                        >
                                            Expenses
                                        </Paragraph>
                                    </Flex>
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: ['flex-start', 'center'],
                                            borderRight: [0, '1px solid lightgray'],
                                            cursor: 'pointer',
                                            width: ['100%', 'auto'],
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                            height: 60,
                                            borderBottom: [
                                                state.dataToShow === 'my_details'
                                                    ? '6px solid #e8b923'
                                                    : '1px solid #ccc',
                                                state.dataToShow === 'my_details'
                                                    ? '6px solid #e8b923'
                                                    : '6px solid #fff',
                                            ],
                                        }}
                                        onClick={() => updateState({ ...state, dataToShow: 'my_details' })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: ['14px', '14px'],
                                                alignSelf: 'center',
                                                color: state.dataToShow === 'my_details' ? 'primary' : 'text',
                                                fontWeight: state.dataToShow === 'my_details' ? '700' : '400',
                                            }}
                                        >
                                            My Details
                                        </Paragraph>
                                    </Flex>
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: ['flex-start', 'center'],
                                            borderRight: [0, '1px solid lightgray'],
                                            cursor: 'pointer',
                                            width: ['100%', 'auto'],
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                            height: 60,
                                            borderBottom: [
                                                state.dataToShow === 'shared_documents'
                                                    ? '6px solid #e8b923'
                                                    : '1px solid #ccc',
                                                state.dataToShow === 'shared_documents'
                                                    ? '6px solid #e8b923'
                                                    : '6px solid #fff',
                                            ],
                                        }}
                                        onClick={() => updateState({ ...state, dataToShow: 'shared_documents' })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: ['14px', '14px'],
                                                alignSelf: 'center',
                                                color: state.dataToShow === 'shared_documents' ? 'primary' : 'text',
                                                fontWeight: state.dataToShow === 'shared_documents' ? '700' : '400',
                                            }}
                                        >
                                            Shared Documents
                                        </Paragraph>
                                    </Flex>
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: ['flex-start', 'center'],
                                            borderRight: [0, '1px solid lightgray'],
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            justifyContent: 'center',
                                            width: ['100%', 'auto'],
                                            height: 60,
                                            borderTopRightRadius: 12,
                                            borderBottom: [
                                                state.dataToShow === 'settings'
                                                    ? '6px solid #e8b923'
                                                    : '1px solid #fff',
                                                state.dataToShow === 'settings'
                                                    ? '6px solid #e8b923'
                                                    : '6px solid #fff',
                                            ],
                                        }}
                                        onClick={() => updateState({ ...state, dataToShow: 'settings' })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: ['14px', '14px'],
                                                alignSelf: 'center',
                                                color: state.dataToShow === 'settings' ? 'primary' : 'text',
                                                fontWeight: state.dataToShow === 'settings' ? '700' : '400',
                                            }}
                                        >
                                            Settings
                                        </Paragraph>
                                    </Flex>
                                </Flex>

                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        backgroundColor: 'white',
                                        borderBottomLeftRadius: 12,
                                        borderBottomRightRadius: 12,
                                        borderTopRightRadius: 12,
                                        paddingTop: 10,
                                        paddingBottom: 50,
                                        paddingLeft: [15, 30],
                                        paddingRight: 30,
                                        border: '1px solid lightgray',
                                        maxWidth: ['92%', 1200],
                                    }}
                                >
                                    {state.dataToShow === 'documents' && state.documents?.length ? (
                                        <>
                                            <Paragraph
                                                sx={{
                                                    fontWeight: 400,
                                                    mt: 20,
                                                    fontSize: 22,
                                                    width: [280, '100%'],
                                                }}
                                            >
                                                Documents To Sign
                                            </Paragraph>

                                            <Divider mb={10} sx={{ width: [280, '100%'] }} />

                                            <Flex
                                                sx={{
                                                    mb: '0',
                                                    pb: '0',
                                                    borderRadius: '10px',
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    ml: '5px',
                                                    mt: '5px',
                                                    mr: '5px',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {Object.entries(state?.document_groups || {}).map(
                                                    ([key, value], groupIdx) => {
                                                        const clientHasFocus =
                                                            state.clientToShow === key ||
                                                            (state.clientToShow === undefined && groupIdx === 0);
                                                        return (
                                                            <Flex
                                                                key={`${value?.[0]?.id}`}
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    alignSelf: 'center',
                                                                    cursor: 'pointer',
                                                                    height: 60,
                                                                    maxWidth: 300,
                                                                    borderBottom: clientHasFocus
                                                                        ? '6px solid #e8b923'
                                                                        : '1px solid lightgray',
                                                                }}
                                                                onClick={() => {
                                                                    updateState({
                                                                        ...state,
                                                                        clientToShow: key,
                                                                    });
                                                                }}
                                                            >
                                                                <Paragraph
                                                                    sx={{
                                                                        textAlign: 'center',
                                                                        width: '100%',
                                                                        fontSize: '15px',
                                                                        pl: '10px',
                                                                        pr: '10px',
                                                                        pb: clientHasFocus ? '0px' : '5px',
                                                                        alignSelf: 'center',
                                                                        fontWeight: clientHasFocus ? '600' : '400',
                                                                    }}
                                                                >
                                                                    {value?.[0]?.client?.name}
                                                                </Paragraph>
                                                            </Flex>
                                                        );
                                                    }
                                                )}
                                            </Flex>

                                            <Paragraph
                                                sx={{
                                                    width: [280, 'unset'],
                                                    fontWeight: 400,
                                                    fontSize: [15, 15],
                                                    mt:
                                                        Object.entries(state?.document_groups || {})?.length > 1
                                                            ? 30
                                                            : 10,
                                                    mb: 10,
                                                }}
                                            >
                                                Please review and sign the following documents
                                            </Paragraph>
                                        </>
                                    ) : null}

                                    {state.dataToShow === 'documents' && !state.documents?.length && (
                                        <Paragraph sx={{ fontWeight: 400, fontSize: 20, mt: 40 }}>
                                            You currently have 0 documents to sign
                                        </Paragraph>
                                    )}

                                    {state.dataToShow === 'my_details' && (
                                        <MyDetails state={state} updateState={updateState} />
                                    )}

                                    {state.dataToShow === 'receipts' && (
                                        <ListReceipts state={state} updateState={updateState} />
                                    )}

                                    {state.dataToShow === 'shared_documents' && (
                                        <SharedDocuments state={state} updateState={updateState} />
                                    )}

                                    {state.dataToShow === 'settings' && (
                                        <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                                            <Paragraph sx={{ fontWeight: 400, fontSize: 22, mt: 10 }}>
                                                Settings
                                            </Paragraph>
                                            <Divider />
                                            <Paragraph sx={{ fontWeight: 400, fontSize: 18 }}>
                                                Change Password
                                            </Paragraph>

                                            <Flex sx={{ alignItems: 'center', mt: 20 }}>
                                                <Input
                                                    id="old-password"
                                                    type={state.oldPasswordType || 'password'}
                                                    placeholder="Enter your old password"
                                                    sx={{ width: 300, mr: 30 }}
                                                    value={state.oldPassword}
                                                    onChange={(e) =>
                                                        updateState({ ...state, oldPassword: e.target.value })
                                                    }
                                                />
                                                <Image
                                                    sx={{ width: '25px', cursor: 'pointer' }}
                                                    src="https://cdn.accru.finance/registration/elipsis.png"
                                                    alt="Click to show password"
                                                    onClick={() =>
                                                        updateState({
                                                            ...state,
                                                            oldPasswordType:
                                                                state.oldPasswordType === 'password'
                                                                    ? 'text'
                                                                    : 'password',
                                                        })
                                                    }
                                                />
                                            </Flex>

                                            <Flex sx={{ alignItems: 'center', mt: 10, mb: 10 }}>
                                                <Input
                                                    id="new-password"
                                                    type={state.newPasswordType || 'password'}
                                                    placeholder="Enter your new password"
                                                    sx={{ width: 300, mr: 30 }}
                                                    value={state.newPassword}
                                                    onChange={(e) =>
                                                        updateState({ ...state, newPassword: e.target.value })
                                                    }
                                                />
                                                <Image
                                                    sx={{ width: '25px', cursor: 'pointer' }}
                                                    src="https://cdn.accru.finance/registration/elipsis.png"
                                                    alt="Click to show password"
                                                    onClick={() =>
                                                        updateState({
                                                            ...state,
                                                            newPasswordType:
                                                                state.newPasswordType === 'password'
                                                                    ? 'text'
                                                                    : 'password',
                                                        })
                                                    }
                                                />
                                            </Flex>

                                            <PasswordValidation password={state.newPassword} />

                                            <Button
                                                onClick={async () => {
                                                    try {
                                                        updateState({
                                                            ...state,
                                                            loading: true,
                                                            changePasswordSuccess: false,
                                                            changePasswordError: false,
                                                        });

                                                        await Auth.changePassword(
                                                            await Auth.currentAuthenticatedUser(),
                                                            document.getElementById('old-password').value,
                                                            document.getElementById('new-password').value
                                                        );

                                                        updateState({
                                                            ...state,
                                                            loading: false,
                                                            changePasswordSuccess: true,
                                                            changePasswordError: false,
                                                            oldPassword: null,
                                                            newPassword: null,
                                                        });
                                                    } catch (e) {
                                                        updateState({
                                                            ...state,
                                                            loading: false,
                                                            changePasswordSuccess: false,
                                                            changePasswordError: e?.message,
                                                        });

                                                        setTimeout(() => {
                                                            updateState({
                                                                ...state,
                                                                loading: false,
                                                                changePasswordSuccess: false,
                                                                changePasswordError: false,
                                                            });
                                                        }, 1500);
                                                    }
                                                }}
                                                sx={{ width: 100, height: 40, fontSize: 14, mt: 20 }}
                                            >
                                                Save
                                            </Button>
                                            {state.changePasswordSuccess && (
                                                <Paragraph sx={{ mt: 20, color: 'green' }}>Password updated</Paragraph>
                                            )}
                                            {state.changePasswordError && (
                                                <Paragraph sx={{ mt: 20, color: 'red' }}>
                                                    {state.changePasswordError}
                                                </Paragraph>
                                            )}
                                        </Flex>
                                    )}

                                    {state.dataToShow === 'documents' &&
                                        state?.document_groups?.[
                                            state.clientToShow === undefined
                                                ? Object.entries(state.document_groups)[0][0]
                                                : state.clientToShow
                                        ]?.map((x, idx) => {
                                            return (
                                                <Flex
                                                    key={`doc_${idx}`}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        border: '1px solid lightGrey',
                                                        borderRadius: 12,
                                                        minHeight: '130px',
                                                        width: [280, 700, 1000],
                                                        alignItems: 'center',
                                                        mt: 20,
                                                        padding: ['20px 10px', '20px'],
                                                        flexDirection: ['column', 'row'],
                                                        justifyContent: ['flex-start', 'space-between'],
                                                    }}
                                                >
                                                    <Flex
                                                        sx={{
                                                            ml: [10, 'unset'],
                                                            flexDirection: 'column',
                                                            alignSelf: ['flex-start', 'unset'],
                                                        }}
                                                    >
                                                        <Paragraph
                                                            sx={{
                                                                fontSize: [18, 22],
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            {x.name}
                                                        </Paragraph>
                                                        <Paragraph sx={{ mt: 10 }}>
                                                            {x.description?.length > 151
                                                                ? `${x.description.slice(0, 150)}...`
                                                                : x.description}
                                                        </Paragraph>
                                                    </Flex>
                                                    <Flex
                                                        sx={{ alignSelf: ['flex-start', 'unset'], ml: [10, 'unset'] }}
                                                    >
                                                        {x.definition?.status === 'completed' && (
                                                            <Paragraph
                                                                sx={{ color: 'green', alignSelf: 'center', mr: 20 }}
                                                            >
                                                                COMPLETED
                                                            </Paragraph>
                                                        )}
                                                        {x.status !== 'finalised' && (
                                                            <Button
                                                                onClick={() => {
                                                                    fireOffNotification(
                                                                        `has opened document: ${x.name}`
                                                                    );
                                                                    updateState({
                                                                        ...state,
                                                                        docsign: decorateDoc(
                                                                            x,
                                                                            state.organisation?.user_id
                                                                        ),
                                                                        view: 'editDocument',
                                                                        showFinaliseConfirmation: false,
                                                                    });
                                                                }}
                                                                variant="light"
                                                                sx={{
                                                                    width: 130,
                                                                    height: 40,
                                                                    mt: [20, 'unset'],
                                                                }}
                                                            >
                                                                Let's Go
                                                            </Button>
                                                        )}
                                                        {x.status === 'finalised' && (
                                                            <Button
                                                                onClick={async () => {
                                                                    // await downloadFinalisedDoc(x, state, updateState);
                                                                    updateState({
                                                                        ...state,
                                                                        docsign: x,
                                                                        view: 'editDocument',
                                                                    });
                                                                }}
                                                                sx={{
                                                                    width: 130,
                                                                    height: 45,
                                                                    fontSize: 14,
                                                                    mt: [20, 'unset'],
                                                                }}
                                                            >
                                                                View
                                                            </Button>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            );
                                        })}

                                    {state.dataToShow === 'questionnaires' && state.questionnaires?.length ? (
                                        <>
                                            <Paragraph sx={{ fontWeight: 400, mt: 20, fontSize: 22 }}>
                                                Questionnaires
                                            </Paragraph>
                                            <Divider mb={10} />

                                            <Flex
                                                sx={{
                                                    mb: '0',
                                                    pb: '0',
                                                    borderRadius: '10px',
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                    ml: '5px',
                                                    mt: '5px',
                                                    mr: '5px',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {Object.entries(state?.questionnaire_groups || {}).map(
                                                    ([key, value], groupIdx) => {
                                                        const clientHasFocus =
                                                            state.clientToShowForQuestionnaires === key ||
                                                            (state.clientToShowForQuestionnaires === undefined &&
                                                                groupIdx === 0);
                                                        return (
                                                            <Flex
                                                                key={`${value?.[0]?.id}`}
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    alignSelf: 'center',
                                                                    cursor: 'pointer',
                                                                    height: 60,
                                                                    maxWidth: 300,
                                                                    borderBottom: clientHasFocus
                                                                        ? '6px solid #e8b923'
                                                                        : '1px solid lightgray',
                                                                }}
                                                                onClick={() => {
                                                                    updateState({
                                                                        ...state,
                                                                        clientToShowForQuestionnaires: key,
                                                                    });
                                                                }}
                                                            >
                                                                <Paragraph
                                                                    sx={{
                                                                        textAlign: 'center',
                                                                        width: '100%',
                                                                        fontSize: '15px',
                                                                        pl: '10px',
                                                                        pr: '10px',
                                                                        pb: clientHasFocus ? '0px' : '5px',
                                                                        alignSelf: 'center',
                                                                        fontWeight: clientHasFocus ? '600' : '400',
                                                                    }}
                                                                >
                                                                    {value?.[0]?.client_name}
                                                                </Paragraph>
                                                            </Flex>
                                                        );
                                                    }
                                                )}
                                            </Flex>

                                            <Paragraph
                                                sx={{
                                                    width: [300, 'unset'],
                                                    fontWeight: 400,
                                                    fontSize: 15,
                                                    mt: 30,
                                                    mb: 10,
                                                }}
                                            >
                                                Please complete the following questionnaires
                                            </Paragraph>
                                        </>
                                    ) : null}

                                    {state.dataToShow === 'questionnaires' && !state.questionnaires?.length && (
                                        <Paragraph sx={{ fontWeight: 400, fontSize: 20, mt: 40 }}>
                                            You currently have 0 questionnaires to complete
                                        </Paragraph>
                                    )}

                                    {state.dataToShow === 'questionnaires' &&
                                        state?.questionnaire_groups?.[
                                            state.clientToShowForQuestionnaires === undefined
                                                ? Object.entries(state.questionnaire_groups)[0][0]
                                                : state.clientToShowForQuestionnaires
                                        ]?.map((x, idx) => (
                                            <Flex
                                                key={`qu_${idx}`}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    border: '1px solid lightGrey',
                                                    borderRadius: 12,
                                                    minHeight: '130px',
                                                    width: [300, 700, 1000],
                                                    mt: 20,
                                                    padding: '30px 20px',
                                                    flexDirection: ['column', 'row'],
                                                    justifyContent: ['flex-start', 'space-between'],
                                                    alignItems: ['flex-start', 'center'],
                                                }}
                                            >
                                                <Flex sx={{ flexDirection: 'column' }}>
                                                    <Paragraph sx={{ fontSize: [18, 22], fontWeight: 600 }}>
                                                        {x.name}
                                                    </Paragraph>
                                                    <Paragraph sx={{ mt: 10, width: [200, 600, 700] }}>
                                                        {x.definition?.description?.length > 151
                                                            ? `${x.definition?.description.slice(0, 150)}...`
                                                            : x.definition?.description}
                                                    </Paragraph>
                                                </Flex>
                                                <Flex
                                                    sx={{
                                                        mt: [10, 'unset'],
                                                        ml: [0, 'unset'],
                                                        alignSelf: ['flex-start', 'unset'],
                                                    }}
                                                >
                                                    {x?.status === 'completed' && (
                                                        <Paragraph
                                                            sx={{
                                                                color: 'green',
                                                                alignSelf: 'center',
                                                                mr: [40, 40, 20],
                                                            }}
                                                        >
                                                            COMPLETED
                                                        </Paragraph>
                                                    )}
                                                    {x?.status !== 'completed' && (
                                                        <Button
                                                            onClick={() => {
                                                                fireOffNotification(
                                                                    `has opened questionnaire: ${x.name}`
                                                                );
                                                                updateState({
                                                                    ...state,
                                                                    questionnaire: {
                                                                        ...x,
                                                                        definition: {
                                                                            ...x.definition,
                                                                            questions: x.definition.questions?.map(
                                                                                (question) => {
                                                                                    if (!question.uuid) {
                                                                                        question.uuid = uuid.v4();
                                                                                    }
                                                                                    return question;
                                                                                }
                                                                            ),
                                                                        },
                                                                    },
                                                                    view: 'editQuestionnaire',
                                                                });
                                                            }}
                                                            sx={{ width: 130, height: 45 }}
                                                            variant="light"
                                                        >
                                                            Let's Go
                                                        </Button>
                                                    )}
                                                    {x?.status === 'completed' && (
                                                        <Flex sx={{ flexDirection: 'column' }}>
                                                            <Button
                                                                onClick={async () => {
                                                                    updateState({ ...state, loading: true });
                                                                    await axios({
                                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/foo/users/foo/qs/${x?.id}`,
                                                                        method: 'PUT',
                                                                        data: { unlock: true },
                                                                    });

                                                                    updateState({
                                                                        ...state,
                                                                        questionnaires: state.questionnaires.map(
                                                                            (q) => {
                                                                                if (q.id === x.id) {
                                                                                    q.status = 'todo';
                                                                                }
                                                                                return q;
                                                                            }
                                                                        ),
                                                                        loading: false,
                                                                    });
                                                                }}
                                                                sx={{ width: 130, height: 45 }}
                                                                variant="light"
                                                            >
                                                                Unlock
                                                            </Button>
                                                            <Button
                                                                sx={{ mt: 10, height: 45, fontSize: 14 }}
                                                                onClick={() => {
                                                                    fireOffNotification(
                                                                        `has opened questionnaire: ${x.name}`
                                                                    );
                                                                    updateState({
                                                                        ...state,
                                                                        questionnaire: {
                                                                            ...x,
                                                                            definition: {
                                                                                ...x.definition,
                                                                                questions: x.definition.questions?.map(
                                                                                    (question) => {
                                                                                        if (!question.uuid) {
                                                                                            question.uuid = uuid.v4();
                                                                                        }
                                                                                        return question;
                                                                                    }
                                                                                ),
                                                                            },
                                                                        },
                                                                        view: 'viewAnswers',
                                                                    });
                                                                }}
                                                            >
                                                                View Answers
                                                            </Button>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        ))}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    if (document.getElementById('logo_wrapper')) {
        document.getElementById('logo_wrapper').style.display = 'flex';
    }

    return (
        <Flex
            sx={{
                minHeight: '100vh',
                height: 'auto',
                paddingBottom: '0px',
                width: '100vw',
                flexDirection: 'column',
                backgroundColor: 'primary',
                position: 'relative',
            }}
            data-testid="home-screen"
        >
            {state.loading && <Spinner />}

            <Flex
                sx={{
                    minHeight: 'calc(100vh)',
                    height: 'auto',
                    width: '100vw',
                    flexDirection: 'row',
                }}
            >
                <Sidebar
                    currentPage="Home"
                    overlay={overlay}
                    accountSummary={null}
                    user={user}
                    copy={{}}
                    settingsJourney={settingsJourney}
                    updateSettingsJourney={updateSettingsJourney}
                />
                <Flex
                    sx={{
                        flexDirection: 'column',
                        height: 'auto',
                        width: '750px',
                        backgroundColor: '#F8F8F8',
                    }}
                >
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            ml: 160,
                            mt: 0,
                        }}
                    >
                        <Paragraph sx={{ fontWeight: 600, fontSize: 24, color: '#000', mt: 45 }}>Overview</Paragraph>
                        <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: '#000', mt: 50 }}>KPIs</Paragraph>

                        <Paragraph sx={{ mt: 30, color: '#708090', fontWeight: 500 }}>Current Clients</Paragraph>
                        <Paragraph sx={{ mt: 10, fontSize: 20, fontWeight: 600 }}>
                            {state.data?.clients_count || ''}
                        </Paragraph>
                        <Divider sx={{ width: 250, mt: 20, color: '#CCC' }} />

                        <Paragraph sx={{ mt: 30, color: '#708090', fontWeight: 500 }}>
                            Expected Fee Income {moment().format('YYYY')}
                        </Paragraph>
                        <Paragraph sx={{ mt: 10, fontSize: 20, fontWeight: 600 }}>
                            £
                            {state.data?.fee_income
                                ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(
                                      state.data?.fee_income
                                  )
                                : ''}
                        </Paragraph>
                        <Divider sx={{ width: 250, mt: 20, color: '#CCC' }} />

                        {state.data?.tasks_by_status?.length && (
                            <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: '#000', mt: 50 }}>
                                All Tasks
                            </Paragraph>
                        )}

                        <Flex sx={{ ml: -60, mt: -60, maxWidth: 395, mb: 80 }}>
                            {state.data?.tasks_by_status?.length && (
                                <PieChart cursor="pointer" width={400} height={400}>
                                    <Pie
                                        data={state.data?.tasks_by_status}
                                        cx={200}
                                        cy={200}
                                        innerRadius={70}
                                        outerRadius={100}
                                        fill="#8884d8"
                                        paddingAngle={5}
                                        dataKey="count"
                                        label
                                    >
                                        {state.data.tasks_by_status.map((entry, index) => (
                                            <Cell
                                                onClick={() => {
                                                    window.location.assign(
                                                        `/tasks?status=${entry.status}&status_label=${entry.name}`
                                                    );
                                                }}
                                                key={`cell-${index}`}
                                                fill={
                                                    COLORS[
                                                        state?.organisation?.default_visualisation?.statuses?.findIndex(
                                                            (x) => x.key === entry.status
                                                        ) % COLORS.length
                                                    ]
                                                }
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend wrapperStyle={{ width: 340, whiteSpace: 'break-spaces', marginLeft: 30 }} />
                                </PieChart>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex sx={{ flexDirection: 'column', height: 'auto', width: '100%', backgroundColor: '#FFF' }}>
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            ml: 70,
                            mb: 30,
                        }}
                    >
                        <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: '#000', mt: 50 }}>My Tasks</Paragraph>

                        <Flex sx={{ mt: 70, width: 800, height: state.data?.user_task_totals?.length ? 500 : 300 }}>
                            {state.data?.user_task_totals?.length ? (
                                <BarChart cursor="pointer" width={800} height={400} data={state.data?.user_task_totals}>
                                    <CartesianGrid strokeDasharray="4 4" />
                                    <XAxis fontSize={'14px'} dataKey="name" />
                                    <YAxis fontSize={'14px'} />
                                    <Tooltip />
                                    <Bar
                                        onClick={(data, index) => {
                                            window.location.assign(
                                                `/tasks?user_id=${state.organisation?.user?.raw_id}&status=${data.payload.id}&status_label=${data.payload.name}`
                                            );
                                        }}
                                        dataKey="count"
                                    >
                                        {state.data?.user_task_totals?.map((entry, index) => {
                                            return (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={
                                                        COLORS[
                                                            state?.organisation?.default_visualisation?.statuses?.findIndex(
                                                                (x) => x.label === entry?.name
                                                            ) % COLORS.length
                                                        ]
                                                    }
                                                />
                                            );
                                        })}
                                    </Bar>
                                </BarChart>
                            ) : (
                                <Paragraph>
                                    {state.loading ? '' : 'There are no tasks assigned to you at present ✅'}
                                </Paragraph>
                            )}
                        </Flex>
                        <Paragraph sx={{ fontWeight: 600, fontSize: 20, mb: 10, color: '#000', mt: -70 }}>
                            Recent Activity
                        </Paragraph>

                        {!state.data?.activity?.length ? (
                            <Paragraph>Your activity will appear here soon</Paragraph>
                        ) : null}

                        {state.data?.activity?.length ? (
                            <Flex sx={{ mb: 30 }}>
                                <Paragraph sx={{ mt: 30, color: '#708090', fontWeight: 500, width: 80 }}></Paragraph>
                                <Paragraph sx={{ mt: 30, color: '#708090', fontWeight: 500, width: 200 }}>
                                    User
                                </Paragraph>
                                <Paragraph sx={{ mt: 30, color: '#708090', fontWeight: 500, width: 400 }}>
                                    Activity
                                </Paragraph>
                                <Paragraph sx={{ ml: 30, mt: 30, color: '#708090', fontWeight: 500, width: 200 }}>
                                    When
                                </Paragraph>
                            </Flex>
                        ) : null}

                        {state.data?.activity &&
                            state.data?.activity?.map((x, idx) => {
                                let ago = Math.ceil(moment().diff(moment(x.created_at), 'minutes', true));
                                let agoUnits = 'mins';

                                if (ago > 60 * 24) {
                                    agoUnits = 'days';
                                    ago = Math.ceil(moment().diff(moment(x.created_at), 'days', true));
                                } else if (ago > 120) {
                                    agoUnits = 'hours';
                                    ago = Math.ceil(moment().diff(moment(x.created_at), 'hours', true));
                                }
                                return (
                                    <Flex
                                        key={`activity_${idx}`}
                                        onClick={() => x.href && window.location.assign(x.href)}
                                        sx={{
                                            flexDirection: 'column',
                                            height: 50,
                                            mb: 60,
                                            cursor: x.href ? 'pointer' : 'auto',
                                        }}
                                    >
                                        <Flex sx={{ height: 50, mb: 20, alignItems: 'center', fontSize: 14 }}>
                                            <Flex sx={{ width: 80 }}>
                                                <Image
                                                    sx={{ width: 50, height: 50 }}
                                                    src={
                                                        x?.avatarData ||
                                                        `data:image/png;base64,${new Identicon(x?.email_address, 50)}`
                                                    }
                                                />
                                            </Flex>
                                            <Paragraph
                                                sx={{ width: 200 }}
                                            >{`${x.first_name} ${x.last_name}`}</Paragraph>
                                            <Paragraph sx={{ width: 400 }}>{ucase(x.description)}</Paragraph>
                                            <Paragraph sx={{ ml: 30, width: 200 }}>
                                                {ago} {agoUnits} ago
                                            </Paragraph>
                                        </Flex>
                                        <Divider sx={{ color: '#CCC', width: 800 }} />
                                    </Flex>
                                );
                            })}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const isDateType = (typeOfTextEntry) => ['date', 'date_with_slashes'].includes(typeOfTextEntry);

const decorateDoc = (doc, userId) => {
    doc.signature_placeholder_locations?.signatureLocations?.forEach((x) => {
        console.log(doc.signature_locations?.[userId]);
        const signedLocation = doc.signature_locations?.[userId]?.find(
            (y) => y.x === x.x && y.y === x.y && y.type === 'textInput'
        );
        if (signedLocation) {
            if (signedLocation.userInput && isDateType(signedLocation.typeOfTextEntry)) {
                x.userInput = moment(
                    signedLocation.userInput,
                    signedLocation.typeOfTextEntry === 'date' ? 'DD-MM-YYYY' : 'DD/MM/YYYY'
                ).format('YYYY-MM-DD');
            } else {
                x.userInput = signedLocation.userInput;
            }
        }
    });

    doc.additional_data?.pdfs?.forEach((pdf) => {
        pdf.signature_placeholder_locations?.signatureLocations?.forEach((loc) => {
            const signedLocation = pdf.signature_locations?.[userId]?.find(
                (y) => y.x === loc.x && y.y === loc.y && y.type === 'textInput'
            );
            if (signedLocation) {
                loc.userInput = signedLocation.userInput;
            }
        });
    });

    return doc;
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        userData: account.user,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
