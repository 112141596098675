/* eslint-disable no-undef */
import { Flex, Paragraph, Button, Input, Checkbox, Box, Spinner } from 'theme-ui';
import axios from 'axios';
import { PgPagination } from '../documents/paging';
import { Filters } from '../documents/uploads';
import { loadUploads } from '../documents/load-uploads';

export const Title = ({ text, subtitle, boxStyling, titleFontWeight = '600', titleFontSize = 18 }) => {
    return (
        <Box sx={boxStyling || {}}>
            <Paragraph sx={{ fontSize: titleFontSize, fontWeight: titleFontWeight, color: 'primary' }}>
                {text}
            </Paragraph>
            {subtitle && (
                <Paragraph sx={{ fontSize: '13px', mt: '4px', fontWeight: '400', color: 'primary' }}>
                    {subtitle}
                </Paragraph>
            )}
        </Box>
    );
};

const createTemplate = async (state, updateState, errorState, updateErrorState) => {
    let errors = 0;
    let errorData = {
        description: false,
        shareEmails: false,
        googleURL: false,
        selectedDocs: false,
        labels: false,
    };

    if (state.description === '') {
        errors++;
        errorData.description = true;
    }

    if (state?.templateToGenerate === 'google' && state.shareEmails === '') {
        errors++;
        errorData.shareEmails = true;
    }

    if (state?.templateToGenerate === 'google' && state.googleURL === '') {
        errors++;
        errorData.googleURL = true;
    }

    if (state?.templateToGenerate === 'google' && !state.selectedDocs.length) {
        errors++;
        errorData.selectedDocs = true;
    }

    if (state?.templateToGenerate === 'word' && state.wordDocIdToUse === '') {
        errors++;
        errorData.wordDocIdToUse = true;
    }

    if (errors > 0) {
        updateErrorState({ ...errorState, ...errorData });
        updateState({ ...state, error: 'Please fix the errors in your data' });
    } else {
        updateState({ ...state, loading: true });
        updateErrorState({ ...errorState, ...errorData });

        let templateData = {};
        if (state?.templateToGenerate === 'google') {
            const shareArray = state?.shareEmails?.trim() === '' ? [] : state?.shareEmails.split(',') || [];

            templateData = {
                share_with: shareArray,
                templates: [
                    {
                        type: 'google',
                        url: state?.googleURL,
                    },
                ],
            };
        } else if (state?.templateToGenerate === 'word') {
            templateData = {
                templates: [
                    {
                        type: 'word',
                        existing_file_id: state?.wordDocIdToUse,
                    },
                ],
            };
        }

        await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_AQRU_AI_API}/uploads`,
            data: {
                description: state.description,
                type: state?.templateType,
                existing_files: state?.selectedDocs || [],
                ...templateData,
            },
        });

        const { uploads, total } = await loadUploads(state.offset, state.limit);

        updateState({
            ...state,
            loading: false,
            partialLoading: false,
            partialWordLoading: false,
            error: null,
            componentToShow: 'list',
            offset: 0,
            limit: 3,
            selectedDocs: [],
            chatHistory: [],
            documents: [],
            selectLabels: [],
            docTotal: 0,
            docOffset: 0,
            docLimit: 5,
            googleURL: '',
            description: '',
            shareEmails: '',
            templateToGenerate: 'google',
            templateType: 'legal_template',
            wordDocIdToUse: '',
            uploads,
            total,
        });
    }
};

const FileType = ({ type, icon, title, state, updateState }) => (
    <Flex
        sx={{
            flex: 1,
            border: `1px ${state?.templateToGenerate === type ? 'primary' : '#a3a69b'} solid`,
            borderRadius: '4px',
            mr: '10px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: state?.templateToGenerate === type ? 'primary' : '#a3a69b',
            cursor: 'pointer',
        }}
        onClick={() => updateState({ ...state, templateToGenerate: type })}
    >
        <i
            style={{
                marginTop: '0px',
                fontSize: '15px',
            }}
            className={`${icon}`}
        />
        <Paragraph sx={{ mt: '6px', fontSize: '13px' }}>{title}</Paragraph>
    </Flex>
);

export const FileSelectWdget = ({
    state,
    updateState,
    total,
    limit,
    type,
    documents,
    selectedDocs,
    showFilters = false,
    minHeight = '400px',
    innerMinHeight = '80px',
    selectedDocVar = 'selectedDocs',
    maxFilesToSelect = 10,
}) => {
    return (
        <Box
            sx={{
                minHeight,
                border: '0px solid red',
                mt: '-18px',
            }}
        >
            {showFilters && <Filters showAddDocument={false} state={state} updateState={updateState} mini />}

            {selectedDocs?.length > 0 && (
                <Paragraph
                    sx={{
                        fontSize: '13px',
                        fontWeight: '500',
                        mb: '10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => updateState({ ...state, selectedDocs: [], selectedWordDocs: [], error: null })}
                >
                    Deselect all ({` ${selectedDocs?.length} selected`})
                </Paragraph>
            )}
            <Box sx={{ minHeight: innerMinHeight }}>
                {documents?.map((item, i) => (
                    <Flex
                        key={`${item?.id}`}
                        sx={{
                            borderBottom: '1px solid lightgrey',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                            height: '50px',
                            cursor: 'pointer',
                            mt: i === 0 ? '-9px' : 'initial',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}
                        onClick={() => {
                            const maxSelected = state?.[selectedDocVar]?.length >= maxFilesToSelect || false;

                            if (selectedDocVar === 'selectedWordDocs') {
                                updateState({
                                    ...state,
                                    wordDocIdToUse: item?.id,
                                    [selectedDocVar]: [item?.id],
                                    error: null,
                                });
                            } else {
                                const docArray = state?.[selectedDocVar];
                                const exists = docArray?.includes(item?.id);
                                if (exists) {
                                    const idx = docArray.indexOf(item?.id);
                                    docArray.splice(idx, 1);
                                } else {
                                    if (!maxSelected) {
                                        docArray.push(item?.id);
                                    }
                                }
                                updateState({ ...state, [selectedDocVar]: docArray, error: null });
                            }
                        }}
                    >
                        <Checkbox sx={{ zIndex: 9 }} checked={selectedDocs?.includes(item?.id)} onChange={() => {}} />
                        <Paragraph sx={{ fontSize: '14px' }}>{item?.file_name || item?.description}</Paragraph>
                    </Flex>
                ))}
            </Box>
            <Box sx={{ mb: '30px' }} />

            <Box sx={{ mt: selectedDocVar === 'selectedWordDocs' ? '-8px' : '70px', width: '100%' }}>
                <PgPagination
                    currentState={state}
                    updateState={updateState}
                    totalPages={total ? Math.ceil(total / limit) : 1}
                    totalRecords={total}
                    type={type}
                />
            </Box>
        </Box>
    );
};

export const AddNewTemplate = ({ state, updateState, errorState, defaultErrorState, updateErrorState, userData }) => {
    return (
        <Flex
            sx={{
                width: '1200px',
                minHeight: '450px',
                flexDirection: 'row',
                border: '0px red solid',
                mt: '50px',
            }}
        >
            {/* 1 */}
            <Flex
                sx={{
                    flexDirection: 'column',
                    pr: '40px',
                    width: '460px',
                    borderRight: '1px solid #a3a69b',
                }}
            >
                <Title
                    text={`Select Documents`}
                    subtitle="Select the files from your library to populate your template"
                />
                <Box sx={{ mb: '20px' }} />
                {state?.partialLoading && <Spinner />}
                {!state?.partialLoading && (
                    <FileSelectWdget
                        state={state}
                        updateState={updateState}
                        total={state.docTotal}
                        limit={state.docLimit}
                        type="docsOnly"
                        documents={state?.documents}
                        selectedDocs={state?.selectedDocs}
                        showFilters
                        selectedDocVar="selectedDocs"
                    />
                )}
            </Flex>
            {/* 2 */}
            <Flex sx={{ flexDirection: 'column', mr: '40px', pl: '40px' }}>
                <Title
                    text="Description"
                    subtitle="Please provide a short description of the document you wish to build"
                />

                <Input
                    sx={{
                        mt: '10px',
                        mb: '25px',
                        width: '400px',
                        background: '#FFFFFF',
                        border: errorState?.description ? '1px solid red' : '1px solid #a3a69b',
                        borderRadius: '10px',
                        height: '40px',
                        fontSize: '14px',
                    }}
                    type="text"
                    id="description"
                    data-testid="description"
                    name="description"
                    label=""
                    placeholder="Description..."
                    onChange={(e) => updateState({ ...state, description: e.target.value })}
                    error={null}
                />

                <Title text="Template Type" subtitle="Please select the type of template you wish to create" />
                <Flex sx={{ width: '180px', height: '70px', mt: '10px', mb: '20px' }}>
                    <FileType
                        type="google"
                        title="Google"
                        icon="fab fa-google"
                        state={state}
                        updateState={updateState}
                    />
                    <FileType
                        type="word"
                        title="Word"
                        icon="far fa-file-word"
                        state={state}
                        updateState={updateState}
                    />
                </Flex>

                {state?.templateToGenerate === 'google' && (
                    <Flex sx={{ minHeight: '250px', flexDirection: 'column' }}>
                        <Title text="Google Doc" subtitle="Paste in the URL of your google document template" />
                        <Input
                            sx={{
                                mt: '10px',
                                mb: '25px',
                                width: '400px',
                                background: '#FFFFFF',
                                border: errorState?.googleURL ? '1px solid red' : '1px solid #a3a69b',
                                borderRadius: '10px',
                                height: '40px',
                                fontSize: '14px',
                            }}
                            type="text"
                            id="googleURL"
                            data-testid="googleURL"
                            name="googleURL"
                            label=""
                            placeholder="Google url..."
                            onChange={(e) => updateState({ ...state, googleURL: e.target.value })}
                            error={null}
                        />

                        <Title text="Share with" subtitle="Add the emails as a comma separated list" />

                        <Input
                            sx={{
                                mt: '10px',
                                mb: '25px',
                                width: '400px',
                                background: '#FFFFFF',
                                border: errorState?.shareEmails ? '1px solid red' : '1px solid #a3a69b',
                                borderRadius: '10px',
                                height: '40px',
                                fontSize: '14px',
                            }}
                            type="text"
                            id="shareEmails"
                            data-testid="shareEmails"
                            name="shareEmails"
                            label=""
                            value={state?.shareEmails}
                            placeholder="test@example.com,admin@example.com"
                            onChange={(e) => updateState({ ...state, shareEmails: e.target.value })}
                            error={null}
                        />
                    </Flex>
                )}

                {state?.templateToGenerate === 'word' && (
                    <Flex sx={{ width: '460px', minHeight: '250px', flexDirection: 'column' }}>
                        <Title text="Select Word Template" subtitle="Please select a word document from your library" />
                        <Box sx={{ width: '400px', minHeight: '45px', mb: '6px', mt: '20px' }}>
                            {state?.partialWordLoading && <Spinner />}
                            {!state?.partialWordLoading && (
                                <FileSelectWdget
                                    state={state}
                                    updateState={updateState}
                                    total={state.docWordTotal}
                                    limit={state.docWordLimit}
                                    type="docsWordOnly"
                                    documents={state?.documentsWord}
                                    selectedDocs={state?.selectedWordDocs}
                                    minHeight="100px"
                                    selectedDocVar="selectedWordDocs"
                                />
                            )}
                        </Box>
                    </Flex>
                )}

                <Flex sx={{ mb: '20px' }} />
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        mt: state?.templateToGenerate === 'word' ? '0' : '40px',
                        width: '400px',
                    }}
                >
                    <Button
                        variant="primary"
                        sx={{
                            width: '180px',
                            mt: '0',
                            alignSelf: 'flex-end',
                            backgroundColor: 'white',
                            color: 'primary',
                            border: '1px solid #0C1B2A',
                        }}
                        data-testid="submit"
                        onClick={async () => {
                            updateState({
                                ...state,
                                templateToGenerate: 'google',
                                wordDocIdToUse: '',
                                componentToShow: 'list',
                                error: null,
                            });
                            updateErrorState(defaultErrorState);
                        }}
                    >
                        Cancel
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '13px',
                                marginLeft: '9px',
                            }}
                            className={`fas fa-times-circle`}
                        />
                    </Button>
                    <Button
                        variant="primary"
                        sx={{
                            width: '180px',
                            mt: '0',
                            alignSelf: 'flex-end',
                            backgroundColor: 'primary',
                        }}
                        data-testid="submit"
                        onClick={async () => await createTemplate(state, updateState, errorState, updateErrorState)}
                    >
                        Create
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '13px',
                                marginLeft: '9px',
                            }}
                            className={`fas fa-plus-circle`}
                        />
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};
