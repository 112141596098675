import moment from 'moment';

export const validateSelectInput = (item: any) => item && item?.toLowerCase() !== 'select' && item !== '';

export const validateDOB = (dob: any) => {
    const dobRegEx = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    const validDOBRegex = dobRegEx.test(dob);
    let validDOB = false;
    if (validDOBRegex) {
        const [day, month, year] = dob.split('-'); // @ts-ignore
        const years = moment().diff(`${year}-${month}-${day}`, 'years', true);
        validDOB = years >= 18;
    }
    return validDOB;
};

export const validateTextInput = (text: string, minLength = 2, maxLength = 50) =>
    text?.length >= minLength && text?.length <= maxLength;

export const validateInputIsNotEmpty = (text: string) => text && text !== undefined && text !== null && text !== '';
