import { Button, Flex, Input, Paragraph } from 'theme-ui';
import { attachFile } from './api';

const handleFileChange = (state, updateState) => (event) => {
    const files = Object.values(event.target.files);

    if (files.length > 5) {
        return updateState({
            ...state,
            attachError: 'Please upload a maximum of 5 files',
        });
    }

    updateState({
        ...state,
        filesToUpload: files,
        attachError: null,
    });
};

const AttachFile = ({ state, updateState, page = 'task' }) => (
    <Flex sx={{ flexDirection: 'column', mt: 30, mb: 30, borderTop: '1px solid #CCC', width: 400 }}>
        <Paragraph sx={{ mb: 20, mt: 20 }}>Select your files to upload (maximum 5 files)</Paragraph>

        <Input
            id="file_input"
            style={{
                fontSize: '15px',
                border: '1px solid #ccc',
            }}
            type="file"
            multiple
            accept=".pdf,.txt,.docx,.xlsx"
            onChange={handleFileChange(state, updateState)}
        />

        <Flex sx={{ mt: 70, alignSelf: 'center', justifyContent: 'space-between', width: 320 }}>
            <Button sx={{ width: 150 }} onClick={() => attachFile(state, updateState, page)}>
                Upload
            </Button>
            <Button
                sx={{
                    width: 150,
                    backgroundColor: 'white',
                    color: 'text',
                    border: '1px solid #CCC',
                }}
                onClick={() => updateState({ ...state, showAttachFileForm: false })}
            >
                Cancel
            </Button>
        </Flex>

        {state.attachError && (
            <Paragraph sx={{ mt: 20, color: 'red', alignSelf: 'center' }}>{state.attachError}</Paragraph>
        )}
    </Flex>
);

export default AttachFile;
