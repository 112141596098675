/** @jsxImportSource theme-ui */
import { Button, Box } from 'theme-ui';

const ButtonComponent = (props: any) => (
    <Button {...props} variant={props?.variant} onClick={props?.onClick}>
        <Box sx={{ paddingLeft: [20, 32], paddingRight: [20, 32] }}>{props?.children}</Box>
    </Button>
);

export default ButtonComponent;
