import { Button, Flex, Heading, Paragraph, Switch } from 'theme-ui';
import Divider from './divider';
import { generateEngagementLetter } from './clients/api';
import { useState } from 'react';
import ReactSelect from 'react-select';
import SignatureCanvas from './signature';

// select schedules
// signatures?
// t and cs
// company vs individual services
// request professional clearance?

// you may -> is tegistered office
// allow > 1 primary contact and ask

const EngagementLetterModal = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({
        services: Object.entries(state.organisation?.workflow_definition?.services || {})
            ?.filter((x) => {
                if (Array.isArray(x[1].client_types)) {
                    return x[1].client_types.includes(state.clientInView?.type);
                }
                return true;
            })
            ?.map((x) => {
                return {
                    value: x[0],
                    label: x[1]?.label,
                };
            }),
        services_selected: Object.entries(state.organisation?.workflow_definition?.services || {})?.map((x) => {
            return {
                value: x[0],
                label: x[1]?.label,
            };
        }),
        held_at_offices: false,
        request_professional_clearance: false,
        add_signature: false,
        primary_contact_selected: null,
        primary_contacts: state.clientInView?.client_data?.key_contacts?.map((x) => ({
            value: x,
            label: `${x.first_name} ${x.last_name} ${x.is_primary_contact ? '(Primary Contact)' : ''}`,
        })),
    });

    return (
        <Flex sx={{ ml: 20, mt: 20 }}>
            <Flex sx={{ flexDirection: 'column', width: '95%' }}>
                <Heading sx={{ fontSize: 20, mt: '5px', fontWeight: 500 }}>Generate an engagement letter</Heading>
                <Divider />

                <Paragraph
                    sx={{ mb: 10 }}
                >{`Is the registered office to be held at ${state.organisation?.name} offices?`}</Paragraph>
                <Switch
                    onClick={() => updateLocalState({ ...localState, held_at_offices: !localState.held_at_offices })}
                    sx={{ mb: 20 }}
                />

                <Paragraph sx={{ mb: 10 }}>{'Request professional clearance?'}</Paragraph>
                <Switch
                    onClick={() =>
                        updateLocalState({
                            ...localState,
                            request_professional_clearance: !localState.request_professional_clearance,
                        })
                    }
                    sx={{ mb: 20 }}
                />

                <Paragraph
                    sx={{
                        mb: 10,
                    }}
                >
                    Services
                </Paragraph>
                <Flex sx={{ mr: 20 }}>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '480px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '30px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (value) => {
                            updateLocalState({
                                ...localState,
                                services_selected: value,
                            });
                        }}
                        isMulti
                        placeholder={'Select the services offered'}
                        value={localState.services_selected}
                        options={localState.services}
                    />
                </Flex>

                <Paragraph
                    sx={{
                        mb: 10,
                    }}
                >
                    Contact to be use on the letter
                </Paragraph>
                <Flex sx={{ mr: 20 }}>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '480px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '30px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (value) => {
                            updateLocalState({
                                ...localState,
                                primary_contact_selected: value,
                            });
                        }}
                        placeholder={'Select the contact to be used on the letter'}
                        value={localState.primary_contact_selected}
                        options={localState.primary_contacts}
                    />
                </Flex>

                <Flex sx={{ mb: -10, height: 140 }}>
                    <Flex sx={{ flexDirection: 'column', mr: 30 }}>
                        <Paragraph sx={{ mb: 10 }}>Add a signature?</Paragraph>
                        <Switch
                            onClick={() =>
                                updateLocalState({
                                    ...localState,
                                    add_signature: !localState.add_signature,
                                })
                            }
                            sx={{ mb: 10 }}
                        />
                    </Flex>
                    {localState.add_signature && (
                        <Flex sx={{ flexDirection: 'column' }}>
                            <SignatureCanvas
                                width={320}
                                height={120}
                                drawingComplete={(res) =>
                                    updateLocalState({
                                        ...localState,
                                        signature: res.base64,
                                    })
                                }
                            />
                        </Flex>
                    )}
                </Flex>

                <Button
                    onClick={async () => {
                        if (!localState.primary_contact_selected) {
                            return updateLocalState({
                                ...localState,
                                error: 'Please select or create a primary contact for the client to be used on the letter',
                            });
                        }
                        updateLocalState({
                            ...localState,
                            error: null,
                        });
                        await generateEngagementLetter(state.clientInView?.id, state, updateState, {
                            ...localState,
                            services_selected: localState.services_selected,
                        });
                    }}
                    sx={{ width: 100, height: 40, fontSize: 14, mt: 15, mr: -10, alignSelf: 'flex-end' }}
                >
                    Generate
                </Button>

                {localState.error && (
                    <Paragraph sx={{ mt: -40, width: 400, color: 'red' }}>{localState.error}</Paragraph>
                )}
            </Flex>
        </Flex>
    );
};

export default EngagementLetterModal;
