export const actions = {
    CLOSE_ALL_MENUS: 'MENU/CLOSE_ALL_MENUS',
    TOGGLE_SIDE_MENU: 'MENU/TOGGLE_SIDE_MENU',
    TOGGLE_TOP_MENU: 'MENU/TOGGLE_TOP_MENU',
    DISMISS_TOP_MENU: 'MENU/DISMISS_TOP_MENU',
};

const initialState = {
    showSideMenu: false,
    showTopMenu: false,
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actions.CLOSE_ALL_MENUS:
            return { ...state, showSideMenu: false, showTopMenu: false };
        case actions.TOGGLE_SIDE_MENU:
            return { ...state, showSideMenu: !state.showSideMenu, showTopMenu: false };
        case actions.TOGGLE_TOP_MENU:
            return { ...state, showTopMenu: !state.showTopMenu };
        case actions.DISMISS_TOP_MENU:
            return { ...state, showTopMenu: false };
        default:
            return { ...state };
    }
};

export default reducer;
