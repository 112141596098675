import { useEffect, useState } from 'react';
import { Flex, Paragraph, Image, Box, Alert } from 'theme-ui';
import Button from '../../../button';
import Spinner from '../../../spinner';
import OverlayTitle from '../../title';
import BackBtn from '../../back-button';
import { InputLabel, FormInput } from '../../../form-elements';
import { CountryListSelect, loadCountriesAndReturn } from '../../../select-country';
import { Auth } from 'aws-amplify';
import { PhoneCodeSelect } from './country-select-phone';
import axios from 'axios';
import Input from '../../../form-input';
import OverlaySubtitle from '../../subtitle';
import { connect } from 'react-redux';
import { actions as userActions } from '../../../../store/reducers/users';
import { checkProgress } from '../../../../utils/selectors';
import UnverifiedBlock from '../../../unverified-block';

enum SetupStep {
    startSetup,
    confirmCode,
    success,
}

const defaultState = {
    loading: true,
    countries: [],
    countryCode: '+00',
    enteredNumber: '',
    step: SetupStep.startSetup,
    confirmationCode: '',
};

const defaultErrorState = {
    countryCode: false,
    enteredNumber: false,
    confirmationCode: false,
    apiError: false,
    message: '',
};

const renderStep = (currentState: any, updateState: any, errorState: any, updateErrorState: any, props: any) => {
    const allProps = { ...props, currentState, updateState, errorState, updateErrorState };
    switch (currentState.step) {
        case SetupStep.startSetup:
            return <Setup {...allProps} />;
        case SetupStep.confirmCode:
            return <ConfirmStep {...allProps} />;
        case SetupStep.success:
            return <SuccessStep {...allProps} />;
        default:
            return <Setup {...allProps} />;
    }
};

const errorHandler = (currentState: any, updateState: any, errorState: any, updateErrorState: any) => {
    let countryCodeErr = false;
    let enteredNumberErr = false;
    let codeError = false;
    if (currentState.step === SetupStep.startSetup) {
        const { countryCode, enteredNumber } = currentState;
        if (countryCode === '+00' || !countryCode) {
            countryCodeErr = true;
        }
        if (enteredNumber === '' || !enteredNumber || enteredNumber.length < 6) {
            enteredNumberErr = true;
        }
        updateErrorState({ ...errorState, countryCode: countryCodeErr, enteredNumber: enteredNumberErr });
    } else if (currentState.step === SetupStep.confirmCode) {
        const { confirmationCode } = currentState;
        if (confirmationCode.length !== 5) {
            codeError = true;
        }
        updateErrorState({ ...errorState, confirmationCode: codeError });
    }
    return [countryCodeErr, enteredNumberErr, codeError].includes(true);
};

const step1 = async (currentState: any, updateState: any, errorState: any, updateErrorState: any) => {
    updateState({ ...currentState, loading: true });

    const errorExists = errorHandler(currentState, updateState, errorState, updateErrorState);
    if (errorExists) {
        return updateState({ ...currentState, loading: false });
    }

    const { countryCode, enteredNumber } = currentState;

    try {
        const res = await axios({
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/reminders`,
            method: 'POST',
            data: {
                country_code: countryCode,
                phone_number: enteredNumber,
                flow: 'init',
            },
        });

        return updateState({ ...currentState, loading: false, step: SetupStep.confirmCode });
    } catch (e: any) {
        updateErrorState({
            ...errorState,
            apiError: true,
            message: e.response.data.message || 'Something has gone wrong, please try again later',
        });
        return updateState({ ...currentState, loading: false });
    }
};

const step2 = async (
    currentState: any,
    updateState: any,
    errorState: any,
    updateErrorState: any,
    refreshCoreData: any
) => {
    const { countryCode, enteredNumber, confirmationCode } = currentState;

    const errorExists = errorHandler(currentState, updateState, errorState, updateErrorState);

    if (errorExists) {
        return updateState({ ...currentState, loading: false });
    }

    updateState({ ...currentState, loading: true });
    try {
        const res = await axios({
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/reminders`,
            method: 'POST',
            data: {
                country_code: countryCode,
                phone_number: enteredNumber,
                flow: 'confirm',
                code: confirmationCode,
            },
        });
        await refreshCoreData();
        return updateState({ ...currentState, loading: false, step: SetupStep.success });
    } catch (e: any) {
        updateErrorState({ ...errorState, apiError: true, message: e.response.data.message });
        return updateState({ ...currentState, loading: false });
    }
};

const SmsNotifications = (props: any) => {
    const [currentState, updateState] = useState(defaultState);
    const [errorState, updateErrorState] = useState(defaultErrorState);
    const progress = checkProgress(props.userData);

    useEffect(() => {
        (async () => {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const { countries, countriesFiltered } = await loadCountriesAndReturn(currentState, updateState);
            updateState({ ...currentState, countries, loading: false });
        })();
    }, []);

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {currentState.loading && <Spinner />}
            <BackBtn overlay={props.overlay} updateOverlay={props.updateOverlay} backComponent="SettingsOverlay" />
            <OverlayTitle centered title="Set up SMS notifications" />
            <OverlaySubtitle
                centered
                title="Receive SMS notifications if the value of your loan reaches one of our thresholds."
            />
            {!progress.setup && (
                <UnverifiedBlock progress={progress} overlay={props.overlay} updateOverlay={props.updateOverlay} />
            )}
            {progress.setup && renderStep(currentState, updateState, errorState, updateErrorState, { ...props })}
        </Flex>
    );
};

const CancelButton = (props: any) => {
    return (
        <Box
            sx={{ cursor: 'pointer', mt: 4 }}
            onClick={() => {
                props.updateOverlay({ ...props.overlay, show: false });
            }}
        >
            <Paragraph>Cancel</Paragraph>
        </Box>
    );
};

const ConfirmStep = (props: any) => {
    const { currentState, updateState, errorState, updateErrorState } = props;
    return (
        <Flex
            sx={{
                mt: 3,
                flexDirection: 'column',
                width: '80%',
                alignItems: 'center',
                '@media screen and (max-width: 700px)': {
                    justifyContent: 'space-between',
                    width: '93%',
                },
            }}
        >
            <Image
                sx={{
                    width: 180,
                    height: 'auto',
                }}
                src={`${process.env.REACT_APP_CDN_BASE_URL}/images/check.png`}
                alt="confirm code"
            />
            <Paragraph sx={{ mt: 4, mb: 4 }}>Please enter the five digit code we've sent to your phone</Paragraph>
            <Input
                sx={{
                    mb: 2,
                    height: 50,
                    width: 220,
                    alignSelf: 'center',
                    textAlign: 'center',
                    border: errorState.confirmationCode ? '1px red solid ' : '1px solid #D1D1D1',
                }}
                id="confirmationCode"
                type="number"
                data-testid="confirm-sms"
                name="phone"
                maxLength={5}
                placeholder="Enter the 5 digit code"
                onChange={(e: any) => updateState({ ...currentState, confirmationCode: e.target.value })}
            />
            {errorState.apiError && <Alert variant="error">{errorState.message}</Alert>}
            <Button
                sx={{
                    mt: 4,
                }}
                variant="primary"
                onClick={() => step2(currentState, updateState, errorState, updateErrorState, props.refreshCoreData)}
                data-testid="confirm-btn"
            >
                Confirm
            </Button>

            <CancelButton {...props} />
        </Flex>
    );
};

const SuccessStep = (props: any) => {
    return (
        <Flex
            sx={{
                mt: 4,
                flexDirection: 'column',
                width: '80%',
                alignItems: 'center',
                '@media screen and (max-width: 700px)': {
                    justifyContent: 'space-between',
                    width: '93%',
                },
            }}
        >
            <Paragraph sx={{ fontSize: 20, alignItems: 'center', fontWeight: 'bold' }}>Congratulations</Paragraph>
            <Image
                sx={{ width: '280px', height: 'auto', mt: 2 }}
                src={`${process.env.REACT_APP_CDN_BASE_URL}/images/complete.png`}
                alt="Success"
            />
            <Paragraph
                data-testid="sms-success"
                sx={{
                    my: 4,
                    lineHeight: '22px',
                    textAlign: 'center',
                }}
            >
                You're all setup to receive SMS notifications if the value of your loan reaches one of our thresholds.
            </Paragraph>
            <Button
                sx={{
                    mt: 3,
                }}
                variant="primary"
                onClick={() =>
                    props.updateOverlay({ ...props.overlay, show: false, componentToRender: 'SettingsOverlay' })
                }
                data-testid="confirm-btn"
            >
                Let's go
            </Button>
        </Flex>
    );
};

const Setup = (props: any) => {
    const { currentState, updateState, errorState, updateErrorState } = props;
    return (
        <Flex
            id="content"
            sx={{
                mt: 6,
                flexDirection: 'column',
                width: '80%',
                alignItems: 'center',
                '@media screen and (max-width: 700px)': {
                    justifyContent: 'space-between',
                    width: '93%',
                },
            }}
        >
            <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="Country code" />

            <PhoneCodeSelect
                width="300px"
                dataTestId="country-code"
                stateKey="countryCode"
                currentState={currentState}
                updateState={updateState}
                errorState={errorState}
                errorStateKey="address"
                hasError={errorState?.countryCode}
                updateErrorState={updateErrorState}
                value={currentState.countryCode}
                countries={currentState.countries}
                mtSelect="5px"
                mbSelect="10px"
                mtArrow="0px"
            />
            <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="Enter your phone number" />
            <Flex
                sx={{
                    alignItems: 'center',
                    width: '300px',
                }}
            >
                <Paragraph mr={3}>{currentState.countryCode}</Paragraph>
                <FormInput
                    width="300px"
                    mt="5px"
                    mb="7px"
                    errorState={errorState}
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey="enteredNumber"
                    placeholder="Enter your phone number"
                    type="number"
                />
            </Flex>
            <Flex mt={2} mb={3}>
                <Paragraph>Your number is:&nbsp;&nbsp;</Paragraph>
                <Paragraph>
                    {`${currentState.countryCode}`}&nbsp;{currentState.enteredNumber ?? ''}
                </Paragraph>
            </Flex>
            {errorState.apiError && <Alert variant="error">{errorState.message}</Alert>}
            <Button
                sx={{
                    mt: 3,
                }}
                variant="primary"
                onClick={() => step1(currentState, updateState, errorState, updateErrorState)}
                data-testid="confirm-btn"
            >
                Submit
            </Button>
            <CancelButton {...props} />
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
});

const mapStateToProps = (state: any) => {
    const { account } = state;
    return {
        userData: account.user,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsNotifications);
