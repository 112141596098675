import { Flex } from 'theme-ui';
import KYCScreens from './questions-dsl';
import SettingsScreens from './settingsJourney';

const JourneyComponents = {
    ...KYCScreens,
    ...SettingsScreens,
};

const ComponentToRender = ({ Component, overlay, updateOverlay, copy }: any) => (
    <Flex
        sx={{
            position: 'relative',
            height: '100%',
            width: '100%',
            marginTop: '40px',
        }}
    >
        <Component overlay={overlay} updateOverlay={updateOverlay} copy={copy} />
    </Flex>
);

const JourneyContent = ({ overlay, updateOverlay, copy }: any) => (
    <ComponentToRender // @ts-ignore
        Component={JourneyComponents[`${overlay?.componentToRender}`]}
        overlay={overlay}
        updateOverlay={updateOverlay}
        copy={copy}
    />
);

export default JourneyContent;
