/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import mixpanel from 'mixpanel-browser';
import { Auth } from 'aws-amplify';

export const trackLoggedInEvent = (event: string, metadata: any) => {
    try {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                try {
                    const meta = {
                        distinct_id: user.username,
                        source: 'web',
                        ...(metadata || {}),
                    };

                    if (process.env.REACT_APP_IS_LIVE === '1') {
                        mixpanel.track(event, meta);
                    } else {
                        console.log('Analytics event', event, meta);
                    }
                } catch (ex) {
                    console.log(ex);
                }
            })
            .catch((e) => {});
    } catch (e) {
        //
    }
};
