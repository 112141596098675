/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
import axios from 'axios';
import * as uuid from 'uuid';
import * as moment from 'moment';

export const createClient = async (companyNumber, authCode) => {
    await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/clients`,
        method: 'POST',
        data: {
            company_number: companyNumber,
            client_data: {
                authentication_code: authCode,
            },
        },
    });
};

export const buildClientsCsv = (organisation, clients, users) => {
    const contactColumns =
        'Title,Email,Secondary Email,Contact Name,Preferred Name,Telephone Number,Job Title,Is Primary Contact,Address,Invited To User Portal';

    const relationColumns = 'Preparer,Partner,Reviewer';

    const serviceKeys = Object.entries(organisation?.workflow_definition.services || {}).map((entry) => entry[0]);
    const services = Object.entries(organisation?.workflow_definition.services || {}).map(
        (entry) => '"' + entry[1]?.label + '"'
    );

    const rows = [
        `Name,Type,Company Number,Status,Client Code,Registered Office Address,Stat Accounts End Date,Confirmation Statement Made Up To,${Object.entries(
            organisation?.schema
        )
            .map((entry) => entry[1]?.label)
            .join(',')},${services.join(
            ','
        )},${relationColumns},${contactColumns},${contactColumns},${contactColumns},${contactColumns},${contactColumns}`,
    ];

    clients.forEach((client) => {
        const data = [];
        Object.entries(organisation?.schema).forEach((entry) => {
            if (entry[1]?.type === 'dropdown' && client?.client_data?.[entry[0]]) {
                data.push(
                    '"' + entry[1]?.dropdownOptions?.find((x) => x.id === client?.client_data?.[entry[0]])?.label + '"'
                );
            } else {
                data.push(
                    '"' +
                        (client.client_data?.[`${client.org_uuid}_${entry[0]}`] ||
                            client.client_data?.[entry[0]] ||
                            '') +
                        '"'
                );
            }
        });

        const contactData = [];

        (client.client_data?.[`${client.org_uuid}_key_contacts`] || client.client_data?.key_contacts)?.forEach(
            (item) => {
                const attributes = [];
                attributes.push('"' + (item.title || '') + '"');
                attributes.push('"' + (item.email_address || '') + '"');
                attributes.push('"' + (item.secondary_email_address || '') + '"');
                attributes.push(
                    '"' + (item.first_name || '') + ' ' + (item.middle_name || '') + ' ' + (item.last_name || '') + '"'
                );
                attributes.push('"' + (item.preferred_name || '') + '"');
                attributes.push('"' + (item.telephone_number || '') + '"');
                attributes.push('"' + (item.job_title || '') + '"');
                attributes.push('"' + (item.is_primary_contact ? 'Primary' : '') + '"');

                const addressLine = `${item?.address?.line1 || ''} ${item?.address?.city || ''} ${
                    item?.address?.postcode || ''
                }`.trim();

                attributes.push('"' + (addressLine || '') + '"');

                const userInvitedToUsePortal = users?.find((x) => x.email_address === item.email_address);

                attributes.push('"' + (userInvitedToUsePortal ? 'Yes' : 'No') + '"');

                contactData.push(attributes.join(','));
            }
        );

        const relationData = [];

        relationData.push(
            '"' +
                client?.relations
                    ?.filter((x) => x.type === 'assigned_to')
                    ?.map((x) => x.name)
                    .join(', ') +
                '"'
        );
        relationData.push(
            '"' +
                client?.relations
                    ?.filter((x) => x.type === 'partner')
                    ?.map((x) => x.name)
                    .join(', ') +
                '"'
        );
        relationData.push(
            '"' +
                client?.relations
                    ?.filter((x) => x.type === 'reviewer')
                    ?.map((x) => x.name)
                    .join(', ') +
                '"'
        );

        let regOfficeAddress = '';
        if (client.companies_house_data?.profile?.registeredOfficeAddress?.addressLineOne) {
            regOfficeAddress = `${client.companies_house_data?.profile?.registeredOfficeAddress?.addressLineOne}`;
            regOfficeAddress += ` ${client.companies_house_data?.profile?.registeredOfficeAddress?.locality || ''}`;
            regOfficeAddress += ` ${client.companies_house_data?.profile?.registeredOfficeAddress?.postalCode || ''}`;
            regOfficeAddress += ` ${client.companies_house_data?.profile?.registeredOfficeAddress?.country || ''}`;
        }

        let statAccountDate = '';
        if (client.companies_house_data?.profile?.accounts?.nextAccounts?.periodEndOn) {
            statAccountDate = moment(
                client.companies_house_data?.profile?.accounts?.nextAccounts?.periodEndOn,
                'YYYY-MM-DD'
            ).format('DD-MM-YYYY');
        }

        let confirmationStatementDate = '';
        if (client.companies_house_data?.profile?.confirmationStatement?.nextMadeUpTo) {
            confirmationStatementDate = moment(
                client.companies_house_data?.profile?.confirmationStatement?.nextMadeUpTo,
                'YYYY-MM-DD'
            ).format('DD-MM-YYYY');
        }

        rows.push(
            `"${client.name}","${client.type}","${client.company_number}","${client.status}","${
                client.internal_reference_code || ''
            }","${regOfficeAddress}","${statAccountDate}","${confirmationStatementDate}",${data.join(',')},${serviceKeys
                .map((key) => (client.client_data?.new_client_services_offered?.includes(key) ? 'Yes' : 'No'))
                .join(',')},${relationData.join(',')},${contactData.join(',')}`
        );
    });

    const encoded = 'data:text/plain;charset=utf-8,' + encodeURIComponent(rows.join('\n'));

    const a = document.createElement('a');
    a.href = encoded;
    a.download = 'Clients.csv';

    a.click();
};

export const downloadReport = async (state, updateState, report_name = 'client_users') => {
    updateState({ ...state, loading: true });

    let {
        data: { csv },
    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/reports`, {
        params: {
            report_name,
        },
    });

    const encoded = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv.join('\n'));

    const a = document.createElement('a');
    a.href = encoded;
    a.download = `${report_name}.csv`;

    a.click();

    updateState({ ...state, loading: false });
};

export const downloadClients = async (state, updateState) => {
    updateState({ ...state, loading: true });
    let {
        data: { clients },
    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients`, {
        params: {
            limit: 1000000,
            offset: 0,
        },
    });

    let {
        data: { users },
    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/*/users`, {
        params: {
            limit: 1000000,
            offset: 0,
        },
    });

    buildClientsCsv(state.organisation, clients, users);

    updateState({ ...state, loading: false });
};

export const loadClients = async (
    state,
    offset,
    limit,
    name,
    type,
    include_archived,
    sortByKeys = 'name,internalReferenceCode,type',
    sortByDirections = 'ASC,ASC,ASC'
) => {
    let params = {
        offset,
        limit,
        type,
        sort_by_keys: sortByKeys,
        sort_by_directions: sortByDirections,
        status: state.clientStatus?.value || 'customer',
    };
    if (name) {
        params.name = name;
    }
    if (type) {
        params.type = type === 'all' ? undefined : type;
    }

    if (include_archived) {
        params.include_archived = '1';
    }

    params.customColumn = state.customColumn?.value;
    params.customColumnOperator = state.customColumnOperator?.value || '=';
    params.customColumnFilterValue = state.customColumnFilterValue;
    params.default_preparer = state.default_preparer?.value;
    params.default_reviewer = state.default_reviewer?.value;
    params.default_partner = state.default_partner?.value;

    let [{ data }, { data: organisation }] = await Promise.all([
        axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients`, { params }),
        axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations`),
    ]);

    data.organisation = organisation;

    return data;
};

export const loadClientAlerts = async (offset, limit, alerts) => {
    if (true) {
        return {
            clients: [],
            total: 0,
        };
    }

    let params = { offset, limit };
    if (alerts) {
        params.alerts = alerts;
    }

    params.mock_date = '2030-01-01T00:00:00Z'; // this forces items to be in alerts list

    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/alerts`, { params });
    console.log('alerts', data);
    return data;
};

const getTimezone = () => {
    const offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? '+' : '-';
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    return `UTC${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const getScreenInfo = () => {
    const screen = window.screen;
    const width = screen.width;
    const height = screen.height;
    const scalingFactor = window.devicePixelRatio;
    const colorDepth = screen.colorDepth;

    const info = `width=${width}&height=${height}&scaling-factor=${scalingFactor}&colour-depth=${colorDepth}`;

    return info;
};

const getWindowInfo = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const info = `width=${screenWidth}&height=${screenHeight}`;
    return info;
};

export const verifyHMRCToken = async (client_id) => {
    let deviceId = localStorage.getItem('af_device_id');

    if (!deviceId) {
        deviceId = uuid.v4();
        localStorage.setItem('af_device_id', deviceId);
    }

    let { data } = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_AQRU_AI_API}/hmrc/verify-token`,
        data: {
            app_env: process.env.REACT_APP_APP_ENV || 'local',
            client_id,
            user_agent: window.navigator.userAgent,
            device_id: deviceId,
            timezone: getTimezone(),
            screen_info: getScreenInfo(),
            window_info: getWindowInfo(),
        },
    });

    return data;
};

export const generateEngagementLetter = async (clientId, state, updateState, generationParams) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const {
            data: { id },
        } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}/documents`,
            data: {
                type: 'engagement',
                ...(generationParams.add_signature && generationParams.signature
                    ? { signature: generationParams.signature.split('base64,')[1] }
                    : {}),
                doc_params: {
                    held_at_offices: generationParams.held_at_offices,
                    request_professional_clearance: generationParams.request_professional_clearance,
                    primary_contact: generationParams.primary_contact_selected?.value,
                    services: generationParams.services_selected,
                },
            },
        });

        const {
            data: { documents },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}/documents`,
        });

        updateState({ ...state, loading: false, documents, error: null, showEngagementLetterModal: false });

        const {
            data: { url },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${id}`, {});

        const a = document.createElement('a');

        a.href = url;
        a.download = `${moment.utc().format('YYYY MM DD')} - SF Engagement Letter - ${state.clientInView.name}.docx`;

        a.click();
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error, please contact support',
            showEngagementLetterModal: false,
        });
    }
};

export const getSingleClient = async (id, useRawId = false) => {
    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/${id}`, {
        params: { raw_id: useRawId ? '1' : '0' },
    });
    return data;
};

export const deleteClient = (state, updateState) => async () => {
    try {
        updateState({
            ...state,
            loading: true,
            updateClientError: null,
        });
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.id}`,
            method: 'DELETE',
        });
        window.location.assign('/clients');
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            updateClientError: 'There has been an error deleting your client, please try again later',
        });
    }
};

export const updateClientData = async (id, client_data) => {
    if (client_data.name || client_data.internal_reference_code || client_data.company_number) {
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${id}`,
            method: 'PUT',
            data: {
                ...client_data,
            },
        });
    } else {
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${id}`,
            method: 'PUT',
            data: {
                client_data,
            },
        });
    }
};
