import { Paragraph } from 'theme-ui';

const OverlayTitle = ({ title, centered = false }: any) => (
    <Paragraph
        data-testid="overlay-title"
        sx={{
            fontSize: '22px',
            fontWeight: '500',
            textAlign: centered ? 'center' : 'left',
            width: '100%',
            marginLeft: centered ? '0' : '65px',
            '@media screen and (max-width: 700px)': {
                marginLeft: '0',
                textAlign: 'center',
            },
        }}
    >
        {title}
    </Paragraph>
);

export default OverlayTitle;
