/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import { Flex, Paragraph, Box } from 'theme-ui';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import Spinner from './spinner';
import { hydrateUserData } from './helper';
import { loadCountriesAndReturn } from '../../select-country';
import { POA, CheckingAddressWithFacts, QuestionContainer, Processing, startOnfidoProcess } from './components';

const refreshDataAction = 'REFRESH_CORE_DATA';

const defaultComponentState = {
    checkingAddress: false,
    onfidoPOA: false,
    onfidoManualPOA: false,
    questionsPart2: false,
    onfidoLoaded: false,
    restartOnfido: false,
    processing: false,
    loading: false,
    error: '',
};

const defaultState = {
    ...defaultComponentState,
    questions: [],
    currentQuestionId: 0,
    lastQuestionId: 0,
    lastQuestionIdFinal: 0,
    questionsComplete: false,
    countriesFiltered: [],
    countries: [],
    loading: true,
    error: '',
};

const componentStateToRender = (
    kycStatus: any,
    onfidoAddressVerification: any,
    poaUploaded: any,
    user: any,
    countries: any
) => {
    // FYI: for quick testing a single component you can force a kyc state by placing the desired return block here
    console.log(kycStatus, onfidoAddressVerification, poaUploaded);

    const country = user?.address?.country || ''; // @ts-ignore
    const countryOfResidence = countries.find((item: any) => item.code === country); // @ts-ignore
    const useOnfidoPoa = countryOfResidence?.proof_of_address_accepted || false;
    const kycStatusParam = new URLSearchParams(window.location.search).get('kyc') || false;

    if (kycStatusParam && kycStatusParam === 'poa') {
        return {
            ...defaultComponentState,
            onfidoPOA: useOnfidoPoa,
            onfidoManualPOA: !useOnfidoPoa,
        };
    }

    if (
        kycStatus === 'verified' ||
        kycStatus === 'edd_ongoing' ||
        (kycStatus === 'not_started' && onfidoAddressVerification === null) ||
        kycStatus === 'retry_all'
    ) {
        return {
            ...defaultComponentState,
        };
    }

    if (kycStatus === 'retry_onfido') {
        return {
            ...defaultComponentState,
            restartOnfido: true,
        };
    }

    if (kycStatus === 'retry_final_questions') {
        return {
            ...defaultComponentState,
            questionsPart2: true,
            questionsComplete: false,
        };
    }

    if (!poaUploaded || kycStatus === 'retry_poa') {
        return {
            ...defaultComponentState,
            onfidoPOA: useOnfidoPoa,
            onfidoManualPOA: !useOnfidoPoa,
        };
    }

    if ((onfidoAddressVerification !== null || poaUploaded) && kycStatus === 'not_started') {
        return {
            ...defaultComponentState,
            questionsPart2: true,
            questionsComplete: user?.repayment_source_of_funds,
        };
    }

    if (
        (poaUploaded && kycStatus === 'pending') ||
        ['on_hold', 'blocked', 'rejected', 'edd_onboarding'].includes(kycStatus)
    ) {
        return {
            ...defaultComponentState,
            processing: true,
        };
    }

    return {
        ...defaultComponentState,
    };
};

const KYCStart = ({ questions, questions2, overlay, updateOverlay, user, refreshCoreData }: any) => {
    const [currentState, updateState] = useState(defaultState);
    const [errorState, updateErrorState] = useState({});
    const [currentlyLoading, updateLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await Auth.currentAuthenticatedUser(); // @ts-ignore
            const { countries, countriesFiltered } = await loadCountriesAndReturn(currentState, updateState);
            const userObject = hydrateUserData(user) || {};
            const { address_verification, poa_uploaded, status } = user?.kyc || {};
            const componentState =
                componentStateToRender(status, address_verification, poa_uploaded, user, countries) || {};

            const updatedState = {
                ...currentState,
                currentQuestionId: 0,
                lastQuestionId: questions?.length - 1,
                lastQuestionIdFinal: questions2?.length - 1,
                countries,
                countriesFiltered,
                ...userObject,
                ...componentState,
            };

            if (questions?.length > 0) {
                updateState({
                    ...updatedState,
                });

                if (componentState?.restartOnfido) {
                    await startOnfidoProcess(updatedState, updateState);
                }
            }
        })();
    }, [questions]);

    return (
        <Flex
            sx={{
                width: '100%',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '20px',
                ml: '20px',
                mr: '20px',
                mb: '100px',
            }}
        >
            {currentState.loading && <Spinner />}

            {currentState?.error && (
                <Paragraph
                    sx={{
                        position: 'absolute',
                        bottom: '-30px',
                        fontSize: '15px',
                        fontWeight: '600',
                        color: 'red',
                        minHeight: '30px',
                        width: '300px',
                        textAlign: 'center',
                    }}
                >
                    {currentState?.error}
                </Paragraph>
            )}

            {!currentState.onfidoLoaded && !currentState?.loading && currentState?.onfidoPOA && (
                <POA currentState={currentState} updateState={updateState} useOnfido={true} />
            )}

            {!currentState.onfidoLoaded && !currentState?.loading && currentState?.onfidoManualPOA && (
                <POA currentState={currentState} updateState={updateState} />
            )}

            {!currentState.onfidoLoaded && !currentState?.loading && currentState?.checkingAddress && (
                <CheckingAddressWithFacts />
            )}

            {!currentState.onfidoLoaded && !currentState?.loading && currentState?.questionsPart2 && (
                <QuestionContainer
                    currentState={currentState}
                    updateState={updateState}
                    questions={questions2}
                    overlay={overlay}
                    updateOverlay={updateOverlay}
                    errorState={errorState}
                    updateErrorState={updateErrorState}
                    refreshCoreData={refreshCoreData}
                    finalQuestions={true}
                    currentlyLoading={currentlyLoading}
                    updateLoading={updateLoading}
                />
            )}

            {!currentState.onfidoLoaded && !currentState?.loading && currentState?.processing && <Processing />}

            {!currentState.onfidoLoaded &&
                !currentState?.loading &&
                !currentState?.processing &&
                !currentState?.onfidoManualPOA &&
                !currentState?.questionsPart2 &&
                !currentState?.onfidoPOA &&
                !currentState?.checkingAddress && (
                    <QuestionContainer
                        currentState={currentState}
                        updateState={updateState}
                        questions={questions}
                        overlay={overlay}
                        updateOverlay={updateOverlay}
                        errorState={errorState}
                        updateErrorState={updateErrorState}
                        refreshCoreData={refreshCoreData}
                        finalQuestions={false}
                        currentlyLoading={currentlyLoading}
                        updateLoading={updateLoading}
                    />
                )}

            {currentState.onfidoLoaded && !currentState?.loading && (
                <Flex
                    sx={{ position: 'absolute', top: '-35px', left: '20px', fontWeight: '500', cursor: 'pointer' }}
                    onClick={() =>
                        updateState({
                            ...currentState,
                            onfidoLoaded: false,
                        })
                    }
                >
                    <i style={{ marginRight: '5px', paddingTop: '2px' }} className="fas fa-undo" /> Return
                </Flex>
            )}
            <Flex
                sx={{
                    minHeight: currentState.onfidoLoaded ? '560px' : '0px',
                    '@media screen and (max-width: 550px)': {
                        width: currentState.onfidoLoaded ? '100%' : 'inherit',
                        marginBottom: currentState.onfidoLoaded ? '180px' : 'inherit',
                        mt: currentState.onfidoLoaded ? '155px' : 'inherit',
                        minHeight: currentState.onfidoLoaded ? '630px' : '0px',
                        maxHeight: currentState.onfidoLoaded ? 'auto' : '0px',
                        height: currentState.onfidoLoaded ? 'auto' : '0px !important',
                    },
                }}
            >
                <Box
                    sx={{
                        minHeight: currentState.onfidoLoaded ? '560px' : '0px',
                        maxHeight: currentState.onfidoLoaded ? 'auto' : '0px',
                        height: currentState.onfidoLoaded ? 'auto' : '0px !important',
                        marginTop: currentState.onfidoLoaded ? '5px' : '-2500px',
                        '@media screen and (max-width: 550px)': {
                            minHeight: currentState.onfidoLoaded ? '630px' : '0px',
                        },
                    }}
                    id="onfido-mount"
                />
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    refreshCoreData: () => dispatch({ type: refreshDataAction }),
});

const mapStateToProps = (state: any) => ({
    loading: state?.account?.user?.partialLoading,
    questions: state?.account?.versionsConfig?.kycQuestionsPart1 || [],
    questions2: state?.account?.versionsConfig?.kycQuestionsPart2 || [],
    user: state?.account.user || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(KYCStart);
