/* eslint-disable no-undef */
import axios from 'axios';
import ReactSelect from 'react-select';
import { Button, Flex, Input, Label, Paragraph, Switch } from 'theme-ui';
import Divider from '../divider';
import theme from '../../theme';

export const ucase = (str) => (str ? `${str.substring(0, 1).toUpperCase()}${str.substring(1)}` : '');

const reminderDateOptions = [
    {
        value: 'statutory',
        label: 'Statutory Due Date',
    },
    {
        value: 'internal',
        label: 'Internal Due Date',
    },
];

const additionalReminderOptions = [
    {
        value: 1,
        label: '1 day before',
    },
    {
        value: 2,
        label: '2 days before',
    },
    {
        value: 3,
        label: '3 days before',
    },
    {
        value: 4,
        label: '4 days before',
    },
    {
        value: 5,
        label: '5 days before',
    },
    {
        value: 6,
        label: '6 days before',
    },
    {
        value: 7,
        label: '1 week before',
    },
    {
        value: 14,
        label: '2 weeks before',
    },
    {
        value: 30,
        label: '1 month before',
    },
    {
        value: -1,
        label: 'Daily thereafter',
    },
];

const saveNotificationSettings = async (state, updateState, organisation) => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                task_configuration: {
                    ...(organisation.task_configuration || {}),
                    daily_reminders_from: Array.isArray(state.daily_reminders_from) ? state.daily_reminders_from : [],
                    reminder_date_to_use: state.reminder_date_to_use,
                    reminders_per_service: state.reminders_per_service || [],
                },
            },
        });

        updateState({ ...state, loading: false, error: null });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error saving this, please contact support',
        });
    }
};

const updateMandatoryField = async (refreshCoreData, organisation, key, value) => {
    try {
        const taskConfiguration = organisation?.task_configuration || { mandatory_fields: [] };

        if (value) {
            if (!taskConfiguration.mandatory_fields.find((x) => x === key)) {
                taskConfiguration.mandatory_fields.push(key);
            }
        } else {
            taskConfiguration.mandatory_fields = taskConfiguration.mandatory_fields.filter((x) => x !== key);
        }

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                task_configuration: taskConfiguration,
            },
        });

        await refreshCoreData();
    } catch (e) {
        console.log(e);
    }
};

const TaskConfig = ({ organisation, updateState, state, refreshCoreData }) => {
    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Flex sx={{ mt: 20, width: 900, justifyContent: 'space-between' }}>
                <Paragraph sx={{ color: 'text', fontWeight: 600, mt: 0, fontSize: 20 }}>
                    Notification Settings
                </Paragraph>
            </Flex>

            <Divider width="900px" />

            <Paragraph sx={{ mt: 10 }}>Reminders for tasks that are due</Paragraph>

            <Flex sx={{ alignItems: 'flex-start', mt: 30, flexDirection: 'column' }}>
                <Paragraph sx={{ width: 150, mb: 20, fontWeight: 600, fontSize: 17 }}>Default Setting</Paragraph>
                <Flex sx={{ alignItems: 'center', mb: 10 }}>
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Paragraph sx={{ mr: 20, mb: 10 }}>Reminders (select multiple)</Paragraph>
                        <ReactSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '250px',
                                    minHeight: '40px',
                                    borderRadius: 10,
                                    marginBottom: '0px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            isMulti
                            onChange={async (value) => {
                                updateState({
                                    ...state,
                                    daily_reminders_from: value?.map((x) => x.value),
                                });
                            }}
                            placeholder={'Reminders (select multiple)'}
                            value={
                                Array.isArray(state.daily_reminders_from)
                                    ? state.daily_reminders_from?.map((x) => ({
                                          value: x,
                                          label: additionalReminderOptions?.find((y) => y.value === x)?.label,
                                      }))
                                    : null
                            }
                            options={additionalReminderOptions}
                        />
                    </Flex>
                    <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                        <Paragraph sx={{ mr: 20, mb: 10 }}>Date to use</Paragraph>
                        <ReactSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '250px',
                                    minHeight: '40px',
                                    borderRadius: 10,
                                    marginBottom: '0px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (value) => {
                                updateState({
                                    ...state,
                                    reminder_date_to_use: value?.value,
                                });
                            }}
                            placeholder={'Date to use'}
                            value={
                                typeof state.reminder_date_to_use === 'string'
                                    ? {
                                          value: state.reminder_date_to_use,
                                          label: reminderDateOptions?.find(
                                              (y) => y.value === state.reminder_date_to_use
                                          )?.label,
                                      }
                                    : null
                            }
                            options={reminderDateOptions}
                        />
                    </Flex>
                </Flex>
                <Divider />
            </Flex>

            <Paragraph sx={{ mb: 10, mt: 20, fontWeight: 600, fontSize: 17 }}>Per service settings</Paragraph>

            {(state.reminders_per_service || organisation?.task_configuration?.reminders_per_service || [])?.map(
                (x, idx) => (
                    <Flex sx={{ flexDirection: 'column' }} key={`reminders_per_service_${idx}`}>
                        <Flex sx={{ mt: 20, mb: 10, alignItems: 'center' }}>
                            <Flex sx={{ flexDirection: 'column' }}>
                                <Paragraph sx={{ width: 150, mb: 10 }}>Service</Paragraph>
                                <ReactSelect
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                            width: '250px',
                                            minHeight: '40px',
                                            borderRadius: 10,
                                            marginBottom: '0px',
                                            border: '1px solid #a3a69b',
                                            fontSize: '14px',
                                        }),
                                    }}
                                    onChange={async (value) => {
                                        state.reminders_per_service[idx].service = value?.value;
                                        updateState({
                                            ...state,
                                        });
                                    }}
                                    placeholder={'Select the service'}
                                    value={
                                        x.service
                                            ? {
                                                  value: x.service,
                                                  label: Object.entries(
                                                      organisation?.workflow_definition?.services || {}
                                                  )?.find((y) => y[0] === x.service)?.[1]?.label,
                                              }
                                            : null
                                    }
                                    options={Object.entries(organisation?.workflow_definition?.services || {})?.map(
                                        (x) => ({
                                            value: x[0],
                                            label: x[1]?.label,
                                        })
                                    )}
                                />
                            </Flex>
                            <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                                <Paragraph sx={{ mr: 20, mb: 10 }}>Reminders (select multiple)</Paragraph>
                                <ReactSelect
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                            width: '250px',
                                            minHeight: '40px',
                                            borderRadius: 10,
                                            marginBottom: '0px',
                                            border: '1px solid #a3a69b',
                                            fontSize: '14px',
                                        }),
                                    }}
                                    isMulti
                                    onChange={async (value) => {
                                        state.reminders_per_service[idx].value = value?.map((x) => x.value);
                                        updateState({
                                            ...state,
                                        });
                                    }}
                                    placeholder={'Reminders (select multiple)'}
                                    value={
                                        Array.isArray(state.reminders_per_service?.[idx]?.value)
                                            ? state.reminders_per_service?.[idx]?.value?.map((x) => ({
                                                  value: x,
                                                  label: additionalReminderOptions?.find((y) => y.value === x)?.label,
                                              }))
                                            : null
                                    }
                                    options={additionalReminderOptions}
                                />
                            </Flex>
                            <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                                <Paragraph sx={{ mr: 20, mb: 10 }}>Date to use</Paragraph>
                                <ReactSelect
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                            width: '250px',
                                            minHeight: '40px',
                                            borderRadius: 10,
                                            marginBottom: '0px',
                                            border: '1px solid #a3a69b',
                                            fontSize: '14px',
                                        }),
                                    }}
                                    onChange={async (value) => {
                                        state.reminders_per_service[idx].date_to_use = value?.value;
                                        updateState({
                                            ...state,
                                        });
                                    }}
                                    placeholder={'Date to use'}
                                    value={
                                        state.reminders_per_service?.[idx]?.date_to_use
                                            ? {
                                                  value: state.reminders_per_service[idx]?.date_to_use,
                                                  label: reminderDateOptions?.find(
                                                      (y) => y.value === state.reminders_per_service[idx]?.date_to_use
                                                  )?.label,
                                              }
                                            : null
                                    }
                                    options={reminderDateOptions}
                                />
                            </Flex>
                            <Button
                                onClick={() => {
                                    const reminders_per_service = state.reminders_per_service;

                                    reminders_per_service.splice(idx, 1);
                                    updateState({
                                        ...state,
                                        reminders_per_service,
                                    });
                                }}
                                sx={{ width: 100, ml: 20, mt: 30, height: 40 }}
                            >
                                Delete
                            </Button>
                        </Flex>
                        <Divider />
                    </Flex>
                )
            )}

            <Button
                onClick={async () => {
                    await saveNotificationSettings(state, updateState, organisation);
                }}
                sx={{ width: 100, mt: 20, height: 40 }}
            >
                Save
            </Button>

            <Paragraph
                onClick={() => {
                    const reminders_per_service = state.reminders_per_service || [];

                    reminders_per_service.push({
                        service: '',
                        value:
                            state.statutory_due_date_days_before ||
                            organisation?.task_configuration?.statutory_due_date_days_before ||
                            10,
                    });

                    updateState({ ...state, reminders_per_service });
                }}
                sx={{ cursor: 'pointer', mt: 20, color: 'blue' }}
            >
                Add additional per service settings
            </Paragraph>

            <Flex sx={{ mt: 40, width: 900, justifyContent: 'space-between' }}>
                <Paragraph sx={{ color: 'text', fontWeight: 600, mt: 10, fontSize: 20 }}>Task attributes</Paragraph>
            </Flex>

            <Divider width="900px" />

            <table
                style={{
                    marginTop: 10,
                    width: 520,
                    borderCollapse: 'collapse',
                    fontSize: 14,
                }}
            >
                <thead style={{ color: 'white', backgroundColor: theme.colors.primary, height: 50 }}>
                    <th style={{ width: 400, textAlign: 'left', paddingLeft: 20 }}>Name</th>
                    <th style={{ width: 120, textAlign: 'left', paddingLeft: 20 }}>Mandatory</th>
                </thead>
                {[
                    { key: 'client', label: 'Client' },
                    { key: 'service', label: 'Service' },
                    { key: 'relevant_period_end', label: 'Relevant Period End' },
                    { key: 'title', label: 'Title' },
                    { key: 'description', label: 'Description' },
                    { key: 'due_date', label: 'Internal Due Date' },
                    { key: 'statutory_due_date', label: 'Statutory Due Date' },
                    { key: 'team', label: 'Team' },
                    { key: 'partners', label: 'Partners assigned' },
                    { key: 'reviewers', label: 'Reviewers assigned' },
                    { key: 'assigned_to', label: 'Preparers assigned' },
                    { key: 'status', label: 'Status' },
                    { key: 'priority', label: 'Priority' },
                    { key: 'expected_fee', label: 'Expected Fee' },
                    { key: 'expected_date_billed', label: 'Expected Date Billed' },
                ].map((entry) => (
                    <tr style={{ height: 50, boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)' }} key={entry.key}>
                        <td style={{ textAlign: 'left', paddingLeft: 20 }}>{entry.label}</td>
                        <td style={{ textAlign: 'center', paddingLeft: 20 }}>
                            <Switch
                                defaultChecked={organisation?.task_configuration?.mandatory_fields?.includes(entry.key)}
                                onClick={() =>
                                    updateMandatoryField(
                                        refreshCoreData,
                                        organisation,
                                        entry.key,
                                        !organisation?.task_configuration?.mandatory_fields?.includes(entry.key)
                                    )
                                }
                            />
                        </td>
                    </tr>
                ))}
            </table>
        </Flex>
    );
};

export default TaskConfig;
