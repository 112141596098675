/* eslint-disable no-unused-expressions */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/** @jsxImportSource theme-ui */
import { Flex, Paragraph, Box } from 'theme-ui';
import Identicon from 'identicon.js';
import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions as menuActions } from '../store/reducers/menu';
// const urlPath = window.location.href;

const Menu = (props: any) => {
    const { settingsJourney, updateSettingsJourney, profileJourney, updateProfileJourney } = props;
    const menuItems = [
        {
            title: 'Profile',
            url: '/profile',
            testId: 'menu-profile',
            action: () => updateProfileJourney({ ...profileJourney, show: true }),
        },
        {
            title: 'Settings',
            url: '/',
            component: 'settings',
            testId: 'menu-settings',
            action: () => updateSettingsJourney({ ...settingsJourney, show: true }),
        },
        { title: 'Sign Out', url: '/', logout: true, testId: 'menu-sign-out' },
    ];
    return (
        <Flex
            sx={{
                position: 'absolute',
                right: '17px',
                top: '51px',
                width: '200px',
                height: '200px',
                backgroundColor: 'white',
                border: '1px solid lightgrey',
                borderRadius: '12px',
                zIndex: 99999,
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            {menuItems.map((item) => (
                <Paragraph
                    sx={{
                        mt: 0,
                        mb: 0,
                        pb: 15,
                        pt: 15,
                        pl: 10,
                        ml: 20,
                        mr: '15px',
                        lineHeight: '20px',
                        fontSize: '14px',
                        borderBottom: item.logout ? '0px' : '1px #DDD solid',
                        color: item.logout ? 'red' : 'black',
                        cursor: 'pointer',
                    }}
                    key={item.title}
                    onClick={async () => {
                        if (item.logout) {
                            return props.logout();
                        }

                        if (item.action) {
                            return item.action();
                        }

                        // return props.navigate(`${item.url}`);
                    }}
                    data-testid={item.testId}
                >
                    {item.title}
                </Paragraph>
            ))}
        </Flex>
    );
};

const HeaderComponent = (props: any) => {
    const navigate = useNavigate();

    return (
        <Flex
            ref={props.wrapperRef}
            sx={{
                width: '100vw',
                height: 55,
                position: 'absolute',
                top: 0,
                left: 0,
                justifyContent: 'flex-end',
                alignItems: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
            }}
            data-testid="header-menu"
            onClick={() => {
                props.toggleTopMenu();
            }}
        >
            <i
                style={{
                    fontSize: 20,
                    marginRight: 20,
                    color: '#FFF',
                    cursor: 'pointer',
                    zIndex: 2,
                }}
                className="far fa-chevron-down"
                aria-label="Open menu"
            />
            {props.showTopMenu && <Menu navigate={navigate} {...props} />}
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    logout: (data: any) => dispatch({ type: 'LOGOUT', data }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
    toggleTopMenu: () => dispatch({ type: menuActions.TOGGLE_TOP_MENU }),
});

const mapStateToProps = (state: any) => ({
    showTopMenu: state?.menu?.showTopMenu,
    user: state?.account.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
