import { Box, Paragraph, Flex, Image } from 'theme-ui';

export const PgPaginationV2 = ({
    currentState,
    updateState,
    totalPages,
    totalRecords,
    offsetKey,
    totalKey,
    limitKey,
}) => (
    <Flex sx={{ width: '215px', margin: '10px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
        <Box
            sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
            onClick={() =>
                !currentState.loading &&
                updateState({
                    ...currentState,
                    [offsetKey]: 0,
                })
            }
        >
            <i style={{ marginTop: '4px' }} className="fa fa-fast-backward" />
        </Box>

        {currentState?.[offsetKey] > 0 && (
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                onClick={() =>
                    !currentState.loading &&
                    updateState({
                        ...currentState,
                        [offsetKey]: currentState?.[offsetKey] - currentState?.[limitKey],
                    })
                }
            >
                <i style={{ marginTop: '4px' }} className="fa fa-backward" />
            </Box>
        )}
        {currentState?.[offsetKey] <= 0 && (
            <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                <i style={{ marginTop: '4px' }} className="fa fa-backward" />
            </Box>
        )}
        <Flex sx={{ flexDirection: 'column', width: '130px' }}>
            <Flex
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mt: '3px',
                }}
            >
                <Paragraph
                    sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        width: '80px',
                        textAlign: 'center',
                    }}
                >
                    Page{' '}
                    {currentState?.[offsetKey] === 0
                        ? 1
                        : Math.ceil((currentState?.[offsetKey] + 1) / currentState?.[limitKey])}{' '}
                    of {totalPages}
                </Paragraph>
            </Flex>
            <Flex sx={{ mt: 10, justifyContent: 'center' }}>
                <Paragraph
                    sx={{
                        fontWeight: '600',
                        fontSize: '10px',
                        textAlign: 'center',
                    }}
                >
                    ({new Intl.NumberFormat('en-US').format(totalRecords)} {totalRecords === 1 ? 'record' : 'records'})
                </Paragraph>
            </Flex>
        </Flex>

        {currentState?.[totalKey] > currentState?.[limitKey] + currentState?.[offsetKey] && (
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() =>
                    !currentState.loading &&
                    updateState({
                        ...currentState,
                        [offsetKey]: currentState?.[offsetKey] + currentState?.[limitKey],
                    })
                }
            >
                <i style={{ marginTop: '4px' }} className="fa fa-forward" />
            </Box>
        )}

        {currentState.totalComments <= currentState.commentsLimit + currentState?.[offsetKey] && (
            <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                <i style={{ marginTop: '4px' }} className="fa fa-forward" />
            </Box>
        )}
        <Box
            sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
            onClick={() =>
                !currentState.loading &&
                currentState.totalComments > currentState.commentsLimit + currentState?.[offsetKey] &&
                updateState({
                    ...currentState,
                    [offsetKey]:
                        Math.floor(currentState?.[totalKey] / currentState?.[limitKey]) * currentState?.[limitKey],
                })
            }
        >
            <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-fast-forward" />
        </Box>
    </Flex>
);

export const PgPagination = ({
    additionalStyles,
    currentState,
    updateState,
    totalPages,
    totalRecords,
    type = 'uploads',
}) => {
    if (type === 'uploads') {
        return (
            <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            offset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.offset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                offset: currentState.offset - currentState.limit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                {currentState.offset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page
                        </Paragraph>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                mr: '5px',
                                ml: '10px',
                                width: '170px',
                                height: '30px',
                            }}
                        >
                            {currentState.offset === 0 ? 1 : Math.ceil((currentState.offset + 1) / currentState.limit)}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>

                {currentState.total > currentState.limit + currentState.offset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                offset: currentState.offset + currentState.limit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                {currentState.total <= currentState.limit + currentState.offset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.total > currentState.limit + currentState.offset &&
                        updateState({
                            ...currentState,
                            offset: Math.floor(currentState.total / currentState.limit) * currentState.limit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    } else if (type === 'chats') {
        return (
            <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            chatsOffset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.chatsOffset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                chatsOffset: currentState.chatsOffset - currentState.chatsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                {currentState.chatsOffset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page{' '}
                            {currentState.chatsOffset === 0
                                ? 1
                                : Math.ceil((currentState.chatsOffset + 1) / currentState.chatsLimit)}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>

                {currentState.totalChats > currentState.chatsLimit + currentState.chatsOffset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                chatsOffset: currentState.chatsOffset + currentState.chatsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                {currentState.totalChats <= currentState.chatsLimit + currentState.chatsOffset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.totalChats > currentState.chatsLimit + currentState.chatsOffset &&
                        updateState({
                            ...currentState,
                            chatsOffset:
                                Math.floor(currentState.totalChats / currentState.chatsLimit) * currentState.chatsLimit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    } else if (type === 'docsOnly') {
        return (
            <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            docOffset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.docOffset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                docOffset: currentState.docOffset - currentState.docLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                {currentState.docOffset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page{' '}
                            {currentState.docOffset === 0
                                ? 1
                                : Math.ceil((currentState.docOffset + 1) / currentState.docLimit)}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>

                {currentState.docTotal > currentState.docLimit + currentState.docOffset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                docOffset: currentState.docOffset + currentState.docLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                {currentState.docTotal <= currentState.docLimit + currentState.docOffset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.docTotal > currentState.docLimit + currentState.docOffset &&
                        updateState({
                            ...currentState,
                            docOffset:
                                Math.floor(currentState.docTotal / currentState.docLimit) * currentState.docLimit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    } else if (type === 'docsWordOnly') {
        return (
            <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            docWordOffset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.docWordOffset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                docWordOffset: currentState.docWordOffset - currentState.docWordLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                {currentState.docWordOffset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}

                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page{' '}
                            {currentState.docWordOffset === 0
                                ? 1
                                : Math.ceil((currentState.docWordOffset + 1) / currentState.docWordLimit)}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>

                {currentState.docWordTotal > currentState.docWordLimit + currentState.docWordOffset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                docWordOffset: currentState.docWordOffset + currentState.docWordLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                {currentState.docWordTotal <= currentState.docWordLimit + currentState.docWordOffset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}

                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.docWordTotal > currentState.docWordLimit + currentState.docWordOffset &&
                        updateState({
                            ...currentState,
                            docWordOffset:
                                Math.floor(currentState.docWordTotal / currentState.docWordLimit) *
                                currentState.docWordLimit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    } else if (type === 'definitions') {
        return (
            <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            definitionsOffset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.definitionsOffset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                definitionsOffset: currentState.definitionsOffset - currentState.definitionsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}
                {currentState.definitionsOffset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}
                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page{' '}
                            {currentState.definitionsOffset === 0
                                ? 1
                                : Math.ceil((currentState.definitionsOffset + 1) / currentState.definitionsLimit)}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>
                {currentState.definitionsTotal > currentState.definitionsLimit + currentState.definitionsOffset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                definitionsOffset: currentState.definitionsOffset + currentState.definitionsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}
                {currentState.definitionsTotal <= currentState.definitionsLimit + currentState.definitionsOffset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.definitionsTotal >
                            currentState.definitionsLimit + currentState.definitionsOffset &&
                        updateState({
                            ...currentState,
                            definitionsOffset:
                                Math.floor(currentState.definitionsTotal / currentState.definitionsLimit) *
                                currentState.definitionsLimit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    } else if (type === 'clients') {
        return (
            <Flex
                sx={{
                    width: '215px',
                    margin: '50px auto 20px',
                    justifyContent: 'space-around',
                    color: 'primary',
                    ...(additionalStyles || {}),
                }}
            >
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            clientsOffset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.clientsOffset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                clientsOffset: currentState.clientsOffset - currentState.clientsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}
                {currentState.clientsOffset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}
                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '100px',
                                height: '30px',
                            }}
                        >
                            Page{' '}
                            {currentState.clientsOffset === 0
                                ? 1
                                : Math.ceil((currentState.clientsOffset + 1) / currentState.clientsLimit)}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>
                {currentState.clientsTotal > currentState.clientsLimit + currentState.clientsOffset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                clientsOffset: currentState.clientsOffset + currentState.clientsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}
                {currentState.clientsTotal <= currentState.clientsLimit + currentState.clientsOffset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.clientsTotal > currentState.clientsLimit + currentState.clientsOffset &&
                        updateState({
                            ...currentState,
                            clientsOffset:
                                Math.floor(currentState.clientsTotal / currentState.clientsLimit) *
                                currentState.clientsLimit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    } else if (type === 'clientsAlerts') {
        console.log('totalPages ', totalPages);
        return (
            <Flex sx={{ width: '215px', margin: '50px auto 20px', justifyContent: 'space-around', color: 'primary' }}>
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        updateState({
                            ...currentState,
                            clientsAlertsOffset: 0,
                        })
                    }
                >
                    <i style={{ marginTop: '4px' }} className="fa fa-backward" />
                </Box>

                {currentState.clientsAlertsOffset > 0 && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer', ml: '5px' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                clientsAlertsOffset: currentState.clientsAlertsOffset - currentState.clientsAlertsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}
                {currentState.clientsAlertsOffset <= 0 && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed', ml: '5px' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-left.png"
                            width="26"
                            height="26"
                            alt="prev button"
                        />
                    </Box>
                )}
                <Flex sx={{ flexDirection: 'column', width: '180px' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '14px',
                                mt: '3px',
                                ml: '20px',
                                width: '80px',
                                height: '30px',
                            }}
                        >
                            Page{' '}
                            {currentState.clientsAlertsOffset === 0
                                ? 1
                                : Math.ceil(
                                      (currentState.clientsAlertsOffset + 1) / currentState.clientsAlertsLimit
                                  )}{' '}
                            of {totalPages}
                        </Paragraph>
                    </Flex>
                    <Flex sx={{ width: '150px', height: '40px', mt: '10px' }}>
                        <Paragraph
                            sx={{
                                fontWeight: '600',
                                fontSize: '10px',
                                mt: '0px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            ({new Intl.NumberFormat('en-US').format(totalRecords)}{' '}
                            {totalRecords === 1 ? 'record' : 'records'})
                        </Paragraph>
                    </Flex>
                </Flex>
                {currentState.clientsAlertsTotal >
                    currentState.clientsAlertsLimit + currentState.clientsAlertsOffset && (
                    <Box
                        sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                        onClick={() =>
                            !currentState.loading &&
                            updateState({
                                ...currentState,
                                clientsAlertsOffset: currentState.clientsAlertsOffset + currentState.clientsAlertsLimit,
                            })
                        }
                    >
                        <Image
                            src="https://cdn.accru.finance/arrows/enabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}
                {currentState.clientsAlertsTotal <=
                    currentState.clientsAlertsLimit + currentState.clientsAlertsOffset && (
                    <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                        <Image
                            src="https://cdn.accru.finance/arrows/disabled-right.png"
                            width="26"
                            height="26"
                            alt="next button"
                        />
                    </Box>
                )}
                <Box
                    sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                    onClick={() =>
                        !currentState.loading &&
                        currentState.clientsAlertsTotal >
                            currentState.clientsAlertsLimit + currentState.clientsAlertsOffset &&
                        updateState({
                            ...currentState,
                            clientsAlertsOffset:
                                Math.floor(currentState.clientsAlertsTotal / currentState.clientsAlertsLimit) *
                                currentState.clientsAlertsLimit,
                        })
                    }
                >
                    <i style={{ marginTop: '4px', marginLeft: '10px' }} className="fa fa-forward" />
                </Box>
            </Flex>
        );
    }
};
