import moment from 'moment';
import { Flex, Paragraph } from 'theme-ui';
import { deleteComment } from './api';

const Comment = ({ updateState, state, refreshCallback, data, page = 'task', borderRadius = 10 }) => (
    <Flex
        sx={{
            borderRadius,
            mt: 20,
            padding: 20,
            width: 400,
            borderBottom: '1px solid #ccc',
            flexDirection: 'column',
        }}
    >
        <Paragraph
            dangerouslySetInnerHTML={{ __html: data.content }}
            sx={{ mt: -10, mr: 20, wordWrap: 'break-word', color: '#333' }}
        />
        <Flex sx={{ alignSelf: 'flex-end', flexDirection: 'column' }}>
            <i
                style={{
                    fontSize: '12px',
                    marginLeft: '9px',
                    marginRight: '6px',
                    color: 'red',
                    alignSelf: 'flex-end',
                    cursor: 'pointer',
                }}
                className={`fas fa-trash`}
                onClick={() =>
                    updateState({
                        ...state,
                        showDeleteCommentConfirmation: true,
                        confirmCallback: deleteComment(data.id, state, updateState, refreshCallback, page),
                    })
                }
            />
            <Paragraph sx={{ mt: 10, fontSize: 12 }}>{moment.utc(data.created_at).format('HH:mm Do MMM YY')}</Paragraph>
        </Flex>
    </Flex>
);

export default Comment;
