/** @jsxImportSource theme-ui */
import { Flex, Paragraph } from 'theme-ui';
import validatePassword from '../utils/validate-password';
import theme from '../theme';

const PasswordValidationItem = (props: any) => (
    <Flex sx={{ marginBottom: '3px', ml: '10px', width: 150, alignItems: 'center' }}>
        <i
            style={{
                marginRight: props.passing ? '5px' : '9px',
                marginTop: '4px',
                fontSize: 14,
                color: props.passing ? theme.colors.primary : 'red',
            }}
            className={`far ${props.passing ? 'fa-check' : 'fa-times'}`}
        />
        <Paragraph sx={{ margin: '0px', fontFamily: 'body', fontSize: 14 }}>{props.text}</Paragraph>
    </Flex>
);

const PasswordValidation = (props: any) => {
    const checks = ['10 characters min', '1 number', '1 letter', '1 uppercase letter', '1 special character'];
    const passwordResult = validatePassword(props.password?.trim());
    return (
        <Flex
            sx={{
                mb: '5px',
                mt: '5px',
                flexDirection: 'column',
                ml: [10, 'unset'],
            }}
        >
            <Flex>
                <PasswordValidationItem passing={passwordResult[0]} text={checks[0]} />
                <PasswordValidationItem passing={passwordResult[1]} text={checks[1]} />
            </Flex>
            <Flex>
                <PasswordValidationItem passing={passwordResult[2]} text={checks[2]} />
                <PasswordValidationItem passing={passwordResult[3]} text={checks[3]} />
            </Flex>
        </Flex>
    );
};

export default PasswordValidation;
