/* eslint-disable no-undef */
import { Button, Flex, Paragraph, Spinner } from 'theme-ui';
import Divider from './divider';
import axios from 'axios';
import moment from 'moment';
import theme from '../theme';

const updateNotification = async (id, state, updateState, status, userType) => {
    try {
        updateState({ ...state, isLoading: true });

        const extraPathSegment = userType === 'client_user' ? '/portal' : '';

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/notifications${extraPathSegment}/${id}`,
            method: 'PUT',
            data: {
                status,
            },
        });
        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/notifications${extraPathSegment}`,
        });

        updateState({ ...state, isLoading: false, notifications: data.notifications });
    } catch (e) {
        updateState({ ...state, isLoading: false });
    }
};

const NotificationsPanel = ({ userType, toggleNotificationsPanel, state, updateState }) => {
    return (
        <Flex
            sx={{
                border: `1px solid ${theme.colors.primary}`,
                width: [350, 400],
                height: 600,
                background: '#FFF',
                boxShadow: '0px 0px 12px rgba(0,0,0,0.2)',
            }}
        >
            {state.isLoading && <Spinner sx={{ left: 210, position: 'absolute' }} />}
            <Flex sx={{ flexDirection: 'column', ml: 20, mr: 20, mt: 20 }}>
                <Flex sx={{ justifyContent: 'space-between' }}>
                    <Paragraph sx={{ fontWeight: 500, color: 'text' }}>My Notifications</Paragraph>
                    <i
                        onClick={() => toggleNotificationsPanel()}
                        style={{ cursor: 'pointer', color: theme.colors.primary }}
                        className="fa fa-window-close"
                    />
                </Flex>
                <Divider width={'360px'} />
                <Flex sx={{ flexDirection: 'column', overflowY: 'auto' }}>
                    {state.notifications?.length === 0 && <Paragraph>0 notifications at the moment</Paragraph>}
                    {state.notifications?.map((x, idx) => (
                        <Flex sx={{ flexDirection: 'column' }} key={`notific_${idx}`}>
                            <Flex sx={{ flexDirection: 'column' }}>
                                <Paragraph
                                    dangerouslySetInnerHTML={{ __html: x.text }}
                                    sx={{ fontSize: 14, fontWeight: x.status === 'read' ? '400' : '500' }}
                                />
                                <Paragraph sx={{ fontSize: 10, mt: '5px' }}>
                                    {moment(x.created_at).format('HH:mm DD MMM YY')}
                                </Paragraph>
                                <Flex sx={{ mt: 20 }}>
                                    {x.action_url && (
                                        <a target="_blank" rel="noreferrer" href={x.action_url}>
                                            <Button
                                                sx={{
                                                    width: 100,
                                                    height: 35,
                                                    mr: 10,
                                                    fontSize: 12,
                                                    backgroundColor: 'white',
                                                    color: 'text',
                                                    border: '1px solid lightGrey',
                                                }}
                                            >
                                                <i style={{ marginRight: '7px' }} className="fa fa-envelope-open" />
                                                Open
                                            </Button>
                                        </a>
                                    )}
                                    <Button
                                        onClick={() =>
                                            updateNotification(
                                                x.id,
                                                state,
                                                updateState,
                                                x.status === 'read' ? 'new' : 'read',
                                                userType
                                            )
                                        }
                                        sx={{
                                            width: 130,
                                            height: 35,
                                            mr: 10,
                                            fontSize: 12,
                                            backgroundColor: 'white',
                                            color: 'text',
                                            border: '1px solid lightGrey',
                                        }}
                                    >
                                        {x.status === 'read' ? 'Mark as unread' : 'Mark as read'}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            updateNotification(x.id, state, updateState, 'deleted', userType)
                                        }
                                        sx={{
                                            width: 100,
                                            height: 35,

                                            fontSize: 12,
                                            backgroundColor: 'white',
                                            color: 'text',
                                            border: '1px solid lightGrey',
                                        }}
                                    >
                                        <i style={{ marginRight: '7px', color: 'red' }} className="fa fa-trash" />
                                        Delete
                                    </Button>
                                </Flex>
                            </Flex>
                            <Divider width={'400px'} />
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default NotificationsPanel;
