import moment from 'moment';
import React from 'react';
import Identicon from 'identicon.js';
import { connect } from 'react-redux';
import { Paragraph, Flex } from 'theme-ui';
import OverlayHeading from '../heading';
import OverlayTitle from '../title';

const Row = ({ label, value }) => (
    <Flex sx={{ mb: 20, width: [300, 'auto'] }}>
        <Paragraph sx={{ fontWeight: 'bold', width: [180, 200] }}>{label}</Paragraph>
        <Paragraph sx={{ textAlign: 'left', width: [120, 'auto'], wordBreak: 'break-word' }}>{value}</Paragraph>
    </Flex>
);

const ProfileOverlay = ({ userData, updateOverlay, overlay }) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                mt: '40px',
                alignItems: 'center',
            }}
        >
            <OverlayHeading title="" />
            {userData?.user_id && (
                <Flex sx={{ mt: 20, mb: 20 }}>
                    <img
                        alt="user avatar"
                        width="80"
                        height="80"
                        src={`data:image/png;base64,${new Identicon(userData?.user_id, 50)}`}
                    />
                </Flex>
            )}
            <OverlayTitle centered title="Profile" />{' '}
            <Flex sx={{ alignSelf: ['center', 'flex-start'], flexDirection: 'column', ml: ['unset', 60], mt: 50 }}>
                <Row label="First Name" value={userData?.first_name} />
                <Row label="Last Name" value={userData?.last_name} />
                <Row label="Email Address" value={userData?.email_address} />
                <Row
                    label="Date Of Birth"
                    value={
                        userData?.date_of_birth
                            ? moment(userData?.date_of_birth, 'YYYY-MM-DD').format('Do MMMM YYYY')
                            : ''
                    }
                />
                {userData?.reminder_config && (
                    <Row
                        label="Telephone Number"
                        value={
                            userData?.reminder_config
                                ? `${userData?.reminder_config?.country_code} ${userData?.reminder_config?.phone_number}`
                                : ''
                        }
                    />
                )}
            </Flex>
        </Flex>
    );
};

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        accountSummary: account.accountSummary,
        transactionsInit: account.transactions || [],
    };
};

export default connect(mapStateToProps, null)(ProfileOverlay);
