/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Flex, Image, Paragraph } from 'theme-ui';

const TaskButton = ({ data, statuses, state, organisation }) => {
    const [collected, drag, dragPreview] = useDrag(() => ({
        type: 'task_button',
        item: { id: data?.id, status: data.status, subtasks: data.subtasks },
    }));

    const assignedTo = data?.relations?.filter((x) => x.type === 'assigned_to')?.map((x) => x.id);

    const users = state.users?.filter((x) => assignedTo.includes(x.raw_id));

    const baseUrl = `https://aflow-avatars-${
        process.env.REACT_APP_IS_LIVE === '1' ? 'prod' : 'uat'
    }.s3.eu-west-1.amazonaws.com`;

    const avatarUrls = users
        ?.filter((x) => x?.additional_data?.avatar_key)
        .map((x) => ({
            url: `${baseUrl}/${x?.additional_data?.avatar_key}`,
            user: x,
        }));

    const [localState, updateLocalState] = useState({ avatarData: null });

    useEffect(() => {
        if (avatarUrls?.length) {
            (async () => {
                const res = await Promise.all(
                    avatarUrls.slice(0, Math.min(2, avatarUrls.length)).map(async (x) => {
                        const { data } = await axios.get(x.url);

                        return Promise.resolve({
                            user: x.user,
                            imgData: data,
                            label:
                                x.user?.first_name && x.user?.last_name
                                    ? `${x.user.first_name} ${x.user.last_name}`
                                    : x.user?.email_address || '',
                        });
                    })
                );

                updateLocalState({ ...localState, avatarData: res });
            })();
        }
    }, []);

    const user = users?.[0];

    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div ref={drag} {...collected}>
            <a style={{ textDecoration: 'none' }} href={`/tasks?id=${data.id}`}>
                <Flex
                    sx={{
                        flexDirection: 'column',
                        cursor: 'pointer',
                        borderRadius: 10,
                        filter: 'drop-shadow(0 0 0.2rem #000)',
                        backgroundColor: '#FFF',
                        padding: 25,
                        '&:hover': {
                            background:
                                'radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%)',
                        },
                    }}
                >
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Paragraph
                            sx={{
                                color: 'text',
                                fontWeight: '400',
                                mb: user ? 0 : 20,
                                fontSize: 14,
                                wordWrap: 'break-word',
                            }}
                        >
                            {data.title}
                        </Paragraph>

                        {user?.email_address && !localState.avatarData?.length ? (
                            <Paragraph
                                sx={{
                                    fontSize: 12,
                                    mt: 10,
                                    mb: 10,
                                    wordWrap: 'break-word',
                                    color: '#000',
                                }}
                            >
                                {user?.first_name && user?.last_name
                                    ? `${user.first_name} ${user.last_name}`
                                    : user.email_address}
                            </Paragraph>
                        ) : (
                            <Flex sx={{ height: 20 }} />
                        )}
                    </Flex>

                    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Flex sx={{ alignItems: 'center' }}>
                            <Flex>
                                <Flex
                                    sx={{
                                        width: 18,
                                        height: 18,
                                        borderRadius: 12,
                                        mr: 10,
                                        backgroundColor: organisation?.default_visualisation?.priorities?.find(
                                            (x) => x.key === data.priority
                                        )?.color,
                                    }}
                                />
                                {data.due_date &&
                                    !['done', 'closed'].includes(data.status) &&
                                    moment(data.due_date).isSameOrBefore(moment()) && (
                                        <i
                                            style={{
                                                marginRight: 20,
                                                fontSize: '20px',
                                                color: 'red',
                                                cursor: 'pointer',
                                                mr: 10,
                                            }}
                                            className="fas fa-bell"
                                            aria-hidden="true"
                                        />
                                    )}
                            </Flex>
                            {localState.avatarData?.length &&
                                localState.avatarData?.map((x, idx) => (
                                    <a
                                        key={`avatar_${idx}`}
                                        rel="noreferrer"
                                        href={`/tasks?user_id=${x.user?.raw_id}`}
                                        target="_blank"
                                    >
                                        <Image
                                            sx={{ width: 26, height: 26, mr: '10px' }}
                                            src={x.imgData}
                                            title={x.label}
                                            alt="avatar"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </a>
                                ))}
                            <Paragraph
                                sx={{
                                    fontWeight: '400',
                                    fontSize: 12,
                                    border: '1px solid #CCC',
                                    borderRadius: 12,
                                    padding: '5px 8px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                {statuses?.find((x) => x.key === data.status)?.label}
                            </Paragraph>
                        </Flex>
                    </Flex>
                </Flex>
            </a>
        </div>
    );
};

export default TaskButton;
