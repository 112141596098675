import { Flex, Paragraph, Button, Input, Box, Image, Heading } from 'theme-ui'; //@ts-ignore
import FileBase64 from 'react-file-base64';
import { useState, useEffect } from 'react';
import axios from 'axios';
import OverlayHeading from './heading';
import { trackLoggedInEvent } from './utils/analytics';
import { QuestionCarouselComponent, ProgressComponent, startOnFido } from './helper';

const uploadFile = async (currentState: any, updateState: any, base64: any, type: any) => {
    try {
        const { data: uploadUrlRes } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/kyc/upload-url`,
            data: {
                type: 'poa',
                file_type: type,
            },
        });

        await axios({
            url: uploadUrlRes.url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
            method: 'PUT',
            data: {
                type: 'poa',
                file_type: type,
                base_64: base64,
            },
        });

        updateState({
            ...currentState,
            error: '',
            checkingAddress: false,
            onfidoPOA: false,
            onfidoManualPOA: false,
            questionsPart2: true,
            questionsComplete: false,
            onfidoLoaded: false,
            processing: false,
            loading: false,
        });
    } catch (e) {
        updateState({ ...currentState, error: 'failed to upload document', loading: false });
    }
};

export const startOnfidoProcess = async (currentState: any, updateState: any) => {
    updateState({ ...currentState, loading: true });
    await startOnFido(currentState, updateState, ['document', 'facial_similarity_video'], false);
};

export const POA = ({ currentState, updateState, useOnfido }: any) => {
    const getBase64 = async (file: any) => {
        updateState({ ...currentState, error: '', loading: true });

        const { base64, size, type } = file;

        if (size.split(' ')[0] >= 15000000) {
            updateState({
                ...currentState,
                error: 'File too big please upload a file smaller than 15MB',
                loading: false,
            });
            trackLoggedInEvent('POAManualUploadFailed', {});
        } else {
            await uploadFile(currentState, updateState, base64, type);
            trackLoggedInEvent('POAManualUploadSuccess', {});
        }
    };

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                backgroundColor: 'white',
                minHeight: '400px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '-40px',
                width: '100%',
            }}
        >
            <Flex
                sx={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    marginTop: '0px',
                    minHeight: '480px',
                    overflow: 'scroll',

                    '@media screen and (max-width: 700px)': {
                        mt: '40px',
                    },
                }}
            >
                <Heading
                    data-testid="poa-notice"
                    sx={{
                        width: '80%',
                        textAlign: 'center',
                        mt: 20,
                        mb: '15px',
                        '@media screen and (max-width: 700px)': { mb: '5px', fontSize: '18px' },
                    }}
                >
                    Please ensure your documents are clear.
                </Heading>

                <Paragraph
                    sx={{
                        width: '80%',
                        textAlign: 'center',
                        mt: '5px',
                        fontSize: '16px',
                        mb: 0,
                        '@media screen and (max-width: 700px)': { fontSize: '14px' },
                    }}
                >
                    Upload a photo or PDF of your document.
                </Paragraph>

                <Paragraph
                    sx={{
                        width: '80%',
                        textAlign: 'center',
                        mt: '10px',
                        fontSize: '16px',
                        mb: 0,

                        '@media screen and (max-width: 700px)': { fontSize: '14px' },
                    }}
                >
                    Make sure all four corners are visible and the text is legible.
                    <br />
                    Your proof of address may not be accepted if it is not clear enough.
                </Paragraph>

                <Paragraph
                    sx={{
                        width: '80%',
                        textAlign: 'center',
                        mt: '5px',
                        fontSize: '14px',
                        mb: 0,
                        color: 'red',

                        '@media screen and (max-width: 700px)': { fontSize: '14px' },
                    }}
                >
                    * Bills or bank statements must have been issued within the last 3 months.
                </Paragraph>

                <Flex sx={{ mt: '10px', '@media screen and (max-width: 700px)': { mt: '10px' } }}>
                    <Box
                        sx={{
                            width: '150px',
                            height: '150px',
                            mt: '15px',
                            '@media screen and (max-width: 700px)': { width: '130px' },
                        }}
                    >
                        <Image
                            src={`${process.env.REACT_APP_CDN_BASE_URL}/kyc/poa-bad.png`}
                            width="150px"
                            height="150px"
                            alt="kyc intro"
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '150px',
                            height: '150px',
                            mt: '15px',
                            ml: '30px',
                            '@media screen and (max-width: 700px)': { width: '130px', ml: '10px' },
                        }}
                    >
                        <Image
                            src={`${process.env.REACT_APP_CDN_BASE_URL}/kyc/poa-good.png`}
                            width="150px"
                            height="150px"
                            alt="kyc intro"
                        />
                    </Box>
                </Flex>
                <Flex
                    sx={{
                        mt: '50px',
                        mb: '15px',
                        ml: useOnfido ? '0px' : 'auto',
                        input: {
                            fontSize: '17px',
                            '@media screen and (max-width: 700px)': { fontSize: '14px' },
                        },
                    }}
                >
                    {!useOnfido ? <FileBase64 onDone={getBase64} /> : null}

                    {useOnfido && (
                        <Button
                            sx={{
                                mt: 10,
                                alignSelf: 'center',
                                width: 250,
                            }}
                            variant="secondary"
                            onClick={async () => {
                                await startOnFido(currentState, updateState, ['proof_of_address'], true);
                            }}
                        >
                            Upload Proof of Address
                        </Button>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export const CheckingAddressWithFacts = () => {
    const facts = [
        'On May 10, 2010, Laszlo Hanyecz spent 10,000 Bitcoin for 2 Papa John’s pizzas in Florida. This was the first recorded Bitcoin payment for goods, and was valued at about $40 at the time. ',
        'There have been over 485,000 token contracts created on Ethereum.',
        'Bitcoin became legal tender alongside the US Dollar in El Salvador in 2021',
        'According to CoinATMRadar there are over 34,000 crypto ATMs controlled by 603 operators in 77 countries.',
        'It is well known that there will only be 21 million Bitcoin ever created. However, out of this, an estimated 3.7 million Bitcoin have been lost forever.',
        'Bitcoin became legal tender alongside the US Dollar in El Salvador in 2021',
        '64 crypto companies achieved the status of “unicorn” meaning they are valued over $1 Billion.',
        'NFT sales on OpenSea have now surpassed $14 Billion, at an average sale of $939. ',
        'Even though they grew in popularity in 2021 and are considered digital assets, NFTs aren’t cryptocurrencies. They’re tokens that are not used as a medium of exchange. And NFTs can’t be divided or replicated.',
        'Ethereum, Bitcoin’s top competitor, had the highest amount of transactions per day during Q4 of 2020.',
        'There were 5,098 alternative cryptocurrencies in existence aside from Bitcoin as of 2020.',
        'Banks often lose a lot due to procedures like money-laundry checks, payment processing, reconciliations, compliance, and treasury operations. The implementation of blockchain will see financial institutions save over $25 billion by 2030. That will cut the organizations’ expenses in half. ',
    ];

    const allFacts = [...facts, ...facts, ...facts, ...facts];
    const [currentFact, updateFact] = useState(facts[0]);

    async function waitFor(seconds: any) {
        return new Promise((resolve, reject) => {
            setTimeout(resolve, seconds * 1000);
        });
    }

    useEffect(() => {
        (async () => {
            for (const fact of allFacts) {
                await waitFor(4);
                updateFact(fact);
            }
        })();

        let interval: NodeJS.Timeout;
        function startTimer(duration: any, display: any) {
            let timer = duration,
                minutes,
                seconds;
            interval = setInterval(function () {
                //@ts-ignore
                minutes = parseInt(timer / 60, 10); //@ts-ignore
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? '0' + minutes : minutes;
                seconds = seconds < 10 ? '0' + seconds : seconds;

                display.textContent = minutes + ':' + seconds;

                if (--timer < 0) {
                    clearInterval(interval);
                }
            }, 1000);
        }

        const threeMinutes = 60 * 3,
            display = document.querySelector('#time');
        startTimer(threeMinutes, display);

        return () => {
            //@ts-ignore
            clearInterval(interval);
        };
    }, []);

    return (
        <Box
            sx={{
                width: '98%',
                maxWidth: '700px',
                height: '98%',
                maxHeight: '600px',
                backgroundColor: 'white',
                borderRadius: '12px',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'none',
            }}
        >
            <Flex
                sx={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    marginTop: '20px',
                    height: '480px',
                    overflow: 'scroll',

                    '@media screen and (max-width: 700px)': {
                        mt: '40px',
                    },
                }}
            >
                <Paragraph sx={{ fontSize: '21px', fontWeight: '800', marginBottom: '10px' }}>
                    Please don't refresh the page
                </Paragraph>

                <Box
                    sx={{
                        width: '110px',
                        height: '110px',
                        mt: '15px',
                    }}
                >
                    <Image
                        src={`${process.env.REACT_APP_CDN_BASE_URL}/gifs/loading.gif`}
                        width="110px"
                        height="110px"
                        alt="kyc intro"
                    />
                </Box>
                <Paragraph
                    sx={{
                        fontSize: '18px',
                        width: '80%',
                        minHeight: '30px',
                        textAlign: 'center',
                        fontWeight: '600',
                        color: '#2B8ACC',
                        position: 'relative',
                        mt: '0px',
                        '@media screen and (max-width: 700px)': {
                            fontSize: '16px',
                            width: '90%',
                            textAlign: 'center',
                            mb: '20px',
                        },
                    }}
                >
                    <span id="time">03:00</span>
                </Paragraph>

                <Paragraph
                    sx={{
                        fontSize: '18px',
                        mt: '20px',
                        width: '80%',
                        textAlign: 'center',
                        mb: '20px',
                        '@media screen and (max-width: 700px)': {
                            fontSize: '16px',
                            width: '90%',
                            textAlign: 'center',
                            mb: '20px',
                        },
                    }}
                >
                    We're just verifying your details. This can take up to 3 minutes.
                </Paragraph>

                <Paragraph
                    sx={{
                        fontSize: '17px',
                        width: '80%',
                        minHeight: '50px',
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: '#2B8ACC',
                        position: 'relative',
                        mt: '70px',
                        '@media screen and (max-width: 700px)': {
                            fontSize: '16px',
                            width: '90%',
                            textAlign: 'center',
                            mb: '20px',
                        },
                    }}
                >
                    <i
                        style={{
                            fontSize: '15px',
                            textAlign: 'center',
                            position: 'absolute',
                            top: '-5px',
                            left: '-13px',
                            color: '#2B8ACC',
                        }}
                        className="fas fa-quote-left"
                    />
                    {`${currentFact}`}
                    <i
                        style={{
                            fontSize: '15px',
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: '0px',
                            right: '-15px',
                            color: '#2B8ACC',
                        }}
                        className="fas fa-quote-right"
                    />
                </Paragraph>
            </Flex>
        </Box>
    );
};

export const CheckingAddress = () => (
    <Box
        sx={{
            width: '98%',
            maxWidth: '700px',
            height: '98%',
            maxHeight: '600px',
            backgroundColor: 'white',
            borderRadius: '12px',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'none',
        }}
    >
        <Flex
            sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                marginTop: '40px',
                height: '480px',
                overflow: 'scroll',

                '@media screen and (max-width: 700px)': {
                    mt: '40px',
                },
            }}
        >
            <Paragraph sx={{ fontSize: '21px', fontWeight: '800', marginBottom: '10px' }}>Just a moment...</Paragraph>
            <Flex
                sx={{
                    width: '100px',
                    height: 'auto',
                    margin: '20px auto 0px',
                    '@media screen and (max-width: 700px)': {
                        width: '100px',
                        height: 'auto',
                    },
                }}
            >
                <i
                    style={{
                        fontSize: 100,
                        textAlign: 'center',
                        marginTop: '0px',
                        color: '#2B8ACC',
                    }}
                    className="fal fa-clock"
                />
            </Flex>

            <Paragraph
                sx={{
                    fontSize: '18px',
                    mt: '180px',
                    width: '80%',
                    textAlign: 'center',
                    mb: '20px',
                    '@media screen and (max-width: 700px)': {
                        fontSize: '16px',
                        width: '90%',
                        textAlign: 'center',
                        mb: '20px',
                    },
                }}
            >
                We're just verifying your details. This can take up to 3 minutes.
            </Paragraph>
            <Paragraph
                sx={{
                    fontSize: '18px',
                    width: '80%',
                    textAlign: 'center',
                    mb: '70px',
                    '@media screen and (max-width: 700px)': {
                        fontSize: '16px',
                        width: '90%',
                        textAlign: 'center',
                        mb: '20px',
                    },
                }}
            >
                Please don't refresh the page
            </Paragraph>
        </Flex>
    </Box>
);
export const Processing = () => (
    <Box
        sx={{
            width: '98%',
            maxWidth: '700px',
            height: '98%',
            maxHeight: '600px',
            backgroundColor: 'white',
            borderRadius: '12px',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'none',
        }}
    >
        <Flex
            sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                marginTop: '40px',
                height: '480px',
                overflow: 'scroll',

                '@media screen and (max-width: 700px)': {
                    mt: '40px',
                },
            }}
        >
            <Paragraph sx={{ fontSize: '21px', fontWeight: '800', marginBottom: '10px' }}>Check back soon.</Paragraph>
            <Flex
                sx={{
                    width: '100px',
                    height: 'auto',
                    margin: '20px auto 0px',
                    '@media screen and (max-width: 700px)': {
                        width: '100px',
                        height: 'auto',
                    },
                }}
            >
                <i
                    style={{
                        fontSize: 100,
                        textAlign: 'center',
                        marginTop: '0px',
                        color: '#2B8ACC',
                    }}
                    className="fal fa-clock"
                />
            </Flex>

            <Paragraph
                sx={{
                    fontSize: '18px',
                    mt: '130px',
                    width: '80%',
                    textAlign: 'center',
                    mb: '20px',
                    '@media screen and (max-width: 700px)': {
                        fontSize: '16px',
                        width: '90%',
                        textAlign: 'center',
                        mb: '20px',
                    },
                }}
            >
                We're currently processing your data.
                <br />
                This should take no longer than 24 hours.
            </Paragraph>
            <Paragraph
                sx={{
                    fontSize: '18px',
                    width: '80%',
                    textAlign: 'center',
                    mb: '70px',
                    cursor: 'pointer',
                    '@media screen and (max-width: 700px)': {
                        fontSize: '16px',
                        width: '90%',
                        textAlign: 'center',
                        mb: '20px',
                    },
                }}
            >
                If you need to get in touch, please contact support.
            </Paragraph>
        </Flex>
    </Box>
);

const StandaloneQuestionsComplete = ({ refreshCoreData, currentState, updateState, completionCallback }: any) => (
    <Flex
        sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Flex
            sx={{
                width: '100px',
                height: 'auto',
                margin: '-70px auto 30px',
                '@media screen and (max-width: 700px)': {
                    mt: '0px',
                    width: '100px',
                    height: 'auto',
                },
            }}
        >
            <i
                style={{
                    fontSize: 100,
                    textAlign: 'center',
                    marginTop: '0px',
                    color: '#2B8ACC',
                }}
                className="fas fa-check-circle"
            />
        </Flex>
        <Paragraph
            sx={{ width: '500px', textAlign: 'center', '@media screen and (max-width: 550px)': { width: '300px' } }}
            data-testid="kyc-step1-success-step"
        >
            Thank you, your answers have been saved.
        </Paragraph>

        <Button
            sx={{
                mt: 20,
                width: '200px',
            }}
            variant="primary"
            onClick={async () => {
                await completionCallback();
            }}
            data-testid="review-btn"
        >
            CALL BACK FUNC
        </Button>
        <Button
            sx={{
                mt: 20,
                width: '200px',
            }}
            variant="primary"
            onClick={async () => {
                await refreshCoreData();
                updateState({
                    ...currentState,
                    questionsComplete: false,
                });
            }}
            data-testid="review-btn"
        >
            Review Answers
        </Button>
    </Flex>
);

const FirstQuestionsComplete = ({ refreshCoreData, currentState, updateState }: any) => (
    <Flex
        sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Flex
            sx={{
                width: '100px',
                height: 'auto',
                margin: '-70px auto 30px',
                '@media screen and (max-width: 700px)': {
                    mt: '0px',
                    width: '100px',
                    height: 'auto',
                },
            }}
        >
            <i
                style={{
                    fontSize: 100,
                    textAlign: 'center',
                    marginTop: '0px',
                    color: '#2B8ACC',
                }}
                className="fas fa-check-circle"
            />
        </Flex>
        <Paragraph
            sx={{ width: '500px', textAlign: 'center', '@media screen and (max-width: 550px)': { width: '300px' } }}
            data-testid="kyc-step1-success-step"
        >
            Your answers have been saved, please continue to the next step
        </Paragraph>

        <Button
            sx={{
                mt: '80px',
                width: '200px',
            }}
            variant="secondary"
            onClick={() => {
                startOnfidoProcess(currentState, updateState);
            }}
            data-testid="continue-btn"
        >
            Continue
        </Button>

        <Button
            sx={{
                mt: 20,
                width: '200px',
            }}
            variant="primary"
            onClick={async () => {
                await refreshCoreData();
                updateState({
                    ...currentState,
                    questionsComplete: false,
                });
            }}
            data-testid="review-btn"
        >
            Review Answers
        </Button>
    </Flex>
);

const FinalQuestionsComplete = ({ refreshCoreData, currentState, updateState }: any) => (
    <Flex
        sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Flex
            sx={{
                width: '100px',
                height: 'auto',
                margin: '-70px auto 30px',
                '@media screen and (max-width: 700px)': {
                    width: '100px',
                    height: 'auto',
                },
            }}
        >
            <i
                style={{
                    fontSize: 100,
                    textAlign: 'center',
                    marginTop: '0px',
                    color: '#2B8ACC',
                }}
                className="fas fa-check-circle"
            />
        </Flex>
        <Paragraph sx={{ width: '500px', textAlign: 'center' }}>
            We have saved your answers and will be in touch when you are able to deposit, this should take under 24
            hours, thank you.
        </Paragraph>
    </Flex>
);

export const QuestionContainer = ({
    currentState,
    updateState,
    questions,
    overlay,
    updateOverlay,
    errorState,
    updateErrorState,
    refreshCoreData,
    finalQuestions,
    currentlyLoading,
    updateLoading,
    standalone = false,
    completionCallback = () => {},
    metaData = {},
    user = {},
    accountSummary = {},
    transactions = [],
}: any) => (
    <Flex
        sx={{
            flexDirection: 'column',
            backgroundColor: 'white',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '40px',
            width: '100%',
        }}
    >
        {overlay && <OverlayHeading title="Verify your identity" />}
        {questions?.length > 0 && !standalone && !currentState?.questionsComplete && (
            <ProgressComponent
                currentState={currentState}
                updateState={updateState}
                questions={questions}
                overlay={overlay}
                updateOverlay={updateOverlay}
                errorState={errorState}
                updateErrorState={updateErrorState}
                refreshCoreData={refreshCoreData}
                currentlyLoading={currentlyLoading}
                updateLoading={updateLoading}
            />
        )}

        {questions?.length > 0 && !currentState?.questionsComplete && (
            <QuestionCarouselComponent
                currentState={currentState}
                updateState={updateState}
                questions={questions}
                overlay={overlay}
                updateOverlay={updateOverlay}
                errorState={errorState}
                updateErrorState={updateErrorState}
                refreshCoreData={refreshCoreData}
                finalQuestions={finalQuestions}
                currentlyLoading={currentlyLoading}
                updateLoading={updateLoading}
                standalone={standalone}
                metaData={metaData}
                accountSummary={accountSummary}
                transactions={transactions}
                user={user}
            />
        )}

        {currentState?.questionsComplete && !standalone && !finalQuestions && (
            <FirstQuestionsComplete
                refreshCoreData={refreshCoreData}
                currentState={currentState}
                updateState={updateState}
            />
        )}

        {currentState?.questionsComplete && !standalone && finalQuestions && (
            <FinalQuestionsComplete
                refreshCoreData={refreshCoreData}
                currentState={currentState}
                updateState={updateState}
            />
        )}

        {currentState?.questionsComplete && standalone && (
            <StandaloneQuestionsComplete
                refreshCoreData={refreshCoreData}
                currentState={currentState}
                updateState={updateState}
                standalone={standalone}
                completionCallback={completionCallback}
            />
        )}
    </Flex>
);
