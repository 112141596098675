/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Flex, Image, Input, Label, Paragraph } from 'theme-ui';
import Divider from '../divider';

const previewFile = (file) => {
    if (!file) return;
    const reader = new FileReader();

    reader.onload = function (e) {
        const img = document.getElementById('preview-img');
        img.src = e.target.result;
    };

    reader.readAsDataURL(file);
};

const save = async (state, updateState, refreshCoreData, name) => {
    try {
        if (!state.companyImg && !name) {
            return;
        }

        updateState({ ...state, loading: true });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                name,
            },
        });

        if (!state.companyImg) {
            await refreshCoreData();

            toast('Successfully Saved', { type: 'success' });

            return updateState({
                ...state,
                loading: false,
            });
        }

        const {
            data: { url },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/company-img`,
            method: 'POST',
            data: {
                content_type: state.companyImg.type,
                base_url: `https://aflow-avatars-${
                    process.env.REACT_APP_IS_LIVE === '1' ? 'prod' : 'uat'
                }.s3.eu-west-1.amazonaws.com/`,
            },
        });

        await axios.put(url, state.companyImg, {
            headers: { 'Content-Type': state.companyImg.type },
        });

        await refreshCoreData();

        toast('Successfully Saved', { type: 'success' });

        return updateState({
            ...state,
            loading: false,
        });
    } catch (e) {
        toast('There has been an error saving your data, please try again or contact support', { type: 'error' });
        return updateState({
            ...state,
            loading: false,
        });
    }
};

const OrgSettings = ({ state, updateState, organisation, refreshCoreData }) => {
    useEffect(() => {
        if (organisation?.default_visualisation?.logo) {
            document.getElementById('preview-img').src = organisation?.default_visualisation?.logo;
        }
    }, []);

    const [localName, updateLocalName] = useState(organisation?.name);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>Organisation Settings</Paragraph>
            <Divider width="1200px" />
            <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                <Paragraph sx={{ fontWeight: 400, fontSize: 14, mb: 10 }}>Name</Paragraph>
                <Input sx={{ width: 400 }} onChange={(e) => updateLocalName(e.target.value)} value={localName} />
            </Flex>

            <Flex sx={{ flexDirection: 'column', mt: 30 }}>
                <Flex sx={{ alignItems: 'center' }}>
                    <Label
                        sx={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            color: '#fff',
                            backgroundColor: 'primary',
                            borderRadius: '15px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            width: 260,
                            height: 40,
                            '&:hover': {
                                backgroundColor: '#EFEFEF',
                                color: '#000',
                            },
                        }}
                    >
                        <i
                            style={{
                                fontSize: '18px',
                                cursor: 'pointer',
                                marginRight: '10px',
                            }}
                            className="fas fa-file"
                            aria-hidden="true"
                        />
                        Upload company image
                        <Input
                            id="ref_file_input"
                            sx={{
                                display: 'none',
                            }}
                            accept=".png, .jpeg, .jpg"
                            type="file"
                            onChange={async (e) => {
                                updateState({ ...state, companyImg: e.target.files?.[0] });
                                previewFile(e.target.files?.[0]);
                            }}
                        />
                    </Label>
                    {organisation?.default_visualisation?.logo || state.companyImg ? (
                        <Image sx={{ width: 150, ml: 30 }} alt="Preview" id="preview-img" />
                    ) : null}
                </Flex>
            </Flex>

            <Button onClick={() => save(state, updateState, refreshCoreData, localName)} sx={{ mt: 40, width: 120 }}>
                Save
            </Button>
        </Flex>
    );
};

export default OrgSettings;
