import { Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
});

const openai = new OpenAIApi(configuration);

export const createImage = async (prompt: any, size: any = '256x256', user: any = 'gary') => {
    try {
        const response = await openai.createImage({
            prompt,
            n: 1,
            size,
            user,
        });
        return response;
    } catch (e) {
        console.log('failed to create image ', e);
    }
};
