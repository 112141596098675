/* eslint-disable no-undef */
import { Button, Flex, Input, Paragraph } from 'theme-ui';
import { PgPaginationV2 } from '../documents/paging';
import axios from 'axios';
import { debounce } from 'debounce';
import Divider from '../divider';
import theme from '../../theme';

const unlinkUser = (email_address, state, updateState) => async () => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/unlink-user`,
            method: 'POST',
            data: {
                email_address,
            },
        });

        const {
            data: { users, total: usersTotal },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
            params: { limit: 800 },
        });

        updateState({ ...state, loading: false, error: null, users, usersTotal });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            error: 'Uh oh, there has been an error unlinking this user from your organisation',
        });
    }
};

export const ucase = (str) => (str ? `${str.substring(0, 1).toUpperCase()}${str.substring(1)}` : '');

const UsersTable = ({ organisation, updateState, state }) => {
    const handleDescriptionChange = debounce(async (e) => {
        updateState({ ...state, userSearchClause: e.target.value });
    }, 500);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph sx={{ fontSize: 20, mt: 20, fontWeight: 600 }}>Users</Paragraph>
            <Divider width="1200px" />

            {organisation?.user?.role === 'admin' && (
                <Button
                    sx={{
                        height: 40,
                        mt: 0,
                        width: 160,
                    }}
                    variant="light"
                    onClick={() =>
                        updateState({
                            ...state,
                            userView: 'invite',
                        })
                    }
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '14px',
                            marginRight: '7px',
                            cursor: 'pointer',
                        }}
                        className="fas fa-plus-circle"
                    />
                    Invite new user
                </Button>
            )}

            <Flex>
                <Input
                    sx={{
                        mt: organisation?.user?.role === 'admin' ? '20px' : '10px',
                        mb: '0px',
                        width: '300px',
                        height: '40px',
                        fontSize: '14px',
                    }}
                    type="text"
                    id="userSearchName"
                    data-testid="userSearchName"
                    name="userSearchName"
                    label=""
                    placeholder={'Search for users by email address'}
                    onChange={handleDescriptionChange}
                    error={null}
                />
            </Flex>

            <table style={{ borderCollapse: 'collapse', marginTop: 20, width: 1000 }}>
                <thead style={{ backgroundColor: theme.colors.primary, color: 'white' }}>
                    <tr>
                        <th style={{ padding: 20, textAlign: 'left' }}>Email address</th>
                        <th style={{ padding: 20, textAlign: 'left' }}>Role</th>
                        <th style={{ padding: 20, width: 190 }}></th>
                    </tr>
                </thead>

                <tbody>
                    {state?.users?.map((x, idx) => (
                        <tr key={x.title} style={{ boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)' }}>
                            <td style={{ padding: 20, wordWrap: 'break-word' }}>
                                <Paragraph>{x.email_address}</Paragraph>
                            </td>
                            <td style={{ padding: 20, wordWrap: 'break-word' }}>
                                <Paragraph>{ucase(x.role || 'user')}</Paragraph>
                            </td>
                            <td style={{ padding: 20, justifyContent: 'flex-end' }}>
                                {organisation?.user?.role === 'admin' && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                updateState({
                                                    ...state,
                                                    userView: 'edit',
                                                    user: x,
                                                });
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}
                                            variant="light"
                                        >
                                            <i
                                                style={{
                                                    marginRight: '7px',
                                                    fontSize: '12px',
                                                    cursor: 'pointer',
                                                }}
                                                className="fas fa-pencil"
                                                aria-hidden="true"
                                            />
                                            Edit
                                        </Button>

                                        <Button
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    showUnlinkUserConfirmation: true,
                                                    confirmCallback: unlinkUser(x.email_address, state, updateState),
                                                })
                                            }
                                            sx={{ ml: 10 }}
                                            variant="light"
                                        >
                                            <i
                                                style={{
                                                    marginRight: '7px',
                                                    fontSize: '12px',
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                }}
                                                className="fas fa-trash"
                                                aria-hidden="true"
                                            />
                                            Delete
                                        </Button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Flex>
    );
};

export default UsersTable;
