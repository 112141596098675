/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Flex, Input, Paragraph } from 'theme-ui';
import theme from '../../theme';
import Divider from '../divider';
import Spinner from '../spinner';
import moment from 'moment';
import * as uuid from 'uuid';
import Overlay from '../overlay/overlay';
import ConfirmDialog from '../tasks/confirm-dialog';
import axios from 'axios';
import { lookupIconType } from '../../pages/inbox';

const ItemTypes = {
    FOLDER: 'folder',
    FILE: 'file',
};

const TextInputLabelModal = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({ label: '' });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25 }}>
            <Paragraph>Enter a name for the folder</Paragraph>
            <Input
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, label: e.target.value })}
                sx={{ mt: 10, width: 350 }}
            />
            <Flex>
                <Button
                    onClick={() => {
                        if (!localState.label) {
                            return;
                        }
                        updateState({ ...state, showTextInputLabelModal: false });
                        okHandler && okHandler('textInput', localState.label);
                    }}
                    sx={{ width: 80, height: 40, mt: 20 }}
                >
                    OK
                </Button>
                <Button
                    onClick={() => {
                        updateState({ ...state, showTextInputLabelModal: false });
                    }}
                    sx={{ width: 100, height: 40, mt: 20, ml: 10, color: '#000', backgroundColor: 'white' }}
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    );
};

const AddFileDialog = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({ file: null });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25 }}>
            <Input
                type="file"
                multiple
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, error: null, files: [...e.target.files] })}
                sx={{ mt: 10, width: 350 }}
            />
            {localState.error && <Paragraph sx={{ mt: 10, mb: 0, color: 'red' }}>{localState.error}</Paragraph>}
            <Flex>
                <Button
                    onClick={() => {
                        const MAX_SIZE = 20 * 1024 * 1024;

                        for (const file of localState.files) {
                            if (file.size > MAX_SIZE) {
                                return updateLocalState({
                                    ...localState,
                                    error: 'The maximum file size allowed is 20MB.',
                                });
                            }
                        }

                        okHandler && okHandler(localState.files);
                    }}
                    sx={{ width: 100, height: 40, mt: 20 }}
                >
                    Upload
                </Button>
                <Button
                    onClick={() => {
                        updateState({ ...state, showFileUploadDialog: false });
                    }}
                    sx={{ width: 100, height: 40, mt: 20, ml: 10, color: '#000', backgroundColor: 'white' }}
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    );
};

const saveFileData = async (state, updateState, fileData, clientId, updateClientData, reload = false) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`,
            method: 'GET',
        });

        const client_data = {
            ...(data.client_data || {}),
            file_browser_data: {
                documents: fileData.documents,
            },
        };

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`,
            method: 'PUT',
            data: {
                client_data,
            },
        });

        const newState = { ...state, loading: false, error: null };

        updateState(newState);
        updateClientData(client_data);

        if (reload) {
            await getFileData(newState, updateState, clientId);
        }
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error saving your data, please try again or contact support',
        });
    }
};

const getFileData = async (state, updateState, clientId) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const [
            { data },
            {
                data: { documents: allDocuments },
            },
        ] = await Promise.all([
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`,
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}/documents`,
            }),
        ]);

        updateState({
            ...state,
            loading: false,
            error: null,
            documents: data.client_data?.file_browser_data?.documents || [],
            allDocuments,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error saving your data, please try again or contact support',
        });
    }
};

function deleteFile(tree, fileId) {
    function findAndDelete(documents) {
        for (let i = 0; i < documents.length; i++) {
            if (documents[i].id === fileId) {
                // Remove the file from the array
                documents.splice(i, 1);
                return true; // File deleted successfully
            }
            if (documents[i].documents && documents[i].documents.length > 0) {
                // Recursively check nested folders
                const found = findAndDelete(documents[i].documents);
                if (found) return true;
            }
        }
        return false; // File not found
    }

    const clonedTree = JSON.parse(JSON.stringify(tree)); // Deep clone the tree
    const fileDeleted = findAndDelete(clonedTree.documents);

    if (!fileDeleted) {
        console.error(`File with ID "${fileId}" not found.`);
    }

    return clonedTree;
}

// Recursive Component for Folder or File
const FolderOrFile = ({
    item,
    moveItem,
    parentId,
    stateRef,
    setFileStructure,
    state,
    updateState,
    clientId,
    updateClientData,
    openFolders,
    setOpenFolders,
}) => {
    const [isOpen, setIsOpen] = useState(openFolders.has(item.id));

    const toggleOpen = () => {
        setIsOpen((prev) => {
            const newOpen = !prev;
            setOpenFolders((prevOpenFolders) =>
                newOpen
                    ? new Set([...prevOpenFolders, item.id])
                    : new Set([...prevOpenFolders].filter((id) => id !== item.id))
            );
            return newOpen;
        });
    };

    useEffect(() => {
        setIsOpen(openFolders.has(item.id));
    }, [openFolders, item.id]);

    const [, drag] = useDrag(() => ({
        type: item.type || ItemTypes.FOLDER,
        item: { id: item.id, parentId },
    }));

    const [, drop] = useDrop(() => ({
        accept: [ItemTypes.FOLDER, ItemTypes.FILE],
        drop: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                return;
            }
            if (draggedItem.id !== item.id) {
                if (item.type === ItemTypes.FILE) {
                    moveItem(draggedItem.id, parentId);
                } else {
                    moveItem(draggedItem.id, item.id);
                }
            }
        },
    }));

    if (item.documents && item.documents.length > 0) {
        // Folder with contents
        return (
            <div ref={drop}>
                <div
                    ref={drag}
                    onClick={toggleOpen}
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                        marginLeft: '20px',
                        padding: '8px',
                    }}
                >
                    <i
                        style={{
                            marginRight: '7px',
                            color: theme.colors.gold,
                        }}
                        className={`fas fa-folder`}
                    />{' '}
                    {item.name}{' '}
                    {isOpen ? (
                        <i
                            style={{
                                marginLeft: '7px',
                                color: '#CCC',
                            }}
                            className={`fas fa-chevron-down`}
                        />
                    ) : (
                        <i
                            style={{
                                marginLeft: '7px',
                                color: '#CCC',
                            }}
                            className={`fas fa-chevron-right`}
                        />
                    )}
                </div>
                {isOpen && (
                    <div style={{ marginLeft: '20px' }}>
                        {item.documents.map((doc, idx) => (
                            <FolderOrFile
                                stateRef={stateRef}
                                setFileStructure={setFileStructure}
                                state={state}
                                updateState={updateState}
                                clientId={clientId}
                                updateClientData={updateClientData}
                                key={`${item.id}_${doc.id}_${idx}`}
                                item={doc}
                                moveItem={moveItem}
                                parentId={item.id}
                                openFolders={openFolders}
                                setOpenFolders={setOpenFolders}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    } else if (item.type === 'file') {
        // File
        return (
            <Flex
                ref={(node) => drag(drop(node))}
                sx={{
                    marginLeft: '20px',
                    marginBottom: '5px',
                    cursor: 'pointer',
                    padding: '8px',
                    alignItems: 'center',
                }}
            >
                <i
                    style={{
                        marginRight: '7px',
                        color: '#CCC',
                        fontSize: '15px',
                    }}
                    className={`fas ${lookupIconType(item.name)}`}
                />
                <Paragraph
                    onClick={async () => {
                        const {
                            data: { url },
                        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${item.document_uuid}`, {});

                        const a = window.document.createElement('a');

                        a.href = url;
                        a.target = '_blank';
                        a.download = item.name;

                        return a.click();
                    }}
                    sx={{ fontSize: 15 }}
                >
                    {item.name}
                </Paragraph>
                <i
                    style={{
                        marginLeft: '9px',
                        color: 'red',
                        fontSize: '12px',
                    }}
                    className={`fas fa-trash`}
                    onClick={async (e) => {
                        e.stopPropagation();

                        const confirmCallback = async () => {
                            const newState = {
                                ...state,
                                showDeleteFileConfirmation: false,
                            };

                            updateState(newState);

                            let clonedStructure = JSON.parse(JSON.stringify(stateRef.current));

                            clonedStructure = deleteFile(clonedStructure, item.id);

                            setFileStructure(clonedStructure);

                            await saveFileData(
                                newState,
                                updateState,
                                clonedStructure,
                                clientId,
                                updateClientData,
                                true
                            );

                            if (item.document_uuid) {
                                try {
                                    await axios({
                                        url: `${process.env.REACT_APP_AQRU_AI_API}/documents/${item.document_uuid}`,
                                        method: 'DELETE',
                                    });
                                } catch (e) {
                                    //
                                }
                            }
                        };

                        updateState({ ...state, showDeleteFileConfirmation: true, confirmCallback });
                    }}
                />
            </Flex>
        );
    }

    return (
        <div ref={drop}>
            <div
                ref={drag}
                onClick={toggleOpen}
                style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    marginLeft: '20px',
                    padding: '8px',
                }}
            >
                <i
                    style={{
                        marginRight: '7px',
                        color: theme.colors.gold,
                    }}
                    className={`fas fa-folder`}
                />{' '}
                {item.name}{' '}
                {isOpen ? (
                    <i
                        style={{
                            marginLeft: '7px',
                            color: '#CCC',
                        }}
                        className={`fas fa-chevron-down`}
                    />
                ) : (
                    <i
                        style={{
                            marginLeft: '7px',
                            color: '#CCC',
                        }}
                        className={`fas fa-chevron-right`}
                    />
                )}
            </div>
        </div>
    );
};

const CannotDeleteDialog = ({ state, updateState }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mt: 25, justifyContent: 'center', alignItems: 'center' }}>
            <Paragraph sx={{ textAlign: 'center' }}>Please delete the contents of this folder first</Paragraph>
            <Button
                onClick={() => {
                    updateState({ ...state, showCannotDeleteDialog: false });
                }}
                sx={{ width: 80, height: 40, mt: 20 }}
            >
                OK
            </Button>
        </Flex>
    );
};

const findFolder = (items, parent, targetId) => {
    for (const item of items) {
        if (item.id === targetId) {
            return { item, parent };
        }

        const folder = findFolder(item.documents, item, targetId);
        if (folder) {
            return folder;
        }
    }
    return null;
};

const RenameFileModal = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({ label: state.documentForEdit?.name });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25 }}>
            <Paragraph>Name</Paragraph>
            <Input
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, label: e.target.value })}
                sx={{ mt: 10, width: 350 }}
            />
            <Flex>
                <Button
                    onClick={() => {
                        if (!localState.label) {
                            return;
                        }
                        updateState({ ...state, showTextInputLabelModal: false });
                        okHandler && okHandler(localState.label);
                    }}
                    sx={{ width: 80, height: 40, mt: 20 }}
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        updateState({ ...state, showRenameFileModal: false });
                    }}
                    sx={{ width: 100, height: 40, mt: 20, ml: 10, color: '#000', backgroundColor: 'white' }}
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    );
};

function sortTreeByName(tree) {
    // Sort by type first (folders before files) and then alphabetically by name
    const sortByName = (a, b) => {
        if (a.type !== 'file') {
            a.type = 'folder';
        }
        if (b.type !== 'file') {
            b.type = 'folder';
        }

        if (a.type === 'folder' && b.type !== 'folder') return -1;
        if (a.type !== 'folder' && b.type === 'folder') return 1;
        return a.name.localeCompare(b.name);
    };

    function sortDocuments(documents) {
        // Sort the current level
        documents.sort(sortByName);

        // Recursively sort nested folders
        documents.forEach((doc) => {
            if (doc.documents && doc.documents.length > 0) {
                sortDocuments(doc.documents);
            }
        });
    }

    const sortedTree = JSON.parse(JSON.stringify(tree)); // Deep clone the tree to avoid mutating state
    sortDocuments(sortedTree.documents); // Start sorting from the root
    return sortedTree;
}

const sortFunc = (a, b) => {
    if (a.type === 'folder' && b.type === 'folder') return -1;
    if (a.type !== 'folder' && b.type === 'folder') return 1;
    return a.name.localeCompare(b.name);
};

// Main File Explorer Component
const FileExplorer = ({ data, clientId, updateClientData }) => {
    const [fileStructure, setFileStructure] = useState(
        sortTreeByName({
            name: '',
            id: 'root',
            documents: data,
        })
    );

    const [openFolders, setOpenFolders] = useState(new Set().add('root'));

    const stateRef = useRef();
    stateRef.current = fileStructure;

    useEffect(() => {
        stateRef.current = fileStructure;
    }, [fileStructure]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const [state, updateState] = useState({});

    async function moveItem(fromId, toId) {
        // Helper function to recursively find and remove the item
        function findAndRemove(items, id) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].id === id) {
                    return items.splice(i, 1)[0]; // Remove and return the item
                }
                if (items[i].documents && items[i].documents.length > 0) {
                    const result = findAndRemove(items[i].documents, id);
                    if (result) return result; // Found in nested documents
                }
            }
            return null; // Not found
        }

        // Helper function to recursively find the target folder
        function findTarget(items, id) {
            for (const item of items) {
                if (item.id === id) {
                    return item; // Return the target folder
                }
                if (item.documents && item.documents.length > 0) {
                    const result = findTarget(item.documents, id);
                    if (result) return result; // Found in nested documents
                }
            }
            return null; // Not found
        }

        const clonedStructure = JSON.parse(JSON.stringify(stateRef.current));

        // Remove the item from its current location
        const itemToMove = findAndRemove(clonedStructure.documents, fromId);
        if (!itemToMove) {
            console.error(`Item with id "${fromId}" not found.`);
            return; // Exit if the item doesn't exist
        }

        if (toId === 'root') {
            clonedStructure.documents.push(itemToMove);
            clonedStructure.documents.sort(sortFunc);
        } else {
            const targetFolder = findTarget(clonedStructure.documents, toId);
            if (!targetFolder || targetFolder.type === 'file') {
                console.error(`Target folder with id "${toId}" not found or is not a folder.`);
                return; // Exit if the target is not a valid folder
            }

            // Ensure the target folder has a `documents` array
            if (!targetFolder.documents) {
                targetFolder.documents = [];
            }

            targetFolder.documents.push(itemToMove);
            targetFolder.documents.sort(sortFunc);

            setOpenFolders((prevOpenFolders) => new Set([...prevOpenFolders, toId]));
        }

        setFileStructure(clonedStructure);

        await saveFileData(state, updateState, clonedStructure, clientId, updateClientData);
    }

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph sx={{ mt: 20, fontSize: 18 }}>Documents</Paragraph>
            <Divider />

            {state.loading ? <Spinner /> : null}

            {state.showCannotDeleteDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={140}
                    embeddedComponent={<CannotDeleteDialog state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showCannotDeleteDialog: false })}
                />
            )}
            {state.showTextInputLabelModal && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={180}
                    embeddedComponent={
                        <TextInputLabelModal okHandler={state.okHandler} state={state} updateState={updateState} />
                    }
                    updateOverlay={() => updateState({ ...state, showTextInputLabelModal: false })}
                />
            )}
            {state.showRenameFileModal && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={180}
                    embeddedComponent={
                        <RenameFileModal okHandler={state.okHandler} state={state} updateState={updateState} />
                    }
                    updateOverlay={() => updateState({ ...state, showRenameFileModal: false })}
                />
            )}
            {state.showFileUploadDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={190}
                    embeddedComponent={
                        <AddFileDialog okHandler={state.okHandler} state={state} updateState={updateState} />
                    }
                    updateOverlay={() => updateState({ ...state, showFileUploadDialog: false })}
                />
            )}

            {state.showDeleteFileConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteFileConfirmation"
                    text="Are you sure you wish to delete this?"
                />
            )}

            <Flex sx={{ mb: '30px', mt: '0px' }}>
                <Button
                    onClick={() => {
                        const okHandler = async (key, name) => {
                            const newDocument = {
                                id: uuid.v4(),
                                name,
                                documents: [],
                                type: 'folder',
                                created_at: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                            };

                            const clonedStructure = JSON.parse(JSON.stringify(stateRef.current));

                            clonedStructure.documents = [newDocument, ...clonedStructure.documents];
                            clonedStructure.documents.sort(sortFunc);

                            setFileStructure(clonedStructure);

                            updateState({
                                ...state,
                                showTextInputLabelModal: false,
                            });

                            await saveFileData(state, updateState, clonedStructure, clientId, updateClientData);
                        };

                        updateState({
                            ...state,
                            showTextInputLabelModal: true,
                            okHandler,
                        });
                    }}
                    sx={{ height: 40, fontSize: 14 }}
                >
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-plus-circle`}
                    />
                    Add Folder
                </Button>

                <Button
                    onClick={() => {
                        const okHandler = async (files) => {
                            updateState({ ...state, loading: true });

                            const clonedStructure = JSON.parse(JSON.stringify(stateRef.current));

                            for (const newFile of files) {
                                const { data } = await axios({
                                    method: 'POST',
                                    url: `${process.env.REACT_APP_AQRU_AI_API}/uploads`,
                                    data: {
                                        description: clientId,
                                        files: [newFile].map((x) => ({
                                            content_type: x?.type,
                                            file_name: x?.name,
                                            labels: [],
                                        })),
                                        client_id: clientId,
                                    },
                                });

                                await axios.put(data.urls[0], newFile, {
                                    headers: { 'Content-Type': newFile.type },
                                });

                                const newDocument = {
                                    id: uuid.v4(),
                                    type: 'file',
                                    name: newFile.name,
                                    document_uuid: data.doc_uuids[0],
                                    created_at: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                                };

                                clonedStructure.documents = [newDocument, ...clonedStructure.documents];
                            }

                            clonedStructure.documents.sort(sortFunc);

                            setFileStructure(clonedStructure);

                            await saveFileData(
                                {
                                    ...state,
                                    okHandler: null,
                                    showFileUploadDialog: false,
                                },
                                updateState,
                                clonedStructure,
                                clientId,
                                updateClientData
                            );
                        };

                        updateState({
                            ...state,
                            showFileUploadDialog: true,
                            okHandler,
                        });
                    }}
                    sx={{ height: 40, fontSize: 14, ml: '10px', backgroundColor: 'white', color: 'black' }}
                >
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-upload`}
                    />
                    Upload Files
                </Button>
            </Flex>

            <DndProvider backend={HTML5Backend}>
                <div style={{ padding: '10px' }}>
                    <FolderOrFile
                        stateRef={stateRef}
                        setFileStructure={setFileStructure}
                        state={state}
                        updateState={updateState}
                        clientId={clientId}
                        updateClientData={updateClientData}
                        key={fileStructure.id}
                        item={fileStructure}
                        moveItem={moveItem}
                        parentId={null}
                        openFolders={openFolders}
                        setOpenFolders={setOpenFolders}
                    />
                </div>
            </DndProvider>
        </Flex>
    );
};

export default FileExplorer;
