import { Paragraph } from 'theme-ui';

const OverlaySubtitle = ({ title, centered }: any) => (
    <Paragraph
        sx={{
            fontSize: '14px',
            width: '100%',
            mt: '7px',
            textAlign: centered ? 'center' : 'left',
            marginLeft: centered ? '0' : '35px',
            mr: 0,
            pr: 0,
            '@media screen and (max-width: 700px)': {
                marginLeft: '0',
                textAlign: 'center',
                width: '90%',
                ml: '5%',
                mr: '5%',
            },
        }}
    >
        {title}
    </Paragraph>
);

export default OverlaySubtitle;
