import { Flex, Paragraph, Input } from 'theme-ui';

export const InputLabel = ({ title, subtitle, customSX = {} }: any) => (
    <Paragraph sx={{ fontSize: '13px', mb: '3px', mt: [0, '5px'], ...customSX }}>
        {title}
        <span style={{ color: 'red' }}>{subtitle}</span>
    </Paragraph>
);

export const FormInput = ({
    state,
    updateState,
    stateKey,
    placeholder,
    errorState,
    updateErrorState,
    mt = '10px',
    mb = [10, 27],
    width = '250px',
    errorKey = false,
    type = 'text',
    disabled = false,
}: any) => {
    const keyToUse = errorKey ? errorKey : stateKey;

    return (
        <Input
            sx={{
                backgroundColor: 'white',
                height: '50px',
                borderRadius: 6,
                borderColor: 'inputBorder',
                width,
                mt,
                mb,
                outline: errorState[keyToUse] ? '1px red solid' : 'none',
            }}
            type={type}
            data-testid={`add-card-${stateKey}`}
            name={`add-card-${stateKey}`}
            placeholder={placeholder}
            value={state[stateKey]}
            onChange={(e: any) => {
                updateState({ ...state, [stateKey]: e.target.value });
                updateErrorState({ ...errorState, [keyToUse]: false });
            }}
            disabled={disabled}
        />
    );
};

export const FormInputBank = ({
    state,
    updateState,
    stateKey,
    placeholder,
    errorState,
    errorStateKey,
    updateErrorState,
    mt = '10px',
    mb = [10, 27],
}: any) => (
    <Input
        sx={{
            backgroundColor: 'white',
            height: '50px',
            borderRadius: 6,
            borderColor: 'inputBorder',
            width: '250px',
            mt,
            mb,
            outline: errorState[errorStateKey] ? '1px red solid' : 'none',
        }}
        type="text"
        data-testid={`add-card-${stateKey}`}
        name={`add-card-${stateKey}`}
        placeholder={placeholder}
        value={state[stateKey]}
        onChange={(e: any) => {
            updateState({ ...state, [stateKey]: e.target.value });
            updateErrorState({ ...errorState, [errorStateKey]: false });
        }}
    />
);

export const Item = ({
    keyName,
    value,
    customSX = {},
    colorValue = false,
    colorOverride = false,
    smaller = false,
    rawValue = 0,
}: any) => {
    const colorToUse = colorOverride ? colorOverride : rawValue > 0 ? 'green' : 'red';
    return (
        <Flex sx={{ mb: '8px', '@media screen and (max-width: 550px)': { mb: '15px' }, ...customSX }}>
            <Paragraph
                sx={{
                    fontSize: smaller ? '15px' : '18px',
                    width: '250px',
                    textAlign: 'right',
                    marginRight: '15px',
                    '@media screen and (max-width: 550px)': {
                        width: '160px',
                        fontSize: '16px',
                        textAlign: 'left',
                    },
                }}
                data-testid={`form-item-${keyName}`}
            >
                {keyName}:
            </Paragraph>
            <Paragraph
                sx={{
                    fontSize: smaller ? '15px' : '18px',
                    fontWeight: '600',
                    color: colorValue ? colorToUse : 'inherit',
                    '@media screen and (max-width: 550px)': {
                        width: '160px',
                        fontSize: '16px',
                        textAlign: 'right',
                    },
                }}
                data-testid={`form-item-value-${keyName}`}
            >
                {value}
            </Paragraph>
        </Flex>
    );
};
