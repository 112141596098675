/* eslint-disable no-undef */
import { Auth } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import axios from 'axios';
import moment from 'moment';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Box, Button, Flex, Image, Input, Paragraph } from 'theme-ui';
import Divider from '../components/divider';
import Compose from '../components/email/compose';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { actions as userActions } from '../store/reducers/users';
import theme from '../theme';
import { defaultOverlayState } from '../utils/default-states';
import EmailInList from '../components/email/email-in-list';
import Folders from '../components/email/folders';

export const emailBodyDefaultState = () => ({
    newEmailBody: '',
    newEmailClient: '',
    newEmailTask: '',
    newEmailSubject: '',
    newEmailBodyShowingPreviousChain: '',
    newEmailTo: null,
    emailTemplate: '',
    showCc: false,
    showBcc: false,
    newEmailBcc: null,
    newEmailCc: null,
    newEmailFiles: [],
    newEmailDraftId: null,
});

export function downloadFile(contentBytes, fileName, mimeType) {
    // Convert the Base64 string to a Blob
    const binary = atob(contentBytes); // Decode Base64 to binary string
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([array], { type: mimeType });

    // Create a download link and trigger it
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const clearSearchFilter = (state, updateState) => {
    updateState({ ...state, emailSearchFilter: '' });
    if (document.getElementById('email_filter')) {
        document.getElementById('email_filter').value = '';
    }
    return { ...state, emailSearchFilter: '' };
};

export const loadMessages = async (state, updateState, showToast, type = 'inbox', loadingMore = false) => {
    try {
        updateState({ ...state, loading: true });

        const [
            { data: emailsPayload },
            {
                data: { templates },
            },
        ] = await Promise.all([
            loadingMore && !state.nextLotOfEmailsLink
                ? Promise.resolve({ data: { value: [] } })
                : axios.get(`${process.env.REACT_APP_AQRU_AI_API}/outlook/emails`, {
                      params: {
                          type,
                          next_batch: loadingMore ? encodeURIComponent(state.nextLotOfEmailsLink) : '',
                          ...(state.emailSearchFilter
                              ? {
                                    search: encodeURIComponent(state.emailSearchFilter),
                                }
                              : {}),
                      },
                  }),

            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates?limit=1000`,
            }),
        ]);

        const { value } = emailsPayload;

        updateState({
            ...state,
            loading: false,
            nextLotOfEmailsLink: emailsPayload?.['@odata.nextLink'],
            emails: loadingMore ? [...state.emails, ...value] : value,
            selectedEmailIndex: -1,
            emailsSelected: [],
            connected: true,
            skip: loadingMore ? state.skip : 0,
            type,
            view: 'read',
            templates,
            ...emailBodyDefaultState(),
        });
    } catch (ex) {
        updateState({ ...state, loading: false });
        if (showToast) {
            toast('There has been an error loading your messages', { type: 'error' });
        }
    }
};

const EmailAvatar = ({ name }) => {
    // Extract the first letters of the first two words

    if (!name) return null;

    const initials = name
        .split(' ')
        .slice(0, 2) // Take only the first two words
        .map((word) => word.charAt(0).toUpperCase()) // Get the first letter and capitalize it
        .join('');

    return (
        <Box
            sx={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: theme.colors.gold,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '24px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
        >
            {initials}
        </Box>
    );
};

export const updateMessages = async (messages, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        for (const message of messages) {
            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/outlook/messages/${message.id}`,
                method: 'PUT',
                data: {
                    message_data: message.message_data,
                },
            });
        }

        await loadMessages(state, updateState, true, state.type);
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('There has been an error updating your message', { type: 'error' });
    }
};

const Inbox = ({ accountSummary, userData, organisation, refreshCoreData }) => {
    const [state, updateState] = useState({
        loading: false,
        emails: [],
        selectedEmailIndex: 0,
        type: 'inbox',
        view: 'read',
        skip: 0,
    });

    const [user, updateUser] = useState(userData);

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const [overlay, updateOverlay] = useState(defaultOverlayState);

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                await Auth.currentSession();
                refreshCoreData();

                try {
                    await loadMessages(state, updateState, false, 'inbox');
                } catch (ex) {
                    //
                }
            } catch (e) {
                console.log(e);
                toast('Something has gone wrong connecting to Outlook', { type: 'error' });
            }
        })();
    }, []);

    let title = 'Email';
    if (state.type === 'inbox') {
        title = 'Inbox';
    } else if (state.type === 'sent') {
        title = 'Sent items';
    }

    if (state.view === 'compose') {
        title = 'New email';
    }

    if (state.replyType === 'all') {
        title = 'Reply All';
    } else if (state.replyType === 'single') {
        title = 'Reply';
    }

    const emailFilterDebounced = debounce((e) => {
        updateState({
            ...state,
            emailSearchFilter: e.target.value,
        });
    }, 500);

    useEffect(() => {
        (async () => {
            try {
                await loadMessages(state, updateState, true, state.type);
            } catch (ex) {
                //
            }
        })();
    }, [state.emailSearchFilter]);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.getElementById('emails_list');

            if (scrollTop + clientHeight >= scrollHeight) {
                (async () => {
                    try {
                        await loadMessages(state, updateState, true, state.type, true);
                    } catch (ex) {
                        console.log(ex);
                    }
                })();
            }
        };

        const scrollableDiv = document.getElementById('emails_list');
        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', handleScroll);
        }

        return () => {
            scrollableDiv?.removeEventListener('scroll', handleScroll);
        };
    }, [state.emails]);

    return (
        <Flex
            sx={{
                width: '100vw',
                flexDirection: 'column',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundColor: '#fff',
                height: 'calc(100vh - 100px)',
                maxHeight: 'calc(100vh - 100px)',
                overflow: state.view === 'compose' ? 'unset' : 'hidden',
            }}
            data-testid="home-screen"
        >
            <Flex
                sx={{
                    width: '100vw',
                    flexDirection: 'column',
                }}
            >
                <Sidebar currentPage="Email" overlay={overlay} accountSummary={accountSummary} user={user} copy={{}} />

                <Flex
                    sx={{
                        border: '0px solid lightgrey',
                        backgroundColor: '#ffffff',
                        borderTopLeftRadius: '25px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        mt: '15px',
                        ml: '130px',
                    }}
                >
                    <Flex sx={{ flexDirection: 'column', ml: 30 }}>
                        <Flex
                            sx={{
                                alignItems: 'center',
                                mt: 10,
                                mb: 20,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Flex sx={{ alignItems: 'center' }}>
                                <Paragraph
                                    sx={{
                                        textAlign: 'left',
                                        fontSize: '25px',
                                        fontWeight: '400',
                                        mr: 10,
                                    }}
                                >
                                    {title}
                                </Paragraph>
                            </Flex>
                            {state.connected ? (
                                <Button
                                    variant="light"
                                    sx={{ width: 200, mr: 10 }}
                                    onClick={async () => {
                                        updateState({ ...state, loading: true });
                                        try {
                                            await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/outlook/logout`);
                                        } catch (e) {
                                            //
                                        }
                                        updateState({ ...state, loading: false, connected: false, emails: [] });
                                    }}
                                >
                                    Sign out of Outlook
                                </Button>
                            ) : null}
                        </Flex>

                        <Divider mt={0} width="100%" />

                        {state.loading && <Spinner />}

                        {!state.loading && !state.connected ? (
                            <Flex sx={{ flexDirection: 'column' }}>
                                <Image sx={{ width: 100, mb: 20 }} src="/outlook.png" />
                                <Button
                                    sx={{ width: 100 }}
                                    onClick={async () => {
                                        const { data } = await axios.get(
                                            `${process.env.REACT_APP_AQRU_AI_API}/outlook/auth/login`
                                        );
                                        return window.location.assign(data.response);
                                    }}
                                >
                                    Connect
                                </Button>
                            </Flex>
                        ) : null}

                        {state.connected ? (
                            <Flex sx={{ mt: 10 }}>
                                <Folders state={state} updateState={updateState} />
                                {state.view === 'read' ? (
                                    <Flex sx={{ flexDirection: 'column', minWidth: '500px' }}>
                                        <Flex sx={{ mb: 20, flexDirection: 'column' }}>
                                            <Input
                                                type="text"
                                                autoComplete="off"
                                                id="email_filter"
                                                onChange={emailFilterDebounced}
                                                placeholder="Search..."
                                                sx={{ height: 40, mb: 20, width: 450 }}
                                            />
                                            {state.emailsSelected?.length ? (
                                                <Flex>
                                                    <Button
                                                        onClick={async () => {
                                                            await updateMessages(
                                                                state.emailsSelected?.map((x) => ({
                                                                    id: state.emails[x]?.id,
                                                                    message_data: {
                                                                        isRead: true,
                                                                    },
                                                                })),
                                                                state,
                                                                updateState
                                                            );
                                                        }}
                                                        variant="light"
                                                        sx={{ mr: 10 }}
                                                    >
                                                        <i
                                                            className="fa fa-book"
                                                            style={{
                                                                marginRight: '7px',
                                                            }}
                                                        />
                                                        Mark as Read
                                                    </Button>
                                                    <Button
                                                        onClick={async () => {
                                                            await updateMessages(
                                                                state.emailsSelected?.map((x) => ({
                                                                    id: state.emails[x]?.id,
                                                                    message_data: {
                                                                        isRead: false,
                                                                    },
                                                                })),
                                                                state,
                                                                updateState
                                                            );
                                                        }}
                                                        variant="light"
                                                    >
                                                        <i
                                                            className="fab fa-readme"
                                                            style={{
                                                                marginRight: '7px',
                                                            }}
                                                        />
                                                        Mark as Unread
                                                    </Button>
                                                    <Button
                                                        sx={{ ml: 10 }}
                                                        onClick={async () => {
                                                            updateState({
                                                                ...clearSearchFilter(state, updateState),
                                                                emailsSelected: [],
                                                            });
                                                        }}
                                                        variant="light"
                                                    >
                                                        <i
                                                            className="fa fa-times"
                                                            style={{
                                                                marginRight: '7px',
                                                            }}
                                                        />
                                                        Clear
                                                    </Button>
                                                </Flex>
                                            ) : null}
                                        </Flex>
                                        <Flex
                                            id={'emails_list'}
                                            sx={{
                                                width: '450px',
                                                bg: 'muted',
                                                borderColor: '#EFEFEF',
                                                flexDirection: 'column',
                                                height: 'calc(100vh - 310px)',
                                                overflowY: 'auto',
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            {state.emails?.map((email, index) => (
                                                <EmailInList
                                                    key={`email_${email.id}`}
                                                    state={state}
                                                    email={email}
                                                    updateState={updateState}
                                                    index={index}
                                                />
                                            ))}
                                        </Flex>
                                    </Flex>
                                ) : null}
                                {state.view === 'read' ? (
                                    <>
                                        {state.emails?.[state.selectedEmailIndex]?.body?.content ? (
                                            <Flex
                                                sx={{
                                                    flexDirection: 'column',
                                                    ml: 30,
                                                    maxWidth: 900,
                                                    height: 'calc(100vh - 310px)',
                                                    overflowY: 'auto',
                                                    boxSizing: 'border-box',
                                                }}
                                            >
                                                <Flex sx={{ flexDirection: 'column' }}>
                                                    <Flex
                                                        sx={{
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Flex sx={{ alignItems: 'center', minWidth: 400 }}>
                                                            <EmailAvatar
                                                                name={
                                                                    state.type === 'inbox'
                                                                        ? state.emails?.[state.selectedEmailIndex]?.from
                                                                              ?.emailAddress?.name
                                                                        : state.emails?.[state.selectedEmailIndex]
                                                                              ?.toRecipients?.[0]?.emailAddress?.name
                                                                }
                                                            />
                                                            {state.type === 'inbox' ? (
                                                                <Flex sx={{ flexDirection: 'column' }}>
                                                                    <Paragraph sx={{ ml: 10, fontSize: 13 }}>
                                                                        {`${
                                                                            state.emails?.[state.selectedEmailIndex]
                                                                                ?.from?.emailAddress?.name
                                                                        }`}
                                                                    </Paragraph>
                                                                    <Paragraph sx={{ ml: 10, fontSize: 12 }}>
                                                                        {`<${
                                                                            state.emails?.[state.selectedEmailIndex]
                                                                                ?.from?.emailAddress?.address
                                                                        }>`}
                                                                    </Paragraph>
                                                                </Flex>
                                                            ) : (
                                                                <Flex sx={{ flexDirection: 'column' }}>
                                                                    <Paragraph sx={{ ml: 10, fontSize: 13 }}>
                                                                        {`${
                                                                            state.emails?.[state.selectedEmailIndex]
                                                                                ?.toRecipients?.[0]?.emailAddress?.name
                                                                        }`}
                                                                    </Paragraph>
                                                                    <Paragraph sx={{ ml: 10, fontSize: 12 }}>
                                                                        {`<${
                                                                            state.emails?.[state.selectedEmailIndex]
                                                                                ?.toRecipients?.[0]?.emailAddress
                                                                                ?.address
                                                                        }>`}
                                                                    </Paragraph>
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                        <Paragraph sx={{ mr: 10 }}>
                                                            {moment(
                                                                state.emails?.[state.selectedEmailIndex]
                                                                    ?.receivedDateTime
                                                            ).format('HH:mm DD/MM/YYYY')}
                                                        </Paragraph>
                                                    </Flex>

                                                    {state.emails?.[state.selectedEmailIndex]?.ccRecipients?.length ? (
                                                        <Flex sx={{ flexDirection: 'row', mt: 10, ml: '5px' }}>
                                                            <Paragraph sx={{ mr: '5px' }}>Cc:</Paragraph>
                                                            <Paragraph>
                                                                {state.emails?.[state.selectedEmailIndex]?.ccRecipients
                                                                    ?.map((cc) => cc?.emailAddress?.name)
                                                                    ?.join(' ')}
                                                            </Paragraph>
                                                        </Flex>
                                                    ) : null}

                                                    {state.emails?.[state.selectedEmailIndex]?.bccRecipients?.length ? (
                                                        <Flex sx={{ flexDirection: 'row', mt: 10, ml: '5px' }}>
                                                            <Paragraph sx={{ mr: '5px' }}>Bcc:</Paragraph>
                                                            <Paragraph>
                                                                {state.emails?.[state.selectedEmailIndex]?.bccRecipients
                                                                    ?.map((cc) => cc?.emailAddress?.name)
                                                                    ?.join(' ')}
                                                            </Paragraph>
                                                        </Flex>
                                                    ) : null}
                                                    {state.email?.[state.selectedEmailIndex]?.bccRecipients?.length ? (
                                                        <Flex sx={{ flexDirection: 'column', mt: 10, ml: '5px' }}>
                                                            <Paragraph>Bcc:</Paragraph>
                                                            <Paragraph>
                                                                {state.email?.[state.selectedEmailIndex]?.bccRecipients
                                                                    ?.map((cc) => cc?.emailAddress?.name)
                                                                    ?.join(' ')}
                                                            </Paragraph>
                                                        </Flex>
                                                    ) : null}

                                                    {state.type === 'inbox' ? (
                                                        <Flex
                                                            sx={{
                                                                mb: 20,
                                                                mr: 10,
                                                                mt: 20,
                                                                justifyContent: 'flex-start',
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() => {
                                                                    const email =
                                                                        state.emails?.[state.selectedEmailIndex];
                                                                    updateState({
                                                                        ...state,
                                                                        view: 'compose',
                                                                        replyType: 'single',
                                                                        newEmailSubject: email?.subject?.startsWith(
                                                                            'Re:'
                                                                        )
                                                                            ? email?.subject
                                                                            : 'Re: ' + email?.subject,
                                                                        newEmailTo: [
                                                                            {
                                                                                value: state.emails?.[
                                                                                    state.selectedEmailIndex
                                                                                ]?.from?.emailAddress?.address,
                                                                                label: state.emails?.[
                                                                                    state.selectedEmailIndex
                                                                                ]?.from?.emailAddress?.name,
                                                                            },
                                                                        ],
                                                                        newEmailBodyShowingPreviousChain:
                                                                            `<p style="margin:0;"><b>From:</b> ${
                                                                                email?.from?.emailAddress?.name
                                                                            } &lt;${
                                                                                email?.from?.emailAddress?.address
                                                                            }&gt;</p><p style="margin:0;"><b>Sent:</b> ${moment
                                                                                .utc(
                                                                                    email.sentDateTime,
                                                                                    'YYYY-MM-DDTHH:mm:ssZ'
                                                                                )
                                                                                .format(
                                                                                    'DD MMMM YYYY HH:mm'
                                                                                )}</p><p style="margin:0;"><b>To:</b> ${email.toRecipients
                                                                                ?.map(
                                                                                    (recip) =>
                                                                                        `${recip.emailAddress.name} <${recip.emailAddress.address}>`
                                                                                )
                                                                                ?.join(
                                                                                    ' '
                                                                                )}</p><p style="margin:0;"><b>Subject:</b> ${
                                                                                email.subject
                                                                            }</p>
<br/> <br/>
` + email.body.content,
                                                                    });
                                                                }}
                                                                variant="light"
                                                                sx={{ mr: 10 }}
                                                            >
                                                                <i
                                                                    className="fa fa-reply"
                                                                    style={{
                                                                        marginRight: '5px',
                                                                    }}
                                                                />
                                                                Reply
                                                            </Button>
                                                            <Button
                                                                variant="light"
                                                                onClick={() => {
                                                                    const email =
                                                                        state.emails?.[state.selectedEmailIndex];

                                                                    const cc = [
                                                                        ...(email.toRecipients || []),
                                                                        ...(email.ccRecipients || []),
                                                                    ];

                                                                    updateState({
                                                                        ...state,
                                                                        view: 'compose',
                                                                        replyType: 'all',
                                                                        newEmailSubject: email?.subject?.startsWith(
                                                                            'Re:'
                                                                        )
                                                                            ? email?.subject
                                                                            : 'Re: ' + email?.subject,
                                                                        newEmailTo: [
                                                                            {
                                                                                value: email?.from?.emailAddress
                                                                                    ?.address,
                                                                                label: email?.from?.emailAddress?.name,
                                                                            },
                                                                        ],
                                                                        showCc: cc.length,
                                                                        newEmailCc: cc?.map((item) => ({
                                                                            value: item?.emailAddress?.address,
                                                                            label: item?.emailAddress?.name,
                                                                        })),
                                                                    });
                                                                }}
                                                            >
                                                                <i
                                                                    className="fa fa-reply-all"
                                                                    style={{
                                                                        marginRight: '5px',
                                                                    }}
                                                                />
                                                                Reply All
                                                            </Button>
                                                            <Button
                                                                sx={{ ml: 10 }}
                                                                variant="light"
                                                                onClick={() => {
                                                                    const email =
                                                                        state.emails?.[state.selectedEmailIndex];

                                                                    updateState({
                                                                        ...state,
                                                                        view: 'compose',
                                                                        replyType: 'forward',
                                                                        newEmailSubject: email?.subject,
                                                                        newEmailTo: [],
                                                                        newEmailCc: [],
                                                                        newEmailBody: email.body.content,
                                                                    });
                                                                }}
                                                            >
                                                                <i
                                                                    className="fa fa-share"
                                                                    style={{
                                                                        marginRight: '5px',
                                                                    }}
                                                                />
                                                                Forward
                                                            </Button>
                                                        </Flex>
                                                    ) : null}

                                                    {state.emails?.[state.selectedEmailIndex]?.file_attachments_loaded
                                                        ?.length ? (
                                                        <Flex
                                                            sx={{
                                                                flexDirection: 'row',
                                                                flexWrap: 'wrap',
                                                                gap: 10,
                                                            }}
                                                        >
                                                            {state.emails?.[
                                                                state.selectedEmailIndex
                                                            ]?.file_attachments_loaded?.map((file, idx) => (
                                                                <Flex
                                                                    sx={{
                                                                        padding: 20,
                                                                        border: '1px solid #EFEFEF',
                                                                        cursor: 'pointer',
                                                                        alignItems: 'center',
                                                                    }}
                                                                    key={file.id}
                                                                    onClick={() => {
                                                                        downloadFile(
                                                                            file.contentBytes,
                                                                            file.name,
                                                                            file.contentType
                                                                        );
                                                                    }}
                                                                >
                                                                    <i
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            cursor: 'pointer',
                                                                            marginRight: '7px',
                                                                            color: theme.colors.primary,
                                                                        }}
                                                                        className={`fas ${lookupIconType(
                                                                            file.contentType
                                                                        )}`}
                                                                        aria-hidden="true"
                                                                    />
                                                                    <Paragraph>{file.name}</Paragraph>
                                                                </Flex>
                                                            ))}
                                                        </Flex>
                                                    ) : null}

                                                    {state.emails?.[state.selectedEmailIndex]?.isDraft ? (
                                                        <Button
                                                            onClick={() => {
                                                                const email = state.emails?.[state.selectedEmailIndex];

                                                                updateState({
                                                                    ...state,
                                                                    view: 'compose',
                                                                    newEmailDraftId: email.id,
                                                                    replyType: null,
                                                                    newEmailSubject: email?.subject,
                                                                    newEmailTo: email.toRecipients?.map((x) => ({
                                                                        value: x?.emailAddress?.address,
                                                                        label: x?.emailAddress?.name,
                                                                    })),
                                                                    showCc: email.ccRecipients.length,
                                                                    newEmailCc: email.ccRecipients?.map((item) => ({
                                                                        value: item?.emailAddress?.address,
                                                                        label: item?.emailAddress?.name,
                                                                    })),
                                                                    showBcc: email.bccRecipients.length,
                                                                    newEmailBcc: email.bccRecipients?.map((item) => ({
                                                                        value: item?.emailAddress?.address,
                                                                        label: item?.emailAddress?.name,
                                                                    })),
                                                                    newEmailBody: email.body.content,
                                                                });
                                                            }}
                                                            sx={{ mt: 20, width: 120 }}
                                                            variant="light"
                                                        >
                                                            <i
                                                                style={{
                                                                    fontSize: '16px',
                                                                    cursor: 'pointer',
                                                                    marginRight: '7px',
                                                                }}
                                                                className={`fas fa-pencil`}
                                                                aria-hidden="true"
                                                            />
                                                            Continue
                                                        </Button>
                                                    ) : null}

                                                    <Divider />
                                                </Flex>

                                                <Flex
                                                    sx={{ flexDirection: 'column' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            state.emails?.[state.selectedEmailIndex]?.body?.content ||
                                                            '',
                                                    }}
                                                />
                                            </Flex>
                                        ) : (
                                            <Flex
                                                sx={{
                                                    flexDirection: 'column',
                                                    width: 700,
                                                }}
                                            >
                                                <Image
                                                    src="/select-img.svg"
                                                    alt="My Icon"
                                                    sx={{
                                                        width: 200,
                                                        height: 200,
                                                        alignSelf: 'center',
                                                        mt: 50,
                                                    }}
                                                />
                                                <Paragraph sx={{ fontWeight: 600, textAlign: 'center' }}>
                                                    {state.emails?.length
                                                        ? 'Select an item to read'
                                                        : 'No drafts currently'}
                                                </Paragraph>
                                                {state.emails?.length ? (
                                                    <Paragraph sx={{ textAlign: 'center' }}>
                                                        Nothing is selected
                                                    </Paragraph>
                                                ) : null}
                                            </Flex>
                                        )}
                                    </>
                                ) : (
                                    <Compose state={state} updateState={updateState} />
                                )}
                            </Flex>
                        ) : null}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const lookupIconType = (contentType) => {
    if (contentType.includes('pdf')) {
        return 'fa-file-pdf';
    }
    if (contentType.includes('docx') || contentType.includes('wordprocessingml.document')) {
        return 'fa-file-word';
    }
    if (contentType.includes('png') || contentType.includes('jpeg') || contentType.includes('jpg')) {
        return 'fa-file-image';
    }
    return 'fa-file';
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
