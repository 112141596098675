import { Select, Box, Paragraph } from 'theme-ui';
import { countryCodeEmoji } from 'country-code-emoji';
import axios from 'axios';

export const loadCountries = async (currentState: any, updateState: any) => {
    try {
        updateState({
            ...currentState,
            loading: true,
        });

        const { data: countryList } = await axios({
            url: `${process.env.REACT_APP_CDN_BASE_URL}/countries/codes.json?cache=${new Date().getTime()}`,
        });

        updateState({
            ...currentState,
            loading: false,
            countries: [{ code: 'SELECT', name: 'Please select a country', dial: '+00' }, ...countryList],
            countriesFiltered: [
                { code: 'SELECT', name: 'Please select a country', dial: '+00' },
                ...countryList.filter((item: any) => !item.disabled),
            ],
        });
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            alert: 'We failed to retrieve country list',
        });
    }
};

export const loadCountriesAndReturn = async (currentState: any, updateState: any) => {
    try {
        updateState({
            ...currentState,
            loading: true,
        });

        const { data: countryList } = await axios({
            url: `${process.env.REACT_APP_CDN_BASE_URL}/countries/codes.json?cache=${new Date().getTime()}`,
        });

        return {
            countries: [{ code: 'SELECT', name: 'Please select a country', dial: '+00' }, ...countryList],
            countriesFiltered: [
                { code: 'SELECT', name: 'Please select a country', dial: '+00' },
                ...countryList.filter((item: any) => !item.disabled),
            ],
        };
    } catch (e) {
        updateState({
            ...currentState,
            loading: false,
            alert: 'We failed to retrieve country list',
        });
    }
};

export const CountryListSelect = ({
    label,
    currentState,
    updateState,
    stateKey,
    errorState,
    updateErrorState,
    value,
    countries = [],
    use2DigitCodeAsValue = false,
    selectSx = {},
    mt = '25px',
    mtSelect = '10px',
    mtArrow = '15px',
    mbSelect = '0px',
    dataTestId,
    errorStateKey = 'address',
    hasError = false,
    width = '250px',
}: any) => (
    <>
        {label && (
            <Paragraph
                sx={{
                    mt,
                    textAlign: 'left',
                    maxWidth: '450px',
                    color: '#09042B',
                    fontSize: '16px',
                }}
            >
                {label} <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
            </Paragraph>
        )}
        <Select
            data-testid={dataTestId}
            sx={{
                mt: mtSelect,
                mb: mbSelect,
                width,
                border: hasError ? '1px red solid' : '',
                ...selectSx,
            }}
            value={value}
            arrow={
                <Box
                    as="svg" // @ts-ignore
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentcolor"
                    sx={{
                        ml: -28,
                        mt: mtArrow,
                        alignSelf: 'center',
                        pointerEvents: 'none',
                    }}
                >
                    <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                </Box>
            }
            onChange={(e) => {
                updateState({ ...currentState, [stateKey]: e.target.value });

                updateErrorState({
                    ...errorState,
                    [errorStateKey]: hasError,
                });
            }}
        >
            {countries.map((item: any) => (
                <option key={item.name} value={use2DigitCodeAsValue ? item.code2Digit : item.code}>
                    {item.name}&nbsp;&nbsp;
                    {item.code === 'SELECT' ? '' : countryCodeEmoji(item.code2Digit)}
                </option>
            ))}
        </Select>
    </>
);
