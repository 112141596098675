/** @jsxImportSource theme-ui */
import { Heading, Box, Paragraph, Flex, Image, Link } from 'theme-ui';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import Spinner from '../../components/spinner';
import Input from '../../components/form-input';
import Waves from '../waves';

const isValidEmail = (email: string) => email && email.trim() !== '' && email.trim().match(/^\S+@\S+\.\S+$/);

const resetPassword = async (currentState: any, updateState: any, navigate: any) => {
    if (!isValidEmail(currentState.email)) {
        return updateState({
            ...currentState,
            error: 'Please enter a valid email.',
        });
    }

    updateState({ error: null, loading: true });

    try {
        await Auth.forgotPassword(currentState.email);
        updateState({
            loading: false,
            error: null,
        });
        return navigate('/reset-password/sent');
    } catch (error: any) {
        if (error.code === 'UserNotFoundException' || error.code === 'InvalidParameterException') {
            return navigate('/reset-password/sent');
        }

        return updateState({
            loading: false,
            error: 'We are currently unable to process your request, please try again later.',
        });
    }
};

const Request = (props: any) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: null,
        email: '',
    });

    const navigate = useNavigate();

    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                backgroundSize: 'cover',
            }}
        >
            {currentState.loading && <Spinner />}

            <Waves />

            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'white',
                    width: ['100%', '600px'],
                    height: '550px',
                    p: '50px',
                }}
            >
                <Box sx={{ margin: '0px auto 30px' }}>
                    <Image
                        src={`https://cdn.accru.finance/af/logo-dark.png`}
                        width="320px"
                        height="auto"
                        alt="refer a friend"
                        data-testid="login-logo"
                    />
                </Box>

                <Heading sx={{ textAlign: 'center', mt: 0, mb: 30 }}>Forgot password</Heading>

                <Paragraph
                    sx={{
                        mb: 30,
                        textAlign: 'center',
                        width: [300, 350],
                        alignSelf: 'center',
                        lineHeight: '22px',
                    }}
                >
                    Enter your email and we will send a link for you to set a new password. This may take a few minutes.
                </Paragraph>

                <Input
                    sx={{ mb: 30, alignSelf: 'center', width: [320, 380] }}
                    type="email"
                    id="email"
                    name="email"
                    data-testid="email"
                    placeholder="Email"
                    onChange={(e: any) => updateState({ ...currentState, email: e.target.value })}
                />

                <Button
                    sx={{ alignSelf: 'center', width: 300 }}
                    data-testid="submit-button"
                    variant="primary-wide"
                    onClick={() => resetPassword(currentState, updateState, navigate)}
                >
                    Send password reset link
                </Button>

                <Link
                    onClick={() => navigate('/')}
                    sx={{
                        mt: 20,
                        fontSize: '15px',
                        fontWeight: '400',
                        alignSelf: 'center',
                        color: 'fontDark',
                        zIndex: 100,
                    }}
                >
                    Cancel
                </Link>

                {currentState.error && <Paragraph sx={{ color: 'red', mt: 20 }}>{currentState.error}</Paragraph>}
            </Flex>
        </Flex>
    );
};

export default Request;
