import { Flex } from 'theme-ui';
import { useEffect } from 'react';
import { InputLabel, FormInput } from './form-elements';
import { CountryListSelect } from './select-country';

export const Address = ({ currentQuestion, currentState, updateState, errorState, updateErrorState }: any) => {
    useEffect(() => {
        updateState({ ...currentState, loading: false });
    }, []);
    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                '@media screen and (max-width: 550px)': {
                    flexDirection: 'column',
                    minHeight: '650px',
                    width: '340px',
                    mt: '20px',
                    mb: '0',
                },
            }}
        >
            <Flex
                sx={{
                    border: '0px red solid',
                    minHeight: '350px',
                    mt: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    '@media screen and (max-width: 550px)': {
                        flexDirection: 'column',
                        width: '340px',
                        ml: '0',
                        mt: '-125px',
                    },
                }}
            >
                <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="Address line 1" subtitle=" *" />

                <FormInput
                    width="300px"
                    mt="5px"
                    mb="7px"
                    errorState={errorState}
                    errorKey="address"
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey="street"
                    placeholder="Address line 1"
                />

                <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="Address line 2" />
                <FormInput
                    width="300px"
                    mt="5px"
                    mb="7px"
                    errorState={errorState}
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey="sub_street"
                    placeholder="Address line 2"
                />

                <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="City" subtitle=" *" />
                <FormInput
                    width="300px"
                    mt="5px"
                    mb="7px"
                    errorState={errorState}
                    errorKey="address"
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey="town"
                    placeholder="City"
                />
            </Flex>

            <Flex
                sx={{
                    border: '0px red solid',
                    minHeight: '350px',
                    mt: '10px',
                    ml: '15px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    '@media screen and (max-width: 550px)': {
                        ml: '0',
                        width: '340px',
                        mt: '-100px',
                    },
                }}
            >
                <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="State" />
                <FormInput
                    width="300px"
                    mt="5px"
                    mb="7px"
                    errorState={errorState}
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey="state"
                    placeholder="State"
                />

                <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="Country" subtitle=" *" />
                <CountryListSelect
                    width="300px"
                    dataTestId="country"
                    stateKey="country"
                    currentState={currentState}
                    updateState={updateState}
                    errorState={errorState}
                    errorStateKey="address"
                    hasError={errorState?.address}
                    updateErrorState={updateErrorState}
                    value={currentState.country}
                    countries={currentState.countriesFiltered}
                    mtSelect="5px"
                    mbSelect="10px"
                    mtArrow="0px"
                />
                <InputLabel customSX={{ textAlign: 'left', width: '300px' }} title="Post code" subtitle=" *" />
                <FormInput
                    width="300px"
                    mt="5px"
                    mb="7px"
                    errorState={errorState}
                    errorKey="address"
                    state={currentState}
                    updateState={updateState}
                    updateErrorState={updateErrorState}
                    stateKey="post_code"
                    placeholder="Post code"
                />
            </Flex>
        </Flex>
    );
};
