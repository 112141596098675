/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Box, Button, Flex, Heading, Image, Paragraph } from 'theme-ui';
import {
    downloadClients,
    downloadReport,
    getSingleClient,
    loadClientAlerts,
    loadClients,
} from '../components/clients/api';
import {
    Create,
    Edit,
    List,
    defaultClientColumns,
    filterDocuments,
    sortClients,
} from '../components/clients/components';
import ReportTable from '../components/clients/report-table';
import Divider from '../components/divider';
import EngagementLetterModal from '../components/engagement-letter-modal';
import MfaSetup from '../components/overlay/mfa/setup';
import Overlay from '../components/overlay/overlay';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';

export const defaultState = {
    loading: true,
    initialLoad: true,
    partialLoading: false,
    keyContactsView: 'list',
    error: null,
    success: null,
    listToShow: 'all', // all || alerts
    companyId: '',
    mode: 'list', // list || create || edit
    organisation: { name: '', schema: {} },
    clientInView: {},
    clients: [],
    clientsOffset: 0,
    clientsLimit: 100,
    clientsTotal: 0,
    clientsAlerts: [],
    clientsAlertsOffset: 0,
    clientsAlertsLimit: 10,
    clientsAlertsTotal: 0,
    chatHistory: [],
    documentToQuery: { history: [] },
    documentQuery: '',
    userId: '',
    reloadAll: false,
    userDoesNotBelongToAnOrganisation: false,
    schemaKeys: [],
    fullSchema: {},
    selectLabelsAlerts: [],
    clientSearchName: '',
    clientSearchFilters: [],
    countriesFiltered: [],
    countries: [],
    clientTabInFocus: 'documents',
    totalDocuments: 0,
    taskDocumentsOffset: 0,
    documentsLimit: 3,
    totalComments: 0,
    taskCommentsOffset: 0,
    view: 'main',
    groupByService: true,
    fromDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    commentsLimit: 3,
    newClientServicesOffered: {},
    clientType: { value: 'company', label: 'Company' },
    clientTypeFilter: { value: 'all', label: 'All client types' },
    sortByKeys: ['name', 'internalReferenceCode', 'type'],
    sortByDirections: ['ASC', 'ASC', 'ASC'],
    client_sort_by: JSON.parse(
        localStorage.getItem('client_sort_by') || JSON.stringify([{ value: 'name_asc', label: 'Name ascending' }])
    ),
    columns: JSON.parse(
        localStorage.getItem('client_columns') || JSON.stringify(defaultClientColumns.slice(0, 3))
    )?.map((x) => x.value),
    report_to_download: {
        value: 'clients',
        label: 'Clients',
    },
    showAdvancedFilter: window.localStorage.getItem('show_advanced_client_filters') === 'true',
};

export const defaultErrorState = { selectedDocs: false, description: false, shareEmails: false };

const titleMapping = {
    list: { title: 'Clients', subtitle: 'All of the companies for your organisation' },
    create: { title: 'Create client', subtitle: 'Create a new client' },
    edit: { title: 'Client record', subtitle: 'The client data' },
};

const Title = ({ state, updateState }) => (
    <Flex
        sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: '30px',
        }}
    >
        <Flex sx={{ flexDirection: 'column', alignSelf: 'center' }}>
            <Flex sx={{ mt: '30px', mb: 10 }}>
                <Heading
                    sx={{
                        fontWeight: '400',
                        fontSize: '25px',
                        color: 'text',
                        textAlign: 'left',
                        mr: 20,
                    }}
                >
                    {titleMapping[state?.mode]?.title +
                        `${
                            state?.mode === 'edit' && state.clientInView?.name
                                ? ` - ${state.clientInView?.name}${
                                      state.clientInView?.archived ? ' - ARCHIVED' : ''
                                  } - ${state.clientInView?.internal_reference_code}`
                                : ''
                        }`}
                </Heading>
                {state?.mode === 'list' &&
                (!state.report_to_download || state.report_to_download?.value === 'clients') ? (
                    <Button
                        variant="primary"
                        sx={{
                            width: '140px',
                            height: '42px',
                            fontSize: '14px',
                        }}
                        data-testid="submit"
                        onClick={async () =>
                            updateState({
                                ...state,
                                mode: 'create',
                                individualName: '',
                                clientType: { value: 'company', label: 'Company' },
                            })
                        }
                    >
                        <i
                            style={{
                                marginRight: '9px',
                            }}
                            className={`fas fa-plus-circle`}
                        />
                        New client
                    </Button>
                ) : null}
            </Flex>
            <Divider mt={10} mb={30} width="1300px" />
        </Flex>
    </Flex>
);

const Clients = ({ refreshCoreData, userData, accountSummary, isMobile, organisation, logout }) => {
    const [state, updateState] = useState({ ...defaultState, organisation });
    const [errorState, updateErrorState] = useState(defaultErrorState);
    const [user, updateUser] = useState(userData);
    const navigate = useNavigate();

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        (async () => {
            try {
                const queryParams = new URLSearchParams(window.location.search);

                const deeplinkToClient = !!queryParams.get('id');
                const useRawId = !!queryParams.get('raw_id');
                const tab = queryParams.get('tab');

                const authenticatedUser = await Auth.currentAuthenticatedUser();

                const { attributes } = authenticatedUser;

                if (authenticatedUser?.preferredMFA === 'NOMFA') {
                    return updateState({ ...state, mfaSetupRequired: true });
                }

                updateUser(attributes);

                refreshCoreData();

                const { clientsOffset, clientsLimit, clientsAlertsOffset, clientsAlertsLimit } = state;

                const sortByKeys = [];
                const sortByDirections = [];
                state.client_sort_by?.forEach((item) => {
                    const [key, direction] = item.value.split('_');
                    if (['name', 'type', 'internalReferenceCode', 'tasks_count'].includes(key)) {
                        sortByKeys.push(key);
                        sortByDirections.push(direction.toUpperCase());
                    }
                });

                const [
                    { clients, total: clientsTotal, organisation },
                    { clients: clientsAlerts, total: clientsAlertsTotal },
                    data,
                    {
                        data: { users },
                    },
                ] = await Promise.all([
                    loadClients(
                        state,
                        clientsOffset,
                        clientsLimit,
                        '',
                        '',
                        state.include_archived,
                        sortByKeys.join(','),
                        sortByDirections.join(',')
                    ),
                    loadClientAlerts(clientsAlertsOffset, clientsAlertsLimit),
                    deeplinkToClient ? getSingleClient(queryParams.get('id'), useRawId) : Promise.resolve(null),
                    axios.get(`${process.env.REACT_APP_AQRU_AI_API}/organisations/users?limit=200`),
                ]);

                const selectLabelsAlerts = [];

                if (deeplinkToClient) {
                    try {
                        return updateState({
                            ...state,
                            users,
                            userId: authenticatedUser.username,
                            documentToQuery: data,
                            clientsAlerts,
                            clientsAlertsTotal,
                            clients,
                            clientsTotal,
                            organisation,
                            loading: false,
                            initialLoad: false,
                            mode: 'edit',
                            clientInView: data,
                            selectLabelsAlerts,
                            tab,
                            defaultPartners: data.relations
                                ?.filter((x) => x.type === 'partner')
                                ?.map((x) => ({ value: x.id, label: x.email_address })),
                            defaultReviewers: data.relations
                                ?.filter((x) => x.type === 'reviewer')
                                ?.map((x) => ({ value: x.id, label: x.email_address })),
                            defaultAssignees: data.relations
                                ?.filter((x) => x.type === 'assigned_to')
                                ?.map((x) => ({ value: x.id, label: x.email_address })),
                        });
                    } catch (e) {
                        console.log(e);
                        window.location.assign('/clients');
                    }
                } else {
                    return updateState({
                        ...state,
                        userId: authenticatedUser.username,
                        users,
                        documentToQuery: data,
                        clientsAlerts,
                        clientsAlertsTotal,
                        clients,
                        clientsTotal,
                        organisation,
                        loading: false,
                        initialLoad: false,
                        selectLabelsAlerts,
                    });
                }
            } catch (e) {
                if (e.response?.status === 400 && e?.response?.data?.code === 'NO_ORG') {
                    updateState({
                        ...state,
                        loading: false,
                        userDoesNotBelongToAnOrganisation: true,
                    });
                } else {
                    logout();
                    navigate('/');
                }
            }
        })();
    }, []);

    const loadFresh = useCallback(
        async (keepExistingRecords) => {
            try {
                if (!state?.initialLoad && !state.loading && state?.mode === 'list') {
                    const { clientsOffset, clientsLimit, clientTypeFilter } = state;

                    updateState({ ...state, loading: true });

                    const sortByKeys = [];
                    const sortByDirections = [];
                    state.client_sort_by?.forEach((item) => {
                        const [key, direction] = item.value.split('_');
                        if (['name', 'type', 'internalReferenceCode', 'tasks_count'].includes(key)) {
                            sortByKeys.push(key);
                            sortByDirections.push(direction.toUpperCase());
                        }
                    });

                    const {
                        clients,
                        total: clientsTotal,
                        organisation,
                    } = await loadClients(
                        state,
                        keepExistingRecords ? clientsOffset : 0,
                        clientsLimit,
                        document.getElementById('clientSearchName').value,
                        clientTypeFilter?.value,
                        state.include_archived,
                        sortByKeys.join(','),
                        sortByDirections.join(',')
                    );

                    return updateState({
                        ...state,
                        loading: false,
                        clients: sortClients(
                            keepExistingRecords
                                ? [
                                      ...state.clients,
                                      ...clients.filter((x) => !state.clients?.find((y) => y.id === x.id)),
                                  ]
                                : clients,
                            state.client_sort_by,
                            state.organisation
                        ),
                        clientsTotal,
                        organisation,
                    });
                }
            } catch (e) {
                //swallow
                console.log(e);
            }
        },
        [
            state.sortByDirections,
            state.clientsOffset,
            state.clientTypeFilter,
            state.clientStatus,
            state.mode,
            state.include_archived,
            state.customColumn,
            state.customColumnOperator,
            state.customColumnFilterValue,
            state.default_preparer,
            state.default_reviewer,
            state.default_partner,
        ]
    );

    useEffect(() => {
        if (state.report_to_download?.value && state.report_to_download?.value !== 'clients') {
            return;
        }
        loadFresh(true);
    }, [state.sortByDirections, state.clientsOffset]);

    useEffect(() => {
        if (state.report_to_download?.value && state.report_to_download?.value !== 'clients') {
            return;
        }
        loadFresh(false);
    }, [
        state.clientTypeFilter,
        state.clientStatus,
        state.include_archived,
        state.default_preparer,
        state.default_reviewer,
        state.default_partner,
    ]);

    useEffect(() => {
        if (state.report_to_download?.value && state.report_to_download?.value !== 'clients') {
            return;
        }

        // if (!state.customColumn || !state.customColumnFilterValue) {
        //     return;
        // }

        loadFresh(false);
    }, [state.customColumn, state.customColumnOperator, state.customColumnFilterValue]);

    useEffect(() => {
        if (state.report_to_download?.value && state.report_to_download?.value !== 'clients') {
            return;
        }

        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 250 && !stateRef.current.loading) {
                updateState({
                    ...stateRef.current,
                    clientsOffset: stateRef.current.clientsOffset + stateRef.current.clientsLimit,
                    clientsLimit: stateRef.current.clientsLimit,
                    clientSearchName: document.getElementById('clientSearchName')?.value || '',
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [state.clientSearchName]);

    if (state.userDoesNotBelongToAnOrganisation) {
        return (
            <Flex sx={{ width: '100vw', height: '100vh' }}>
                <Flex
                    sx={{
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        alignItems: 'center',
                        padding: 40,
                        flexDirection: 'column',
                        margin: '0 auto',
                        width: 600,
                    }}
                >
                    <Image
                        sx={{ mt: 40, width: 300, mb: 0, ml: 0 }}
                        src={'https://cdn.accru.finance/af/logo-dark.png'}
                        alt=""
                    />
                    <Paragraph sx={{ mt: 50, fontWeight: 400, fontSize: 20 }}>
                        Welcome to Accounting Flow. You're almost there!
                    </Paragraph>
                    <Paragraph sx={{ mt: 30, mb: 30, fontWeight: 400, textAlign: 'center' }}>
                        You currently don't belong to an organisation yet. You should receive an invite to join your
                        organisation shortly.
                    </Paragraph>
                    <Button onClick={() => logout()}>Sign out</Button>
                </Flex>
            </Flex>
        );
    }

    if (state.mfaSetupRequired) {
        return (
            <Flex
                sx={{
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    alignItems: 'center',
                    padding: 40,
                    margin: '0 auto',
                    width: 600,
                    mt: 60,
                }}
            >
                <Image
                    sx={{ mt: 40, width: 300, mb: 0, ml: 0 }}
                    src={'https://cdn.accru.finance/af/logo-dark.png'}
                    alt=""
                />
                <Paragraph sx={{ mt: 50, fontWeight: 400, fontSize: 20 }}>
                    Welcome to Accounting Flow. You're almost there!
                </Paragraph>
                <Paragraph sx={{ mt: 20, fontWeight: 400, fontSize: 20 }}>
                    To keep you and your data safe, it's time to setup MFA
                </Paragraph>
                <MfaSetup />
            </Flex>
        );
    }

    if (!state.organisation) {
        return (
            <Flex
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: '#FFF',
                }}
            >
                <Spinner />
            </Flex>
        );
    }

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: 'auto',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundColor: '#fff',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Clients" accountSummary={accountSummary} user={user} copy={{}} />

                    {state.showEngagementLetterModal && (
                        <Overlay
                            copy={{}}
                            maxWidth={600}
                            maxHeight={850}
                            embeddedComponent={<EngagementLetterModal state={state} updateState={updateState} />}
                            updateOverlay={() => updateState({ ...state, showEngagementLetterModal: false })}
                        />
                    )}

                    {(state.loading || state.dataLoading) && <Spinner />}

                    <Flex
                        sx={{
                            width: 'fit-content',
                            border: '0px solid lightgrey',
                            minHeight: 'calc(100vh)',
                            backgroundColor: '#ffffff',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mt: '0px',
                            ml: '130px',
                        }}
                    >
                        <>
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    ml: '30px',
                                    width: 'auto',
                                }}
                            >
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Title state={state} updateState={updateState} />

                                    {state?.mode === 'create' && (
                                        <Flex>
                                            <Button
                                                variant="primary"
                                                sx={{
                                                    width: '130px',
                                                    mt: '-40px',
                                                    mb: 10,
                                                    height: 40,
                                                    alignSelf: 'flex-end',
                                                    backgroundColor: 'white',
                                                    color: 'text',
                                                    border: '1px solid lightGrey',
                                                }}
                                                data-testid="submit"
                                                onClick={async () => {
                                                    updateState({
                                                        ...state,
                                                        mode: 'list',
                                                        error: null,
                                                        companyId: null,
                                                        internal_reference_code: null,
                                                        vat_registered: false,
                                                        vat_number: '',
                                                        newClientServicesOffered: {},
                                                    });
                                                    updateErrorState(defaultErrorState);
                                                }}
                                            >
                                                <i
                                                    style={{
                                                        marginTop: '0px',
                                                        fontSize: '12px',
                                                        marginLeft: '0px',
                                                        marginRight: '6px',
                                                        color: 'text',
                                                    }}
                                                    className={`fas fa-chevron-left`}
                                                />{' '}
                                                Back
                                            </Button>
                                        </Flex>
                                    )}

                                    {state?.mode === 'list' && (
                                        <Flex sx={{ mt: -50, mb: 20 }}>
                                            <Flex sx={{ flexDirection: 'column' }}>
                                                <Paragraph sx={{ ml: 0, mb: '5px', fontSize: 14 }}>View</Paragraph>
                                                <Flex sx={{ alignItems: 'center' }}>
                                                    <ReactSelect
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '280px',
                                                                minHeight: '40px',
                                                                borderRadius: 10,
                                                                marginBottom: '10px',
                                                                border: '1px solid #a3a69b',
                                                                fontSize: '14px',
                                                            }),
                                                        }}
                                                        onChange={async (value) => {
                                                            updateState({
                                                                ...state,
                                                                report_to_download: value,
                                                            });
                                                        }}
                                                        placeholder={'Select your view'}
                                                        value={state.report_to_download}
                                                        options={[
                                                            {
                                                                value: 'clients',
                                                                label: 'Clients',
                                                            },
                                                            {
                                                                value: 'client_users',
                                                                label: 'Client Users',
                                                            },
                                                            {
                                                                value: 'questionnaire_status',
                                                                label: 'Questionnaire Progress',
                                                            },
                                                            {
                                                                value: 'docs',
                                                                label: 'Doc Sign Progress',
                                                            },
                                                            {
                                                                value: 'invoices',
                                                                label: 'Invoices',
                                                            },
                                                        ]}
                                                    />
                                                    <Button
                                                        onClick={async () => {
                                                            if (!state.report_to_download?.value) return;

                                                            if (state.report_to_download?.value === 'clients') {
                                                                await downloadClients(state, updateState);
                                                            } else {
                                                                await downloadReport(
                                                                    state,
                                                                    updateState,
                                                                    state.report_to_download?.value
                                                                );
                                                            }
                                                        }}
                                                        sx={{ ml: 10, mr: 10, mt: -10, height: 40 }}
                                                        variant="light"
                                                    >
                                                        <i
                                                            style={{
                                                                marginTop: '0px',
                                                                marginRight: '7px',
                                                                color: '#444',
                                                            }}
                                                            className={`far fa-download`}
                                                        />
                                                        Download
                                                    </Button>

                                                    {!state.report_to_download ||
                                                    state.report_to_download?.value === 'clients' ? (
                                                        <Button
                                                            variant="light"
                                                            sx={{
                                                                width: '110px',
                                                                height: '40px',
                                                                color: '#444',
                                                                mt: -10,
                                                            }}
                                                            data-testid="submit"
                                                            onClick={async () => {
                                                                const tempState = {
                                                                    ...state,
                                                                    clientSearchFilters: [],
                                                                    clientTypeFilter: {
                                                                        value: 'all',
                                                                        label: 'All client types',
                                                                    },
                                                                    clientSearchName: '',
                                                                    clientsOffset: 0,
                                                                    report_to_download: null,
                                                                    customColumn: null,
                                                                    customColumnOperator: null,
                                                                    clientStatus: null,
                                                                    customColumnFilterValue: null,
                                                                    default_preparer: null,
                                                                    default_reviewer: null,
                                                                    default_partner: null,
                                                                    include_archived: false,
                                                                };
                                                                // if (type === 'all') {
                                                                //     document.getElementById('clientSearchName').value = null;
                                                                // }
                                                                await filterDocuments(tempState, updateState, false);
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    marginTop: '0px',
                                                                    marginRight: '7px',
                                                                    color: '#444',
                                                                }}
                                                                className={`far fa-times-circle`}
                                                            />
                                                            Clear
                                                        </Button>
                                                    ) : null}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                            {state?.success && (
                                <Paragraph
                                    sx={{ ml: '30px', mt: '20px', fontSize: '14px', fontWeight: '600', color: 'green' }}
                                >
                                    Client data updated
                                </Paragraph>
                            )}

                            <Flex
                                sx={{
                                    width: 'auto',
                                    ml: '30px',
                                    flexDirection: 'row',
                                    border: '0px red solid',
                                    mt: '20px',
                                }}
                            >
                                {state?.mode === 'list' &&
                                    state?.listToShow === 'all' &&
                                    state.report_to_download &&
                                    state.report_to_download.value !== 'clients' && (
                                        <ReportTable state={state} updateState={updateState} />
                                    )}

                                {state?.mode === 'list' &&
                                    state?.listToShow === 'all' &&
                                    (!state.report_to_download || state.report_to_download?.value === 'clients') && (
                                        <List
                                            state={state}
                                            updateState={updateState}
                                            isMobile={isMobile}
                                            navigate={navigate}
                                            type="all"
                                        />
                                    )}

                                {state?.mode === 'create' && (
                                    <Create
                                        state={state}
                                        updateState={updateState}
                                        isMobile={isMobile}
                                        navigate={navigate}
                                        errorState={errorState}
                                        updateErrorState={updateErrorState}
                                    />
                                )}

                                {state?.mode === 'edit' && (
                                    <Edit
                                        state={state}
                                        organisation={organisation}
                                        updateState={updateState}
                                        isMobile={isMobile}
                                        navigate={navigate}
                                        errorState={errorState}
                                        updateErrorState={updateErrorState}
                                        stateRef={stateRef}
                                        user={user}
                                    />
                                )}
                            </Flex>
                            {state.error && (
                                <Paragraph
                                    sx={{
                                        color: 'red',
                                        minHeight: '20px',
                                        mt: '20px',
                                        width: '100%',
                                        textAlign: 'left',
                                        ml: '30px',
                                        fontWeight: '600',
                                    }}
                                >
                                    {state.error}
                                </Paragraph>
                            )}
                            <Box sx={{ height: '150px' }} />
                        </>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
