/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { Button, Flex, Label, Link, Paragraph } from 'theme-ui';
import axios from 'axios';
import { connect } from 'react-redux';
import { actions as userActions } from '../store/reducers/users';
import { defaultOverlayState } from '../utils/default-states';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { frequencyOptions } from '../components/settings/manage-workflow';
import AsyncSelect from 'react-select/async';
import { loadClientsAsync } from '../components/tasks/filters';
import Divider from '../components/divider';
import moment from 'moment';
import theme from '../theme';

const limit = 30;

const fetchEvents = async (state, updateState, refreshCoreData, clearOutExistingData = false) => {
    updateState({ ...state, loading: true });
    try {
        refreshCoreData();

        const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks/clone-events`, {
            params: {
                limit,
                offset: state.offset,
                services: state.services?.map((x) => x.value).join(','),
                client_id:
                    !!state.clientSelected && state.clientSelected?.value !== 'unassigned'
                        ? state.clientSelected?.value
                        : undefined,
            },
        });

        let events = clearOutExistingData
            ? {}
            : state.events.reduce((accum, item) => {
                  accum[item.id] = item;
                  return accum;
              }, {});

        data.events.forEach((event) => {
            events[event.id] = event;
        });

        updateState({
            ...state,
            loading: false,
            events: Object.values(events).sort((a, b) => {
                if (a.task_title < b.task_title) {
                    return -1;
                }
                if (a.task_title > b.task_title) {
                    return 1;
                }

                return 0;
            }),
        });
    } catch (error) {
        updateState({ ...state, loading: false, events: [], error: true });
        console.error('Error fetching events:', error);
    }
};

const Schedule = ({ accountSummary, userData, organisation, refreshCoreData }) => {
    const [state, updateState] = useState({
        loading: true,
        services: null,
        events: [],
        sortByDirections: ['ASC'],
        offset: 0,
    });

    const [user, updateUser] = useState(userData);

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        fetchEvents(state, updateState, refreshCoreData);
    }, []);

    useEffect(() => {
        fetchEvents(state, updateState, refreshCoreData);
    }, [state.offset]);

    useEffect(() => {
        fetchEvents({ ...state, offset: 0 }, updateState, refreshCoreData, true);
    }, [state.clientSelected, state.services]);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 250 && !stateRef.current.loading) {
                updateState({
                    ...stateRef.current,
                    offset: stateRef.current.offset + limit,
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [state.clientSelected]);

    const [overlay, updateOverlay] = useState(defaultOverlayState);

    return (
        <Flex
            sx={{
                minHeight: '100vh',
                paddingBottom: '100px',
                width: '100vw',
                flexDirection: 'column',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundColor: '#fff',
            }}
            data-testid="home-screen"
        >
            <Flex
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    flexDirection: 'column',
                }}
            >
                <Sidebar
                    currentPage="Schedule"
                    overlay={overlay}
                    accountSummary={accountSummary}
                    user={user}
                    copy={{}}
                />

                <Flex
                    sx={{
                        width: 'fit-content',
                        border: '0px solid lightgrey',
                        minHeight: 'calc(100vh)',
                        backgroundColor: '#ffffff',
                        borderTopLeftRadius: '25px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        mt: '15px',
                        ml: '130px',
                    }}
                >
                    <Flex sx={{ flexDirection: 'column', ml: 30 }}>
                        <Paragraph
                            sx={{
                                textAlign: 'left',
                                fontSize: '25px',
                                color: 'text',
                                fontWeight: '400',
                                mt: 33,
                                mb: 20,
                            }}
                        >
                            Scheduled Tasks
                        </Paragraph>

                        <Divider mt={0} />

                        {state.loading && <Spinner />}

                        <Flex sx={{ mb: 0 }}>
                            <Label id="client-label" sx={{ mt: 20, mr: 0, width: 80 }}>
                                Client
                            </Label>
                            <AsyncSelect
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '280px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                }}
                                aria-labelledby="client-label"
                                onChange={async (c) => {
                                    updateState({ ...state, clientSelected: c });
                                }}
                                placeholder="Filter client by name"
                                value={state.clientSelected}
                                defaultOptions={[{ value: 'unassigned', label: 'All clients' }]}
                                loadOptions={loadClientsAsync(state, updateState)}
                            />
                        </Flex>

                        <Flex sx={{ mt: 10, alignItems: 'center', mb: 40 }}>
                            <Label id="service-label" sx={{ width: 80, mt: '5px' }}>
                                Service
                            </Label>
                            <ReactSelect
                                aria-labelledby="service-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '280px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        border: `1px solid #a3a69b`,
                                        fontSize: '14px',
                                    }),
                                }}
                                onChange={(c) =>
                                    updateState({
                                        ...state,
                                        services: c,
                                    })
                                }
                                isMulti
                                placeholder="Select..."
                                value={state?.services}
                                options={[
                                    ...(Object.entries(organisation?.workflow_definition?.services || {})?.map(
                                        ([key, value]) => ({
                                            value: key,
                                            label: value.label,
                                        })
                                    ) || []),
                                ]}
                            />
                        </Flex>

                        <table
                            style={{
                                width: 1500,
                                marginBottom: 30,
                                fontSize: 14,
                                borderCollapse: 'collapse',
                            }}
                        >
                            <thead
                                style={{
                                    backgroundColor: theme.colors.primary,
                                    color: 'white',
                                    cursor: 'pointer',
                                    position: 'sticky',
                                    top: 0,
                                }}
                            >
                                <tr>
                                    <th style={{ width: 250, textAlign: 'left', padding: 20 }}>Title</th>
                                    <th style={{ width: 250, textAlign: 'left', padding: 20 }}>Service</th>
                                    <th style={{ width: 250, textAlign: 'left', padding: 20 }}>Starts On</th>
                                    <th style={{ width: 250, textAlign: 'left', padding: 20 }}>Frequency</th>
                                    <th style={{ width: 250, textAlign: 'left', padding: 20 }}>
                                        When To Set Relevant Period End
                                    </th>
                                    <th style={{ width: 10, textAlign: 'left', padding: 20 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.events.map((event) => (
                                    <tr
                                        style={{
                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                                            cursor: 'pointer',
                                            borderLeft: 'drop-shadow(0 0 0.18rem #015dc5)',
                                        }}
                                        key={event.id}
                                    >
                                        <td style={{ width: 200, padding: 20 }}>{event.task_title}</td>
                                        <td style={{ width: 200, padding: 20 }}>
                                            {organisation?.workflow_definition?.services?.[event?.service]?.label}
                                        </td>
                                        <td style={{ width: 200, padding: 20 }}>
                                            {event.starting_on ? moment(event.starting_on).format('DD/MM/YYYY') : ''}
                                        </td>
                                        <td style={{ width: 200, padding: 20 }}>
                                            {event.frequency
                                                ? `Every ${event.frequency > 1 ? `${event.frequency} ` : ''}${
                                                      event.additional_data?.everyUnit?.value || 'month'
                                                  }${event.frequency > 1 ? 's' : ''}`
                                                : ''}
                                        </td>
                                        <td style={{ width: 200, padding: 20 }}>
                                            {
                                                [
                                                    {
                                                        value: 'last_business_day',
                                                        label: 'Last business day of month',
                                                    },
                                                    { value: 'end_of_month', label: 'End of month' },
                                                    { value: 'end_of_quarter', label: 'End of quarter' },
                                                    {
                                                        value: 'end_of_previous_month',
                                                        label: 'End of previous month',
                                                    },
                                                    ...frequencyOptions,
                                                ]?.find((y) => y.value === event?.when_to_set_service_end_date)?.label
                                            }
                                        </td>
                                        <td style={{ width: 80, padding: 20 }}>
                                            <Link
                                                sx={{ textDecoration: 'none', color: 'blue', '&:visited': 'blue' }}
                                                rel="noreferrer"
                                                target="_blank"
                                                href={`/tasks?id=${event.task_id}&show_schedule=1`}
                                            >
                                                <Button variant="light">
                                                    <i
                                                        style={{
                                                            marginRight: '7px',
                                                            fontSize: '12px',
                                                            cursor: 'pointer',
                                                        }}
                                                        className="fas fa-pencil"
                                                        aria-hidden="true"
                                                    />
                                                    Edit
                                                </Button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
