/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button, Box } from 'theme-ui';
import { Auth } from 'aws-amplify';
import WebSocket from 'isomorphic-ws';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Spinner from '../components/spinner';
import Sidebar from '../components/sidebar';
import { List, Edit, Create } from '../components/definitions/components';
import { actions as userActions } from '../store/reducers/users';
import { actions as menuActions } from '../store/reducers/menu';
import { loadDocuments, loadDefinitions } from '../components/documents/load-docs';

export const defaultState = {
    loading: true,
    forceUploadDocs: false,
    initialLoad: true,
    partialLoading: false,
    error: null,
    definitionId: '',
    definitionObjectInView: '',
    finalisedResults: [],
    description: '',
    mode: 'list', // list || create || edit
    docOffset: 0,
    docLimit: 6,
    docTotal: 0,
    selectLabels: [],
    selectedDocs: [],
    documents: [],
    definitions: [],
    definitionsOffset: 0,
    definitionsLimit: 10,
    definitionsTotal: 0,
    statuses: 'PROCESSED',
    chatHistory: [],
    documentToQuery: {},
    documentQuery: '',
};

export const defaultErrorState = { selectedDocs: false, description: false, shareEmails: false };

const titleMapping = {
    list: { title: 'Drafts', subtitle: 'Compare two files and create a document' },
    create: { title: 'Create Draft', subtitle: 'Compare two files and create a document' },
    edit: { title: 'Edit Draft', subtitle: 'Select which of the comparisons are correct' },
};

const Title = ({ state }) => (
    <Flex
        sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            mb: '30px',
        }}
    >
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph
                sx={{
                    fontWeight: '600',
                    fontSize: '25px',
                    color: 'text',
                    textAlign: 'left',
                    mt: '30px',
                }}
            >
                {titleMapping[state?.mode]?.title}
            </Paragraph>
            <Paragraph
                sx={{
                    fontSize: '15px',
                    color: 'text',
                    textAlign: 'left',
                    mt: '10px',
                }}
            >
                {titleMapping[state?.mode]?.subtitle}
            </Paragraph>
        </Flex>
    </Flex>
);

let ws;
export const queryDocument = async (state, updateState, stateRef) => {
    const newState = {
        ...stateRef?.current,
        loading: true,
        chatBotAnswer: '',
        chatBotError: '',
        documentToQuery: {
            ...stateRef?.current.documentToQuery,
            history: [
                ...stateRef.current.documentToQuery.history,
                {
                    type: 'question',
                    text: stateRef?.current.documentQuery,
                    timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                },
            ],
        },
    };
    const TheChatQuery = {
        document_ids: stateRef?.current?.selectedDocs || [],
        chat_id: stateRef?.current.documentToQuery.id,
        question: stateRef?.current.documentQuery,
    };

    console.log('chat query:', TheChatQuery);
    ws.send(JSON.stringify(TheChatQuery));

    updateState({ ...newState, documentQuery: '', error: null });
};

const Definitions = ({
    dataLoading,
    refreshCoreData,
    userData,
    accountSummary,
    isMobile,
    dismissTopMenu,
    logout,
    timestampOfLastDataLoad,
}) => {
    const [state, updateState] = useState(defaultState);
    const [errorState, updateErrorState] = useState(defaultErrorState);
    const [user, updateUser] = useState(userData);
    const navigate = useNavigate();

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        (async () => {
            try {
                const { attributes } = await Auth.currentAuthenticatedUser();
                const session = await Auth.currentSession();

                updateUser(attributes);
                await refreshCoreData();
                const { definitionsOffset, definitionsLimit } = state;

                const {
                    documents,
                    labels: selectLabels,
                    total: docTotal,
                } = await loadDocuments(state.docOffset, state.docLimit);
                const { total: definitionsTotal, definitions } = await loadDefinitions(
                    definitionsOffset,
                    definitionsLimit
                );

                ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${session.getAccessToken().getJwtToken()}`);
                ws.onopen = function open() {
                    console.log('connected');
                };
                ws.onclose = function close() {
                    console.log('disconnected');
                };
                ws.onmessage = function incoming({ data }) {
                    console.log(data);
                    if (typeof data === 'string') {
                        const { search_result } = JSON.parse(data);

                        console.log('ref : ', stateRef.current);
                        updateState({
                            ...stateRef.current,
                            loading: false,
                            chatBotError: '',
                            chatBotAnswer: search_result,
                            documentToQuery: {
                                ...stateRef.current.documentToQuery,
                                history: [
                                    ...stateRef.current.documentToQuery.history,
                                    {
                                        type: 'answer',
                                        text: search_result,
                                        timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                                    },
                                ],
                            },
                        });
                    }
                };

                return updateState({
                    ...state,
                    forceUploadDocs: documents?.length < 1,
                    documents,
                    selectLabels,
                    shareEmails: attributes?.email,
                    docTotal,
                    definitionsTotal,
                    definitions,
                    loading: false,
                    initialLoad: false,
                });
            } catch (e) {
                console.log(e);
                navigate('/');
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    const { definitionsOffset, definitionsLimit } = state;
                    const { total: definitionsTotal, definitions } = await loadDefinitions(
                        definitionsOffset,
                        definitionsLimit
                    );

                    return updateState({ ...state, loading: false, definitions, definitionsTotal });
                }
            } catch (e) {
                //swallow
            }
        })();
    }, [state.definitionsOffset]);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    const {
                        documents,
                        labels: selectLabels,
                        total: docTotal,
                    } = await loadDocuments(state.docOffset, state.docLimit);

                    return updateState({ ...state, loading: false, selectLabels, docTotal, documents });
                }
            } catch (e) {
                //swallow
            }
        })();
    }, [state.docOffset]);

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: 'primary',
                    position: 'relative',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Definitions" accountSummary={accountSummary} user={user} copy={{}} />
                    {dataLoading && (
                        <Flex
                            sx={{
                                width: '100%',
                                height: '100vh',
                                zIndex: '9999',
                                position: 'absolute',
                            }}
                        >
                            <Spinner />
                        </Flex>
                    )}

                    {state.loading && <Spinner />}

                    <Flex
                        sx={{
                            width: 'calc(100% - 110px)',
                            border: '0px solid lightgrey',
                            minHeight: 'calc(100vh)',
                            backgroundColor: '#ffffff',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mt: '15px',
                            ml: '120px',
                        }}
                    >
                        <>
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    ml: '50px',
                                    position: 'relative',
                                    width: '1200px',
                                }}
                            >
                                {state?.mode === 'list' && !state?.forceUploadDocs && (
                                    <Button
                                        variant="primary"
                                        sx={{
                                            width: '180px',
                                            position: 'absolute',
                                            top: '40px',
                                            right: '0px',
                                            backgroundColor: 'primary',
                                        }}
                                        data-testid="submit"
                                        onClick={async () => updateState({ ...state, mode: 'create' })}
                                    >
                                        New
                                        <i
                                            style={{
                                                marginTop: '0px',
                                                fontSize: '13px',
                                                marginLeft: '9px',
                                            }}
                                            className={`fas fa-plus-circle`}
                                        />
                                    </Button>
                                )}
                                <Title state={state} />
                            </Flex>

                            <Flex
                                sx={{
                                    width: '1200px',
                                    minHeight: '500px',
                                    ml: '50px',
                                    flexDirection: 'row',
                                    border: '0px red solid',
                                    mt: '20px',
                                }}
                            >
                                {state?.mode === 'list' && state?.definitions?.length > 0 && !state.loading && (
                                    <List
                                        state={state}
                                        updateState={updateState}
                                        isMobile={isMobile}
                                        navigate={navigate}
                                    />
                                )}

                                {state?.forceUploadDocs && (
                                    <Box sx={{ mt: '0px', ml: '0px' }}>
                                        <Paragraph sx={{ mt: '50px' }}>
                                            You need to upload some files before you can do this.
                                        </Paragraph>
                                        <Button
                                            variant="primary"
                                            sx={{
                                                width: '180px',
                                                mt: '30px',
                                                backgroundColor: 'primary',
                                            }}
                                            data-testid="submit"
                                            onClick={async () => navigate('/')}
                                        >
                                            Upload Files
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '13px',
                                                    marginLeft: '9px',
                                                }}
                                                className={`fas fa-plus-circle`}
                                            />
                                        </Button>
                                    </Box>
                                )}
                                {!state?.forceUploadDocs &&
                                    state?.mode === 'list' &&
                                    state?.definitions?.length < 1 &&
                                    !state.loading && (
                                        <Paragraph sx={{ mt: '50px' }}>
                                            To get started you need to create your first Draft.
                                        </Paragraph>
                                    )}

                                {state?.mode === 'create' && !state.loading && (
                                    <Create
                                        state={state}
                                        updateState={updateState}
                                        isMobile={isMobile}
                                        navigate={navigate}
                                        errorState={errorState}
                                        updateErrorState={updateErrorState}
                                    />
                                )}

                                {state?.mode === 'edit' && (
                                    <Edit
                                        state={state}
                                        updateState={updateState}
                                        isMobile={isMobile}
                                        navigate={navigate}
                                        errorState={errorState}
                                        updateErrorState={updateErrorState}
                                        stateRef={stateRef}
                                        user={user}
                                        queryDocument={queryDocument}
                                    />
                                )}
                            </Flex>
                            {state.error && (
                                <Paragraph
                                    sx={{
                                        color: 'red',
                                        minHeight: '20px',
                                        mt: '20px',
                                        width: '100%',
                                        textAlign: 'left',
                                        ml: '50px',
                                        fontWeight: '600',
                                    }}
                                >
                                    {state.error}
                                </Paragraph>
                            )}
                            <Box sx={{ height: '150px' }} />
                        </>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Definitions);
