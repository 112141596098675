import { debounce } from 'debounce';
import ReactSelect from 'react-select';
import { useState } from 'react';
import { Box, Button, Flex, Input, Label, Paragraph } from 'theme-ui';
import {
    TableHeaderDocList,
    TableHeaderDocOnlyList,
    TableHeaderUploads,
    TableRowDocList,
    TableRowDocOnlyList,
    TableRowUploads,
} from '../table';
import { GetDocUrlViaDocId } from './load-uploads';
import { renameSingleFile, deleteSingleFile } from './load-docs';
import { PgPagination } from './paging';
import Overlay from '../overlay/overlay';

import { loadDocuments } from './load-docs';

export const UploadList = ({ state, updateState, deleteFile, scrollLatestIntoView, navigate, isMobile }) => (
    <Flex
        sx={{
            flexDirection: 'column',
            ml: '50px',
            width: '1300px',
        }}
    >
        <Flex
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                mb: '30px',
            }}
        >
            <Flex sx={{ flexDirection: 'column' }}>
                <Paragraph
                    sx={{
                        fontWeight: '600',
                        fontSize: '25px',
                        color: 'primary',
                        textAlign: 'left',
                        mt: '30px',
                    }}
                >
                    Query Your Data
                </Paragraph>
                <Paragraph
                    sx={{
                        fontSize: '15px',
                        color: 'primary',
                        textAlign: 'left',
                        mt: '10px',
                    }}
                >
                    Create custom chatbots from your uploaded documents.
                </Paragraph>
            </Flex>
            <Button
                onClick={() => navigate('/upload')}
                sx={{
                    backgroundColor: 'primary',
                    mt: '40px',
                    fontSize: '15px',
                    width: '190px',
                }}
            >
                Document Upload
                <i
                    style={{
                        marginTop: '0px',
                        fontSize: '13px',
                        marginLeft: '9px',
                    }}
                    className={`fas fa-chevron-up`}
                />
            </Button>
        </Flex>

        <Flex
            sx={{
                mt: '90px',
                width: '100%',
                minHeight: ['auto', '370px'],
                border: '1px solid #eaecf0',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderTopRightRadius: 12,
                borderTopLeftRadius: 12,
            }}
        >
            <TableHeaderUploads isMobile={isMobile} />
            {state.uploads.map((item, i) => {
                return (
                    <TableRowUploads
                        isMobile={isMobile}
                        key={`table_row_${i}`}
                        item={item}
                        i={i}
                        navigate={navigate}
                        scrollLatestIntoView={scrollLatestIntoView}
                        state={state}
                        updateState={updateState}
                        deleteFile={deleteFile}
                    />
                );
            })}
        </Flex>

        <PgPagination
            currentState={state}
            updateState={updateState}
            totalPages={state.total ? Math.ceil(state.total / state.limit) : 1}
            totalRecords={state.total}
        />
    </Flex>
);

const fileIconMapping = {
    pdf: 'file-pdf',
    docx: 'file-word',
    txt: 'text',
    mp3: 'file-audio',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'text/csv',
};

export const DocumentsAndUploads = ({
    state,
    updateState,
    deleteFile,
    scrollLatestIntoView,
    navigate,
    isMobile,
    userData,
}) => (
    <Flex
        sx={{
            flexDirection: 'column',
            ml: '0px',
            mt: '50px',
            width: '1300px',
        }}
    >
        <Flex
            sx={{
                mt: '0px',
                width: '100%',
                minHeight: ['auto', '70px'],
                border: '1px solid #eaecf0',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderTopRightRadius: 12,
                borderTopLeftRadius: 12,
            }}
        >
            <TableHeaderDocList isMobile={isMobile} />

            {state.uploads.map((item, i) => {
                return (
                    <TableRowDocList
                        isMobile={isMobile}
                        key={`table_row_${i}`}
                        item={item}
                        i={i}
                        navigate={navigate}
                        scrollLatestIntoView={scrollLatestIntoView}
                        state={state}
                        updateState={updateState}
                        deleteFile={deleteFile}
                    />
                );
            })}
        </Flex>

        <PgPagination
            currentState={state}
            updateState={updateState}
            totalPages={state.total ? Math.ceil(state.total / state.limit) : 1}
            totalRecords={state.total}
        />
    </Flex>
);

const filterDocuments = async (state, updateState, partialLoading = false) => {
    updateState({ ...state, loading: !partialLoading, partialLoading: true });
    const { docSearchDescription, docSearchLabels } = state;
    const description = docSearchDescription !== '' ? docSearchDescription : false;
    const labelParam = docSearchLabels?.map((el) => el?.value) || [];

    const {
        documents,
        labels,
        total: docTotal,
    } = await loadDocuments(0, state?.docLimit, description, labelParam, null, state.statuses);

    return updateState({
        ...state,
        loading: false,
        partialLoading: false,
        documents,
        docOffset: 0,
        docLimit: state?.docLimit,
        selectLabels: labels,
        docTotal,
    });
};

const Title = ({ text, subtitle }) => {
    return (
        <Box>
            <Paragraph sx={{ fontSize: '18px', fontWeight: '600', color: 'primary' }}>{text}</Paragraph>
            {subtitle && (
                <Paragraph sx={{ fontSize: '13px', fontWeight: '400', color: 'primary' }}>{subtitle}</Paragraph>
            )}
        </Box>
    );
};

export const Filters = ({ state, updateState, mini = false, showAddDocument = true }) => {
    const handleDescriptionChange = debounce(async (e) => {
        const tempState = { ...state, docSearchDescription: e.target.value };
        updateState(tempState);
        await filterDocuments(tempState, updateState, mini);
    }, 500);

    return (
        <Flex
            sx={{
                width: mini ? '400px' : '100%',
                border: '0px red solid',
                mb: '20px',
                flexDirection: mini ? 'column' : 'row',
                mt: -130,
            }}
        >
            <Flex sx={{ height: 65, mt: 20, alignItems: 'center' }}>
                <Flex sx={{ flexDirection: 'column', width: mini ? '200px' : '315px', mr: mini ? '20px' : 0, mt: 35 }}>
                    <Label id="file-name-label" sx={{ m: 0 }}>
                        Search by file name
                    </Label>
                    <Input
                        aria-labelledby="file-name-label"
                        sx={{
                            mt: 10,
                            mb: '25px',
                            width: mini ? '200px' : '300px',
                            background: '#FFFFFF',
                            border: '1px solid #a3a69b',
                            borderRadius: '10px',
                            height: '40px',
                            fontSize: '14px',
                        }}
                        type="text"
                        id="docSearchDescription"
                        data-testid="docSearchDescription"
                        name="docSearchDescription"
                        label=""
                        placeholder={'Search by file name'}
                        onChange={handleDescriptionChange}
                        error={null}
                    />
                </Flex>
                {state?.selectLabels?.length > 0 && (
                    <Flex sx={{ flexDirection: 'column', width: mini ? '200px' : '315px', mt: 20 }}>
                        <Label id="labels-label" sx={{ m: 0 }}>
                            Search by labels
                        </Label>
                        <ReactSelect
                            aria-labelledby="labels-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: mini ? '200px' : '300px',
                                    height: '40px',
                                    marginTop: 10,
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (value) => {
                                const tempState = { ...state, docSearchLabels: value };
                                await filterDocuments(tempState, updateState, mini);
                            }}
                            placeholder={mini ? 'Select' : 'Select document labels'}
                            value={state?.docSearchLabels}
                            options={state?.selectLabels.map((item) => ({
                                value: item,
                                label: item,
                            }))}
                            isMulti
                        />
                    </Flex>
                )}
            </Flex>
            <Flex sx={{ alignItems: 'center', mt: 52 }}>
                <Button
                    variant="primary"
                    sx={{
                        width: mini ? '92px' : '110px',
                        height: '40px',
                        backgroundColor: 'white',
                        borderRadius: 10,
                        color: '#444',
                        border: '1px solid #a3a69b',
                        fontSize: '13px',
                    }}
                    data-testid="submit"
                    onClick={async () => {
                        const tempState = { ...state, docSearchLabels: [], docSearchDescription: '' };
                        document.getElementById('docSearchDescription').value = '';
                        await filterDocuments(tempState, updateState, mini);
                    }}
                >
                    Clear
                </Button>
                {showAddDocument && state?.componentToShow !== 'addNew' && state?.componentToShow !== '' && (
                    <Button
                        variant="primary"
                        sx={{
                            width: '180px',
                            backgroundColor: 'primary',
                            fontSize: 14,
                            ml: 15,
                            height: 40,
                        }}
                        data-testid="submit"
                        onClick={async () => updateState({ ...state, componentToShow: 'addNew' })}
                    >
                        Add Document
                    </Button>
                )}
            </Flex>
        </Flex>
    );
};

export const DocumentList = ({ state, updateState, deleteFile, scrollLatestIntoView, navigate, isMobile }) => (
    <Flex
        sx={{
            flexDirection: 'column',
            ml: '0px',
            mt: '20px',
            width: '1300px',
        }}
    >
        <Filters state={state} updateState={updateState} />
        <Flex
            sx={{
                mt: '0px',
                width: '100%',
                minHeight: ['auto', '70px'],
                border: '1px solid #eaecf0',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderTopRightRadius: 12,
                borderTopLeftRadius: 12,
            }}
        >
            <TableHeaderDocOnlyList isMobile={isMobile} />
            {state.documents.map((item, i) => {
                return (
                    <TableRowDocOnlyList
                        isMobile={isMobile}
                        key={`table_row_${i}`}
                        item={item}
                        i={i}
                        navigate={navigate}
                        scrollLatestIntoView={scrollLatestIntoView}
                        state={state}
                        updateState={updateState}
                        deleteFile={deleteFile}
                    />
                );
            })}
        </Flex>
        <PgPagination
            currentState={state}
            updateState={updateState}
            totalPages={state.docTotal ? Math.ceil(state.docTotal / state.docLimit) : 1}
            totalRecords={state.docTotal}
            type="docsOnly"
        />
    </Flex>
);

const DrillDownTitle = ({ text, subtitle }) => {
    return (
        <Box sx={{ mt: '25px' }}>
            <Paragraph sx={{ fontSize: '15px', fontWeight: '600', color: 'primary' }}>{text}</Paragraph>
            {subtitle && (
                <Paragraph sx={{ fontSize: '12px', fontWeight: '400', color: 'primary' }}>{subtitle}</Paragraph>
            )}
        </Box>
    );
};

const TextAndIcon = ({ label, value, icon = 'google' }) => (
    <Flex>
        {label && (
            <Paragraph
                sx={{ fontSize: '13px', fontWeight: '600', mt: '15px', mr: '15px', textTransform: 'capitalize' }}
            >
                {label}
            </Paragraph>
        )}
        <Paragraph
            sx={{
                fontSize: '13px',
                fontWeight: '400',
                mt: '7px',
                textTransform: 'capitalize',
            }}
        >
            {icon && (
                <i
                    style={{
                        marginTop: '0px',
                        marginRight: '12px',
                        fontSize: '15px',
                    }} // @ts-ignore
                    className={`far fa-${icon}`}
                />
            )}
            {value}
        </Paragraph>
    </Flex>
);

const TextIconAndAction = ({ text, icon, action }) => (
    <Flex
        sx={{ height: '30px', justifyContent: 'flex-start', cursor: 'pointer' }}
        onClick={() => {
            action();
        }}
    >
        <Paragraph
            sx={{
                fontSize: '13px',
                fontWeight: '400',
                mt: '15px',
                textTransform: 'capitalize',
            }}
        >
            <i
                style={{
                    marginTop: '0px',
                    marginRight: '6px',
                    fontSize: '15px',
                }} // @ts-ignore
                className={`${icon}`}
            />
            {text}
        </Paragraph>
        <Paragraph
            sx={{
                fontSize: '14px',
                fontWeight: '600',
                mt: '13px',
                ml: '40px',
            }}
        >
            <i
                style={{
                    marginTop: '0px',
                    fontSize: '14px',
                }} // @ts-ignore
                className={`fas fa-external-link`}
            />
        </Paragraph>
    </Flex>
);
const DocumentTemplates = ({ item }) => {
    const data = item?.document_templates[0];
    const baseGoogleDocUrl = 'https://docs.google.com/document/d/';
    const googleDocUrl = data ? data?.original_google_doc_id : null;
    const copiedGoogleDocUrl = data ? `${baseGoogleDocUrl}${data?.copied_google_doc_id}` : null;
    const templateType = data?.type;
    if (templateType === 'google' && !copiedGoogleDocUrl && !googleDocUrl) return null;

    if (templateType === 'google') {
        return (
            <Flex sx={{ flexDirection: 'column' }}>
                <DrillDownTitle text="Google Docs" />
                {googleDocUrl && (
                    <TextIconAndAction
                        text="Original Google Doc"
                        icon="fab fa-google"
                        action={() => window.open(googleDocUrl)}
                    />
                )}
                {copiedGoogleDocUrl && (
                    <TextIconAndAction
                        text="Generated Google Doc"
                        icon="fab fa-google"
                        action={() => window.open(copiedGoogleDocUrl)}
                    />
                )}
            </Flex>
        );
    }
};

export const UploadDrilledDownView = ({ item }) => {
    const status = item.processing_status || 'error';
    const templateType = item?.document_templates[0]?.type;

    return (
        <Flex
            sx={{
                flex: 1,
                minHeight: '350px',
                border: '0px solid red',
                borderBottom: '1px solid #eaecf0',
                background: '#f9fafb',
            }}
        >
            <Flex sx={{ flex: 1, minHeight: '50px', ml: '50px', flexDirection: 'column', mb: '20px' }}>
                <DrillDownTitle text="Type" />
                <TextAndIcon value={`${templateType} - ${item?.type?.replace(/_/g, ' ')}`} icon={false} />

                <DrillDownTitle text="Status" />
                {/* <TextAndIcon value={item?.processing_status?.replace(/_/g, ' ')} icon={false} /> */}
                <TextAndIcon value={item?.document_templates[0]?.status} icon={false} />

                <DocumentTemplates item={item} />

                {item?.share_with?.length > 0 && (
                    <Flex sx={{ flexDirection: 'column' }}>
                        <DrillDownTitle text="Shared with:" />
                        {item?.share_with?.map((email) => (
                            <TextAndIcon key={email} value={email} icon="envelope" />
                        ))}
                    </Flex>
                )}

                {item?.existing_document_uploads?.length > 0 && (
                    <>
                        <DrillDownTitle text="Documents used" />
                        {item?.existing_document_uploads?.map((doc) => {
                            const showLinkIcon = doc?.processing_status === 'PROCESSED';
                            const DocId = doc?.id;
                            return (
                                <Flex
                                    key={`${DocId}`}
                                    sx={{
                                        height: '30px',
                                        justifyContent: 'flex-start',
                                        cursor: showLinkIcon ? 'pointer' : 'not-allowed',
                                    }}
                                    onClick={async () => {
                                        if (showLinkIcon) {
                                            const data = await GetDocUrlViaDocId(DocId);
                                            window.open(data?.url);
                                        }
                                    }}
                                >
                                    <Paragraph
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: '400',
                                            mt: '15px',
                                            textTransform: 'capitalize',
                                            color: status === 'PROCESSED' ? 'inherit' : '#BBB',
                                        }}
                                    >
                                        <i
                                            style={{
                                                marginTop: '0px',
                                                marginRight: '10px',
                                                fontSize: '15px',
                                                color: status === 'PROCESSED' ? 'inherit' : '#BBB',
                                            }} // @ts-ignore
                                            className={`fas fa-${fileIconMapping[doc?.file_extension]}`}
                                        />{' '}
                                        {doc?.file_name?.toLowerCase() || 'file name unavailable'}
                                    </Paragraph>
                                    <Paragraph
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            mt: '13px',
                                            ml: '40px',
                                        }}
                                    >
                                        {showLinkIcon && (
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '14px',
                                                    color: status === 'PROCESSED' ? 'inherit' : '#BBB',
                                                }} // @ts-ignore
                                                className={`fas fa-${
                                                    status === 'PROCESSED' ? 'external-link' : 'times-circle'
                                                }`}
                                            />
                                        )}
                                    </Paragraph>
                                </Flex>
                            );
                        })}
                    </>
                )}

                {item?.document_uploads?.length > 0 && (
                    <>
                        <DrillDownTitle text="Output file" />
                        {item?.document_uploads?.map((doc) => {
                            const DocId = doc?.id;
                            const showLinkIcon = doc?.processing_status === 'PROCESSED';

                            return (
                                <Flex
                                    key={`${DocId}`}
                                    sx={{
                                        height: '30px',
                                        justifyContent: 'flex-start',
                                        cursor: showLinkIcon ? 'pointer' : 'not-allowed',
                                    }}
                                    onClick={async () => {
                                        if (showLinkIcon) {
                                            const data = await GetDocUrlViaDocId(DocId);
                                            window.open(data?.url);
                                        }
                                    }}
                                >
                                    <Paragraph
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: '400',
                                            mt: '15px',
                                            textTransform: 'capitalize',
                                            color: status === 'PROCESSED' ? 'inherit' : '#BBB',
                                        }}
                                    >
                                        <i
                                            style={{
                                                marginTop: '0px',
                                                marginRight: '10px',
                                                fontSize: '15px',
                                                color: status === 'PROCESSED' ? 'inherit' : '#BBB',
                                            }} // @ts-ignore
                                            className={`fas fa-${fileIconMapping[doc?.file_extension]}`}
                                        />{' '}
                                        {doc?.file_name?.toLowerCase() || 'file name unavailable'}
                                    </Paragraph>
                                    <Paragraph
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            mt: '13px',
                                            ml: '40px',
                                        }}
                                    >
                                        {showLinkIcon && (
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '14px',
                                                    color: status === 'PROCESSED' ? 'inherit' : '#BBB',
                                                }} // @ts-ignore
                                                className={`fas fa-${
                                                    status === 'PROCESSED' ? 'external-link' : 'times-circle'
                                                }`}
                                            />
                                        )}
                                    </Paragraph>
                                </Flex>
                            );
                        })}
                    </>
                )}

                <Box sx={{ mt: '20px' }} />
            </Flex>
        </Flex>
    );
};

const deleteFile = async (id, state, updateState) => {
    updateState({ ...state, loading: true });
    await deleteSingleFile(id);
    window.location.reload();
};

const renameFile = async (id, state, updateState, toggleRenamed) => {
    const name = state?.renameFile;

    if (name !== '') {
        updateState({ ...state, loading: true });
        const theDocuments = state?.documents;
        const theDoc = theDocuments.find((item) => item?.id === id);
        theDoc.file_name = name;

        await renameSingleFile(id, name);
        updateState({ ...state, loading: false, documents: theDocuments });
        toggleRenamed(true);
        setTimeout(() => {
            toggleRenamed(false);
        }, 2000);
    }
};

export const UploadDrilledDownViewDoc = ({ item, state, updateState }) => {
    const fileName = item?.file_name || item?.description || 'name unavailable';
    const DocId = item?.id;
    const processed = item.processing_status === 'PROCESSED';
    const [renamed, toggleRenamed] = useState(false);

    return (
        <Flex
            sx={{
                flex: 1,
                minHeight: '350px',
                border: '0px solid red',
                borderBottom: '1px solid #eaecf0',
                background: '#f9fafb',
            }}
        >
            {state.showDeleteDocOverlay && (
                <Overlay
                    size="small"
                    updateOverlay={() => updateState({ ...state, showDeleteDocOverlay: false })}
                    embeddedComponent={
                        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                            <Paragraph
                                sx={{ fontSize: 16, fontWeight: 400, mt: 70, textAlign: 'center', ml: 20, mr: 20 }}
                            >
                                Are you sure you wish to delete this file ?
                            </Paragraph>
                            <Flex sx={{ width: 150, mt: 50, justifyContent: 'space-between' }}>
                                <Button
                                    sx={{
                                        backgroundColor: 'white',
                                        color: '#000',
                                        border: '1px solid #CCC',
                                        width: 70,
                                    }}
                                    onClick={() => updateState({ ...state, showDeleteDocOverlay: false })}
                                >
                                    No
                                </Button>
                                <Button
                                    sx={{ backgroundColor: 'primary', width: 70 }}
                                    onClick={() => deleteFile(DocId, state, updateState)}
                                >
                                    Yes
                                </Button>
                            </Flex>
                        </Flex>
                    }
                />
            )}
            <Flex sx={{ flex: 1, minHeight: '50px', ml: '50px', flexDirection: 'column', mb: '20px' }}>
                <DrillDownTitle text="View" />
                {processed && (
                    <TextIconAndAction
                        text={fileName}
                        icon={`${processed ? 'fal fa-file' : 'fal fa-cross'}`}
                        action={async () => {
                            if (processed) {
                                const data = await GetDocUrlViaDocId(DocId);
                                window.open(data?.url);
                            }
                        }}
                    />
                )}
                {!processed && (
                    <Paragraph
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            mt: '15px',
                            mr: '15px',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                        }}
                    >
                        File unavailable.
                    </Paragraph>
                )}
                <DrillDownTitle text="Status" />
                <TextAndIcon value={item?.processing_status?.replace(/_/g, ' ').toLowerCase()} icon={false} />

                {processed && (
                    <>
                        <DrillDownTitle text="Rename File" />
                        {!renamed && (
                            <Flex>
                                <Input
                                    sx={{
                                        mt: '10px',
                                        mb: '25px',
                                        width: '300px',
                                        background: '#FFFFFF',
                                        border: '1px solid #a3a69b',
                                        borderRadius: '10px',
                                        height: '40px',
                                        fontSize: '14px',
                                    }}
                                    type="text"
                                    id="renameFile"
                                    data-testid="renameFile"
                                    name="renameFile"
                                    label=""
                                    value={state?.renameFile || fileName}
                                    placeholder="File name ..."
                                    onChange={(e) => {
                                        updateState({ ...state, renameFile: e.target.value });
                                    }}
                                    error={null}
                                />
                                <Button
                                    onClick={() => renameFile(DocId, state, updateState, toggleRenamed)}
                                    sx={{
                                        mt: '10px',
                                        ml: '20px',
                                        fontSize: '13px',
                                        height: '40px',
                                        width: '130px',
                                        backgroundColor: 'white',
                                        color: 'primary',
                                        border: '1px solid gray',
                                        borderRadius: 10,
                                    }}
                                >
                                    Rename
                                </Button>
                            </Flex>
                        )}
                        {renamed && (
                            <Paragraph
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    textAlign: 'left',
                                    height: '40px',
                                    width: '300px',
                                    ml: 0,
                                    mt: '10px',
                                    color: 'green',
                                }}
                            >
                                File renamed successfully
                            </Paragraph>
                        )}
                    </>
                )}

                <Button
                    onClick={() => updateState({ ...state, showDeleteDocOverlay: true })}
                    sx={{
                        mt: '25px',
                        // ml: '20px',
                        fontSize: '13px',
                        height: '40px',
                        width: '120px',
                        backgroundColor: 'white',
                        color: 'primary',
                        border: '1px solid gray',
                        borderRadius: 10,
                    }}
                >
                    Delete File
                </Button>

                <Box sx={{ mt: '20px' }} />
            </Flex>
        </Flex>
    );
};
