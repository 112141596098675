/* eslint-disable no-undef */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Flex, Input, Paragraph, Textarea } from 'theme-ui';
import Divider from '../divider';
import Overlay from '../overlay/overlay';
import { generatePdf } from './generate-invoice';
import { loadInvoices } from './invoices';
import uniqueByKey from '../../utils/unique-by-key';

export const vatOptions = [
    { value: '20', label: '20% Standard Rated', multiplier: 1.2 },
    { value: '0', label: '0% Zero Rated', multiplier: 1 },
    { value: '0_exempt', label: '0% Exempt', multiplier: 1 },
    { value: 'no-vat', label: 'No VAT', multiplier: 1 },
];

export const replaceTokens = (str, state) => {
    if (state.clientInView.name) {
        str = str?.replace(/{clientName}/g, state.clientInView.name);
    }
    if (state.invoice?.model?.bank_details?.payable_by) {
        str = str?.replace(/{dueDate}/g, state.invoice?.model?.bank_details?.payable_by);
    }
    if (state.invoice?.invoice_number) {
        str = str?.replace(/{invoiceNumber}/g, state.invoice?.invoice_number);
    }
    if (state.invoice?.model.totals.total) {
        str = str?.replace(/{totalDue}/g, state.invoice?.model.totals.total);
    }
    return str;
};

const EmailDialog = ({ state, updateState }) => {
    const ccOptions = uniqueByKey(
        state.clientInView.relations?.map((x) => ({
            value: x.email_address,
            label: `${x.first_name} (${x.email_address})`,
        })),
        'email_address'
    );

    const [localState, updateLocalState] = useState({
        toOptions: state.clientInView.client_data?.key_contacts?.map((x) => ({
            value: x.email_address,
            label: `${x.first_name} ${x.last_name} (${x.email_address})`,
        })),
        ccOptions,
        bcc: [...ccOptions],
        to: [],
        subject: replaceTokens(
            state.clientInView.organisation?.default_visualisation?.invoiceOptions?.defaultSubjectLine,
            state
        ),
        body: replaceTokens(
            state.clientInView.organisation?.default_visualisation?.invoiceOptions?.defaultInvoiceBody,
            state
        ),
    });
    return (
        <Flex sx={{ flexDirection: 'column', m: 15 }}>
            <Paragraph sx={{ fontSize: 18 }}>Email Invoice</Paragraph>
            <Divider />
            <Flex sx={{ alignItems: 'center' }}>
                <Paragraph sx={{ mr: 10, mt: -25, width: 30 }}>To</Paragraph>
                <CreatableSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '480px',
                            minHeight: '40px',
                            borderRadius: 10,
                            marginBottom: '30px',
                            border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={async (c) => {
                        updateLocalState({
                            ...localState,
                            to: c,
                            emailError: null,
                        });
                    }}
                    isMulti
                    placeholder={'Select the contacts to be emailed'}
                    value={localState.to}
                    options={localState.toOptions}
                />
            </Flex>
            <Flex sx={{ alignItems: 'center' }}>
                <Paragraph sx={{ mr: 10, mt: -25, width: 30 }}>Cc</Paragraph>
                <CreatableSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '480px',
                            minHeight: '40px',
                            borderRadius: 10,
                            marginBottom: '30px',
                            border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={async (c) => {
                        updateLocalState({
                            ...localState,
                            cc: c,
                            emailError: null,
                        });
                    }}
                    isMulti
                    placeholder={"Select the contacts to be cc'd"}
                    value={localState.cc}
                    options={[...localState.toOptions, ...localState.ccOptions]}
                />{' '}
            </Flex>
            <Flex sx={{ alignItems: 'center' }}>
                <Paragraph sx={{ mr: 10, mt: -25, width: 30 }}>Bcc</Paragraph>
                <CreatableSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '480px',
                            minHeight: '40px',
                            borderRadius: 10,
                            marginBottom: '30px',
                            border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={async (c) => {
                        updateLocalState({
                            ...localState,
                            bcc: c,
                            emailError: null,
                        });
                    }}
                    isMulti
                    placeholder={"Select the contacts to be bcc'd"}
                    value={localState.bcc}
                    options={localState.ccOptions}
                />{' '}
            </Flex>
            <Paragraph sx={{ mb: 10 }}>Subject</Paragraph>
            <Input
                onChange={(e) => updateLocalState({ ...localState, subject: e.target.value })}
                value={localState.subject}
                sx={{ mb: 10 }}
            />
            <Paragraph sx={{ mb: 10 }}>Body</Paragraph>
            <ReactQuill
                theme="snow"
                value={localState.body}
                style={{ height: 300, width: 560 }}
                onChange={(val) =>
                    updateLocalState({
                        ...localState,
                        body: val,
                    })
                }
            />
            <Flex sx={{ position: 'absolute', bottom: 20 }}>
                <Button
                    sx={{ width: 100, height: 40, fontSize: 14 }}
                    onClick={async () => {
                        try {
                            if (!localState.to?.length) {
                                return updateLocalState({
                                    ...localState,
                                    emailError: true,
                                });
                            }
                            updateLocalState({
                                ...localState,
                                error: null,
                            });
                            updateState({ ...state, loading: true });
                            await axios({
                                url: `${process.env.REACT_APP_AQRU_AI_API}/invoices/${state.invoice.uuid}/email`,
                                method: 'POST',
                                data: {
                                    to: localState.to?.map((x) => x.value?.trim().toLowerCase()),
                                    cc: localState.cc?.map((x) => x.value?.trim().toLowerCase()),
                                    bcc: localState.bcc?.map((x) => x.value?.trim().toLowerCase()),
                                    subject: localState.subject,
                                    body: localState.body,
                                },
                            });
                            updateState({ ...state, loading: false, showEmailDialog: false });
                        } catch (e) {
                            updateState({ ...state, loading: false });
                            toast('We are unable to send your email at this time, please try again later', {
                                type: 'error',
                            });
                        }
                    }}
                >
                    Send
                </Button>
                <Button
                    onClick={() => updateState({ ...state, showEmailDialog: false })}
                    variant="light"
                    sx={{ ml: 10, width: 100, height: 40, fontSize: 14 }}
                >
                    Cancel
                </Button>
            </Flex>
            {localState.error && <Paragraph sx={{ mt: 20, color: 'red' }}>{localState.error}</Paragraph>}
        </Flex>
    );
};

const pdfColumnsDefaults = [
    { value: 'date', label: 'Date' },
    { value: 'service', label: 'Service' },
    { value: 'description', label: 'Description' },
    { value: 'time_spent', label: 'Time Spent' },
    { value: 'rate', label: 'Rate' },
    { value: 'vat', label: 'VAT' },
    { value: 'total', label: 'Total' },
];

const InvoiceOptions = ({ state, updateState, lineItems }) => {
    const [localState, updateLocalState] = useState({
        pdfColumns: pdfColumnsDefaults,
        invoiceNumber: '',
        bankDetails: {
            payableBy: state.invoice?.model?.payableBy
                ? state.invoice?.model?.payableBy
                : state?.organisation?.default_visualisation?.invoiceOptions?.dueDateDays
                ? moment()
                      .add(state?.organisation?.default_visualisation?.invoiceOptions?.dueDateDays, 'days')
                      .format('YYYY-MM-DD')
                : null,
        },
        customFields: [...(state?.organisation?.default_visualisation?.invoiceOptions?.customFields || [])],
        autoGenerateInvoiceNumber: true,
        invoiceDate: state.invoice?.model?.invoiceDate || moment().format('YYYY-MM-DD'),
        comment: state.invoice?.model?.comment || '',
    });

    const handleBankDetailsChange = (e) => {
        updateLocalState({
            ...localState,
            bankDetails: { ...localState.bankDetails, [e.target.name]: e.target.value },
        });
    };

    return (
        <Box sx={{ mt: 20 }}>
            {state.showEmailDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={600}
                    maxHeight={900}
                    embeddedComponent={<EmailDialog state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showEmailDialog: false })}
                />
            )}

            {localState.customFields?.map((field, fieldIdx) => (
                <Flex key={`cf_${fieldIdx}`} sx={{ flexDirection: 'column', mb: 3, width: 500 }}>
                    <Paragraph sx={{ mb: 10 }}>{field.label}</Paragraph>
                    {field.type.value === 'text_area' ? (
                        <Textarea
                            sx={{
                                height: 80,
                                backgroundColor: localState.customFields?.[fieldIdx]?.editable ? 'white' : '#EFEFEF',
                            }}
                            disabled={localState.customFields?.[fieldIdx]?.editable === false}
                            value={field?.editable ? field?.value || '' : replaceTokens(field?.defaultValue, state)}
                            onChange={(e) => {
                                if (!field.editable) return;

                                updateLocalState({
                                    ...localState,
                                    customFields: localState.customFields?.map((customField, ffIdx) => {
                                        if (ffIdx === fieldIdx) {
                                            customField.value = e.target.value;
                                        }
                                        return customField;
                                    }),
                                });
                            }}
                        />
                    ) : null}
                    {field.type.value !== 'text_area' ? (
                        <Input
                            sx={{
                                height: 50,
                                backgroundColor: localState.customFields?.[fieldIdx]?.editable ? 'white' : '#EFEFEF',
                            }}
                            type={field?.editable ? field.type?.value : 'text'}
                            disabled={localState.customFields?.[fieldIdx]?.editable === false}
                            value={field?.editable ? field?.value || '' : replaceTokens(field?.defaultValue, state)}
                            onChange={(e) =>
                                updateLocalState({
                                    ...localState,
                                    customFields: localState.customFields?.map((customField, ffIdx) => {
                                        if (ffIdx === fieldIdx) {
                                            customField.value = e.target.value;
                                        }
                                        return customField;
                                    }),
                                })
                            }
                        />
                    ) : null}
                </Flex>
            ))}

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="invoice-date">
                    Invoice Date
                </Paragraph>
                <Input
                    sx={{ height: 50 }}
                    id="invoice-date"
                    type="date"
                    value={localState.invoiceDate}
                    onChange={(e) => updateLocalState({ ...localState, invoiceDate: e.target.value })}
                />
            </Box>

            {!localState.autoGenerateInvoiceNumber && (
                <Box mb={4}>
                    <Paragraph sx={{ mb: 10 }} htmlFor="invoice-number">
                        Invoice number:
                    </Paragraph>
                    <Input
                        sx={{ height: 50 }}
                        id="invoice-number"
                        name="invoiceNumber"
                        value={localState.invoiceNumber}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoiceNumber: e.target.value,
                            })
                        }
                        placeholder="Invoice number"
                    />
                </Box>
            )}

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="payable-by">
                    Payable by:
                </Paragraph>
                <Input
                    sx={{ height: 50 }}
                    id="payable-by"
                    type="date"
                    name="payableBy"
                    value={localState.bankDetails.payableBy}
                    onChange={handleBankDetailsChange}
                />
            </Box>

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="cols_pdf">
                    Columns on invoice pdf
                </Paragraph>
                <ReactSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '480px',
                            minHeight: '40px',
                            borderRadius: 10,
                            marginBottom: '30px',
                            border: '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={async (value) => {
                        updateLocalState({
                            ...localState,
                            pdfColumns: value,
                        });
                    }}
                    isMulti
                    placeholder={'Select the columns'}
                    value={localState.pdfColumns}
                    options={pdfColumnsDefaults}
                />
            </Box>

            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Flex sx={{ ml: 150, alignItems: 'center' }}>
                    <Button
                        sx={{ width: 200 }}
                        onClick={async () => {
                            await generatePdf(state, updateState, lineItems, localState, state.setInvoices);
                        }}
                    >
                        <i style={{ marginRight: '7px' }} className="fa fa-file-pdf" />
                        Generate Invoice
                    </Button>
                    {state.invoice && (
                        <Button
                            sx={{ ml: 10, width: 200, height: 40 }}
                            variant="light"
                            onClick={async () => {
                                updateState({ ...state, showEmailDialog: true });
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-envelope"></i>Email Invoice
                        </Button>
                    )}
                    {state.invoice && (
                        <Button
                            sx={{ ml: 10, width: 200, height: 40 }}
                            variant="light"
                            onClick={async () => {
                                updateState({
                                    ...state,
                                    error: null,
                                    showDeleteInvoiceConfirmation: true,
                                    confirmCallback: async () => {
                                        updateState({ ...state, loading: true });

                                        try {
                                            await axios.delete(
                                                `${process.env.REACT_APP_AQRU_AI_API}/invoices/${state.invoice.uuid}`,
                                                {}
                                            );

                                            await loadInvoices(
                                                { ...state, error: null, invoice: null },
                                                updateState,
                                                state.setInvoices
                                            );

                                            toast('Invoice successfully deleted', {
                                                type: 'success',
                                                autoClose: 5000,
                                                position: 'top-right',
                                            });
                                        } catch (e) {
                                            updateState({
                                                ...state,
                                                loading: false,
                                            });
                                            toast('We are currently unable to delete your invoice', {
                                                type: 'error',
                                                autoClose: 5000,
                                                position: 'top-right',
                                            });
                                        }
                                    },
                                });
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-trash"></i>Delete Invoice
                        </Button>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default InvoiceOptions;
