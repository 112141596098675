import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Flex, Paragraph } from 'theme-ui';
import { saveComment } from './api';

const AddAComment = ({ state, updateState, page = 'task' }) => (
    <Flex sx={{ flexDirection: 'column', mt: 30, mb: 30, width: 400 }}>
        <Paragraph sx={{ mb: 20, mt: 20 }}>Add a comment</Paragraph>

        <ReactQuill
            theme="snow"
            value={state.newCommentText}
            onChange={(val) =>
                updateState({
                    ...state,
                    newCommentText: val,
                })
            }
        />

        <Flex sx={{ mt: 70, alignSelf: 'center', justifyContent: 'space-between', width: 320 }}>
            <Button sx={{ width: 150 }} onClick={() => saveComment(state, updateState, page)}>
                Save
            </Button>
            <Button
                sx={{
                    width: 150,
                    backgroundColor: 'white',
                    color: 'text',
                    border: '1px solid #CCC',
                }}
                onClick={() => updateState({ ...state, showSubmitCommentForm: false })}
            >
                Cancel
            </Button>
        </Flex>

        {state.commentError && (
            <Paragraph sx={{ mt: 20, color: 'red', alignSelf: 'center' }}>{state.commentError}</Paragraph>
        )}
    </Flex>
);

export default AddAComment;
