import { Box } from 'theme-ui';
import * as uuid from 'uuid';

let lastX;
let lastY;
let lastWidth;
let originalRatio;

export function resizeFont(svgTextElement, maxWidth, maxHeight) {
    let fontSize = parseFloat(svgTextElement.getAttribute('font-size').replace('px', ''));

    let textWidth = svgTextElement.getBBox().width;
    let textHeight = svgTextElement.getBBox().height;

    while ((textWidth > maxWidth || textHeight > maxHeight) && fontSize > 1) {
        fontSize--;
        svgTextElement.setAttribute('font-size', fontSize + 'px');
        textWidth = svgTextElement.getBBox().width;
        textHeight = svgTextElement.getBBox().height;
        svgTextElement.setAttribute('y', textHeight / 2);
    }

    // While the text is too small to fit, increase the font size
    while (textWidth < maxWidth && textHeight < maxHeight && fontSize < 100) {
        fontSize++;
        svgTextElement.setAttribute('font-size', fontSize + 'px');
        textWidth = svgTextElement.getBBox().width;
        textHeight = svgTextElement.getBBox().height;
        svgTextElement.setAttribute('y', textHeight / 2);
    }

    return { fontSize };
}

const PdfFrame = ({ localState, canvasRef, updateLocalState, drawImageOnCanvas, textInputSvg, updateState, state }) => {
    return (
        <Box
            sx={{
                padding: 10,
                border: localState.pdfs[localState.pdfIndex]?.pdfDocument ? '1px solid lightGrey' : 0,
                position: 'relative',
                width: localState.pdfs[localState.pdfIndex].pdfPageWidth + 2 || '500px',
                height: localState.pdfs[localState.pdfIndex].pdfPageHeight + 2 || '1px',
                mt: 20,
                cursor: 'grab',
            }}
            id={`canvas-wrapper-${localState.pdfIndex}`}
            onMouseDown={(event) => {
                event.preventDefault();

                if (event.target.id?.indexOf('delete_button') > -1) {
                    localState.pdfs[localState.pdfIndex].signatureLocations = localState.pdfs[
                        localState.pdfIndex
                    ].signatureLocations.filter((item) => {
                        if (item.uuid === event.target.id.replace('delete_button_', '')) {
                            return false;
                        }

                        return true;
                    });
                    updateLocalState({ ...localState });
                    event.target.parentNode.remove();
                    return;
                }

                let selectedElement = null;
                let offset = { x: 0, y: 0 };
                if (event.target.classList.contains('draggable-svg')) {
                    selectedElement = event.target;
                } else if (event.target.parentNode.classList.contains('draggable-svg')) {
                    selectedElement = event.target.parentNode;
                } else if (event.target.parentNode.parentNode.classList.contains('draggable-svg')) {
                    selectedElement = event.target.parentNode.parentNode;
                }

                if (event.target.classList.contains('resize-handle')) {
                    const rect = event.target.getBoundingClientRect();
                    offset.x = event.clientX - rect.left;
                    offset.y = event.clientY - rect.top;

                    lastX = event.clientX;
                    lastY = event.clientY;
                    lastWidth = event.target.offsetWidth;
                    originalRatio = event.target.offsetHeight / event.target.offsetWidth;

                    return updateLocalState({
                        ...localState,
                        selectedElement: event.target,
                        lastX: event.clientX,
                        lastY: event.clientY,
                        offset,
                        isDragging: false,
                        isResizing: true,
                    });
                }

                if (selectedElement) {
                    selectedElement = selectedElement.parentNode;
                    const rect = selectedElement.getBoundingClientRect();
                    offset.x = event.clientX - rect.left;
                    offset.y = event.clientY - rect.top;

                    updateLocalState({
                        ...localState,
                        selectedElement,
                        offset,
                        isDragging: true,
                        isResizing: false,
                    });
                }
            }}
            onMouseMove={(event) => {
                if (localState.isResizing && localState.selectedElement && localState.offset) {
                    const containerRect = document
                        .getElementById(`canvas-wrapper-${localState.pdfIndex}`)
                        ?.getBoundingClientRect();

                    if (!containerRect) return;

                    const dx = event.clientX - lastX;

                    const newWidth = lastWidth + dx;

                    const newHeight =
                        (localState.selectedElement.dataset.type === 'textInput' ? 0.15 : 0.23) * newWidth;

                    localState.selectedElement.style.width = `${Math.max(10, newWidth)}px`;
                    localState.selectedElement.style.height = `${Math.max(10, newHeight)}px`;
                    localState.selectedElement.style.fontSize = `${newWidth * 0.1}px`;

                    lastX = event.clientX;
                    lastY = event.clientY;
                    lastWidth = newWidth;

                    let newFontSize = newWidth * 0.1;

                    if (localState.selectedElement.dataset.type === 'textInput') {
                        const textComponent = document.getElementById(
                            `${localState.selectedElement.id.replace('resize_', '')}_text`
                        );
                        if (textComponent) {
                            const { fontSize } = resizeFont(textComponent, newWidth, newHeight);
                            newFontSize = fontSize;
                        }
                    }

                    const deleteButton = document.getElementById(
                        `delete_button_${localState.selectedElement.id.replace('resize_', '')}`
                    );
                    if (deleteButton) {
                        deleteButton.style.left = localState.selectedElement.offsetWidth + 'px';
                        deleteButton.style.top = '-10px';
                    }

                    updateLocalState({
                        ...localState,
                        lastX: event.clientX,
                        lastY: event.clientY,
                        newFontSize,
                    });
                }

                if (localState.isDragging && localState.selectedElement && localState.offset) {
                    const containerRect = document
                        .getElementById(`canvas-wrapper-${localState.pdfIndex}`)
                        ?.getBoundingClientRect();

                    if (!containerRect) return;

                    const newLeft = event.clientX - containerRect.left - localState.offset.x;
                    const newTop = event.clientY - containerRect.top - localState.offset.y;

                    localState.selectedElement.style.left = `${newLeft}px`;
                    localState.selectedElement.style.top = `${newTop}px`;

                    updateLocalState({
                        ...localState,
                    });
                }
            }}
            onMouseUp={(event) => {
                const containerRect = document
                    .getElementById(`canvas-wrapper-${localState.pdfIndex}`)
                    ?.getBoundingClientRect();

                if (!containerRect || (!localState.isDragging && !localState.isResizing)) return;

                if (localState.isDragging) {
                    const newLeft = event.clientX - containerRect.left - localState.offset.x;
                    const newTop = event.clientY - containerRect.top - localState.offset.y;

                    localState.pdfs[localState.pdfIndex].signatureLocations = localState.pdfs[
                        localState.pdfIndex
                    ].signatureLocations.map((item) => {
                        if (item.uuid === localState.selectedElement.id.replace('resize_', '')) {
                            item.x = newLeft;
                            item.y = newTop;
                        }

                        return item;
                    });
                }

                if (localState.isResizing) {
                    localState.pdfs[localState.pdfIndex].signatureLocations = localState.pdfs[
                        localState.pdfIndex
                    ].signatureLocations.map((item) => {
                        if (item.uuid === localState.selectedElement.id.replace('resize_', '')) {
                            item.svgText = item.svgText.replace(
                                /width="\d+"/g,
                                `width="${parseFloat(localState.selectedElement.style.width.replace('px', ''))}"`
                            );
                            item.svgText = item.svgText.replace(
                                /height="\d+"/g,
                                `height="${parseFloat(localState.selectedElement.style.height.replace('px', ''))}"`
                            );
                            item.newFontSize = localState.newFontSize;
                        }

                        return item;
                    });
                }

                updateLocalState({
                    ...localState,
                    isDragging: false,
                    isResizing: false,
                    selectedElement: null,
                    offset: null,
                    lastX: null,
                    lastY: null,
                    newFontSize: null,
                });
            }}
            onDragOver={(e) => {
                e.preventDefault();
            }}
            onDrop={(e) => {
                e.preventDefault();

                let svgText = e.dataTransfer.getData('svgText');
                const clientUserId = e.dataTransfer.getData('clientUserId');
                const offsetX = e.dataTransfer.getData('offsetX');
                const offsetY = e.dataTransfer.getData('offsetY');
                const isCompanySignature = e.dataTransfer.getData('isCompanySignature');
                const font = e.dataTransfer.getData('font');
                const type = e.dataTransfer.getData('type');

                const canvas = document.getElementById(`canvas-wrapper-${localState.pdfIndex}`);

                const canvasRect = canvas.getBoundingClientRect();

                const x = e.clientX - canvasRect.left - offsetX;
                const y = e.clientY - canvasRect.top - offsetY;

                const dropComponent = (type = 'signature', label, typeOfTextEntry) => {
                    const uniqueId = uuid.v4();

                    if (type === 'textInput') {
                        svgText = textInputSvg(`${uniqueId}_text`, label);
                    }

                    drawImageOnCanvas(svgText, x, y, `canvas-wrapper-${localState.pdfIndex}`, {
                        isCompanySignature,
                        font,
                        uniqueId,
                        type,
                    });

                    localState.pdfs[localState.pdfIndex].signatureLocations = [
                        ...(localState.pdfs[localState.pdfIndex].signatureLocations || []),
                        {
                            clientUserId,
                            svgText,
                            x,
                            y,
                            isCompanySignature,
                            font,
                            page: localState.page,
                            uuid: uniqueId,
                            type,
                            label,
                            ...(type === 'textInput' ? { typeOfTextEntry } : {}),
                        },
                    ];

                    updateLocalState({
                        ...localState,
                        locationToMove: null,
                        originalLocationObject: null,
                    });
                };

                if (type === 'textInput') {
                    updateState({ ...state, showTextInputLabelModal: true });
                    updateLocalState({
                        ...localState,
                        okHandler: dropComponent,
                    });
                } else {
                    dropComponent();
                }
            }}
        >
            <canvas
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: localState.pdfs[localState.pdfIndex].pdfPageWidth || '500px',
                    height: localState.pdfs[localState.pdfIndex].pdfPageHeight || '1px',
                }}
                id={`pdf-canvas-${localState.pdfIndex}`}
                ref={canvasRef}
            />
        </Box>
    );
};

export default PdfFrame;
