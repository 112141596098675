import moment from 'moment';

export const actions = {
    USER_LOADED: '/user/loaded',
    RESET: 'RESET',
    IS_LOADING: 'IS_LOADING',
    ERROR: 'ERROR',
    CORE_DATA_LOADED: 'CORE_DATA_LOADED',
    CLEAR_ERRORS: 'CLEAR_ERRORS',
    REFRESH_CORE_DATA: 'REFRESH_CORE_DATA',
    REFRESH_ACCOUNT_SUMMARY_DATA: 'REFRESH_ACCOUNT_SUMMARY_DATA',
    REFRESH_USER_DATA: 'REFRESH_USER_DATA',
    RESIZE: 'RESIZE',
    WITHDRAW_FIAT_STATE_UPDATE: 'WITHDRAW_FIAT_STATE_UPDATE',
    WITHDRAW_CRYPTO_STATE_UPDATE: 'WITHDRAW_CRYPTO_STATE_UPDATE',
};

const mobileMaxWidth = 600;

const mobileCheck = () => window.innerWidth <= mobileMaxWidth;

const initialState = {
    loggedIn: false,
    user: null,
    accountSummary: null,
    transactions: [],
    loading: false,
    error: null,
    organisation: null,
    versionsConfig: null,
    timestampOfLastDataLoad: null,
    privacy_enabled: false,
    isMobile: mobileCheck(),
    withdrawFiatState: {},
    withdrawCryptoState: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'WITHDRAW_FIAT_STATE_UPDATE':
            return {
                ...state,
                withdrawFiatState: action.withdrawFiatState,
            };
        case 'WITHDRAW_CRYPTO_STATE_UPDATE':
            return {
                ...state,
                withdrawCryptoState: action.withdrawCryptoState,
            };
        case 'LOG_USER_IN':
            return {
                ...state,
                loggedIn: true,
                data: action.data,
            };
        case 'RESIZE':
            return {
                ...state,
                isMobile: mobileCheck(),
            };
        case 'LOG_USER_OUT':
            return {
                ...state,
                ...initialState,
            };
        case actions.USER_LOADED:
            return { ...state, data: action.data, loggedIn: true, timestampOfLastDataLoad: moment.utc() };
        case actions.CORE_DATA_LOADED:
            return {
                ...state,
                user: action.user,
                accountSummary: action.accountSummary,
                versionsConfig: action.versionsConfig,
                transactions: action.transactions,
                organisation: action.organisation,
                loggedIn: true,
                timestampOfLastDataLoad: moment.utc(),
            };
        case actions.RESET:
            return { ...initialState, loggedIn: false };
        case actions.IS_LOADING:
            return { ...state, loading: action.loading };
        case actions.ERROR:
            return { ...state, error: action.error };
        case actions.CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default reducer;
