/* eslint-disable prefer-const */
import { Flex, Radio, Label, Select, Box } from 'theme-ui';
import { useState, useEffect } from 'react';
import { CountryListSelect } from './select-country';
import { InputLabel } from './form-elements';

export const CountrySelect = ({ currentQuestion, currentState, updateState, errorState, updateErrorState }: any) => {
    const isRequired = currentQuestion?.required;
    const saveAs = currentQuestion?.saveAs;
    let countriesToUse = currentState.countries;

    if (currentQuestion?.filteredCountries) {
        countriesToUse = currentState.countriesFiltered;
    }

    if (currentQuestion?.hideFromCitizenship) {
        countriesToUse = countriesToUse.filter((item: any) => !item?.hideFromCitizenship);
    }

    useEffect(() => {
        setTimeout(() => {
            updateState({ ...currentState, loading: false });
        }, 200);
    }, [currentQuestion?.saveAs]);

    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {isRequired && (
                <InputLabel
                    customSX={{ textAlign: 'left', width: '300px' }}
                    title="Required"
                    subtitle={`${isRequired ? ' *' : ''}`}
                />
            )}
            <CountryListSelect
                width="300px"
                dataTestId={[saveAs]}
                stateKey={[saveAs]}
                currentState={currentState}
                updateState={updateState}
                errorState={errorState}
                errorStateKey={`${saveAs}`}
                hasError={currentState?.[saveAs]?.toLowerCase() === 'select'}
                updateErrorState={updateErrorState}
                value={currentState?.[saveAs] || 'select'}
                countries={countriesToUse}
                mt="0"
            />
        </Flex>
    );
};

export const AdditionalNationality = ({
    currentQuestion,
    currentState,
    updateState,
    errorState,
    updateErrorState,
}: any) => {
    const [radioState, updateRadio] = useState(currentState.OtherNationalityRadio);
    const countriesToUse = currentState.countries;

    useEffect(() => {
        updateState({ ...currentState, loading: false });
    }, []);

    return (
        <Flex
            sx={{
                border: '0px red solid',
                minHeight: '350px',
                mt: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                '@media screen and (max-width: 550px)': {
                    mt: '-50px',
                },
            }}
        >
            <Flex
                sx={{
                    width: '300px',
                    height: '50px',
                    ml: '70px',
                    justifyContent: 'space-between',
                    alignItems: 'space-between',
                }}
            >
                <Label sx={{ color: 'inherit' }} data-testid="other-nationality-yes">
                    <Radio
                        name="selectedType"
                        value="false"
                        defaultChecked={currentState.OtherNationalityRadio}
                        onClick={() => {
                            updateRadio(true);
                            updateState({ ...currentState, OtherNationalityRadio: true });
                        }}
                    />
                    Yes
                </Label>
                <Label sx={{ color: 'inherit' }} data-testid="other-nationality-no">
                    <Radio
                        name="selectedType"
                        value="true"
                        defaultChecked={!currentState.OtherNationalityRadio}
                        onClick={() => {
                            updateRadio(false);
                            updateState({ ...currentState, OtherNationalityRadio: false });
                        }}
                    />
                    No
                </Label>
            </Flex>
            {radioState && (
                <CountryListSelect
                    width="300px"
                    dataTestId="other_nationality"
                    stateKey="other_nationality"
                    currentState={currentState}
                    updateState={updateState}
                    errorState={errorState}
                    errorStateKey="other_nationality"
                    hasError={errorState.other_nationality}
                    updateErrorState={updateErrorState}
                    value={currentState?.other_nationality}
                    countries={countriesToUse}
                    mt="0"
                />
            )}
        </Flex>
    );
};

export const Dropdown = ({ currentQuestion, currentState, updateState, errorState, updateErrorState }: any) => {
    const isRequired = currentQuestion?.required;
    useEffect(() => {
        setTimeout(() => {
            updateState({ ...currentState, loading: false });
        }, 200);
    }, [currentQuestion?.saveAs]);

    return (
        <Flex
            sx={{
                width: '100%',
                height: '370px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {isRequired && (
                <InputLabel
                    customSX={{ textAlign: 'left', width: '300px', mb: '5px' }}
                    title="Required"
                    subtitle={`${isRequired ? ' *' : ''}`}
                />
            )}
            {!currentState?.loading && (
                <Select
                    sx={{
                        outline: errorState[currentQuestion?.saveAs] ? '1px red solid' : 'none',
                        width: '300px',
                    }}
                    value={currentState[currentQuestion?.saveAs]}
                    onChange={(e: any) => {
                        updateState({ ...currentState, [currentQuestion?.saveAs]: e.target.value });
                        updateErrorState({
                            ...errorState,
                            [currentQuestion?.saveAs]: e.target.value === 'select',
                        });
                    }}
                    data-testid={`select-${[currentQuestion?.saveAs]}`}
                >
                    <option key="select" value="select">
                        Select an option
                    </option>
                    {
                        // @ts-ignore
                        currentQuestion?.options?.map((item: any) => (
                            <option key={item.id} value={item.id}>
                                {item.label}
                            </option>
                        ))
                    }
                </Select>
            )}
            {currentState?.loading && (
                <Box
                    sx={{
                        width: '300px',
                        height: '50px',
                        backgroundColor: 'white',
                        borderRadius: 6,
                        borderColor: 'inputBorder',
                        mt: '5px',
                        mb: '15px',
                        border: '1px solid lightgrey',
                    }}
                />
            )}
        </Flex>
    );
};
