import { Button, Flex, Image, Paragraph } from 'theme-ui';
import Divider from '../divider';
import * as uuid from 'uuid';

const SelectedClientUser = ({ svg, idx, x, localState, updateLocalState, textInputSvg }) => {
    const signedObject = localState.pdfs?.[localState.pdfIndex]?.signature_locations?.[x.value]?.find(
        (item) => item.clientUserId === x.value
    );

    return (
        <Flex sx={{ flexDirection: 'column', cursor: 'pointer' }} key={`cus_${idx}`}>
            <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Flex sx={{ alignItems: 'center' }}>
                            <Paragraph sx={{ width: 150, mr: 10 }}>Signature</Paragraph>
                            <Image
                                sx={{ cursor: 'grab', mr: 20 }}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('text', e.target.id);
                                    e.dataTransfer.setData('offsetX', e.nativeEvent.offsetX);
                                    e.dataTransfer.setData('offsetY', e.nativeEvent.offsetY);
                                    e.dataTransfer.setData(
                                        'svgText',
                                        svg(
                                            idx,
                                            x.label,
                                            localState.pdfs[localState.pdfIndex].signatureSizes?.[x.value] || 'medium'
                                        )
                                    );
                                    e.dataTransfer.setData('clientUserId', x.value);
                                    e.dataTransfer.setData('type', 'signature');
                                }}
                                src={'data:image/svg+xml;utf8,' + encodeURIComponent(svg(idx, x.label, 'medium'))}
                            />
                        </Flex>
                        <Flex sx={{ mt: 20, alignItems: 'center' }}>
                            <Paragraph sx={{ width: 150, mr: 10 }}>Text Input</Paragraph>
                            <Image
                                sx={{ cursor: 'grab' }}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('text', e.target.id);
                                    e.dataTransfer.setData('offsetX', e.nativeEvent.offsetX);
                                    e.dataTransfer.setData('offsetY', e.nativeEvent.offsetY);
                                    e.dataTransfer.setData('svgText', textInputSvg(uuid.v4()));
                                    e.dataTransfer.setData('clientUserId', x.value);
                                    e.dataTransfer.setData('type', 'textInput');
                                }}
                                src={'data:image/svg+xml;utf8,' + encodeURIComponent(textInputSvg(uuid.v4()))}
                            />
                        </Flex>
                    </Flex>
                    <Flex sx={{ mt: 20, alignItems: 'center' }}>
                        <Button
                            onClick={() => {
                                const locations = localState.pdfs[localState.pdfIndex].signatureLocations?.filter(
                                    (item) => item.clientUserId === x.value
                                );

                                locations?.forEach((item) => {
                                    document.getElementById(item.uuid)?.parentNode?.remove();
                                    document.getElementById(item.uuid)?.remove();
                                });

                                localState.pdfs[localState.pdfIndex].signatureLocations = localState.pdfs[
                                    localState.pdfIndex
                                ].signatureLocations?.filter((location) => location.clientUserId !== x.value);

                                updateLocalState(localState);
                            }}
                            variant="light"
                            sx={{ width: 120, mr: 20, fontSize: 14 }}
                        >
                            <i
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '8px',
                                }}
                                className="fas fa-trash"
                                aria-hidden="true"
                            />
                            Clear
                        </Button>
                        {signedObject && <Paragraph sx={{ color: 'green' }}>User has signed</Paragraph>}
                    </Flex>
                </Flex>
            </Flex>
            <Divider />
        </Flex>
    );
};

export default SelectedClientUser;
