import { Button, Flex, Paragraph } from 'theme-ui';
import Divider from '../divider';
import theme from '../../theme';
import { clearSearchFilter, loadMessages } from '../../pages/inbox';

const Folders = ({ state, updateState }) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                minWidth: 200,
                mr: 20,
                overflow: 'hidden',
                height: 400,
            }}
        >
            <Button
                onClick={() => {
                    updateState({
                        ...state,
                        view: 'compose',
                        selectedEmailIndex: -1,
                        message_id_of_reply: null,
                        replyType: null,
                    });
                }}
                sx={{ mb: 30, width: 150 }}
            >
                New Email
            </Button>
            <Paragraph sx={{ fontSize: 16 }}>Folders</Paragraph>
            <Divider />
            <Flex
                sx={{
                    bg: state.view === 'read' && state.type === 'inbox' ? 'highlight' : 'background',
                    padding: 10,
                    cursor: 'pointer',
                    alignItems: 'center',
                    borderLeft: `3px solid ${theme.colors.primary}`,
                }}
                onClick={async () => {
                    await loadMessages(clearSearchFilter(state, updateState), updateState, false, 'inbox');
                }}
            >
                <i
                    className="fa fa-envelope"
                    style={{
                        fontSize: 14,
                        marginRight: '7px',
                    }}
                />
                <Paragraph>Inbox</Paragraph>
            </Flex>
            <Flex
                onClick={async () => {
                    await loadMessages(clearSearchFilter(state, updateState), updateState, false, 'sent');
                }}
                sx={{
                    mt: 20,
                    padding: 10,
                    cursor: 'pointer',
                    alignItems: 'center',
                    bg: state.view === 'read' && state.type === 'sent' ? 'highlight' : 'background',
                }}
            >
                <i
                    className="fa fa-paper-plane"
                    style={{
                        fontSize: 14,
                        marginRight: '7px',
                    }}
                />
                <Paragraph>Sent Items</Paragraph>
            </Flex>
            <Flex
                onClick={async () => {
                    await loadMessages(clearSearchFilter(state, updateState), updateState, false, 'drafts');
                }}
                sx={{
                    mt: 20,
                    padding: 10,
                    cursor: 'pointer',
                    alignItems: 'center',
                    bg: state.view === 'read' && state.type === 'drafts' ? 'highlight' : 'background',
                }}
            >
                <i
                    className="fa fa-file"
                    style={{
                        fontSize: 14,
                        marginRight: '7px',
                    }}
                />
                <Paragraph>Drafts</Paragraph>
            </Flex>
        </Flex>
    );
};

export default Folders;
