import { Button, Flex, Input, Paragraph } from 'theme-ui';
import SignatureCanvas from '.';
import { useEffect, useState } from 'react';

const SignatureSetupModal = ({ state, updateState }) => {
    const names = [];
    state.organisation?.clients?.forEach((x) => {
        if (!names.find((y) => y.label === x.user_name)) {
            names.push({
                value: x.id,
                label: x.user_name,
            });
        }
    });

    const [name, setName] = useState(state.modalSignature || names?.[0]?.label);

    useEffect(() => {
        if (!name) return;

        ['Pacifico', 'Great Vibes'].forEach((x, idx) => {
            const canvas = document.getElementById(`canvas-signature-${idx + 1}`);
            const ctx = canvas.getContext('2d');
            ctx.font = `20px ${x}`;
            ctx.fillStyle = 'black';
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillText(name, 30, 40);
        });
    }, [name]);

    return (
        <Flex sx={{ flexDirection: 'column', ml: 20, mt: 20 }}>
            <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>Provide a signature</Paragraph>

            <Paragraph sx={{ mt: 10 }}>You can either draw a signature:</Paragraph>

            <SignatureCanvas
                defaultValue={state.drawnSignature}
                id={`signature_canvas_123`}
                containerSx={{ mt: 10 }}
                width={250}
                height={60}
                drawingComplete={(res) => {
                    updateState({
                        ...state,
                        drawnSignature: res.base64,
                        signatureBase64: res.base64,
                        signatureChosen: null,
                        modalSignature: null,
                        nameUsedForSignature: null,
                    });
                }}
            />

            <Flex sx={{ flexDirection: 'column' }}>
                <Paragraph sx={{ mt: 30, mb: 0 }}>or you can select one of the following signatures:</Paragraph>

                <Flex sx={{ flexDirection: 'column', mt: 10, width: 600 }}>
                    <Paragraph sx={{ mt: 10, mb: 0 }}>Please enter your name</Paragraph>
                    <Input
                        sx={{ height: 40, mt: 10, mb: 10, width: 300 }}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            updateState({ ...state, modalSignature: name });
                        }}
                    />
                </Flex>
                {name ? (
                    <>
                        <Flex
                            sx={{
                                cursor: 'pointer',
                                mt: 20,
                                width: 300,
                                border: state.signatureChosen === 'pacifico' ? '1px solid #000' : '1px solid #EFEFEF',
                            }}
                            onClick={(e) => {
                                updateState({
                                    ...state,
                                    signatureChosen: 'pacifico',
                                    drawnSignature: '',
                                    nameUsedForSignature: name,
                                    modalSignature: name,
                                    signatureBase64: null,
                                });
                            }}
                        >
                            <canvas id="canvas-signature-1" width="300" height="60"></canvas>
                        </Flex>
                        <Flex
                            sx={{
                                cursor: 'pointer',
                                mt: 20,
                                width: 300,
                                border:
                                    state.signatureChosen === 'great-vibes' ? '1px solid #000' : '1px solid #EFEFEF',
                            }}
                            onClick={(e) => {
                                updateState({
                                    ...state,
                                    signatureChosen: 'great-vibes',
                                    nameUsedForSignature: name,
                                    drawnSignature: '',
                                    modalSignature: name,
                                    signatureBase64: null,
                                });
                            }}
                        >
                            <canvas id="canvas-signature-2" width="300" height="60"></canvas>
                        </Flex>
                    </>
                ) : null}
            </Flex>

            <Button
                sx={{ mt: 20, width: 200 }}
                onClick={() => updateState({ ...state, showSignatureSetupModal: false })}
            >
                Submit
            </Button>

            <Flex sx={{ height: 20 }} />
        </Flex>
    );
};

export default SignatureSetupModal;
