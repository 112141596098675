/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
import { Box, Button, Flex, Image, Input, Paragraph, Spinner, Textarea } from 'theme-ui';
import Identicon from 'identicon.js';
import moment from 'moment';
import * as uuid from 'uuid';
import axios from 'axios';
import { FileSelectWdget } from '../templates/add-new';
import { getSingleChat } from '../documents/load-docs';
import { loadChats } from '../documents/load-docs';
import { useEffect, useRef } from 'react';
import { PgPagination } from '../documents/paging';
import Divider from '../divider';
import { buildClientsCsv } from '../clients/api';
import { downloadTasksToCsv } from '../tasks/api';

function downloadResultsSet(organisation, answer, recordType) {
    if (recordType === 'clients') {
        buildClientsCsv(organisation, answer);
    }
    if (recordType === 'tasks') {
        downloadTasksToCsv(answer, organisation);
    }
}

function convertHtmlToRichText(html) {
    console.log(html);
    const tempDiv = document.createElement('div');

    html = html.replace(/<br\s*\/?>/gi, '\n');
    html = html.replace(/<\/p>|<\/div>/gi, '\n');
    html = html.replace(/<p>|<div>/gi, '');

    // Set the inner HTML of the div to your HTML string
    tempDiv.innerHTML = html;

    // Extract the text content from the div
    const plainText = tempDiv.textContent || tempDiv.innerText || '';

    // Copy the plain text to the clipboard
    const tempTextArea = document.createElement('textarea');
    document.body.appendChild(tempTextArea);
    tempTextArea.value = plainText;
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
}

export const Title = ({ state, dividerWidth = '1400px' }) => (
    <Flex
        sx={{
            width: '1200px',
            minHeight: '20px',
            mt: '25px',
            mb: '20px',
            pb: '10px',
            border: '0px solid red',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
        }}
    >
        <Flex
            sx={{
                width: '500px',
                height: 'auto',
                ml: '30px',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Paragraph
                sx={{
                    fontWeight: '400',
                    fontSize: '25px',
                    color: 'text',
                    textAlign: 'left',
                }}
            >
                {state?.mode === 'existing_chat' && 'Chat with your documents'}
                {state?.mode === 'new_chat' && 'Create a new chat'}
            </Paragraph>
            <Divider width={dividerWidth} />
        </Flex>
    </Flex>
);

export const FileSelect = ({ state, updateState }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mt: 100, height: '600px', border: '0px red solid' }}>
            <FileSelectWdget
                state={state}
                updateState={updateState}
                total={state.docTotal}
                limit={state.docLimit}
                type="docsOnly"
                documents={state?.documents}
                selectedDocs={state?.selectedDocs}
                showFilters
                selectedDocVar="selectedDocs"
                innerMinHeight="320px"
            />
        </Flex>
    );
};

export const Chats = ({ state, updateState, chats, minHeight = '400px' }) => {
    const totalPages = state.totalChats ? Math.ceil(state.totalChats / state.chatsLimit) : 1;
    return (
        <Box
            sx={{
                minHeight,
                mt: '-18px',
            }}
        >
            <Flex sx={{ width: '100%', justifyContent: 'flex-start' }}>
                <Paragraph
                    variant="primary"
                    sx={{
                        width: '150px',
                        mt: '20px',
                        mb: '20px',
                        backgroundColor: 'white',
                        color: 'text',
                        border: '0px solid #CCC',
                        fontSize: 14,
                        cursor: 'pointer',
                    }}
                    data-testid="submit"
                    onClick={() => updateState({ ...state, mode: 'new_chat', sidebar: 'files', selectedDocs: [] })}
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '13px',
                            marginRight: '9px',
                        }}
                        className={`fas fa-plus-circle`}
                    />{' '}
                    Create new chat
                </Paragraph>
            </Flex>
            <Flex
                sx={{
                    maxHeight: '505px',
                    minHeight: '505px',
                    border: '0px solid red',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                }}
            >
                {chats?.map((item, i) => (
                    <Flex
                        key={`${item?.id}`}
                        sx={{
                            borderBottom: '1px solid lightgrey',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                            height: '50px',
                            cursor: 'pointer',
                            mt: i === 0 ? '-9px' : 'initial',
                            textTransform: 'capitalize',
                        }}
                        onClick={async () => {
                            updateState({
                                ...state,
                                loading: true,
                            });

                            const data = await getSingleChat(item?.id);
                            let selectedDocs = [];
                            const history = data?.history || [];
                            if (history?.length > 0) {
                                selectedDocs = history[history?.length - 1]?.document_ids || [];
                            }
                            updateState({
                                ...state,
                                loading: false,
                                documentToQuery: data,
                                selectedDocs,
                                sidebar: 'files',
                                selectedChatId: item?.id,
                            });
                        }}
                    >
                        <i
                            style={{
                                fontSize: '15px',
                                lineHeight: '32px',
                                mt: '0px',
                                mb: '0px',
                            }}
                            className="fal fa-comment-alt-dots"
                        />{' '}
                        <Paragraph
                            sx={{
                                fontSize: '14px',
                                ml: '15px',
                                width: '80%',
                                fontWeight:
                                    (!state?.selectedChatId && i === 0) || state?.selectedChatId === item.id
                                        ? '600'
                                        : '400',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                pr: '15px',
                                '&:hover': { fontWeight: '500' },
                            }}
                        >
                            {item?.label || 'name not set'}
                        </Paragraph>
                    </Flex>
                ))}
            </Flex>
            {totalPages > 1 && (
                <>
                    <Box sx={{ mb: '30px' }} />

                    <PgPagination
                        currentState={state}
                        updateState={updateState}
                        totalPages={totalPages}
                        totalRecords={state.totalChats}
                        type={'chats'}
                    />
                </>
            )}
        </Box>
    );
};

export const ChatHistory = ({ state, updateState, queryDocument }) => {
    return (
        <Flex sx={{ flexDirection: 'column', width: '215px', height: '500px', border: '0px red solid' }}>
            <Chats state={state} updateState={updateState} chats={state?.chats} queryDocument={queryDocument} />
        </Flex>
    );
};

const InputTitle = ({ text, subtitle }) => {
    return (
        <Box>
            <Paragraph sx={{ fontSize: '18px', fontWeight: '600', color: 'text' }}>{text}</Paragraph>
            {subtitle && <Paragraph sx={{ fontSize: '13px', fontWeight: '400', color: 'text' }}>{subtitle}</Paragraph>}
        </Box>
    );
};

const createNewChat = async (state, updateState) => {
    if (state?.newChatLabel === '' && state?.selectedDocs?.length < 1) {
        return updateState({ ...state, error: 'Please provide a label for your new chat and select some documents' });
    } else if (state?.newChatLabel === '') {
        return updateState({ ...state, error: 'Please provide a label for your new chat' });
    } else if (state?.selectedDocs?.length < 1) {
        return updateState({ ...state, error: 'Please select some documents you wish to query' });
    } else {
        const newChatIdGeneratedByTheClient = uuid.v4();

        updateState({
            ...state,
            error: null,
            loading: true,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/chats/${newChatIdGeneratedByTheClient}`,
            method: 'PUT',
            data: {
                label: state?.newChatLabel,
            },
        });

        const { chats, total: totalChats } = await loadChats();

        updateState({
            ...state,
            mode: 'existing_chat',
            loading: false,
            selectedChatId: newChatIdGeneratedByTheClient,
            error: null,
            newChatLabel: null,
            chats,
            totalChats,
            documentToQuery: chats[0],
        });
    }
};

export const Initial = ({ state, updateState }) => (
    <Flex>
        <Flex
            sx={{
                width: '760px',
                minHeight: '600px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                borderRight: '0px #a3a69b solid',
                mt: '40px',
            }}
        >
            {/* LEFT BLANK FOR NOW */}
            <Paragraph sx={{ fontSize: '14px', textAlign: 'center' }}></Paragraph>
        </Flex>
    </Flex>
);

export const FreshChat = ({ state, updateState }) => (
    <Flex>
        <Flex
            sx={{
                width: '760px',
                minHeight: '600px',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
                borderRight: '1px #a3a69b solid',
                mt: '40px',
                pl: '50px',
            }}
        >
            <Box sx={{ width: '600px' }}>
                <InputTitle text="Add a label" subtitle="This is so you can identify your new chat" />
                <Input
                    sx={{
                        mt: '10px',
                        mb: '25px',
                        width: '100%',
                        background: '#FFFFFF',
                        border: '1px solid #a3a69b',
                        borderRadius: '10px',
                        height: '40px',
                        fontSize: '14px',
                    }}
                    type="text"
                    id="newChatLabel"
                    data-testid="newChatLabel"
                    name="newChatLabel"
                    label=""
                    placeholder="Enter a label for your new chat"
                    value={state?.newChatLabel}
                    onChange={(e) => updateState({ ...state, newChatLabel: e.target.value })}
                    error={null}
                />
                <Flex
                    sx={{
                        flexDirection: 'row',
                        justifyContent: state?.chats?.length > 0 ? 'center' : 'flex-start',
                        alignItems: 'space-between',
                    }}
                >
                    <Button
                        onClick={async () => await createNewChat(state, updateState)}
                        sx={{ width: '190px', fontSize: '14px' }}
                    >
                        Create Chat
                    </Button>
                    {state?.chats?.length > 0 && (
                        <Button
                            onClick={() =>
                                updateState({ ...state, mode: 'existing_chat', error: null, newChatLabel: '' })
                            }
                            sx={{
                                width: '190px',
                                fontSize: '14px',
                                color: 'text',
                                background: '#FFFFFF',
                                border: '1px solid #CCC',
                                ml: '15px',
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                </Flex>
            </Box>
        </Flex>
    </Flex>
);

export const ChatBot = ({ state, updateState, queryDocument, stateRef, user = {}, isLoading }) => {
    const bottomRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        if (state?.documentToQuery?.history?.length) {
            bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [state?.documentToQuery?.history]);

    useEffect(() => {
        updateState({ ...stateRef?.current, chat_group_id: uuid.v4() });
    }, []);

    return (
        <Flex>
            <Flex
                sx={{
                    width: 650,
                    minHeight: 400,
                    borderRadius: 12,
                    flexDirection: 'column',
                    mt: '0px',
                    position: 'relative',
                }}
            >
                <Flex sx={{ flexDirection: 'column ' }}>
                    <Flex sx={{ flexDirection: 'column', ml: 15, mt: 10, mr: 15 }}>
                        <Paragraph sx={{ color: 'text', fontWeight: 500, mt: 10 }}>
                            Chat with your AI assistant
                        </Paragraph>
                        <Divider />
                    </Flex>
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            // borderRadius: 10,
                            width: '100%',
                            height: '410px',
                            mb: '20px',
                            overflow: 'scroll',
                            border: '0px solid #CCC',
                            padding: '30 0',
                            background: '#FFF',
                            position: 'relative',
                        }}
                    >
                        {(state?.documentToQuery?.history || []).map((x, i) => {
                            let chunks;

                            try {
                                chunks = x.text?.trim()?.split('\n') || [];
                            } catch {
                                chunks = x.text;
                            }

                            return (
                                <Flex key={`speech_${i}_container`} sx={{ position: 'relative' }}>
                                    <Flex
                                        sx={{
                                            // borderRadius: '12px',
                                            padding: '10px 15px',
                                            backgroundColor: x.type === 'question' ? '#FFF' : '#F7F7F8',
                                            color: x.type === 'question' ? '#444' : '#444',
                                            borderBottom: '1px solid #CCC',
                                            // mb: '15px',
                                            width: '100%',
                                            lineHeight: '27px',
                                            height: 'auto',
                                            flexDirection: 'column',
                                            alignSelf: x.type === 'answer' ? 'flex-end' : 'flex-start',
                                        }}
                                        key={`speech_${i}`}
                                    >
                                        <Flex sx={{ flex: 1, justifyContent: 'flex-start' }}>
                                            <Box
                                                sx={{
                                                    width: '35px',
                                                    minHeight: `${25 * chunks.length}px`,
                                                    border: '0px solid blue',
                                                    mr: '10px',
                                                    mt: '5px',
                                                }}
                                            >
                                                {x.type === 'answer' && (
                                                    <img
                                                        alt="user avatar"
                                                        width="25"
                                                        height="25"
                                                        src={'https://cdn.accru.finance/af/AF-icon.png'}
                                                    />
                                                )}
                                                {x.type === 'question' && user?.email && (
                                                    <img
                                                        alt="user avatar"
                                                        width="25"
                                                        height="25"
                                                        src={`data:image/png;base64,${new Identicon(user?.email, 50)}`}
                                                    />
                                                )}
                                            </Box>
                                            {!x.answerContainsComponent && (
                                                <Paragraph
                                                    dangerouslySetInnerHTML={{
                                                        __html: chunks.join('<br/>')?.replace(/\n\n/g, '<br/><br/>'),
                                                    }}
                                                    sx={{
                                                        fontSize: '13px',
                                                        mr: 20,
                                                        width: '100%',
                                                        mt: '3px',
                                                    }}
                                                />
                                            )}

                                            {x.answerContainsComponent && x.text}

                                            {/* {x.resultsSetType?.includes('multipleResults') && (
                                                <Button
                                                    onClick={() => {
                                                        downloadResultsSet(x.organisation, chunks, x.recordType);
                                                    }}
                                                    sx={{ width: 160, height: 40, fontSize: 13 }}
                                                >
                                                    Download results
                                                </Button>
                                            )} */}
                                        </Flex>

                                        <Flex
                                            sx={{
                                                alignSelf: 'flex-end',
                                                mt: '0px',
                                                '&:active': {
                                                    boxShadow: '0 1px #CCC',
                                                    transform: 'translateY(1px)',
                                                },
                                            }}
                                            onClick={() => {
                                                convertHtmlToRichText(chunks.join(''));
                                            }}
                                        >
                                            {x.timestamp && (
                                                <Paragraph
                                                    sx={{
                                                        fontSize: '10px',
                                                        mt: '0px',
                                                        mr: '15px',
                                                        alignSelf: 'flex-end',
                                                    }}
                                                >
                                                    {moment(x.timestamp).format('HH:mm DD-MM-YY')}
                                                </Paragraph>
                                            )}
                                            <i
                                                style={{
                                                    fontSize: '15px',
                                                    lineHeight: '32px',
                                                    cursor: 'pointer',
                                                    mt: '0px',
                                                    mb: '0px',
                                                }}
                                                className="fas fa-copy"
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            );
                        })}

                        {state.loading && <Spinner sx={{ ml: 180, mt: 20 }} />}

                        <Box ref={bottomRef} id="scrollToDiv" sx={{ height: 1 }} />
                    </Flex>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '20px',
                            background: 'linear-gradient(0deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.2) 100%)',
                            width: '100%',
                            height: '20px',
                        }}
                    />
                    <Flex
                        sx={{
                            border: '1px #CCC solid',
                            borderRadius: '10px',
                            m: '0 auto',
                            width: '90%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#FFF',
                            position: 'relative',
                        }}
                    >
                        <Textarea
                            sx={{
                                alignSelf: 'center',
                                width: '100%',
                                fontSize: '13px',
                                background: 'transparent',
                                border: '0px solid #CCC',
                                height: '65px',
                                p: '10px 13px',
                                borderRadius: '11px',
                                resize: 'none',
                                '&:focus': {
                                    outline: 0,
                                },
                            }}
                            rows={15}
                            type="text"
                            id="query"
                            data-testid="query"
                            name="query"
                            label=""
                            value={state.documentQuery}
                            placeholder="Type your question here..."
                            onChange={(e) => updateState({ ...state, documentQuery: e.target.value, error: null })}
                            error={null}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    if (state.documentQuery === '') return;

                                    if (state?.selectedDocs?.length < 1) {
                                        updateState({
                                            ...state,
                                            error: 'Please select at least one document.',
                                        });
                                    } else {
                                        await queryDocument(state, updateState, stateRef);
                                    }
                                }
                            }}
                        />

                        <Flex
                            sx={{
                                width: '30px',
                                height: '30px',
                                border: `1px solid ${state.documentQuery === '' ? 'lightgray' : '#017AFE'}`,
                                mr: '15px',
                                borderRadius: '100px',
                                backgroundColor: state.documentQuery === '' ? 'lightgray' : '#017AFE',
                                color: 'white',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: state.documentQuery === '' ? 'not-allowed' : 'pointer',
                                position: 'absolute',
                                right: '0px',
                            }}
                            onClick={async () => {
                                if (state.documentQuery === '') return;

                                if (state?.selectedDocs?.length < 1) {
                                    updateState({
                                        ...state,
                                        error: 'Please select at least one document.',
                                    });
                                } else {
                                    await queryDocument(state, updateState, stateRef);
                                }
                            }}
                        >
                            <i
                                style={{
                                    fontSize: '13px',
                                    lineHeight: '32px',
                                    mt: '0px',
                                    mb: '0px',
                                }}
                                className="fas fa-location-arrow"
                            />
                        </Flex>
                    </Flex>
                </Flex>
                {!state.loading && state.chatBotError && (
                    <Flex
                        sx={{
                            mt: 20,
                            mb: 30,
                            ml: 30,
                            mr: 30,
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Paragraph sx={{ color: 'red' }}>{state.chatBotError}</Paragraph>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
