import { useReducer } from 'react';
import { Auth } from 'aws-amplify';
import { Flex, Spinner } from 'theme-ui';
import validatePassword from '../../../../utils/validate-password';
import OverlayTitle from '../../title';
import BackBtn from '../../back-button';
import PasswordSuccess from './password-success';
import PasswordInput from './password-input';
import axios from 'axios';

const initialState = {
    showOld: false,
    showNew: false,
    showTotp: false,
    oldPwd: '',
    newPwd: '',
    totp: '',
    error: '',
    loading: false,
    success: false,
};

const attemptToResetPassword = async (currentState: any, dispatch: any) => {
    try {
        const { oldPwd, newPwd, totp } = currentState;
        if (!newPwd || !oldPwd) {
            return dispatch({
                type: 'ERROR',
                payload: 'Please enter your old and new password',
            });
        }
        if (oldPwd === newPwd) {
            return dispatch({
                type: 'ERROR',
                payload: 'New password cannot be the same as the old one.',
            });
        }
        if (validatePassword(newPwd).includes(false)) {
            return dispatch({
                type: 'ERROR',
                payload: 'Please check the password requirements and try again',
            });
        }

        dispatch({ type: 'LOADING', payload: true });

        const res = await axios({
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/users/change-password`,
            method: 'POST',
            data: {
                old_pwd: oldPwd,
                new_pwd: newPwd,
                mfa_code: totp,
            },
        });

        dispatch({ type: 'SUCCESS' });
    } catch (e: any) {
        if (e?.message?.includes('Incorrect username or password')) {
            return dispatch({
                type: 'ERROR',
                payload: 'Your current password does not match',
            });
        }

        return dispatch({
            type: 'ERROR',
            payload: 'We were unable to reset your password',
        });
    }
};

const pwdReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'TOG':
            return {
                ...state,
                [action.payload]: !state[action.payload],
            };
        case 'CHANGE':
            return {
                ...state,
                [action.input]: action.payload,
            };
        case 'ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'SUCCESS':
            return {
                ...initialState,
                success: true,
            };

        default:
            return { ...state };
    }
};

const ChangePassword = (props: any) => {
    const [state, dispatch] = useReducer(pwdReducer, initialState);

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <BackBtn overlay={props.overlay} updateOverlay={props.updateOverlay} backComponent="SettingsOverlay" />
            <OverlayTitle centered title="Change Password" />
            {state.loading && <Spinner />}
            {!state.success && (
                <PasswordInput state={state} dispatch={dispatch} attemptToResetPassword={attemptToResetPassword} />
            )}
            {state.success && <PasswordSuccess overlay={props.overlay} updateOverlay={props.updateOverlay} />}
        </Flex>
    );
};

export default ChangePassword;
