import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import './confetti.css';

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const confettiColors = [
    '#E57373',
    '#F06292',
    '#BA68C8',
    '#9575CD',
    '#7986CB',
    '#64B5F6',
    '#4FC3F7',
    '#4DD0E1',
    '#4DB6AC',
    '#81C784',
    '#AED581',
    '#FFD54F',
    '#FFB74D',
    '#FF8A65',
    '#A1887F',
];

function Confetti({ percentage }) {
    const [animationComplete, setAnimationComplete] = useState(false);

    useEffect(() => {
        document.getElementById('confetti')?.addEventListener('animationend', () => {
            setAnimationComplete(true);
        });
    }, []);

    return (
        <div id={'confetti'}>
            {!animationComplete &&
                percentage === 100 &&
                Array.from({ length: 200 }).map((_, index) => (
                    <Box
                        id={`confetti_${index}`}
                        key={`confetti_${index}`}
                        sx={{
                            top: `${getRandomNumber(-30, 30)}%`,
                            left: `${getRandomNumber(50, 100)}%`,
                            backgroundColor: confettiColors[getRandomNumber(0, confettiColors.length)],
                        }}
                        className="confetti-piece"
                    />
                ))}
        </div>
    );
}

export default Confetti;
