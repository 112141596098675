import axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import { actions, actions as userActions } from '../reducers/users';
import { loadAvatar } from '../../components/settings/my-profile';

export const loadOrganisation = async () => {
    try {
        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
        });

        const url = loadAvatar(data);

        if (url) {
            const res = await axios.get(url);
            data.avatarData = res.data;
        }

        return { ...data };
    } catch (e) {
        return null;
    }
};

export function* refreshCoreData(): any {
    yield put({ type: userActions.IS_LOADING, loading: true });

    try {
        const [organisation] = yield all([loadOrganisation()]);

        yield put({
            type: userActions.CORE_DATA_LOADED,
            organisation,
        });

        return yield put({ type: userActions.IS_LOADING, loading: false });
    } catch (e) {
        yield put({ type: 'LOGOUT' });
    }
}

function* refreshCoreDataSaga() {
    // @ts-ignore
    yield takeLatest(actions.REFRESH_CORE_DATA, refreshCoreData);
}

export default refreshCoreDataSaga;
