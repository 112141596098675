/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
import React from 'react'; // @ts-ignore
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Amplify from 'aws-amplify';
import AppWrapper from './components/app-wrapper';
import theme from './theme';
import reducers from './store/reducers';
import { rootSaga } from './store/sagas/index';
import { interceptor } from './utils/axios';
import LogIn from './pages/log-in';
import Home from './pages/dashboard';
import ImageAi from './pages/image';
import Settings from './pages/settings';
import Questionnaires from './pages/questionnaires';
import Definitions from './pages/definitions';
import Clients from './pages/clients';
import Upload from './pages/templates';
import Tasks from './pages/tasks';
import YourDocuments from './pages/library';
import Timesheets from './pages/timesheets';
import Verify from './pages/verify';
import DocSign from './pages/doc-sign-v2';
import RequestResetPasswordLink from './pages/reset-password/request';
import RequestResetPasswordSent from './pages/reset-password/sent';
import RequestResetSet from './pages/reset-password/set';
import Infinity from './pages/chat';
import Schedule from './pages/schedule';
import MagicLinks from './pages/magic-links';
import Inbox from './pages/inbox';
import Proposals from './pages/proposals';

Amplify.configure({
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {},
});

const sagaMiddleware = createSagaMiddleware();
// @ts-ignore
const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composer(applyMiddleware(sagaMiddleware));
const store = createStore(reducers, enhancers);
sagaMiddleware.run(rootSaga);
interceptor(store);

const NoRoute = () => <p style={{ margin: '100px' }}>404 , Page not found ... </p>;

const BackOffice = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
                <AppWrapper>
                    <Router>
                        <Routes>
                            <Route path="/" element={<LogIn />} />
                            <Route path="/verify" element={<Verify />} />
                            <Route path="/magic-links/:id" element={<MagicLinks />} />
                            <Route path="/reset-password/request" element={<RequestResetPasswordLink />} />
                            <Route path="/reset-password/sent" element={<RequestResetPasswordSent />} />
                            <Route path="/reset-password/set" element={<RequestResetSet />} />
                            <Route path="/dashboard" element={<Home />} />
                            <Route path="/image" element={<ImageAi />} />
                            <Route path="/templates" element={<Upload />} />
                            <Route path="/library" element={<YourDocuments />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/tasks" element={<Tasks />} />
                            <Route path="/schedule" element={<Schedule />} />
                            <Route path="/definitions" element={<Definitions />} />
                            <Route path="/clients" element={<Clients />} />
                            <Route path="/timesheets" element={<Timesheets />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/questionnaires" element={<Questionnaires />} />
                            <Route path="/proposals" element={<Proposals />} />
                            <Route path="/chats" element={<Infinity />} />
                            <Route path="/doc-sign" element={<DocSign />} />
                            <Route path="/inbox" element={<Inbox />} />
                            <Route path="*" element={<NoRoute />} />
                        </Routes>
                    </Router>
                </AppWrapper>
            </DndProvider>
        </ThemeProvider>
    </Provider>
);

export default BackOffice;
