/* eslint-disable no-undef */
import axios from 'axios';

export const loadDocuments = async (offset, limit, description, labelParam, doc_types, statuses) => {
    let params = { offset, limit };

    if (doc_types) {
        params = { ...params, doc_types };
    }

    if (description) {
        params = { ...params, description };
    }

    if (labelParam) {
        const joined = labelParam.join(',');
        params = { ...params, labels: joined };
    }

    if (statuses) {
        params = { ...params, statuses };
    }

    let {
        data: { documents, labels, total },
    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents`, { params });
    return { documents, labels, total };
};

export const deleteSingleFile = async (id) => {
    let { data } = await axios.delete(`${process.env.REACT_APP_AQRU_AI_API}/documents/${id}`);
    return data;
};

export const renameSingleFile = async (id, name) => {
    let { data } = await axios.put(`${process.env.REACT_APP_AQRU_AI_API}/documents/${id}`, { file_name: name });
    return data;
};

export const loadChats = async (params = {}) => {
    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/chats`, { params });
    return data;
};

export const getSingleChat = async (id) => {
    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/chats/${id}`);
    return data;
};

export const createChat = async (id) => {
    await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/chats/${id}`,
        method: 'PUT',
        data: {},
    });
};

export const getSingleDefinition = async (id) => {
    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/definitions/${id}`);
    return data;
};

export const loadDefinitions = async (offset, limit) => {
    let params = { offset, limit };

    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/definitions`, { params });
    return data;
};
