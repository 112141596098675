/* eslint-disable no-undef */
import { Button, Flex, Image, Paragraph } from 'theme-ui';
import Divider from '../divider';
import AsyncSelect from 'react-select/async';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { debounce } from 'debounce';
import { toast } from 'react-toastify';
import { QuickbooksImg } from './components';

const loadCustomersAsync = (localState, updateLocalState, state, updateState) => async (inputValue) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/xero/customers`, {
            params: { customer_name: inputValue },
        });

        return data.contacts.map((x) => ({
            value: x.contactID,
            label: x.name,
        }));
    } catch (e) {
        toast(e.response?.data?.message, { type: 'error' });
        return [];
    }
};

const linkClient = async (id, state, updateState, localState, updateLocalState, create_company = false) => {
    try {
        updateState({ ...state, loading: true });

        const { data: linkResponse } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/xero/link-client`,
            method: 'POST',
            data: {
                xero_id: id,
                uuid: state.clientInView.id,
                create_company,
            },
        });

        if (create_company && linkResponse.xeroCustomerId) {
            id = linkResponse.xeroCustomerId;
        }

        if (id) {
            const { data } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/xero/customers`,
                params: {
                    xero_id: id,
                },
            });

            updateLocalState({ ...localState, linkedClient: data.linkedClient });
        } else {
            updateLocalState({ ...localState, linkedClient: null, client: null });
        }

        updateState({
            ...state,
            loading: false,
            clientInView: {
                ...state.clientInView,
                xero_customer_id: id,
            },
        });
    } catch (e) {
        updateState({ ...state, loading: false });
        toast(e.response?.data?.message, { type: 'error' });
        return [];
    }
};

const XeroLinkOverlay = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({});

    useEffect(() => {
        if (state.clientInView?.xero_customer_id) {
            (async () => {
                try {
                    updateState({ ...state, loading: true });
                    const { data } = await axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/xero/customers`,
                        params: {
                            xero_id: state.clientInView?.xero_customer_id,
                        },
                    });

                    updateLocalState({ ...localState, linkedClient: data.linkedClient });
                    updateState({ ...state, loading: false });
                } catch (e) {
                    updateState({ ...state, loading: false });
                }
            })();
        }
    }, []);

    return (
        <Flex sx={{ mt: 45, ml: 20, flexDirection: 'column' }}>
            <Divider width="95%" />

            {state.clientInView?.xero_customer_id && localState.linkedClient ? (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Image sx={{ width: 40 }} src="/xero2.png" />
                    <Paragraph sx={{ mr: 15, mt: 20 }}>
                        You have linked your client to {localState.linkedClient?.name} in Xero
                    </Paragraph>
                    <Button
                        onClick={async () => {
                            await linkClient(null, state, updateState, localState, updateLocalState);
                        }}
                        sx={{ mt: 10, width: 100, height: 40 }}
                    >
                        Unlink
                    </Button>
                </Flex>
            ) : null}

            {!state.clientInView?.xero_customer_id ? (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Image sx={{ width: 40 }} src="/xero2.png" />
                    <Paragraph sx={{ mt: 20 }}>Find Customer in Xero</Paragraph>
                    <Flex sx={{ width: '450px' }}>
                        <AsyncSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '450px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    client: c,
                                });
                            }}
                            placeholder="Filter by customer name in Xero"
                            value={localState.client}
                            loadOptions={loadCustomersAsync(localState, updateLocalState, state, updateState)}
                        />
                    </Flex>
                    {localState.client ? (
                        <Button
                            onClick={async () => {
                                await linkClient(
                                    localState.client.value,
                                    state,
                                    updateState,
                                    localState,
                                    updateLocalState
                                );
                            }}
                            variant="primary"
                            sx={{ mt: 0, width: 160, height: 40, fontSize: 14 }}
                        >
                            Link
                        </Button>
                    ) : null}
                    <Button
                        onClick={async () => {
                            await linkClient(null, state, updateState, localState, updateLocalState, true);
                        }}
                        variant="light"
                        sx={{ mt: localState.client ? 130 : 170, width: 300, height: 40 }}
                    >
                        Create New Xero Customer
                    </Button>
                </Flex>
            ) : null}
        </Flex>
    );
};

export default XeroLinkOverlay;
