import { Flex, Paragraph, Button, Input, Image, Box, Alert } from 'theme-ui';
import { InputLabel } from '../../../form-elements';
import PasswordValidation from '../../../password-validation';

const PasswordInput = ({ state, dispatch, attemptToResetPassword }: any) => (
    <Flex
        id="form"
        sx={{
            mt: 5,
            flexDirection: 'column',
            width: '80%',
            alignItems: 'center',
            '@media screen and (max-width: 700px)': {
                justifyContent: 'space-between',
                width: '93%',
            },
        }}
    >
        <Flex sx={{ flexDirection: 'column', alignItems: ['center', 'unset'] }}>
            <InputLabel title="Old Password" subtitle=" *" />
            <Flex>
                <Input
                    sx={{ mb: 3, width: [270, 320] }}
                    id="password"
                    name="password"
                    type={state.showOld ? 'text' : 'password'}
                    value={state.oldPwd}
                    data-testid="old-password"
                    autoComplete="old-password"
                    placeholder="Enter your old password"
                    onChange={(e: any) =>
                        dispatch({
                            type: 'CHANGE',
                            payload: e.target.value,
                            input: 'oldPwd',
                        })
                    }
                />
                <Box
                    onClick={() => dispatch({ type: 'TOG', payload: 'showOld' })}
                    sx={{
                        mt: 10,
                        ml: '5px',
                        alignSelf: 'flex-start',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                >
                    <Image
                        sx={{ width: '25px' }}
                        src={`${process.env.REACT_APP_CDN_BASE_URL}/registration/elipsis.png`}
                        alt="Click to show password"
                    />
                </Box>
            </Flex>
        </Flex>
        {/* /* ------------------------ OLD / NEW ------------------------------ */}
        <Flex sx={{ flexDirection: 'column', alignItems: ['center', 'unset'] }}>
            <InputLabel title="New Password" subtitle=" *" />
            <Flex>
                <Input
                    sx={{ mb: 2, width: [270, 320] }}
                    id="password"
                    name="password"
                    type={state.showNew ? 'text' : 'password'}
                    data-testid="new-password"
                    autoComplete="new-password"
                    placeholder="Enter your new password"
                    value={state.newPwd}
                    onChange={(e: any) =>
                        dispatch({
                            type: 'CHANGE',
                            payload: e.target.value,
                            input: 'newPwd',
                        })
                    }
                />
                <Box
                    onClick={() => dispatch({ type: 'TOG', payload: 'showNew' })}
                    sx={{
                        mt: 10,
                        ml: '5px',
                        alignSelf: 'flex-start',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                >
                    <Image
                        sx={{ width: '25px' }}
                        src={`${process.env.REACT_APP_CDN_BASE_URL}/registration/elipsis.png`}
                        alt="Click to show password"
                    />
                </Box>
            </Flex>
            <PasswordValidation password={state.newPwd} />
        </Flex>
        {/* /* ----------------------- MFA ------------------------------- */}
        {/* <Flex sx={{ flexDirection: 'column', border: '1px solid green' }}> */}
        <Paragraph
            sx={{
                mb: 20,
                fontSize: '15px',
                width: ['auto', 400],
                textAlign: 'center',
                margin: 'auto',
                my: 4,
            }}
        >
            For security reasons, please enter the code from your authenticator app.
        </Paragraph>
        <Flex>
            <Input
                sx={{ mb: 3, mt: 2, width: [270, 320] }}
                id="mfa"
                name="mfa"
                type={state.showTotp ? 'number' : 'password'}
                value={state.totp}
                data-testid="mfa-input"
                placeholder="Enter 6 Digit MFA Code"
                onChange={(e: any) =>
                    dispatch({
                        type: 'CHANGE',
                        payload: e.target.value,
                        input: 'totp',
                    })
                }
            />
            <Box
                onClick={() => dispatch({ type: 'TOG', payload: 'showTotp' })}
                sx={{
                    mt: 10,
                    ml: '5px',
                    alignSelf: 'flex-start',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Image
                    sx={{ width: '25px' }}
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/registration/elipsis.png`}
                    alt="Click to show password"
                />
            </Box>
        </Flex>
        {/* </Flex> */}
        <Button
            sx={{
                mt: 4,
            }}
            variant="primary-wide"
            onClick={() => {
                attemptToResetPassword(state, dispatch);
            }}
            data-testid="change-pwd-btn"
        >
            Change Password
        </Button>
        {state.error && (
            <Alert variant="error" sx={{ mt: 3 }}>
                {state.error}
            </Alert>
        )}
    </Flex>
);

export default PasswordInput;
