/* eslint-disable no-unsafe-optional-chaining */
import axios from 'axios';
import { connect } from 'react-redux';
import { Paragraph, Flex, Box, IconButton, Alert, Link } from 'theme-ui';
import OverlayTitle from '../title';
import Spinner from '../../spinner';
import { actions as userActions } from '../../../store/reducers/users';
import { useState } from 'react';

const cancelSmsReminders = async (refreshCoreData: any, currentState: any, updateState: any) => {
    try {
        updateState({ ...currentState, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_TRANSACTIONS_API}/reminders`,
            method: 'DELETE',
        });

        updateState({ ...currentState, loading: false, error: null });

        refreshCoreData();
    } catch (e: any) {
        updateState({
            ...currentState,
            loading: false,
            error: e?.response?.data?.message || 'Uh oh, something went wrong, please try again later',
        });
    }
};

const settingsMenuItems = [
    { title: 'Change Password', componentToRender: 'ChangePassword', testId: 'change-password' },
    {
        title: 'Setup SMS Reminders',
        componentToRender: 'SmsReminder',
        enabledFunction: (userData: any) => {
            if (userData.reminder_config) {
                return {
                    customClickFunction: cancelSmsReminders,
                    title: 'Cancel SMS Reminders',
                };
            }
            return {
                customClickFunction: null,
                title: 'Setup SMS Reminders',
            };
        },
        testId: 'setup-sms',
    },
    {
        title: 'Privacy Policy',
        link: `${process.env.REACT_APP_CDN_BASE_URL}/documents/privacy-policy.pdf?t=${new Date().getTime()}`,
        componentToRender: '',
        testId: 'privacy-policy',
    },
    {
        title: 'User Agreement',
        link: `${process.env.REACT_APP_CDN_BASE_URL}/documents/user-agreement.pdf?t=${new Date().getTime()}`,
        componentToRender: '',
        testId: 'user-agreement',
    },
];

const SettingsOverlay = (props: any) => {
    const [currentState, updateState] = useState({ loading: false, error: null });
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            {currentState.loading && <Spinner />}
            <IconButton
                className="far fa-wrench"
                sx={{ marginBottom: 40, fontSize: 40, color: 'grey', mt: 30, alignSelf: 'center' }}
            />
            <OverlayTitle centered title="Settings" />
            <Flex
                sx={{
                    mt: '60px',
                    flexDirection: 'column',
                    '@media screen and (max-width: 700px)': {
                        justifyContent: 'space-between',
                        width: '93%',
                    },
                }}
            >
                {settingsMenuItems.map((item: any) => {
                    const { enabled, title, customClickFunction } = item?.enabledFunction
                        ? item?.enabledFunction(props.userData)
                        : { enabled: true, title: item.title, customClickFunction: null };

                    return (
                        <Item
                            enabled={enabled}
                            {...props}
                            currentState={currentState}
                            updateState={updateState}
                            customClickFunction={customClickFunction}
                            title={title}
                            key={item.title}
                            link={item.link}
                            item={item}
                            testId={item.testId}
                        />
                    );
                })}
            </Flex>
            {currentState.error && <Alert variant="error">{currentState.error}</Alert>}
        </Flex>
    );
};

const Item = (props: any) => {
    const Row = (
        <Flex
            sx={{
                cursor: 'pointer',
                alignSelf: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
            data-testid={props.testId}
            onClick={() => {
                if (props.link) {
                    return;
                }
                if (props.customClickFunction) {
                    props.customClickFunction(props.refreshCoreData, props.currentState, props.updateState);
                } else {
                    props.item.componentToRender &&
                        props.updateOverlay({
                            ...props.overlay,
                            componentToRender: props.item.componentToRender,
                        });
                }
            }}
        >
            <Paragraph>{props.title}</Paragraph>
            <IconButton
                className="far fa-chevron-right"
                sx={{ marginBottom: 20, fontSize: 20, color: 'grey', mt: '-5px', alignSelf: 'center' }}
            />
        </Flex>
    );
    return (
        <Flex sx={{ flexDirection: 'column', width: ['100%', 550], mb: 30 }}>
            {props.link && (
                <Link sx={{ textDecoration: 'none', color: 'inherit' }} href={props.link} target="_blank">
                    {Row}
                </Link>
            )}
            {!props.link && Row}
            <Box sx={{ borderBottom: '1px solid #CCC' }} />
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
});

const mapStateToProps = (state: any) => {
    const { account } = state;
    return {
        userData: account.user,
        accountSummary: account.accountSummary,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsOverlay);
