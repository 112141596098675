/* eslint-disable no-undef */
import { Box, Button, Flex, Image, Input, Paragraph, Textarea } from 'theme-ui';
import ReactSelect from 'react-select';
import { useState } from 'react';
import axios from 'axios';
import { defaultState } from '../../pages/library';
import { loadUploads } from './load-uploads';
import { loadDocuments } from './load-docs';

const defaultErrorState = {
    labels: false,
    files: false,
    description: false,
};

const contentTypesToType = {
    'application/pdf': 'pdf',
    'text/plain': 'txt',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'audio/mpeg': 'mp3',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/csv': 'csv',
};

const Title = ({ text, subtitle }) => {
    return (
        <Box>
            <Paragraph sx={{ fontSize: '18px', fontWeight: '600', color: 'primary' }}>{text}</Paragraph>
            {subtitle && (
                <Paragraph sx={{ fontSize: '13px', mt: '5px', fontWeight: '400', color: 'primary' }}>
                    {subtitle}
                </Paragraph>
            )}
        </Box>
    );
};

const uploadFiles = async (state, updateState, errorState, updateErrorState) => {
    let errors = 0;
    let errorData = {
        description: false,
        files: false,
        labels: false,
    };

    if (!state.files.length) {
        errors++;
        errorData.files = true;
    }

    for (let i = 0; i < state.files.length; i++) {
        console.log(state?.labels[i]);
        if (!state?.labels[i] || state?.labels[i] === '' || state?.labels[i]?.length < 1) {
            errors++;
            errorData.labels = true;
        }

        if (!contentTypesToType[state.files[i][0].type]) {
            return updateState({
                ...state,
                error: `We only support ${Object.values(contentTypesToType).join(', ')} currently`,
            });
        }
    }

    if (errors > 0) {
        updateErrorState({ ...errorState, ...errorData });
        updateState({ ...state, error: 'Please fix the errors in your data' });
    } else {
        updateState({ ...state, loading: true });
        updateErrorState({ ...errorState, ...errorData });

        const { data } = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_AQRU_AI_API}/uploads`,
            data: {
                description: state.description,
                files: state.files.map((file, i) => {
                    const selectLabels = state?.labels[i]?.map((el) => el?.value) || [];
                    const customLabels =
                        state?.customLabels[i]?.trim() === '' ? [] : state?.customLabels[i].split(',') || [];
                    const allLabels = [...selectLabels, ...customLabels];
                    return {
                        file_extension: contentTypesToType[file[0]?.type],
                        content_type: file[0]?.type,
                        file_name: file[0]?.name,
                        labels: allLabels,
                    };
                }),
            },
        });

        for (let i = 0; i < state.files.length; i++) {
            await axios.put(data.urls[i], state.files[i][0], {
                headers: { 'Content-Type': state.files[i][0].type },
            });
        }

        const { uploads, total } = await loadUploads(state.offset, state.limit);
        const { documents, labels, total: docTotal } = await loadDocuments(state.docOffset, state.docLimit);
        return updateState({
            ...defaultState,
            loading: false,
            componentToShow: 'docsOnly',
            uploads,
            total,
            documents,
            selectLabels: labels,
            docTotal,
        });
    }
};

export const AddNewFile = ({ state, updateState }) => {
    const [errorState, updateErrorState] = useState(defaultErrorState);

    const handleFileChange = (event) => {
        const file = event?.target?.files?.length > 0;

        if (file) {
            const newFile = event.target.files;
            const existing = state?.files || [];

            const prompts = state?.prompts || [];
            prompts.push('');
            const labels = state?.labels || [];
            labels.push([]);
            const customLabels = state?.customLabels || [];
            customLabels.push('');
            const showCustomlabelsInput = state?.showCustomlabelsInput || [];
            showCustomlabelsInput.push(false);

            updateState({
                ...state,
                files: [...existing, newFile],
                prompts: [...prompts],
                labels: [...labels],
                showCustomlabelsInput: [...showCustomlabelsInput],
                customLabels: [...customLabels],
            });
        }
    };

    const deleteFileLabelAndPrompt = (idx) => {
        const { prompts, files, labels } = state;
        files.splice(idx, 1);
        prompts.splice(idx, 1);
        labels.splice(idx, 1);

        if (files.length < 1) {
            document.getElementById('file_input').value = null;
        }
        updateState({ ...state, prompts, files, labels });
    };

    return (
        <Flex
            sx={{
                width: '100%',
                border: '0px solid red',
                minHeight: '500px',
                backgroundColor: '#ffffff',
                flexDirection: 'column',
                alignItems: 'flex-start',
                mt: '0',
                ml: '0px',
            }}
        >
            <Flex
                sx={{
                    flexDirection: 'column',
                    ml: '0px',
                }}
            >
                <Flex
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        mb: '130px',
                    }}
                >
                    <Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
                        <Flex
                            sx={{
                                width: '1200px',
                                minHeight: '200px',
                                flexDirection: 'row',
                                border: '0px red solid',
                                mt: '0px',
                            }}
                        >
                            <Flex sx={{ flexDirection: 'column', mr: '40px', width: '400px' }}>
                                {/* selected */}
                                {state?.files?.length > 0 && (
                                    <>
                                        <Title
                                            text={`Selected Files ${state.files?.length} / 10`}
                                            subtitle="Add a short label along with each file"
                                        />
                                        <Box sx={{ mb: '20px' }} />
                                        <Box
                                            sx={{
                                                border: errorState?.labels ? '1px red solid' : '0px solid white',
                                            }}
                                        >
                                            {state.files.map((file, idx) => {
                                                return (
                                                    <Flex
                                                        sx={{
                                                            minHeight: '100px',
                                                            mt: '2px',
                                                            ml: '0px',
                                                            mb: '25px',
                                                            pb: '12px',
                                                            flexDirection: 'column',
                                                            position: 'relative',
                                                        }}
                                                        key={file[0]?.name}
                                                    >
                                                        <Box
                                                            sx={{
                                                                mt: '0',
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                                position: 'absolute',
                                                                color: 'red',
                                                                cursor: 'pointer',
                                                                top: '5px',
                                                                right: '0px',
                                                            }}
                                                            className="far fa-times-circle"
                                                            onClick={() => deleteFileLabelAndPrompt(idx)}
                                                        />
                                                        <Paragraph
                                                            sx={{
                                                                color: 'primary',
                                                                mt: '0',
                                                                mb: '10px',
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            File {idx + 1} - {file[0]?.name} ({file[0]?.size / 1000000}{' '}
                                                            MB)
                                                        </Paragraph>
                                                        <Paragraph
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: '400',
                                                                color: 'primary',
                                                                mb: '5px',
                                                            }}
                                                        >
                                                            Label{' '}
                                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                                *required
                                                            </span>
                                                        </Paragraph>

                                                        <ReactSelect
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    width: '400px',
                                                                    height: '42px',
                                                                    marginBottom: '10px',
                                                                    border: '1px solid #a3a69b',
                                                                    fontSize: '14px',
                                                                }),
                                                            }}
                                                            onChange={(value) => {
                                                                const label = value;
                                                                const labels = state?.labels || [];
                                                                labels[idx] = label;
                                                                updateState({
                                                                    ...state,
                                                                    labels: [...labels],
                                                                });
                                                            }}
                                                            value={state?.labels[idx]}
                                                            options={
                                                                state.selectLabels?.map((item) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })) || [
                                                                    {
                                                                        value: 'other',
                                                                        label: 'other',
                                                                    },
                                                                ]
                                                            }
                                                            isMulti
                                                        />
                                                        <Paragraph
                                                            sx={{
                                                                fontSize: '12px',
                                                                mb: '5px',
                                                                color: 'primary',
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                console.log(state);
                                                                const newValue = state?.showCustomlabelsInput[idx];
                                                                const showCustomlabelsInput =
                                                                    state?.showCustomlabelsInput || [];

                                                                showCustomlabelsInput[idx] = !newValue;
                                                                updateState({
                                                                    ...state,
                                                                    showCustomlabelsInput: [...showCustomlabelsInput],
                                                                });
                                                            }}
                                                        >
                                                            {!state?.showCustomlabelsInput[idx]
                                                                ? 'Add custom label'
                                                                : 'Hide custom input'}
                                                        </Paragraph>
                                                        {state?.showCustomlabelsInput[idx] && (
                                                            <Input
                                                                sx={{
                                                                    mt: '0px',
                                                                    mb: '15px',
                                                                    width: '400px',
                                                                    background: '#FFFFFF',
                                                                    border: '1px solid #a3a69b',
                                                                    borderRadius: '10px',
                                                                    height: '40px',
                                                                    fontSize: '14px',
                                                                }}
                                                                type="text"
                                                                id={`customLabel-${idx}`}
                                                                data-testid={`customLabel-${idx}`}
                                                                name={`customLabel-${idx}`}
                                                                label=""
                                                                value={state?.customLabels[idx] || ''}
                                                                placeholder="Custom label"
                                                                onChange={(e) => {
                                                                    const customLabel = e.target.value;
                                                                    const customLabels = state?.customLabels || [];
                                                                    customLabels[idx] = customLabel;
                                                                    updateState({
                                                                        ...state,
                                                                        customLabels: [...customLabels],
                                                                    });
                                                                }}
                                                                error={null}
                                                            />
                                                        )}
                                                    </Flex>
                                                );
                                            })}
                                        </Box>

                                        <Box sx={{ mt: '20px' }} />
                                    </>
                                )}

                                <Title
                                    text={`${state?.files?.length > 0 ? 'Upload Another File' : 'Upload A File'}`}
                                    subtitle="You may upload up to 10 files"
                                />
                                <Flex
                                    sx={{ ml: '-7px', mt: '20px', flexDirection: 'column' }}
                                    onDragOver={(event) => event.preventDefault()}
                                >
                                    <Input
                                        id="file_input"
                                        style={{
                                            border: errorState?.files ? '1px solid red' : '0px solid #CCC',
                                            fontSize: '15px',
                                        }}
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple="multiple"
                                    />
                                </Flex>
                                <Flex sx={{ justifyContent: 'space-between', width: 370 }}>
                                    <Button
                                        variant="primary"
                                        sx={{
                                            width: '180px',
                                            mt: '125px',
                                            alignSelf: 'flex-end',
                                            backgroundColor: 'white',
                                            color: 'primary',
                                            border: '1px solid #0C1B2A',
                                            fontSize: 14,
                                        }}
                                        data-testid="submit"
                                        onClick={async () => {
                                            updateState({ ...state, componentToShow: 'docsOnly', error: null });
                                            updateErrorState(defaultErrorState);
                                        }}
                                    >
                                        Cancel
                                        <i
                                            style={{
                                                marginTop: '0px',
                                                fontSize: '13px',
                                                marginLeft: '9px',
                                            }}
                                            className={`fas fa-times-circle`}
                                        />
                                    </Button>
                                    <Button
                                        variant="primary"
                                        sx={{
                                            width: '180px',
                                            mt: '125px',
                                            alignSelf: 'flex-end',
                                            backgroundColor: 'primary',
                                            fontSize: 14,
                                        }}
                                        data-testid="submit"
                                        onClick={async () =>
                                            await uploadFiles(state, updateState, errorState, updateErrorState)
                                        }
                                    >
                                        Upload
                                        <i
                                            style={{
                                                marginTop: '0px',
                                                fontSize: '13px',
                                                marginLeft: '9px',
                                            }}
                                            className={`fas fa-plus-circle`}
                                        />
                                    </Button>
                                </Flex>
                            </Flex>

                            {/* row 2 */}
                            {/* NOT IN USE  */}
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    pl: '40px',
                                    borderLeft: '0px solid #a3a69b',
                                }}
                            >
                                {/* <Title
                                    text={`${state?.files?.length > 0 ? 'Upload Another File' : 'Upload A File'}`}
                                    subtitle="You may upload upto 10 files"
                                />
                                <Flex
                                    sx={{ ml: '-7px', mt: '3px', flexDirection: 'column' }}
                                    onDragOver={(event) => event.preventDefault()}
                                >
                                    <Input
                                        id="file_input"
                                        style={{
                                            border: errorState?.files ? '1px solid red' : '0px solid #CCC',
                                            fontSize: '15px',
                                        }}
                                        type="file"
                                        onChange={handleFileChange}
                                        // multiple="multiple"
                                    />
                                </Flex> */}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
