import { Flex, Paragraph } from 'theme-ui';

const ClientSetupProgressIndicator = ({ mandatoryDataSetup, staffSetup, contactsSetup, switchTab }) => {
    const renderStepIcon = (complete, label, tab) => {
        return (
            <Flex sx={{ flexDirection: 'column', cursor: 'pointer' }} onClick={() => switchTab(tab)}>
                <Paragraph sx={{ fontSize: '14px', mb: 10 }}>{label}</Paragraph>
                <i
                    style={{
                        fontSize: 30,
                        textAlign: 'center',
                        marginTop: '0px',
                        color: complete ? '#2B8ACC' : '#CCC',
                    }}
                    className="fas fa-check-circle"
                />
            </Flex>
        );
    };

    return (
        <Flex
            sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '400px',
                mt: '20px',
                mb: '10px',
                alignSelf: 'center',
            }}
        >
            {renderStepIcon(mandatoryDataSetup, 'Client Data Entered', 'client_data', switchTab)}

            {renderStepIcon(staffSetup, 'Staff Setup', 'task_config', switchTab)}

            {renderStepIcon(contactsSetup, 'Contacts Created', 'key_contacts', switchTab)}
        </Flex>
    );
};

export default ClientSetupProgressIndicator;
