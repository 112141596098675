export const defaultOverlayState = {
    show: false,
    title: '',
    text: '',
    action: () => {},
    buttonText: '',
};

export const defaultKYCJourneyState = {
    show: false,
    journey: true,
    componentToRender: 'StartKYC',
    initialComponentToRender: 'StartKYC',
};

export const defaultSettings = {
    show: false,
    journey: true,
    componentToRender: 'SettingsOverlay',
    initialComponentToRender: 'SettingsOverlay',
};

export const defaultProfile = {
    show: false,
    journey: false,
};
