/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import { actions } from "../reducers/users";

const logoutOfCognito = async () => {
  await Auth.signOut();
};

function* logout() {
  yield put({ type: actions.IS_LOADING, loading: true });

  try {
    yield call(logoutOfCognito);
  } catch (e) {
    console.log(e);
  }

  // resets all the stores
  yield put({ type: actions.RESET });

  yield put({ type: actions.IS_LOADING, loading: false });

  window.location.assign("/");
}

function* logoutSaga() {
  // @ts-ignore
  yield takeLatest("LOGOUT", logout);
}

export default logoutSaga;
