export default {
    vat: {
        tabs: [
            {
                label: 'Obligations',
                type: 'obligations',
            },
            {
                label: 'Liabilities',
                type: 'liabilities',
            },
            {
                label: 'Payments',
                type: 'payments',
            },
            {
                label: 'Penalties',
                type: 'penalties',
            },
        ],
        obligations: {
            type: 'table',
            path: 'obligations.obligations',
            rowSchema: {
                periodKey: {
                    label: 'Period key',
                    path: 'periodKey',
                    type: 'string',
                },
                start: {
                    label: 'Start',
                    path: 'start',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
                end: {
                    label: 'End',
                    path: 'end',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
                due: {
                    label: 'Due',
                    path: 'due',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
                status: {
                    label: 'Status',
                    path: 'status',
                    type: 'string',
                    transformer: (value) => {
                        if (value === 'O') return 'Open';
                        if (value === 'F') return 'Fulfilled';
                        return value;
                    },
                },
            },
        },
        liabilities: {
            type: 'table',
            path: 'liabilities.liabilities',
            listTransformer: (list) => list.reverse(),
            rowSchema: {
                type: {
                    label: 'Type',
                    path: 'type',
                    type: 'string',
                },
                taxPeriodFrom: {
                    label: 'Tax period from',
                    path: 'taxPeriod.from',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
                taxPeriodTo: {
                    label: 'Tax period to',
                    path: 'taxPeriod.to',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
                originalAmount: {
                    label: 'Original amount',
                    path: 'originalAmount',
                    type: 'decimal',
                },
                due: {
                    label: 'Due',
                    path: 'due',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
            },
        },
        payments: {
            type: 'table',
            path: 'payments.payments',
            rowSchema: {
                type: {
                    label: 'Amount',
                    path: 'amount',
                    type: 'decimal',
                },
                received: {
                    label: 'Received',
                    path: 'received',
                    type: 'date',
                    format: 'YYYY-MM-DD',
                },
            },
        },
        penalties: {
            type: 'facts',
            label: 'Penalties',
            path: 'penalties.totalisations',
            rowSchema: {
                lateSubmissionPenaltyTotalValue: {
                    label: 'Late Submission Penalty Total Value',
                    path: 'lateSubmissionPenaltyTotalValue',
                    type: 'decimal',
                },
                penalisedPrincipalTotal: {
                    label: 'Penalised Principal Total',
                    path: 'penalisedPrincipalTotal',
                    type: 'decimal',
                },
                latePaymentPenaltyPostedTotal: {
                    label: 'Late Payment Penalty Posted Total',
                    path: 'latePaymentPenaltyPostedTotal',
                    type: 'decimal',
                },
                latePaymentPenaltyEstimateTotal: {
                    label: 'Late Payment Penalty Estimate Total',
                    path: 'latePaymentPenaltyEstimateTotal',
                    type: 'decimal',
                },
            },
        },
    },
};
