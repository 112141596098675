/* eslint-disable react/no-unknown-property */
import { Flex } from "theme-ui";

const BackBtn = ({
  customClickHandler,
  overlay,
  updateOverlay,
  backComponent,
  additionalOverlayProps,
  fromAltDetails = false,
  lightButton = false,
}: any) => (
  <Flex
    sx={{
      position: "absolute",
      top: "-13px",
      left: "20px",
      cursor: "pointer",
      fontWeight: "500",
      zIndex: 100,
      color: lightButton ? "white" : "black",
    }}
    onClick={() =>
      customClickHandler
        ? customClickHandler()
        : updateOverlay({
            ...overlay,
            altDetailsScreen: fromAltDetails,
            componentToRender: backComponent,
            ...(additionalOverlayProps || {}),
          })
    }
  >
    <i
      // @ts-ignore
      sx={{
        fontSize: 18,
        mr: 15,
        ml: 20,
        color: "white",
        mt: "-5px",
        position: "absolute",
        top: "8px",
      }}
      className="fas fa-arrow-left"
    />
  </Flex>
);

export default BackBtn;
