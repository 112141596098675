/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Paragraph } from 'theme-ui';
import { loadDocuments } from '../components/documents/load-docs';
import { loadUploads } from '../components/documents/load-uploads';
import { DocumentsAndUploads } from '../components/documents/uploads';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { AddNewTemplate } from '../components/templates/add-new';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';

const defaultState = {
    loading: true,
    forceUploadDocs: true,
    initialLoad: true,
    partialLoading: false,
    partialWordLoading: false,
    error: null,
    componentToShow: 'list', // addNew || list
    templateType: 'legal_template', // legal_template || ?
    templateToGenerate: 'google', // google || word
    offset: 0,
    limit: 5,
    total: 0,
    chatHistory: [],
    selectLabels: [],
    documents: [],
    docTotal: 0,
    docOffset: 0,
    docLimit: 5,
    selectedDocs: [],
    selectedWordDocs: [],
    documentsWord: [],
    docWordTotal: 0,
    docWordOffset: 0,
    docWordLimit: 3,
    googleURL: '',
    wordDocIdToUse: '',
    description: '',
    shareEmails: '',
    statuses: 'PROCESSED',
};

const defaultErrorState = {
    labels: false,
    selectedDocs: false,
    shareEmails: false,
    googleURL: false,
    description: false,
};

const TemplatesContainer = ({ dataLoading, refreshCoreData, userData, accountSummary, isMobile, dismissTopMenu }) => {
    const [state, updateState] = useState(defaultState);
    const [documents, updateDocuments] = useState({ uploads: [], total: '' });
    const [errorState, updateErrorState] = useState(defaultErrorState);
    const [user, updateUser] = useState(userData);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const { attributes } = await Auth.currentAuthenticatedUser();
                updateUser(attributes);
                await refreshCoreData();
                const { docOffset, docLimit, docWordOffset, docWordLimit } = state;
                const { uploads, total } = await loadUploads(state.offset, state.limit);
                const {
                    documents,
                    labels,
                    total: docTotal,
                } = await loadDocuments(docOffset, docLimit, false, false, null, 'PROCESSED');
                const { documents: documentsWord, total: docWordTotal } = await loadDocuments(
                    docWordOffset,
                    docWordLimit,
                    false,
                    false,
                    'docx',
                    'PROCESSED'
                );

                return updateState({
                    ...state,
                    loading: false,
                    forceUploadDocs: documentsWord?.length < 1,
                    initialLoad: false,
                    uploads,
                    total,
                    documents,
                    selectLabels: labels,
                    docTotal,
                    shareEmails: attributes?.email,
                    documentsWord,
                    docWordTotal,
                });
            } catch (e) {
                console.log(e);
                navigate('/');
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    updateState({ ...state, loading: true });
                    const { uploads, total } = await loadUploads(state.offset, state.limit);

                    return updateState({
                        ...state,
                        loading: false,
                        uploads,
                        total,
                    });
                }
            } catch (e) {
                //
            }
        })();
    }, [state.offset]);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    updateState({ ...state, partialWordLoading: true });
                    const { docWordOffset, docWordLimit } = state;

                    const { documents: documentsWord, total: docWordTotal } = await loadDocuments(
                        docWordOffset,
                        docWordLimit,
                        false,
                        false,
                        'docx',
                        'PROCESSED'
                    );

                    return updateState({
                        ...state,
                        partialWordLoading: false,
                        partialLoading: false,
                        documentsWord,
                        docWordTotal,
                    });
                }
            } catch (e) {
                //
            }
        })();
    }, [state.docWordOffset]);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    updateState({ ...state, partialLoading: true });
                    const { docSearchDescription, docSearchLabels, docOffset, docLimit } = state;
                    const description = docSearchDescription !== '' ? docSearchDescription : false;
                    const labelParam = docSearchLabels?.length > 0 ? docSearchLabels : false;

                    const {
                        documents,
                        labels,
                        total: docTotal,
                    } = await loadDocuments(docOffset, docLimit, description, labelParam, 'PROCESSED');

                    return updateState({
                        ...state,
                        partialLoading: false,
                        partialWordLoading: false,
                        documents,
                        selectLabels: labels,
                        docTotal,
                    });
                }
            } catch (e) {
                //
            }
        })();
    }, [state.docOffset]);

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: 'primary',
                    position: 'relative',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Templates" accountSummary={accountSummary} user={user} copy={{}} />
                    {dataLoading && (
                        <Flex
                            sx={{
                                width: '100%',
                                height: '100vh',
                                zIndex: '9999',
                                position: 'absolute',
                            }}
                        >
                            <Spinner />
                        </Flex>
                    )}

                    {state.loading && <Spinner />}

                    <>
                        <Flex
                            sx={{
                                width: 'calc(100% - 110px)',
                                border: '0px solid lightgrey',
                                minHeight: 'calc(100vh)',
                                backgroundColor: '#ffffff',
                                borderTopLeftRadius: '25px',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                mt: '15px',
                                ml: '120px',
                            }}
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    ml: '50px',
                                    width: '1300px',
                                    position: 'relative',
                                }}
                            >
                                {!state.loading &&
                                    state?.uploads?.length > 0 &&
                                    state?.componentToShow !== 'addNew' && (
                                        <Button
                                            variant="primary"
                                            sx={{
                                                width: '180px',
                                                position: 'absolute',
                                                top: '40px',
                                                right: '0px',
                                                backgroundColor: 'primary',
                                            }}
                                            data-testid="submit"
                                            onClick={async () => updateState({ ...state, componentToShow: 'addNew' })}
                                        >
                                            New
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '13px',
                                                    marginLeft: '9px',
                                                }}
                                                className={`fas fa-plus-circle`}
                                            />
                                        </Button>
                                    )}
                                <Flex
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        mb: '30px',
                                    }}
                                >
                                    <Flex sx={{ flexDirection: 'column' }}>
                                        <Paragraph
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '25px',
                                                color: 'primary',
                                                textAlign: 'left',
                                                mt: '30px',
                                            }}
                                        >
                                            Populate a template
                                        </Paragraph>
                                        <Paragraph
                                            sx={{
                                                fontSize: '15px',
                                                color: 'primary',
                                                textAlign: 'left',
                                                mt: '10px',
                                            }}
                                        >
                                            Populate a template with information extracted from your documents, click{' '}
                                            <span
                                                style={{ fontWeight: '600', cursor: 'pointer' }}
                                                onClick={() => {
                                                    const url =
                                                        'https://docs.google.com/document/d/1cNTNwjxHahSkrgKjDyE6x_WsosftQEulJWsDU_PUiBQ/edit';
                                                    window.open(url, '_blank') || window.location.replace(url);
                                                }}
                                            >
                                                HERE
                                            </span>{' '}
                                            to view an example template
                                        </Paragraph>
                                        {!state.loading && state?.componentToShow === 'addNew' && (
                                            <Box sx={{ mt: '50px' }}>
                                                <AddNewTemplate
                                                    state={state}
                                                    updateState={updateState}
                                                    errorState={errorState}
                                                    defaultErrorState={defaultErrorState}
                                                    updateErrorState={updateErrorState}
                                                    userData={user}
                                                />
                                            </Box>
                                        )}

                                        {!state.loading && state?.componentToShow === 'list' && (
                                            <Box sx={{ mt: '50px' }}>
                                                {state?.uploads?.length < 1 && state?.forceUploadDocs && (
                                                    <Box sx={{ mt: '0px' }}>
                                                        <Paragraph sx={{ mt: '50px' }}>
                                                            You need to upload some files before you can do this.
                                                        </Paragraph>
                                                        <Button
                                                            variant="primary"
                                                            sx={{
                                                                width: '180px',
                                                                mt: '30px',
                                                                backgroundColor: 'primary',
                                                            }}
                                                            data-testid="submit"
                                                            onClick={async () => navigate('/')}
                                                        >
                                                            Upload Files
                                                            <i
                                                                style={{
                                                                    marginTop: '0px',
                                                                    fontSize: '13px',
                                                                    marginLeft: '9px',
                                                                }}
                                                                className={`fas fa-plus-circle`}
                                                            />
                                                        </Button>
                                                    </Box>
                                                )}

                                                {state?.uploads?.length < 1 && !state?.forceUploadDocs && (
                                                    <Box sx={{ mt: '0px' }}>
                                                        <Button
                                                            variant="primary"
                                                            sx={{
                                                                width: '180px',
                                                                mt: '30px',
                                                                backgroundColor: 'primary',
                                                            }}
                                                            data-testid="submit"
                                                            onClick={async () =>
                                                                updateState({ ...state, componentToShow: 'addNew' })
                                                            }
                                                        >
                                                            Get Started
                                                            <i
                                                                style={{
                                                                    marginTop: '0px',
                                                                    fontSize: '13px',
                                                                    marginLeft: '9px',
                                                                }}
                                                                className={`fas fa-plus-circle`}
                                                            />
                                                        </Button>
                                                    </Box>
                                                )}
                                                {state?.uploads?.length > 0 && (
                                                    <DocumentsAndUploads
                                                        state={state}
                                                        updateState={updateState}
                                                        deleteFile={() => {}}
                                                        scrollLatestIntoView={() => {}}
                                                        navigate={navigate}
                                                        isMobile={isMobile}
                                                        userData={userData}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Flex>
                                </Flex>
                                {state.error && (
                                    <Paragraph
                                        sx={{
                                            color: 'red',
                                            minHeight: '20px',
                                            mt: '0px',
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {state.error}
                                    </Paragraph>
                                )}
                            </Flex>
                        </Flex>
                    </>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesContainer);
