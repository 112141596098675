/* eslint-disable no-undef */
import axios from 'axios';

export const GetDocUrl = async (uploadId, docId) => {
    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/uploads/${uploadId}/documents/${docId}`);
    return data;
};
export const GetDocUrlViaDocId = async (docId) => {
    let { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${docId}`);
    return data;
};

export const loadUploads = async (offset, limit, type = 'legal_template') => {
    let {
        data: { uploads, total },
    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/uploads`, {
        params: { offset, limit, type },
    });

    uploads = uploads.map((x) => {
        let processing_status = 'PROCESSED';

        for (let d = 0; d < x.document_uploads.length; d++) {
            if (x.document_uploads[d].processing_status === 'ERROR') {
                processing_status = 'ERROR';
                break;
            }
            if (x.document_uploads[d].processing_status === 'AWAITING_UPLOAD') {
                processing_status = 'AWAITING_UPLOAD';
                break;
            }
        }

        return {
            ...x,
            processing_status,
        };
    });

    return { uploads, total };
};
