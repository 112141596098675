import { Heading, Box, Image, Flex } from "theme-ui";

const OverlayHeading = ({
  title,
  showIcon = false,
  icon = "",
  headingSx = {},
}: any) => (
  <Flex
    sx={{
      height: "auto",
      width: "auto",
      justifyContent: "center",
      borderBottom: "none",
    }}
  >
    {showIcon && icon && (
      <Box sx={{ position: "absolute", top: "-15px", display: "flex" }}>
        <Box
          sx={{ width: "30px", height: "30px", marginRight: "15px", mt: "0" }}
        >
          <Image
            src={`https://cdn.accru.finance/icons/${icon}.png`}
            width="30px"
            height="30px"
            alt="copy"
          />
        </Box>
        <Heading
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            color: "inherit",
          }}
        >
          {title}
        </Heading>
      </Box>
    )}
    {!showIcon && (
      <Heading
        sx={{
          position: "absolute",
          top: "-15px",
          fontSize: "18px",
          fontWeight: "500",
          ...headingSx,
          color: "inherit",
        }}
      >
        {title}
      </Heading>
    )}
  </Flex>
);

export default OverlayHeading;
