/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { debounce } from 'debounce';
import * as moment from 'moment';
import { Button, Flex, Input, Label, Paragraph } from 'theme-ui';
import axios from 'axios';
import { useEffect } from 'react';
import theme from '../../theme';

export const sortOptions = [
    { value: 'client_name_asc', label: 'Client name (A-Z)', sortClause: 'client.name' },
    { value: 'client_name_desc', label: 'Client name (Z-A)', sortClause: 'client.name' },
    { value: 'service_asc', label: 'Service (A-Z)', sortClause: 'task.service' },
    { value: 'service_desc', label: 'Service (Z-A)', sortClause: 'task.service' },
    { value: 'title_asc', label: 'Title (A-Z)', sortClause: 'task.title' },
    { value: 'title_desc', label: 'Title (Z-A)', sortClause: 'task.title' },
    {
        value: 'internal_reference_code_asc',
        label: 'Client identifier (A-Z)',
        sortClause: 'client.internalReferenceCode',
    },
    {
        value: 'internal_reference_code_desc',
        label: 'Client identifier (Z-A)',
        sortClause: 'client.internalReferenceCode',
    },
    {
        value: 'date_sent_to_client_asc',
        label: 'Date sent to client (Oldest-Newest)',
        sortClause: 'task.dateSentToClient',
    },
    {
        value: 'date_sent_to_client_desc',
        label: 'Date sent to client (Newest-Oldest)',
        sortClause: 'task.dateSentToClient',
    },
    {
        value: 'status_asc',
        label: 'Status (Low-High)',
        sortClause: `
        CASE 
            WHEN task.status = 'todo' THEN 1
            WHEN task.status = 'in_progress' THEN 2
            WHEN task.status = 'blocked' THEN 3
            WHEN task.status = 'with_client' THEN 4
            WHEN task.status = 'ready_for_review' THEN 5
            WHEN task.status = 'done' THEN 6
            WHEN task.status = 'closed' THEN 7
            ELSE 8
        END    
    `,
    },
    {
        value: 'status_desc',
        label: 'Status (High-Low)',
        sortClause: `
        CASE 
            WHEN task.status = 'todo' THEN 1
            WHEN task.status = 'in_progress' THEN 2
            WHEN task.status = 'blocked' THEN 3
            WHEN task.status = 'with_client' THEN 4
            WHEN task.status = 'ready_for_review' THEN 5
            WHEN task.status = 'done' THEN 6
            WHEN task.status = 'closed' THEN 7
            ELSE 8
        END    
    `,
    },
    {
        value: 'priority_asc',
        label: 'Priority (Low-High)',
        sortClause: `
        CASE 
            WHEN task.priority = 'lowest' THEN 1
            WHEN task.priority = 'low' THEN 2
            WHEN task.priority = 'medium' THEN 3
            WHEN task.priority = 'high' THEN 4
            WHEN task.priority = 'highest' THEN 5
            ELSE 6
        END    
    `,
    },
    {
        value: 'priority_desc',
        label: 'Priority (High-Low)',
        sortClause: `
        CASE 
            WHEN task.priority = 'lowest' THEN 1
            WHEN task.priority = 'low' THEN 2
            WHEN task.priority = 'medium' THEN 3
            WHEN task.priority = 'high' THEN 4
            WHEN task.priority = 'highest' THEN 5
            ELSE 6
        END    
    `,
    },
    { value: 'due_date_asc', label: 'Internal due date (Oldest-Newest)', sortClause: 'task.dueDate' },
    { value: 'due_date_desc', label: 'Internal due date (Newest-Oldest)', sortClause: 'task.dueDate' },
    {
        value: 'statutory_due_date_asc',
        label: 'Statutory due date (Oldest-Newest)',
        sortClause: 'task.statutoryDueDate',
    },
    {
        value: 'statutory_due_date_desc',
        label: 'Statutory due date (Newest-Oldest)',
        sortClause: 'task.statutoryDueDate',
    },
    {
        value: 'relevant_period_end_asc',
        label: 'Relevant period end (Oldest-Newest)',
        sortClause: 'task.relevantPeriodEnd',
    },
    {
        value: 'relevant_period_end_desc',
        label: 'Relevant period end (Newest-Oldest)',
        sortClause: 'task.relevantPeriodEnd',
    },
    {
        value: 'expected_date_billed_asc',
        label: 'Expected date billed (Oldest-Newest)',
        sortClause: 'task.expectedDateBilled',
    },
    {
        value: 'expected_date_billed_desc',
        label: 'Expected date billed (Newest-Oldest)',
        sortClause: 'task.expectedDateBilled',
    },
    {
        value: 'expected_fee_asc',
        label: 'Expected fee (Smallest-Largest)',
        sortClause: 'task.expectedFee',
    },
    {
        value: 'expected_fee_desc',
        label: 'Expected fee (Largest-Smallest)',
        sortClause: 'task.expectedFee',
    },
];

const OperatorDropDown = ({ onChange, value }) => (
    <ReactSelect
        aria-labelledby="filter-label"
        styles={{
            control: (provided) => ({
                ...provided,
                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                width: '100px',
                minHeight: '40px',
                marginTop: '10px',
                borderRadius: 10,
                marginBottom: '10px',
                border: '1px solid #a3a69b',
                fontSize: '14px',
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 10001,
            }),
        }}
        onChange={onChange}
        placeholder="Select..."
        value={value}
        options={[
            {
                value: '<=',
                label: '<=',
            },
            {
                value: '<',
                label: '<',
            },
            {
                value: '=',
                label: '=',
            },
            {
                value: '>',
                label: '>',
            },
            {
                value: '>=',
                label: '>=',
            },
        ]}
    />
);

export const columnOptions = [
    { value: 'title', label: 'Title' },
    { value: 'status', label: 'Status' },
    { value: 'priority', label: 'Priority' },
    { value: 'relevant_period_end', label: 'Relevant period end' },
    { value: 'due_date', label: 'Internal due date' },
    { value: 'statutory_due_date', label: 'Statutory due date' },
    { value: 'expected_fee', label: 'Expected fee' },
    { value: 'expected_date_billed', label: 'Expected date billed' },
    { value: 'service', label: 'Service' },
    { value: 'client_name', label: 'Client name' },
    { value: 'internal_reference_code', label: 'Client identifier' },
    { value: 'date_sent_to_client', label: 'Date sent to client' },
    { value: 'partners', label: 'Partners' },
    { value: 'reviewers', label: 'Reviewers' },
    { value: 'preparers', label: 'Preparers' },
];

export const defaultColumnOptions = [
    { value: 'client_name', label: 'Client name' },
    { value: 'service', label: 'Service' },
    { value: 'relevant_period_end', label: 'Relevant period end' },
    { value: 'title', label: 'Title' },
    { value: 'status', label: 'Status' },
    { value: 'due_date', label: 'Internal due date' },
    { value: 'statutory_due_date', label: 'Statutory due date' },
    { value: 'priority', label: 'Priority' },
];

export const PriorityItem = ({ priority }) => (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Paragraph>{priority.label}</Paragraph>
        <Flex
            sx={{
                width: 10,
                height: 10,
                borderRadius: 12,
                backgroundColor: priority.color,
                ml: 10,
            }}
        />
    </Flex>
);

export const loadClientsAsync = (state, updateState) => async (inputValue) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients`, {
            params: { name: inputValue },
        });

        return [
            ...(data.clients?.map((x) => ({
                value: x.raw_id,
                uuid: x.id,
                label: x.name,
                services: x.client_data?.new_client_services_offered || [],
                fullData: x,
            })) || []),
        ];
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const loadTasksAsync =
    (state, updateState, clientId, orderKey = 'order_by_internal_due_date') =>
    async (inputValue) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks`, {
                params: {
                    title: inputValue,
                    client_id: clientId,
                    [orderKey]: '1',
                    include_closed: '1',
                },
            });

            return [
                ...(data.tasks?.map((x) => ({
                    value: x.raw_id,
                    uuid: x.id,
                    service: x.additional_data?.service,
                    label: x.title,
                    fullData: x,
                })) || []),
            ];
        } catch (e) {
            console.log(e);
            return [];
        }
    };

const dateComparator = (val1, val2, direction) => {
    if (val1 === null && val2 === null) {
        return 0;
    }

    if (val1 === null) {
        return direction === 'asc' ? 1 : -1;
    }

    if (val2 === null) {
        return direction === 'asc' ? -1 : 1;
    }

    if (moment(val1).isSame(moment(val2), 'day')) {
        return 0;
    }

    if (direction === 'asc') {
        return moment(val1).isBefore(moment(val2)) ? -1 : 1;
    }

    return moment(val1).isBefore(moment(val2)) ? 1 : -1;
};

const sortingComparators = [
    { value: 'title_asc', function: (x, y) => x.title?.toLowerCase()?.localeCompare(y.title?.toLowerCase()) },
    { value: 'title_desc', function: (x, y) => y.title?.toLowerCase()?.localeCompare(x.title?.toLowerCase()) },
    {
        value: 'service_asc',
        function: (x, y) =>
            x.additional_data?.service?.toLowerCase()?.localeCompare(y.additional_data?.service?.toLowerCase()),
    },
    {
        value: 'service_desc',
        function: (x, y) =>
            y.additional_data?.service?.toLowerCase()?.localeCompare(x.additional_data?.service?.toLowerCase()),
    },
    {
        value: 'internal_reference_code_asc',
        function: (x, y) =>
            x.client?.internal_reference_code
                ?.toLowerCase()
                ?.localeCompare(y.client?.internal_reference_code?.toLowerCase()),
    },
    {
        value: 'internal_reference_code_desc',
        function: (x, y) =>
            y.client?.internal_reference_code
                ?.toLowerCase()
                ?.localeCompare(x.client?.internal_reference_code?.toLowerCase()),
    },
    {
        value: 'client_name_asc',
        function: (x, y) => {
            let xName = x.client?.name;
            let yName = y.client?.name;

            if (x.client?.type === 'individual') {
                xName = x.client?.name?.split(' ')?.[1] || x.client?.name;
            }

            if (y.client?.type === 'individual') {
                yName = y.client?.name?.split(' ')?.[1] || y.client?.name;
            }

            return xName?.toLowerCase()?.localeCompare(yName?.toLowerCase());
        },
    },
    {
        value: 'client_name_desc',
        function: (x, y) => {
            let xName = x.client?.name;
            let yName = y.client?.name;

            if (x.client?.type === 'individual') {
                xName = x.client?.name?.split(' ')?.[1] || x.client?.name;
            }

            if (y.client?.type === 'individual') {
                yName = y.client?.name?.split(' ')?.[1] || y.client?.name;
            }

            return yName?.toLowerCase()?.localeCompare(xName?.toLowerCase());
        },
    },
    {
        value: 'status_asc',
        function: (x, y, organisation) => {
            const statusOrder = {};

            organisation?.default_visualisation?.statuses?.forEach((x, idx) => {
                statusOrder[x.key] = idx + 1;
            });

            return statusOrder[x.status] - statusOrder[y.status];
        },
    },
    {
        value: 'status_desc',
        function: (x, y, organisation) => {
            const statusOrder = {};

            organisation?.default_visualisation?.statuses
                ?.slice()
                ?.reverse()
                ?.forEach((x, idx) => {
                    statusOrder[x.key] = idx + 1;
                });

            return statusOrder[x.status] - statusOrder[y.status];
        },
    },
    {
        value: 'priority_asc',
        function: (x, y, organisation) => {
            const priorityOrder = {};

            organisation?.default_visualisation?.priorities?.forEach((x, idx) => {
                priorityOrder[x.key] = idx + 1;
            });

            return priorityOrder[x.priority] - priorityOrder[y.priority];
        },
    },
    {
        value: 'priority_desc',
        function: (x, y, organisation) => {
            const priorityOrder = {};

            organisation?.default_visualisation?.priorities
                ?.slice()
                ?.reverse()
                ?.forEach((x, idx) => {
                    priorityOrder[x.key] = idx + 1;
                });

            return priorityOrder[x.priority] - priorityOrder[y.priority];
        },
    },
    {
        value: 'expected_fee_asc',
        function: (x, y) => {
            return x?.additional_data?.expected_fee - y?.additional_data?.expected_fee;
        },
    },
    {
        value: 'expected_fee_desc',
        function: (x, y) => {
            return y?.additional_data?.expected_fee - x?.additional_data?.expected_fee;
        },
    },
    {
        value: 'due_date_asc',
        function: (a, b) => {
            return dateComparator(a.due_date, b.due_date, 'asc');
        },
    },
    {
        value: 'due_date_desc',
        function: (a, b) => {
            return dateComparator(a.due_date, b.due_date, 'desc');
        },
    },
    {
        value: 'date_sent_to_client_asc',
        function: (a, b) => {
            return dateComparator(a.date_sent_to_client, b.date_sent_to_client, 'asc');
        },
    },
    {
        value: 'date_sent_to_client_desc',
        function: (a, b) => {
            return dateComparator(a.date_sent_to_client, b.date_sent_to_client, 'desc');
        },
    },
    {
        value: 'statutory_due_date_asc',
        function: (a, b) => {
            return dateComparator(a.additional_data?.statutory_due_date, b.additional_data?.statutory_due_date, 'asc');
        },
    },
    {
        value: 'statutory_due_date_desc',
        function: (a, b) => {
            return dateComparator(a.additional_data?.statutory_due_date, b.additional_data?.statutory_due_date, 'desc');
        },
    },
    {
        value: 'expected_date_billed_asc',
        function: (a, b) => {
            return dateComparator(
                a.additional_data?.expected_date_billed,
                b.additional_data?.expected_date_billed,
                'asc'
            );
        },
    },
    {
        value: 'expected_date_billed_desc',
        function: (a, b) => {
            return dateComparator(
                a.additional_data?.expected_date_billed,
                b.additional_data?.expected_date_billed,
                'desc'
            );
        },
    },
    {
        value: 'relevant_period_end_asc',
        function: (a, b) => {
            return dateComparator(
                a.additional_data?.relevant_period_end,
                b.additional_data?.relevant_period_end,
                'asc'
            );
        },
    },
    {
        value: 'relevant_period_end_desc',
        function: (a, b) => {
            return dateComparator(
                a.additional_data?.relevant_period_end,
                b.additional_data?.relevant_period_end,
                'desc'
            );
        },
    },
    {
        value: 'client_data_asc',
        function: (x, y, organisation, key) => {
            const def = organisation.schema[key];

            const xValue = x?.client?.client_data?.[key];
            const yValue = y?.client?.client_data?.[key];

            if (def.type === 'number') {
                return xValue - yValue;
            }
            if (def.type === 'date') {
                return dateComparator(xValue, yValue, 'desc');
            }

            return xValue?.toLowerCase()?.localeCompare(yValue?.toLowerCase());
        },
    },
    {
        value: 'client_data_desc',
        function: (x, y, organisation, key) => {
            const def = organisation.schema[key];

            const xValue = x?.client?.client_data?.[key];
            const yValue = y?.client?.client_data?.[key];

            if (def.type === 'number') {
                return yValue - xValue;
            }
            if (def.type === 'date') {
                return dateComparator(yValue, xValue, 'desc');
            }

            return yValue?.toLowerCase()?.localeCompare(xValue?.toLowerCase());
        },
    },
];

export const sortTasks = (tasks, filters = [], organisation) => {
    return tasks;
};

const Filters = ({ loadTaskData, state, updateState, organisation }) => {
    const handleTitleChange = debounce(async (e) => {
        const tempState = { ...state, searchByTitle: e.target.value };
        updateState(tempState);
        await loadTaskData(tempState, updateState);
        window.localStorage.setItem('searchByTitle', e.target.value);
    }, 500);

    useEffect(() => {
        if (state.searchByTitle) {
            document.getElementById('task_title_filter').value = state.searchByTitle;
        }
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Flex sx={{ mt: 20 }}>
                <Flex sx={{ ml: 30 }}>
                    <Label id="client-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                        Client
                    </Label>
                    <AsyncSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '280px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 10001,
                            }),
                        }}
                        aria-labelledby="client-label"
                        isMulti
                        onChange={async (c) => {
                            const clientSelected =
                                c.length > 1 ? c.filter((x) => x.value !== '' && x.value !== 'unassigned') : c;
                            await loadTaskData({ ...state, clientSelected }, updateState);
                            window.localStorage.setItem(
                                'clientSelected',
                                clientSelected ? JSON.stringify(clientSelected) : ''
                            );
                        }}
                        placeholder="Filter client by name"
                        value={state.clientSelected}
                        defaultOptions={[{ value: 'unassigned', label: 'Unassigned' }]}
                        loadOptions={loadClientsAsync(state, updateState)}
                    />
                </Flex>
                <Flex sx={{ ml: 30, mt: 0 }}>
                    <Label id="service-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                        Service
                    </Label>
                    <ReactSelect
                        aria-labelledby="service-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '280px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 10001,
                            }),
                        }}
                        onChange={async (c) => {
                            await loadTaskData({ ...state, serviceFilter: c }, updateState);
                            window.localStorage.setItem('serviceFilter', c ? JSON.stringify(c) : '');
                        }}
                        isMulti
                        placeholder="Select..."
                        value={state.serviceFilter}
                        options={[
                            ...(Object.entries(organisation?.workflow_definition?.services || {})?.map(
                                ([key, value]) => ({
                                    value: key,
                                    label: value.label,
                                })
                            ) || []),
                        ]}
                    />
                </Flex>
                <Flex sx={{ alignItems: 'center', ml: 30 }}>
                    <Label id="title-label" sx={{ mr: 10, width: state.showAdvancedFilter ? 90 : 70 }}>
                        Title
                    </Label>
                    <Input
                        id="task_title_filter"
                        aria-labelledby="title-label"
                        placeholder="Search by the task title"
                        sx={{ height: 40, fontSize: 14, border: '1px solid #a3a69b', width: 280 }}
                        onChange={handleTitleChange}
                    />
                </Flex>
            </Flex>

            {state.showAdvancedFilter ? (
                <Flex sx={{ mt: 0 }}>
                    <Flex sx={{ ml: 30, mt: 0 }}>
                        <Label id="partners-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                            Partners
                        </Label>
                        <ReactSelect
                            aria-labelledby="partners-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '280px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10001,
                                }),
                            }}
                            onChange={async (c) => {
                                const partners = c.length > 1 ? c.filter((x) => x.value !== '') : c;
                                await loadTaskData({ ...state, partners }, updateState);
                                window.localStorage.setItem('partners', partners ? JSON.stringify(partners) : '');
                            }}
                            placeholder="Select..."
                            isMulti
                            value={state.partners}
                            options={[
                                ...(state?.users?.map((x) => ({
                                    value: x.raw_id,
                                    label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                                })) || []),
                            ]}
                        />
                    </Flex>
                    <Flex sx={{ ml: 30 }}>
                        <Label id="reviewer-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                            Reviewers
                        </Label>
                        <ReactSelect
                            aria-labelledby="reviewer-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '280px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10001,
                                }),
                            }}
                            onChange={async (c) => {
                                const reviewers = c.length > 1 ? c.filter((x) => x.value !== '') : c;
                                await loadTaskData({ ...state, reviewers }, updateState);
                                window.localStorage.setItem('reviewers', reviewers ? JSON.stringify(reviewers) : '');
                            }}
                            placeholder="Select..."
                            isMulti
                            value={state.reviewers}
                            options={[
                                ...(state?.users?.map((x) => ({
                                    value: x.raw_id,
                                    label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                                })) || []),
                            ]}
                        />
                    </Flex>
                    <Flex sx={{ ml: 30, mt: 0 }}>
                        <Label id="preparers-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                            Preparers
                        </Label>
                        <ReactSelect
                            aria-labelledby="preparers-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '280px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10001,
                                }),
                            }}
                            onChange={async (c) => {
                                const assignedTo = c.length > 1 ? c.filter((x) => x.value !== '') : c;
                                await loadTaskData({ ...state, assignedTo }, updateState);
                                window.localStorage.setItem('assignedTo', assignedTo ? JSON.stringify(assignedTo) : '');
                            }}
                            placeholder="Select..."
                            isMulti
                            value={state.assignedTo}
                            options={[
                                { value: organisation?.user?.raw_id, label: 'Me' },
                                ...(state?.users?.map((x) => ({
                                    value: x.raw_id,
                                    label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                                })) || []),
                            ]}
                        />
                    </Flex>
                </Flex>
            ) : null}

            {state.showAdvancedFilter ? (
                <Flex>
                    <Flex sx={{ ml: 30, mt: 0 }}>
                        <Label id="statuses-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                            Statuses
                        </Label>
                        <ReactSelect
                            aria-labelledby="statuses-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '280px',
                                    minHeight: '120px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10001,
                                }),
                            }}
                            onChange={async (c) => {
                                const statuses = c.length > 1 ? c.filter((x) => x.value !== '') : c;
                                await loadTaskData({ ...state, statuses }, updateState);
                                window.localStorage.setItem('statuses', statuses ? JSON.stringify(statuses) : '');
                            }}
                            placeholder="Select..."
                            isMulti
                            value={state.statuses}
                            options={[
                                ...(state?.organisation?.default_visualisation?.statuses?.map((x) => ({
                                    value: x.key,
                                    label: x.label,
                                })) || []),
                            ]}
                        />
                    </Flex>
                    <Flex sx={{ ml: 30, mt: 0, flexDirection: 'column' }}>
                        <Flex sx={{ mt: 0 }}>
                            <Label id="priority-label" sx={{ mr: 10, mt: 20, width: 90 }}>
                                Priority
                            </Label>
                            <ReactSelect
                                aria-labelledby="priority-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '280px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 10001,
                                    }),
                                }}
                                onChange={async (c) => {
                                    const priority = c.length > 1 ? c.filter((x) => x.value !== '') : c;
                                    await loadTaskData({ ...state, priority }, updateState);
                                    window.localStorage.setItem('priority', priority ? JSON.stringify(priority) : '');
                                }}
                                isMulti
                                placeholder="Select..."
                                value={state.priority}
                                options={[
                                    ...(organisation?.default_visualisation?.priorities?.map((x, idx) => ({
                                        value: x.key,
                                        label: x.label,
                                    })) || []),
                                ]}
                            />
                        </Flex>
                        <Flex sx={{ alignItems: 'flex-start', mt: 0 }}>
                            <Label id="title-label" sx={{ mr: 10, mt: 10, width: 90 }}>
                                Statutory due date
                            </Label>
                            <OperatorDropDown
                                value={state.statutory_due_date_comparator || { value: '<', label: '<' }}
                                onChange={async (val) => {
                                    const tempState = {
                                        ...state,
                                        statutory_due_date_comparator: val,
                                    };
                                    updateState(tempState);
                                    await loadTaskData(tempState, updateState);
                                }}
                            />
                            <Input
                                id="stat-due-date"
                                placeholder="Search by the statutory due date"
                                type="date"
                                sx={{
                                    mt: 10,
                                    ml: 20,
                                    height: 40,
                                    fontSize: 14,
                                    border: '1px solid #a3a69b',
                                    width: 160,
                                }}
                                onChange={debounce(async (e) => {
                                    const tempState = {
                                        ...state,
                                        statutory_due_date: moment(e.target.value).format('YYYY-MM-DD'),
                                    };
                                    updateState(tempState);
                                    await loadTaskData(tempState, updateState);
                                }, 900)}
                            />
                        </Flex>
                    </Flex>
                    <Flex sx={{ ml: 30, mt: 0, flexDirection: 'column' }}>
                        <Flex sx={{ alignItems: 'flex-start' }}>
                            <Label id="filter-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                                Sort by
                            </Label>
                            <ReactSelect
                                aria-labelledby="filter-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '280px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 10001,
                                    }),
                                }}
                                onChange={async (c) => {
                                    const tempState = {
                                        ...state,
                                        filters: c,
                                    };

                                    updateState(tempState);
                                    await loadTaskData(tempState, updateState);

                                    window.localStorage.setItem('sorting_filters_v2', c ? JSON.stringify(c) : '');
                                }}
                                placeholder="Select..."
                                isMulti
                                value={state.filters}
                                options={[
                                    ...sortOptions,
                                    ...(
                                        Object.entries((organisation || {})?.schema || {})?.map((entry) => [
                                            {
                                                value: `${entry[0]}_asc`,
                                                label: `${entry[1]?.label} ↑`,
                                                isClientKey: true,
                                                clientDataKey: entry[0],
                                                dataType: entry[1]?.type,
                                            },
                                            {
                                                value: `${entry[0]}_desc`,
                                                label: `${entry[1]?.label} ↓`,
                                                isClientKey: true,
                                                clientDataKey: entry[0],
                                                dataType: entry[1]?.type,
                                            },
                                        ]) || []
                                    ).flat(),
                                ]}
                            />
                        </Flex>
                        <Flex sx={{ alignItems: 'flex-start', mt: 0 }}>
                            <Label id="title-label" sx={{ mr: 10, mt: 10, width: 90 }}>
                                Internal due date
                            </Label>
                            <OperatorDropDown
                                value={state.internal_due_date_comparator || { value: '<', label: '<' }}
                                onChange={async (val) => {
                                    const tempState = {
                                        ...state,
                                        internal_due_date_comparator: val,
                                    };
                                    updateState(tempState);
                                    await loadTaskData(tempState, updateState);
                                }}
                            />
                            <Input
                                id="internal-due-date"
                                placeholder="Search by the internal due date"
                                type="date"
                                sx={{
                                    mt: 10,
                                    ml: 20,
                                    height: 40,
                                    fontSize: 14,
                                    border: '1px solid #a3a69b',
                                    width: 160,
                                }}
                                onChange={debounce(async (e) => {
                                    const tempState = {
                                        ...state,
                                        internal_due_date: moment(e.target.value).format('YYYY-MM-DD'),
                                    };
                                    updateState(tempState);
                                    await loadTaskData(tempState, updateState);
                                }, 900)}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            ) : null}

            {state.showAdvancedFilter ? (
                <Flex>
                    {state.view === 'list' && (
                        <Flex sx={{ ml: 30, mt: 0 }}>
                            <Label id="columns-label" sx={{ mt: 20, mr: 10, width: 90 }}>
                                Columns
                            </Label>
                            <ReactSelect
                                aria-labelledby="columns-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '1140px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 10001,
                                    }),
                                }}
                                onChange={async (c) => {
                                    updateState({
                                        ...state,
                                        columns: c,
                                    });
                                    window.localStorage.setItem('columns', c ? JSON.stringify(c) : '');
                                }}
                                placeholder="Select..."
                                isMulti
                                value={state.columns || defaultColumnOptions}
                                options={[
                                    ...columnOptions,
                                    ...(Object.entries((organisation || {})?.schema || {})?.map((entry) => ({
                                        value: `client__${entry[0]}`,
                                        label: entry[1]?.label,
                                    })) || []),
                                ]}
                            />
                            <Button
                                onClick={() => {
                                    updateState({ ...state, columns: defaultColumnOptions });
                                    window.localStorage.setItem('columns', JSON.stringify(defaultColumnOptions));
                                }}
                                sx={{ ml: 10, height: 40, mt: 10, fontSize: 14 }}
                            >
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '12px',
                                        marginRight: '6px',
                                        color: 'text',
                                    }}
                                    className={`fas fa-sync`}
                                />
                                Reset
                            </Button>
                        </Flex>
                    )}
                </Flex>
            ) : null}
            {state.showAdvancedFilter ? (
                <Flex sx={{ flexDirection: 'row', mb: 20, ml: 30, mt: 20 }}>
                    <Label id="arc-label" sx={{ width: 90, mr: 10, mb: '5px' }}>
                        Filter on client attribute
                    </Label>
                    <ReactSelect
                        isClearable
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '300px',
                                minHeight: '42px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 10001,
                            }),
                        }}
                        onChange={async (c) => {
                            if (!state.customColumnOperator || !state.customColumnFilterValue) {
                                return updateState({
                                    ...state,
                                    customColumn: c,
                                    customColumnOperator:
                                        c?.type === 'dropdown'
                                            ? { value: '=', label: '=' }
                                            : state.customColumnOperator,
                                });
                            }

                            await loadTaskData(
                                {
                                    ...state,
                                    customColumn: c,
                                    customColumnOperator:
                                        c?.type === 'dropdown'
                                            ? { value: '=', label: '=' }
                                            : state.customColumnOperator,
                                },
                                updateState
                            );
                        }}
                        placeholder="Select the custom column"
                        value={state.customColumn}
                        options={
                            Object.entries(state.organisation?.schema || {})?.map((x) => ({
                                value: x[0],
                                label: x[1]?.label,
                                type: x[1]?.type,
                                dropdownOptions: x[1]?.dropdownOptions,
                            })) || []
                        }
                    />
                    {state.customColumn ? (
                        <Flex sx={{ width: 100, ml: 10 }}>
                            <ReactSelect
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '90px',
                                        minHeight: '42px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 10001,
                                    }),
                                }}
                                onChange={async (c) => {
                                    if (!state.customColumn || !state.customColumnFilterValue) {
                                        return updateState({
                                            ...state,
                                            customColumnOperator: c,
                                        });
                                    }

                                    await loadTaskData(
                                        {
                                            ...state,
                                            customColumnOperator: c,
                                        },
                                        updateState
                                    );
                                }}
                                placeholder="="
                                value={state.customColumnOperator}
                                defaultValue={{
                                    value: '=',
                                    label: '=',
                                }}
                                options={[
                                    {
                                        value: '<=',
                                        label: '<=',
                                    },
                                    {
                                        value: '<',
                                        label: '<',
                                    },
                                    {
                                        value: '=',
                                        label: '=',
                                    },
                                    {
                                        value: 'ilike',
                                        label: 'LIKE',
                                    },
                                    {
                                        value: '>',
                                        label: '>',
                                    },
                                    {
                                        value: '>=',
                                        label: '>=',
                                    },
                                ]}
                            />
                        </Flex>
                    ) : null}

                    {state.customColumn && ['date', 'string'].includes(state.customColumn?.type) ? (
                        <Input
                            onChange={debounce(async (e) => {
                                if (!state.customColumn || !state.customColumnOperator) {
                                    return updateState({
                                        ...state,
                                        customColumnFilterValue: e.target.value,
                                    });
                                }

                                await loadTaskData(
                                    {
                                        ...state,
                                        customColumnFilterValue: e.target.value,
                                    },
                                    updateState
                                );
                            }, 400)}
                            type={state.customColumn?.type === 'string' ? 'text' : state.customColumn?.type}
                            placeholder="Filter value"
                            sx={{ height: 40, width: 200 }}
                        />
                    ) : null}

                    {state.customColumn && ['dropdown'].includes(state.customColumn?.type) ? (
                        <ReactSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '250px',
                                    minHeight: '42px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10001,
                                }),
                            }}
                            onChange={async (c) => {
                                if (!state.customColumn || !state.customColumnOperator) {
                                    return updateState({
                                        ...state,
                                        customColumnFilterValue: c.value,
                                    });
                                }

                                await loadTaskData(
                                    {
                                        ...state,
                                        customColumnFilterValue: c.value,
                                    },
                                    updateState
                                );
                            }}
                            placeholder="Select option"
                            options={state.customColumn?.dropdownOptions?.map((x) => ({
                                value: x.id,
                                label: x.label,
                            }))}
                        />
                    ) : null}
                </Flex>
            ) : null}
            <Flex
                onClick={() => {
                    window.localStorage.setItem('show_advanced_filters', state.showAdvancedFilter ? 'false' : 'true');
                    updateState({ ...state, showAdvancedFilter: state.showAdvancedFilter ? false : true });
                }}
                sx={{ ml: 30, mt: state.showAdvancedFilter ? 0 : 20, alignItems: 'center', cursor: 'pointer' }}
            >
                <Paragraph sx={{ color: 'primary' }}>
                    {state.showAdvancedFilter ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                </Paragraph>
                <i
                    style={{
                        top: '0px',
                        marginLeft: 10,
                        cursor: 'pointer',
                        color: theme.colors.primary,
                    }}
                    className={`fas fa-chevron-${state.showAdvancedFilter ? 'up' : 'right'}`}
                />
            </Flex>
        </Flex>
    );
};

export default Filters;
