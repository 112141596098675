import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';

function ProgressIndicator({ percentage, sx }) {
    const [percentageLocal, setPercentage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercentage((prev) => {
                if (prev >= percentage && interval) {
                    clearInterval(interval);
                }
                return prev >= percentage ? percentage : prev + 5;
            });
        }, 100);

        return () => clearInterval(interval);
    }, [percentage]);

    return (
        <Box
            sx={{
                backgroundColor: '#e0e0e0',
                borderRadius: '3px',
                height: '20px',
                overflow: 'hidden',
                ...(sx || {}),
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    background: 'radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%)',
                    transition: 'width 0.3s',
                    width: `${percentageLocal}%`,
                }}
            />
        </Box>
    );
}

export default ProgressIndicator;
