import { Paragraph, Flex, Button } from 'theme-ui';

type Props = { progress: { message: string; setup: boolean }; overlay: any; updateOverlay: (updateParams: any) => any };

const UnverifiedBlock = (props: Props) => {
    return (
        <Flex
            sx={{
                height: '400px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 5,
                '@media screen and (max-width: 550px)': { width: '300px' },
            }}
        >
            <Paragraph sx={{ my: 4, textAlign: 'center' }}>{props.progress.message}</Paragraph>
            <Button
                sx={{
                    mt: '45px',
                    width: '240px',
                }}
                variant="primary"
                onClick={() => {
                    props.updateOverlay({
                        ...props.overlay,
                        show: false,
                        componentToRender: props.overlay.initialComponentToRender,
                    });
                }}
            >
                Close
            </Button>
        </Flex>
    );
};

export default UnverifiedBlock;
