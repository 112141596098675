/** @jsxImportSource theme-ui */
import { Box, Flex, Image, Paragraph } from 'theme-ui';
import { useEffect, useState } from 'react';
import { createImage } from '../../utils/openai-helper';
import Input from '../form-input';
import Button from '../button';
import Spinner from '../spinner';

const defaultState = {
    prompt: '',
    loading: false,
};

const Title = ({ text, subtitle }: any) => {
    return (
        <Box>
            <Paragraph sx={{ fontSize: '18px', fontWeight: '600', color: 'primary' }}>{text}</Paragraph>
            {subtitle && (
                <Paragraph sx={{ fontSize: '13px', fontWeight: '400', color: 'primary' }}>{subtitle}</Paragraph>
            )}
        </Box>
    );
};

const generateImage = async (state: any, updateState: any, setImgUrl: any) => {
    const { prompt } = state;
    if (prompt && prompt !== '') {
        updateState({ ...state, loading: true });
        setImgUrl(null);
        const response = await createImage(prompt);
        setImgUrl(response?.data?.data[0]?.url);
        updateState({ ...state, loading: false, prompt: '' });
    }
};

const ImageAi = (props: any) => {
    const [imgUrl, setImgUrl] = useState(null);
    const [state, updateState] = useState(defaultState);

    return (
        <Flex
            sx={{
                mt: '30px',
                width: '400px',
                minHeight: '250px',
                border: '0px solid lightgrey',
                background: 'transparent',
                borderRadius: '12px',
            }}
        >
            {state?.loading && <Spinner />}
            <Flex
                sx={{
                    flexDirection: 'column',
                    width: '400px',
                    margin: '0 auto',
                    justifyContent: 'center',
                }}
            >
                <Title text={`Description`} subtitle="Short description of the image you wish to generate" />
                <Input
                    sx={{
                        mt: '25px',
                        mb: '25px',
                        alignSelf: 'center',
                        width: '400px',
                        background: '#FFFFFF',
                    }}
                    type="text"
                    id="prompt"
                    data-testid="prompt"
                    name="prompt"
                    label=""
                    placeholder="Enter a description"
                    onChange={(e: any) => updateState({ ...state, prompt: e.target.value })}
                    error={null}
                />
                <Flex sx={{ width: '400px', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        variant="primary"
                        sx={{ width: '180px', backgroundColor: 'white', border: '1px solid primary', color: 'primary' }}
                        data-testid="submit"
                        onClick={() => {
                            updateState({ ...state, loading: true });
                            window.location.reload();
                        }}
                    >
                        Reset
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '13px',
                                marginLeft: '9px',
                            }}
                            className={`fas fa-times`}
                        />
                    </Button>
                    <Button
                        variant="primary"
                        sx={{ width: '180px' }}
                        data-testid="submit"
                        onClick={() => generateImage(state, updateState, setImgUrl)}
                    >
                        Submit
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '13px',
                                marginLeft: '9px',
                            }}
                            className={`fas fa-chevron-right`}
                        />
                    </Button>
                </Flex>
                {imgUrl && <Image sx={{ width: '350px', padding: '20px', margin: '0 auto' }} src={imgUrl} />}
            </Flex>
        </Flex>
    );
};

export default ImageAi;
